import { useDispatch, useSelector } from "react-redux"
import { orderBy } from "lodash"
import { useNavigate } from "react-router-dom"
import { actions } from 'store'
import { alerts, dataGridModel } from "keys"
import { AlertCrud } from "components/Alerts/hooks"
import { app } from "components/Router/routes"
import api from "keys/apis"
import { useQueryClient } from "react-query"
import { queryClient as qc } from "keys"
import { useCallback } from "react"

const TestTakersCrud = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { setAlerts } = AlertCrud()
    const { selectedTestTakers } = useSelector(state => state.testTaker)
    const { controllers } = useSelector(state => state.general)
    const queryClient = useQueryClient()

    const getTestTakers = async () => {
        try {
            const testTakers = await dispatch(actions.getWithLastAssessment())
            dispatch(actions.setTestTakerWithLastAssessment(testTakers.map((item, index) => ({ index, ...item }))))
        }
        catch {
            console.log("error getTestTakers")
        }
    }

    const cleanData = () => {
        abortApis()
        dispatch(actions.setTestTakerWithLastAssessment(null))
    }

    const sortLastResults = (sortModel) => {
        const { sort } = sortModel

        queryClient.setQueryData([qc.getWithLastAssessments], oldData => {
            return {
                ...oldData,
                data: orderBy(oldData.data,
                    [function (assessment) {
                        let sortBy
                        const
                            { last_assessment } = assessment || {},
                            { rating } = last_assessment || {}
                        const
                            { percent_accuracy_rating_index, fluency_rating_index } = rating || {}
                        sortBy = percent_accuracy_rating_index > fluency_rating_index ? "percent_accuracy_rating_index" : "fluency_rating_index"
                        return assessment?.last_assessment?.rating[sortBy]
                    }], [sort])
            }
        });
    }

    const abortApis = () => {
        controllers[api.get_with_last_assessment]()
    }

    const updateMasterDetails = (val) => {
        dispatch(actions.setVisibilityModelByKeys({
            key: [dataGridModel.last_assessments],
            value: {
                affiliation_name: val,
                grade_name: val,
                t_class_name: val
            }
        }))
    }

    const goAddChild = useCallback(() => {
        setAlerts(alerts.createTestTaker, true)
    }, [])

    const addNewGrade = useCallback(() => {
        setAlerts(alerts.createGrade, true)
    }, [])

    const addNewClass = useCallback(() => {
        setAlerts(alerts.createClass, true)
    }, [])

    const goAssessments = () => {
        navigate(app.assessments)
    }

    const sortDates = (sortModel, sortKey) => {
        const { sort } = sortModel

        queryClient.setQueryData([qc.getWithLastAssessments], oldData => {
            return {
                ...oldData,
                data: orderBy(oldData.data,
                    [function (testTaker) {
                        const
                            { last_assessment = {} } = testTaker
                        return last_assessment?.[sortKey]
                    }], [sort])
            }
        });
    }

    const goBatteryAllocation = () => {
        const testTakerWithLastAssessment = queryClient.getQueryData(qc.getWithLastAssessments)?.data

        if (selectedTestTakers.length === 1) {
            const testTaker = testTakerWithLastAssessment.find((assessment) => assessment.id === selectedTestTakers[0])
            dispatch(actions.setTestTaker(testTaker))
        }
        else {
            dispatch(actions.setTestTaker({}))
        }
        navigate(`${app.createAssessments}`)
    }

    const setRowSelectionModel = (newRowSelectionModel) => {
        dispatch(actions.setSelectedTestTakers(newRowSelectionModel))
    }

    const geCreateRecordAssessment = (item) => {
        dispatch(actions.setTestTaker({ test_taker_id: item.test_taker_id }))
        navigate(`${app.createAssessmentRecord}/${item.test_taker_id}`)
    }

    const createUploadAssessment = (item) => {
        dispatch(actions.setTestTaker({ test_taker_id: item.test_taker_id }))
        navigate(`${app.uploadAudioFile}/${item.test_taker_id}`)
    }

    return {
        sortDates,
        getTestTakers,
        cleanData,
        goBatteryAllocation,
        setRowSelectionModel,
        updateMasterDetails,
        goAssessments,
        createUploadAssessment,
        geCreateRecordAssessment,
        sortLastResults,
        addNewGrade,
        addNewClass,
        goAddChild
    }
}

export default TestTakersCrud