import {
  getAssessments,
  getAssessmentDefs,
  getProgressIndication,
  createAssessment,
  getDefinitionTypes,
  getAssessmentReport,
  uploadAudioFile,
  allocationBattery,
  updateAssessmentStatus,
  printAssessment,
  getAdminAssessments
} from './assessment.crud'
import {
  getRating,
  getScoreRatingMethod,
  getMatchingLearning,
  getScoreRatingMethods,
} from './rating.crud'
import {
  getStaticWords,
  saveWord,
  getWords,
  getAudioFile,
  saveReadingAssessment,
  getResults,
  getWordDefs,
  saveWords
} from './words.crud'
import {
  // login,
  // initialFirebase,
  // logout
} from './auth.crud'
import {
  getWordsAndRegions,
  saveRegions
} from './region.crud'
import {
  createAffiliation,
  getAffiliationGroups,
  getMethods,
  getAffiliations
} from './affiliation.crud'
import {
  createTestTaker,
  getGrades,
  getTestTakersOptions,
  getAllTestTakers,
  getWithLastAssessment,
  getTClasses,
  getTestTaker
} from './testTakers.crud'

const appMiddleware = [
  getTClasses,
  getScoreRatingMethods,
  getTestTakersOptions,
  getAllTestTakers,
  getTestTaker,
  getDefinitionTypes,
  createAssessment,
  getAssessmentDefs,
  printAssessment,
  createTestTaker,
  getGrades,
  allocationBattery,
  getResults,
  updateAssessmentStatus,
  getProgressIndication,
  getRating,
  getScoreRatingMethod,
  createAffiliation,
  uploadAudioFile,
  getAffiliations,
  getMatchingLearning,
  getMethods,
  getAffiliationGroups,
  getAssessmentReport,
  getAssessments,
  getStaticWords,
  getWordsAndRegions,
  saveWord,
  saveWords,
  getAdminAssessments,
  getWords,
  // login,
  getWordDefs,
  // initialFirebase,
  // logout,
  getAudioFile,
  saveReadingAssessment,
  saveRegions,
  getWithLastAssessment
]

export default appMiddleware
