// import { DefaultTheme } from 'react-native-paper';

/**
 * This file contains the application's variables.
 *
 * Define color, sizes, etc. here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

// import { ThemeNavigationColors } from 'styles/theme';

/**
 * Colors
 */
export const Colors = {
  transparent: 'rgba(0,0,0,0)',
  inputBackground: '#FFFFFF',
  white: '#ffffff',
  //Typography
  textGray800: '#000000',
  textGray400: '#4D4D4D',
  textGray200: '#A1A1A1',
  primary: '#556ee6',
  lightPrimary: '#d5dbf9',
  success: "#03C03C",
  warning: '#FFBF00',
  error: '#dc3545',
  red: "#ff3a30",
  //ComponentColors
  circleButtonBackground: '#E1E1EF',
  circleButtonColor: '#44427D',
  light: '#dce0e6',
  dark: '#2a3143',
  background: '#EFEFEF',
  secondary: "#34c38f",
  card: '#EFEFEF',
  lightBg: '#f8f6f6'
};

// export const NavigationColors: Partial<ThemeNavigationColors> = {
//   primary: Colors.primary,
//   background: '#EFEFEF',
//   card: '#EFEFEF',
// };

export const paperTheme = {
//   ...DefaultTheme,
  roundness: 2,
  colors: {
    // ...DefaultTheme.colors,
    primary: '#556ee6',
    accent: '#f1c40f',
    danger: '#f46a6a',
    primaryContainer: '#9cabeb',
    onPrimaryContainer: '#556ee6',
    elevation: {
      level1: '#e8eaf0',
      level2: '#e4e6ef',
      level3: '#e0e3f0',
      level4: '#dee1f0',
      level5: '#dadeef'
    },
    surfaceDisabled: '#e0e3f0'

  },
};

/**
 * FontSize
 */
export const FontSize = {
  tiny: 14,
  small: 16,
  regular: 20,
  large: 40,
};

/**
 * Metrics Sizes
 */
const xxs = 3;
const xs = 5;
const tiny = 10;
const small = tiny * 2; // 20
const regular = tiny * 3; // 30
const large = regular * 2; // 60
export const MetricsSizes = {
  xxs,
  xs,
  tiny,
  small,
  regular,
  large,
};

export default {
  Colors,
  // NavigationColors,
  FontSize,
  MetricsSizes,
};