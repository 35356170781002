import styled, { css } from "styled-components";
import { done, primary } from "styles/theme";

const headercss = css`
grid-template-columns: 120px 120px 120px 100px auto 130px 130px;
`


const WrapStatus = styled.div`
border:${props => props.border && `1px solid ${props.border}`};
padding:4px;
padding-left:8px;
padding-right:8px;
border-radius:8px;
`

export { headercss, WrapStatus }