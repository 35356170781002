import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { WrapAssessmentDefs, WrapCreateSet, WrapContent } from './Style'
import { AssessmentDefs, Page } from 'components'
import { CustomSection, WrapAll } from 'styles/GlobalStyle'
import { CreateSet } from 'components/School/CreateAssessments'
import CreateAssessmentsCrud from './hooks/CreateAssessments.crud'

const CreateAssessments = () => {
    const { assessmentDefs } = useSelector(state => state.assessment)

    const { init, cleanData } = CreateAssessmentsCrud()

    useEffect(() => {
        if (!assessmentDefs)
            init()

        return (() => {
            cleanData()
        })
    }, [])

    return (
        <Page>
            <WrapAll>
                <WrapContent>
                    <WrapAssessmentDefs>
                        <AssessmentDefs selectAssessment multiple onSelectAssessment={() => { }} />
                    </WrapAssessmentDefs>
                    <WrapCreateSet>
                        <CustomSection $bg>
                            <CreateSet />
                        </CustomSection>
                    </WrapCreateSet>
                </WrapContent>
            </WrapAll>
        </Page>
    )
}

export default CreateAssessments