import React from 'react'
import { Label, Mandatory, WrapLabel } from './Styled'

const CustomLabel = (props) => {
    const { label, fontSize, mandatory, className } = props

    return (
        <WrapLabel fontSize={fontSize} className={className}>
            <Label>{label}
                {mandatory && <Mandatory> *</Mandatory>}
            </Label>
        </WrapLabel>
    )
}

export default CustomLabel