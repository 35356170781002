import React from 'react'
import { WrapRecordAssessment } from './Style'
import { WrapField, WrapFieldContent, WrapUploadFileButton } from 'components/Form/Styled'
import { Label } from 'components/Form'
import { CreateAssessmentFormCrud } from './hooks'

const RecordAssessment = (props) => {
    const { section, className, label, pickerLabel, mandatory, input = {} } = props
    const { goAssessmentDefs } = CreateAssessmentFormCrud()

    return (
        <WrapRecordAssessment>
            <WrapField className={className} section={section}>
                <WrapFieldContent>
                    <Label label={label} mandatory={mandatory} />
                    <WrapUploadFileButton
                        color="primary"
                        component="label"
                        role={undefined}
                        variant="text"
                        tabIndex={-1}
                        onClick={goAssessmentDefs}
                    >
                        {pickerLabel}
                    </WrapUploadFileButton>
                </WrapFieldContent>
            </WrapField>
        </WrapRecordAssessment>
    )
}

export default RecordAssessment