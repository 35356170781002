import React from "react";
import { WrapTitle } from "./Style";

const PageTitle = React.forwardRef((props, ref) => {
    const { children: title } = props

    return (
        <WrapTitle>
            {title.map((titleWord,index) =>
                <div key={index}>
                    {titleWord.word_def_text}
                </div>
            )}
        </WrapTitle>
    )
})
export default PageTitle