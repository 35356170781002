import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { Label } from 'components/Form'
import { WrapField, WrapFieldContent } from './Styled';

const RadioList = (props) => {
    const { row, label, name, input, options, disabled, section, className, placeholder, multiple, mandatory } = props,
        { onChange, value } = input;

    const handlePlayerToggle = ({ target }) => {
        if (multiple) {
            let nChecked = { ...value }
            const nValue = target.value
            if (nChecked[nValue])
                delete nChecked[nValue]
            else nChecked[target.value] = target.value
            onChange(nChecked)
        }
        else onChange(target.value)
    }

    return (
        <WrapField section={section}>
            <WrapFieldContent>
                {label && <Label label={label} mandatory={mandatory} />}
                <RadioGroup
                    name={name}
                    multiple={multiple}
                    className={className}
                    row={row}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={null}
                    value={value}
                >
                    {options.map(opt => {
                        const { id, label } = opt
                        return (
                            <FormControlLabel
                                key={id}
                                onClick={handlePlayerToggle}
                                value={id}
                                control={<Radio />}
                                label={label}
                                checked={multiple ? value[id] : value}
                            />
                        )
                    }
                    )}
                </RadioGroup>
            </WrapFieldContent>
        </WrapField>
    );
}

export default RadioList
