import { Button } from "components/Form";
import { StatusCell } from ".."
import { ArrowBack, InfoOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { app } from "components/Router/routes";
import { types } from "keys";
import { Tooltip } from "@mui/material";
import { useTheme } from "styles";

const useFields = () => {
    const navigate = useNavigate()
    const { Layout } = useTheme()

    const fields = [
        {
            field: 'id',
            headerName: "ID",
            width: 150,
            headerAlign: 'start',
            align: 'start',
        },
        {
            field: 'test_taker',
            headerName: "שם התלמידה",
            width: 150,
            headerAlign: 'start',
            align: 'start',
        },
        {
            field: 'status',
            // headerName: "התאמה מלאה",
            width: 170,
            headerAlign: 'start',
            align: 'start',
            renderCell: StatusCell,
            renderHeader: (params) => (
                <div style={{ ...Layout.rowHCenter ,...Layout.scGap}}>
                    <span role="img" aria-label="enjoy">
                        התאמה מלאה
                    </span>
                    <Tooltip title="עמודה זו מציגה מידע בהתאם להתאמה מלאה בין האודיו לטקסט המצופה. בחישוב הקריאות מביאים בחשבון גם את סוגי שגיאות ובהתאם לכך נקבעת רמת הקריאות של המילה">
                        <InfoOutlined fontSize="small" />
                    </Tooltip>
                </div>
            ),
            valueGetter: ({ row }) => row.status === types.correct ? false : true
        },
        {
            field: 'minor_error',
            headerName: "שגיאה קלה",
            width: 150,
            headerAlign: 'start',
            align: 'start',
            hideCorrect: true,
            renderCell: StatusCell,
        },
        {
            field: 'self_correction',
            headerName: "תיקון עצמי",
            width: 150,
            headerAlign: 'start',
            align: 'start',
            hideCorrect: true,
            renderCell: StatusCell,
        },
        {
            field: 'reports',
            type: 'actions',
            headerName: "צפיה במבדק",
            width: 110,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                let { reading_assessment } = row
                return [
                    <Button
                        className="action_btn"
                        direction="ltr"
                        icon={<ArrowBack fontSize='small' color={'white'} />}
                        label="לצפיה"
                        action={() => {
                            navigate(`${app.assessmentView}/${reading_assessment}`)
                        }}
                        color="secondary"
                        disabledColor="secondary"
                    />
                ];
            }
        }
    ]

    return fields
}

export default useFields