import { GradeRM } from "models/RM"
import { AlertCrud } from "components/Alerts/hooks"
import { useDispatch } from "react-redux"
import { alerts, queryClient as qc } from "keys"
import { reset } from "redux-form"
import { useMutation } from "react-query"
import { createGradeFetch } from "store/middlewares/testTakers.crud"
import { toast } from "react-toastify"
import { toastConfig } from "config"
import { pulldowns, testTakerRQ } from "store/hooks"

const useCreateGrade = () => {
    const formName = "createGrade"
    const dispatch = useDispatch()
    const { setAlerts } = AlertCrud()
    // const { refetch: refetchGrades } = testTakerRQ.useGetGrades()

    const { mutateAsync: createGradeRQ } = useMutation({
        mutationFn: createGradeFetch,
        onSuccess: async () => {
            setAlerts(alerts.createGrade, false)
            toast(" השכבה נוצרה בהצלחה , התנתק והתחבר מחדש", toastConfig)
            dispatch(reset(formName))
            // const res = await refetchGrades()
            // if (res.data)
            //     pulldowns.updatePulldownStorage("grades", res.data.data)
        }
    })

    const createGrade = async (values) => {
        const gradeRM = new GradeRM(values)
        await createGradeRQ(gradeRM)
    }

    return createGrade
}

export default useCreateGrade