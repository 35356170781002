import styled from "styled-components";

const WrapLevels = styled.div`
display: flex;
row-gap: 8px;
flex-direction: column;
color: white;
width:100%
`

const Title = styled.div`
color:black;
font-size:18px;
`

const Level = styled.div`
width:100%;
box-sizing:border-box;
background:${props => props.color};
border-radius: 2px;
padding:5px;
font-weight:400;
font-size:15px;
`


export { Title, WrapLevels, Level }