import { correct, substitution } from "keys/types";
import { map } from "lodash";
import { keysSourceChanges } from "mock/sourceChanges";


class Phoneme {

    constructor(nPhoneme, nStaticPhoneme) {
        const { error_in_speech = false, phoneme_def_letter_description, letter_add_on, letter_add_on2, letter_description, phoneme_def_vowel_code, letter_add_ons, phoneme_def_letter_add_ons, phoneme_def_letter_add_on2, phoneme_def_letter_add_on, phoneme_def_letter, phoneme_def_index, letter, phoneme_def_id, vowel_status, letter_add_on_status, letter_status, id, phoneme, type, index, vowel_code } = nPhoneme


        if (phoneme_def_id || letter_status === keysSourceChanges.insertion) {
            this.id = id
        }
        this.index = index;//I dont know index
        this.phoneme_def_index = phoneme_def_index// || index
        this.phoneme_def = phoneme_def_id || id
        this.phoneme_def_id = phoneme_def_id
        this.phoneme_def_letter_add_ons = phoneme_def_letter_add_ons

        this.vowel_code = Number(vowel_code)
        this.error_in_speech = error_in_speech;
        this.letter = letter === '?' ? null : letter

        if (letter != phoneme_def_letter) {
            this.substitution_letter = letter
            this.letter = letter || phoneme_def_letter
        }
        this.letter_description = letter_description//CHECK

        this.vowel_status = vowel_status || correct;
        this.letter_status = letter_status || correct;
        this.letter_add_on_status = letter_add_on_status || correct;

        this.phoneme_def_letter = phoneme_def_letter //|| letter

        this.phoneme_def_vowel_code = Number(phoneme_def_vowel_code)

        if (phoneme_def_letter_description) {
            this.phoneme_def_letter_description = Number(phoneme_def_letter_description)
        }

        let add_ons = letter_add_ons || []
        const mapAddOn = map(letter_add_ons, "letter_add_on")

        phoneme_def_letter_add_ons?.forEach(add_on => {
            if (!mapAddOn.includes(add_on.letter_add_on)) {
                add_ons.push(add_on)
            }
        });

        this.letter_add_ons = add_ons

        // let nLetter_add_on = nStaticPhoneme?.letter_add_on || letter_add_on
        // let nLetter_add_on2 = nStaticPhoneme?.letter_add_on2 || letter_add_on2

        // this.letter_add_on = nLetter_add_on
        // this.letter_add_on2 = nLetter_add_on2
    }
}

export default Phoneme