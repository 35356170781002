import styled from 'styled-components'
import { Center } from 'styles/GlobalCss'


const WrapMessage = styled.div`
display:flex;
column-gap:20px;
${Center};
`

export { WrapMessage }