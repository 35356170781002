import { Card } from 'components/Form/Styled'
import styled from 'styled-components'
import { CustomSection, WrapImg } from 'styles/GlobalStyle'
import { bg, black } from 'styles/theme'

const radius = 25

const WrapDashboard = styled.div`
display: flex;
justify-content:center;
overflow:hidden;
height: 100%;
align-items: center;
`

const Dashboard = styled.div`
padding:15px;
display: flex;
flex-direction: column;
row-gap: 15px;
width:95%;
`

const WrapHeader = styled.div`
display: flex;
column-gap: 10px;
justify-content:space-between;
`

const WrapContent = styled.div`
display: flex;
justify-content: space-between;
`

const WrapTitle = styled.div`
width:140px;
`

const Title = styled.div`
color:${black};
font-size:20px;
`

const WrapVideo = styled.div`
display: flex;
`

const WrapActionButton = styled.div`
width:70%;
`

const WrapCartoon = styled(WrapImg)`
position: relative;
right: 112px;
`

export { WrapActionButton, WrapCartoon, WrapVideo, Dashboard, WrapDashboard, WrapHeader, WrapContent, WrapTitle, Title }