import React from 'react'
import { Navigate, useLocation } from "react-router-dom";
import { secretStorage } from 'config';
import { app } from './routes';

const PrivateRoutesAuth = (props) => {
    const token = secretStorage.getItem("user")?.token;
    const isAuth = !!token; // Assuming getToken() returns truthy value when token is present

    const location = useLocation()

    const from = location.state?.from || "/";

    if (isAuth)
        return <Navigate to={from.pathname || app.app} replace />

    return props.children
}

export default PrivateRoutesAuth