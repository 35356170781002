
const constants = {
    title: "בחרו שם משתמש וסיסמא"
}

const useFields = () => {
    const fields = [
        {
            name: "username",
            component: "input",
            placeholder: "שם משתמש",
            className: "_username"
        },
        {
            name: "password",
            component: "input",
            placeholder: "סיסמא",
            className: "_password",
            type: "password"
        }
    ]


    return fields
}


const useNext = () => {

    const continueBtn = {
        label: "המשך",
        systemUI: true,
        action: () => { },
        color: "thirdly",
        className: "_next",
    }

    return continueBtn
}




export default { useFields, useNext, constants }