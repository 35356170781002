import React from 'react';
import { WrapDragIcon, AssessmentName, WrapSectionList, WrapDraggable, WrapAssessmentName } from './Style'
import { useSelector } from 'react-redux';
import { UploadAudioCrud } from './hooks';
import { CustomSection, Space } from 'styles/GlobalStyle';
import { UploadFile } from 'components/Form';
import { Divider } from '@mui/material';

const UploadItem = (props) => {
    const { disabled, item } = props

    const { selectedAssessmentDef } = useSelector(state => state.assessment)

    const { updateSelectedAssessmentDef } = UploadAudioCrud()

    const { name } = item || {}

    return (
        <>
            <CustomSection $disabled={disabled} $noShadow>
                <WrapAssessmentName>
                    <WrapDragIcon />
                    <AssessmentName>
                        {name}
                    </AssessmentName>
                </WrapAssessmentName>
                <Space />
                <div style={{ height: "60px" }}>
                    <UploadFile
                        input={{
                            value: item.audio_file,
                            onChange: updateSelectedAssessmentDef
                        }}
                    />
                </div>
            </CustomSection>
        </>
    );
}

export default UploadItem;
