

class AssessmentDef {

    constructor(assessmentDef) {
        const { num_words, title, affiliation_name, author, readability_score_name, name, id, type_name, lexile_level } = assessmentDef

        this.affiliation_name = affiliation_name
        this.name = name
        this.id = id
        this.type_name = type_name
        this.lexile_level = lexile_level
        this.readability_score_name = readability_score_name
        this.author = author
        this.num_words = num_words
        this.title = title
    }
}

export default AssessmentDef