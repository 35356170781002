import { detailed_decoding, fluency_and_accuracy, indications_for_difficulties, matching_learning } from "keys/progress"
import { isBoolean } from "lodash"
import { useSelector } from "react-redux"
import { HourglassBottom, DirectionsRun, Done } from '@mui/icons-material';
import { CreateAssessmentFormCrud } from "components/School/CreateAssessment/CreateAssessmentForm/hooks";

const ProgressData = () => {
    const { goRating } = CreateAssessmentFormCrud()
    const { progressIndication, isRunProgress } = useSelector(state => state.assessment)

    const isDisabled = (type) => !isBoolean(progressIndication?.[type])

    const icon = {
        disabled: <HourglassBottom color="ligthGray" />,
        inProgress: <DirectionsRun color="ligthGray" />,
        completed: <Done color="white" />
    }

    const getIcon = (item) => {
        if (!item || item.disabled)
            return icon.disabled
        else if (item.completed)
            return icon.completed
        else if (item.start)
            return icon.inProgress
        else return icon.disabled
    }

    const progressList = [
        {
            id: 0,
            label: "שטף ודיוק מילים",
            progress: fluency_and_accuracy,
            disabled: isDisabled(fluency_and_accuracy)
        },
        {
            id: 1,
            label: "פענוח מפורט",
            progress: detailed_decoding,
            disabled: isDisabled(detailed_decoding)
        },
        {
            id: 2,
            label: "אינדקציות לקשיים",
            progress: indications_for_difficulties,
            disabled: isDisabled(indications_for_difficulties)
        },
        {
            id: 3,
            label: "התאמת למידה",
            progress: matching_learning,
            disabled: isDisabled(matching_learning),
            disabledColor: "gray2"
        }
    ]

    const submitBtn = {
        label: "דוחות",
        color: "primary",
        disabledColor: "primary",
        action: goRating,
        disabled: isRunProgress || !progressIndication?.[fluency_and_accuracy]?.completed
    }


    return {
        progressList,
        submitBtn,
        getIcon
    }
}

export default ProgressData