import React from 'react'
import { CopyAll } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useTheme } from 'styles'

const CouponToast = ({ code, name }) => {

    const { Layout } = useTheme()

    return (
        <div >
            <div>
                <div>
                    שם הקופון:
                    {" "}
                    {name}
                </div>
                <div style={{ ...Layout.flex, ...Layout.alignItemsCenter }}>
                    <div>
                        הקוד הוא:
                        {" "}
                        {code}
                    </div>
                    <IconButton onClick={() => navigator.clipboard.writeText(code)}>
                        <CopyAll />
                    </IconButton>
                </div>
            </div>



        </div>
    )
}

export default CouponToast