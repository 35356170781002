
const detailed_decoding = "detailed_decoding"
const fluency_and_accuracy = "fluency_and_accuracy"
const indications_for_difficulties = "indications_for_difficulties"
const matching_learning = "matching_learning"

const p = {
    detailed_decoding,
    fluency_and_accuracy,
    indications_for_difficulties,
    matching_learning
}

export { detailed_decoding, fluency_and_accuracy, indications_for_difficulties, matching_learning }
export default p