import React, { useEffect } from 'react'
import { Alert } from 'components'
import { Form } from 'components/Form'
import { alerts as alertKeys } from 'keys'
import { AlertCrud } from 'components/Alerts/hooks'
import { useSelector } from 'react-redux'
import { initialize, reset } from 'redux-form'
import { useDispatch } from 'react-redux'
import { validate } from 'components/Coupon'
import { couponHooks } from '.'

const CreateCouponPopup = () => {
    const { alerts } = useSelector(state => state.general)

    const dispatch = useDispatch()

    const { setAlerts } = AlertCrud()

    const couponFields = couponHooks.useCouponFields()
    const submitBtns = couponHooks.useSubmitBtns()
    const { formname } = couponHooks.constants

    useEffect(() => {
        return (() => {
            dispatch(reset(formname))
            dispatch(initialize(formname, {}));
        })
    }, [])


    return (
        <Alert
            systemUI
            title={`יצירת קופון חדש`}
            footerActions={submitBtns}
            handleAlert={() => setAlerts(alertKeys.createCoupon, false)}
        >
            <div>
                <Form
                    form={formname}
                    validations={validate}
                    fields={couponFields}
                    submit={() => { }}
                    initialValues={alerts[alertKeys.createTestTaker]?.props}
                />
            </div>
        </Alert>
    )
}

export default CreateCouponPopup