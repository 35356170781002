import React from 'react'
import { AdminAssessments } from 'components'
import { isEqual } from 'lodash'
import { dataGridModel, statuses } from 'keys'
import { useDispatch } from 'react-redux'
import markingWordsHooks from './hooks'
import { actions } from 'store'

const MakingWordsAssessments = () => {
    const dispatch = useDispatch()
    const fields = markingWordsHooks.useFields()
    const tabs = markingWordsHooks.useTabs()

    const updateVisibilityModel = ({ status, sub_status }) => {
        const markingWordsComleted = isEqual(sub_status, statuses.wordsComplete)
        const markingWordsInProgress = isEqual(sub_status, statuses.wordsInProgress)
        const isBrokenFile = status.includes(statuses.brokenFile)
        const markingWordsIsWating = status.includes(statuses.uploaded)

        //hide and show columns in the DataGrid table
        dispatch(actions.setVisibilityModelByKeys({
            key: [dataGridModel.admin_assessments],
            value: {
                marking_words_update_by_username: markingWordsComleted || markingWordsInProgress || isBrokenFile,
                marking_words_duration: markingWordsComleted,
                marking_words_start_date: markingWordsComleted || markingWordsInProgress,
                marking_words_end_date: markingWordsComleted,
                back_to_marking: markingWordsComleted || isBrokenFile,
                update_date: markingWordsIsWating,
                enter_marking_words: markingWordsIsWating,
                view_marking_words: markingWordsComleted || isBrokenFile || markingWordsInProgress,
            }
        }))
    }

    return (
        <AdminAssessments
            tabs={tabs}
            fields={fields}
            updateVisibilityModel={updateVisibilityModel}
        />
    )

}

export default MakingWordsAssessments