import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGrid } from 'components'
import { useQuery, useQueryClient } from 'react-query'
import { queryClient as qc, statuses, types } from 'keys'
import { getFrequencyByWordDefFetch } from 'store/middlewares/assessment.crud'
import { LinguisticLabContext } from './LinguisticLabContext'
import { labHooks } from '.'
import { useTheme } from 'styles'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

const LinguisticLab = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters, statusFilters } = useSelector(state => state.assessment)
    const { selectedWordDef, setSelectedWordDef } = useContext(LinguisticLabContext)

    const queryClient = useQueryClient()

    const fields = labHooks.useFields()

    const { data: frequencyByWordDefData, refetch: refetchGetfrequencyByWordDef } =
        useQuery({
            queryKey: qc.wordFrequency,
            queryFn: () => {
                return getFrequencyByWordDefFetch({ word_def: selectedWordDef, assessmentFilters, statusFilters, headerFilters, testTaker })
            },
            enabled: false, refetchOnMount: false, refetchOnWindowFocus: false,
        })

    const cleanSelectedWord = () => {
        setSelectedWordDef(null)
        queryClient.removeQueries({ queryKey: qc.wordFrequency });
    }

    useEffect(() => {
        if (selectedWordDef)
            refetchGetfrequencyByWordDef()


        return (() => {
            cleanSelectedWord()
        })
    }, [selectedWordDef])

    return (
        <DataGrid
            data={frequencyByWordDefData?.data?.words}
            fields={fields}
            customToolBar={
                <CustomToolBar
                    cleanSelectedWord={cleanSelectedWord}
                    {...frequencyByWordDefData?.data}
                />}
        />
    )
}

const CustomToolBar = (props) => {
    const { word_def, words, cleanSelectedWord } = props

    const { Fonts, Layout } = useTheme()

    const correctWords = words?.filter((word) => word.status === types.correct)

    return (
        <div style={{ ...Layout.grow }}>
            <div style={{ ...Layout.flex, ...Layout.center, ...Layout.xlcGap }}>
                <IconButton onClick={cleanSelectedWord}>
                    <Close />
                </IconButton>
                <div style={{ ...Layout.flex, ...Layout.center, ...Layout.mcGap }}>
                    <b style={{ ...Fonts.textRegular }}>
                        {word_def}
                    </b>
                    {words &&
                        <div style={{ ...Fonts.textSmall }}>
                            {" קריאות המילה"}
                            {` ${correctWords?.length}/${words?.length}`}
                        </div>
                    }
                </div>
            </div>
        </div>

    )
}

export default LinguisticLab