import { paymentHooks } from '..'

const usePayment = () => {
    const payment = paymentHooks.usePayment()

    const paymentBtn = {
        color: "thirdly",
        height: "46px",
        width: "215px",
        fontsize: '18px',
        action: payment,
        disabledColor: "thirdly",
        className: "payment_btn"
    }

    return paymentBtn
}

export default usePayment