import React from 'react'
import { WrapToast, Title, SubTitle, Right, Left } from './Style'
import { CircularProgress } from '@mui/material'

const SuccessToast = () => {

    return (
        <WrapToast>
            <Left>
                <CircularProgress color='primary'/>
            </Left>
            <Right>
                <Title>{"יש למה לחכות :)"}</Title>
                <SubTitle>{"האבחון עולה למערכת ובשעות הקרובות תוכלו כבר לצפות בתוצאות"}</SubTitle>
            </Right>
        </WrapToast>
    )
}

export default SuccessToast