import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ContactUs, Login, Main, Payments, RecoverUsername, Register, ResetPassword, ThankYou, VerifyEmail } from 'components'
import { app, login } from "./routes";
import { RouterData } from "components/Router/hooks";
import { PrivateRoutesApp, PrivateRoutesAuth } from "components/Router";
import { Register1, Register2, Register3 } from "components/Auth/Register";

const CreateRouter = (props) => {
    const { appRoutes } = RouterData()

    return (
        <>
            <Routes>
                <Route exact path={app.login} element={
                    <PrivateRoutesAuth>
                        <Login />
                    </PrivateRoutesAuth>
                } />
                <Route exact path={"/reset/*"} element={
                    <PrivateRoutesAuth>
                        <ResetPassword />
                    </PrivateRoutesAuth>
                } />
                <Route exact path={app.recoverUsername} element={
                    <PrivateRoutesAuth>
                        <RecoverUsername />
                    </PrivateRoutesAuth>
                } />
                <Route exact path={app.thankYou} element={
                    <ThankYou />
                } />
                <Route exact path={"/verify/:token"} element={
                    <PrivateRoutesAuth>
                        <VerifyEmail />
                    </PrivateRoutesAuth>
                } />
                <Route exact path={"/register/*"} element={
                    <PrivateRoutesAuth>
                        <Register />
                    </PrivateRoutesAuth>
                }>
                </Route>
                <Route exact path={app.payments} element={
                    <Payments />
                }>
                </Route>
                <Route exact path={app.contactUs} element={
                    <ContactUs />
                }>
                </Route>
                <Route exact path="/*" element={
                    <PrivateRoutesApp>
                        <Main />
                    </PrivateRoutesApp>
                } />
            </Routes >
        </>
    );
}

export default CreateRouter
