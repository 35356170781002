import { useDispatch, useSelector } from "react-redux"
import { actions } from 'store'
import { convertSelectToValue } from "utils/formatter"

const HeaderCrud = () => {
    const { headerFilters } = useSelector(state => state.header)

    const dispatch = useDispatch()

    const clearPullDowns = () => {
        dispatch(actions.setHeaderFilters({}))
        dispatch(actions.setAssessment(null))
    }

    const handleChangeAffiliationGroup = async (key, value) => {
        const nHeaderFilters = {
            ...headerFilters, [key]: value
            // , affiliation: null, grade: null, t_class: null
        }
        dispatch(actions.setHeaderFilters(nHeaderFilters))
        dispatch(actions.setTestTaker({}))

        // getAffiliationsByParams(convertSelectToValue(nHeaderFilters))
    }

    const handleChangeAffiliation = (key, value) => {
        const nHeaderFilters = {
            ...headerFilters, [key]: value
            // , grade: null, t_class: null
        }
        dispatch(actions.setHeaderFilters(nHeaderFilters))
        dispatch(actions.setTestTaker({}))

        // getGradesByParams(convertSelectToValue(nHeaderFilters))
    }

    const handleChangeGrade = (key, value) => {
        const nHeaderFilters = {
            ...headerFilters, [key]: value
            // , t_class: null
        }
        dispatch(actions.setHeaderFilters(nHeaderFilters))
        dispatch(actions.setTestTaker({}))

        // getTClassesByParams(convertSelectToValue(nHeaderFilters))
    }

    const handleChangeTClasses = (key, value) => {
        const nHeaderFilters = {
            ...headerFilters, [key]: value, test_taker: null
        }
        dispatch(actions.setHeaderFilters(nHeaderFilters))
        dispatch(actions.setTestTaker({}))

        getTestTakersByParams(convertSelectToValue(nHeaderFilters))
    }

    const handleChangeTestTaker = (key, value) => {
        const nHeaderFilters = {
            ...headerFilters, [key]: value
        }
        console.log("nHeaderFilters", nHeaderFilters)
        dispatch(actions.setHeaderFilters(nHeaderFilters))
        dispatch(actions.setTestTaker({}))
    }

    const getGradesByParams = async (headerFilters) => {
        const grades = await dispatch(actions.getGrades(headerFilters))
        dispatch(actions.setGrades(grades))
    }

    const getAffiliationsByParams = async (headerFilters) => {
        const affiliations = await dispatch(actions.getAffiliations(headerFilters))
        dispatch(actions.setAffiliations(affiliations))
    }

    const getTClassesByParams = async (headerFilters) => {
        // const tClasses = await dispatch(actions.getTClasses(headerFilters))
        // dispatch(actions.setTClasses(tClasses))
    }

    const getTestTakersByParams = async (headerFilters) => {
        try {
            const testTakers = await dispatch(actions.getTestTakersOptions(headerFilters))
            dispatch(actions.setTestTakers(testTakers))
        }
        catch {
            console.log("error getAssessments")
        }
    }

    return {
        handleChangeAffiliationGroup,
        handleChangeAffiliation,
        handleChangeGrade,
        handleChangeTClasses,
        getGradesByParams,
        getAffiliationsByParams,
        getTClassesByParams,
        clearPullDowns,
        handleChangeTestTaker
    }
}

export default HeaderCrud