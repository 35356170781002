import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { CreateAssessmentFormData } from './hooks';
import { WrapTabs, WrapSelectType, WrapTab } from './Style'
import { MappingComponentsByType } from 'components/Form';

const BasicTabs = () => {
    const [value, setValue] = useState(0);

    const { tabs } = CreateAssessmentFormData()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <WrapSelectType>
            <WrapTabs>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {tabs.map((tab) =>
                        <Tab
                            label={tab.label}
                            id={`simple-tab-${tab.id}`}
                            aria-controls={`simple-tabpanel-${tab.id}`}
                        />
                    )}
                </Tabs>
            </WrapTabs>
            {tabs.map((tab, index) =>
                <WrapTab
                    role="tabpanel"
                    hidden={value !== index}
                    id={`simple-tabpanel-${index}`}
                    aria-labelledby={`simple-tab-${index}`}
                >
                    {value === index && (
                        MappingComponentsByType[tab.component]({ ...tab })
                    )}
                </WrapTab>
            )}
        </WrapSelectType>
    );
}

export default BasicTabs