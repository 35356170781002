import React, { useContext, useEffect, useState } from 'react'
import { AssessmentFiltersHeader } from 'components/School/AssessmentsAndTestTakers'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { AssessmentsHooks } from 'components/School/Assessments'
import { assessmentRQ } from 'store/hooks'
import { useSelector } from 'react-redux'
import { useTheme } from 'styles'
import { labHooks, WordDefsWithFrequency, WordsByWordDef } from '.'
import { DataGrid, Levels, Page } from 'components'
import { isEmpty } from 'lodash'
import { WordWithFrequency } from './Style'
import { ratingList } from 'keys'
import { CircularProgress, Tooltip } from '@mui/material'
import { actions } from 'store'
import { useDispatch } from 'react-redux'
import { useQuery, useQueryClient } from 'react-query'
import { queryClient as qc } from 'keys'
import { CustomSection, ScrollBox, WrapHeader } from 'styles/GlobalStyle'
import { LinguisticLabContext } from './LinguisticLabContext'
import { TabButton } from 'components/Form'
import { getFrequencyFetch } from 'store/middlewares/assessment.crud'

const LinguisticLab = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const { assessmentFilters } = useSelector(state => state.assessment)
    const { selectedWordDef, selectedTab, setSelectedTab } = useContext(LinguisticLabContext)

    const { Layout, Gutters } = useTheme()

    const { frequency } = labHooks.constants
    const { data: frequencyData, refetch: refetchFrequency, isFetching: getFrequencyLoading } = labHooks.useFrequency()
    const headerItems = labHooks.useHeader()
    const tabs = labHooks.useTabs()

    const currentData = frequencyData?.data

    const removeQueries = () => {
        queryClient.removeQueries({ queryKey: qc.assessmentFrequency });
    }

    const cleanData = () => {
        dispatch(actions.cleanAssessmentFilters({}))
        removeQueries()
    }

    const handleTab = (index) => {
        setSelectedTab(index)
    }

    useEffect(() => {
        return () => {
            cleanData()
        }
    }, [])

    useEffect(() => {
        assessmentFilters.assessment_def && refetchFrequency()
    }, [selectedTab])

    return (
        <WrapDataGrid>
            <AssessmentFiltersHeader
                loading={getFrequencyLoading}
                disabledSearch={!assessmentFilters.assessment_def}
                headerItems={headerItems}
                refetchData={refetchFrequency}
            />

            {isEmpty(currentData) ||
                <div style={{ ...Layout.flex, ...Layout.scGap, ...Gutters.tinyBPadding }}>
                    {tabs.map((tab, index) =>
                        <TabButton
                            loading={getFrequencyLoading}
                            selected={index == selectedTab}
                            handleTab={() => handleTab(index)}
                            {...tab}
                        />
                    )}
                </div>}

            {isEmpty(currentData) ||
                <div style={{ ...Layout.col, ...Layout.lrGap, ...Layout.grow, ...Layout.overflow }}>
                    <CustomSection $shrink $bg height={50} $center>
                        {`תוצאות מבוססות  על ${currentData?.assessment_count} מבדקים על פי הסינון`}
                    </CustomSection>

                    <div style={{ ...Layout.grow, ...Layout.flex, ...Layout.fullHeight, ...Layout.lcGap, ...Layout.overflow }}>

                        {selectedWordDef ?
                            <WordsByWordDef />
                            :
                            <WordDefsWithFrequency {...currentData} />
                        }

                        <div style={{ width: "140px", flexShrink: 0, ...Layout.alignItemsEnd, ...Layout.flex }}>
                            <Levels levels={frequency} title="אחוזי קריאות:" />
                        </div>
                    </div>
                </div>
            }

        </WrapDataGrid>
    )
}

export default LinguisticLab