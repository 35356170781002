import { produce } from 'immer'
import createReducer from "./reducerUtils"
import { fluency_and_accuracy, matching_learning } from 'keys/progress'
import { assessmentUtils } from 'helpers'

const initialState = {
    assessments: null,
    assessmentDefs: null,
    assessment: {},
    id: null,
    audioData: null,
    selectedAssessmentDefs: [],
    selectedAssessmentDef: null,
    progressIndication: {},
    progress: {
        "1": {
            progress: 4
        },
        "2": {
            progress: 10
        },
        "3": {
            progress: 30
        },
        "4": {
            progress: 0
        }
    },
    assessmentIds: [],
    definitionTypes: null,
    assessmentDef: null,
    isRunProgress: false,
    assessmentsGroup: [],
    selectedAssessment: null,
    createBatteryIsRun: false,
    assessmentFilters: {
        year: null,
        semester: null,
        assessment_def: null,
        gender: null
    },
    statusFilters: {},
    selectedAssessments: [],
    categories: []
}

const assessment = {
    setAssessmentIds(state, action) {
        state.assessmentIds = action.payload
    },
    setCategories(state, action) {
        state.categories = action.payload
    },
    updateAssessmentFilters(state, action) {
        state.assessmentFilters = { ...state.assessmentFilters, ...action.payload }
    },
    setSelectedAssessments(state, action) {
        state.selectedAssessments = action.payload
    },
    cleanSelectedAssessments(state, action) {
        state.selectedAssessments = []
    },
    setAssessmentFilters(state, action) {
        state.assessmentFilters = action.payload
    },
    cleanAssessmentFilters(state, action) {
        state.assessmentFilters = {
            year: null,
            semester: null,
            assessment_def: null,
            gender: null
        }
    },
    cleanAssessment(state, action) {
        state.assessment = {}
    },
    updateStatusFilters(state, action) {
        state.statusFilters = { ...state.statusFilters, ...action.payload }
    },
    setStatusFilters(state, action) {
        state.statusFilters = action.payload
    },
    cleanStatusFilters(state, action) {
        state.statusFilters = {}
    },
    setAssessments(state, action) {
        state.assessments = action.payload
    },
    cleanAssessments(state, action) {
        state.assessments = null
    },
    setCreateBatteryIsRun(state, action) {
        state.createBatteryIsRun = action.payload
    },
    setSelectedAssessment(state, action) {
        state.selectedAssessment = action.payload
    },
    setAssessmentsGroup(state, action) {
        state.assessmentsGroup = action.payload
    },
    setIsRunProgress(state, action) {
        state.isRunProgress = action.payload
    },
    setSelectedAssessmentDefs(state, action) {
        state.selectedAssessmentDefs = action.payload
    },
    setSelectedAssessmentDef(state, action) {
        state.selectedAssessmentDef = action.payload
    },
    updateSelectedAssessmentDef(state, action) {
        state.selectedAssessmentDef = { ...state.selectedAssessmentDef, ...action.payload }
    },
    setAssessmentDef(state, action) {
        state.assessmentDef = action.payload
    },
    setDefinitionTypes(state, action) {
        state.definitionTypes = action.payload
    },
    updateAssessment(state, action) {
        state.assessment = { ...state.assessment, ...action.payload }
    },
    startProgress(state, action) {
        let key = action.payload
        state.progressIndication[key] = { ...state.progressIndication[key], start: true }
    },
    setProgressIndication(state, action) {
        state.progressIndication = action.payload
    },
    resetProgressIndication(state, action) {
        state.progressIndication = initialState.progressIndication
    },
    setAssessmentDefs(state, action) {
        state.assessmentDefs = action.payload
    },
    setAssessment(state, action) {
        state.assessment = action.payload
    },
    setId(state, action) {
        state.id = action.payload
    },
    setAudioData(state, action) {
        state.audioData = action.payload
    }
}

export default produce(
    (state, action) => createReducer(state, action, assessment),
    initialState
)

