import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { WrapAssessmentDefs, WrapCreateSet, WrapContent } from './Style'
import { AssessmentDefs, Page } from 'components'
import { CustomSection, WrapAll } from 'styles/GlobalStyle'
import { UploadAudioFile } from 'components/School/UploadAudio'
import { UploadAudioCrud } from './hooks'
import { assessmentDefRQ } from 'store/hooks'

const UploadAudio = () => {
    const { assessmentDefs } = useSelector(state => state.assessment)

    const [prevSelectedAssessments, setPrevSelectedAssessments] = useState([])

    const { init, cleanData, onSelectAssessment } = UploadAudioCrud()

    const { } = assessmentDefRQ.useGetAllAssessmentDefs()

    useEffect(() => {
        if (!assessmentDefs)
            init()

        return (() => {
            cleanData()
        })
    }, [])

    return (
        <Page>
            <WrapAll>
                <WrapContent>
                    <WrapAssessmentDefs>
                        <AssessmentDefs selectAssessment onSelectAssessment={() => { }} />
                    </WrapAssessmentDefs>
                    <WrapCreateSet>
                        <CustomSection $bg>
                            <UploadAudioFile prevSelectedAssessments={prevSelectedAssessments} setPrevSelectedAssessments={setPrevSelectedAssessments} />
                        </CustomSection>
                    </WrapCreateSet>
                </WrapContent>
            </WrapAll>
        </Page>
    )
}

export default UploadAudio