import { IconButton } from "@mui/material"
import { AlertCrud } from "components/Alerts/hooks"
import { alerts } from "keys"


const useGenerateCouponCode = () => {
    const { setAlerts } = AlertCrud()

    const openCouponAlert = () => {
        setAlerts(alerts.createCoupon, true)
    }

    const generateCouponCodeBtn = {
        id: 0,
        label: "צור קוד קופון",
        name: "report",
        action: openCouponAlert,
        component: "button",
        height: "35px",
        width: '120px',
        color: "rating5",
        disabledColor: 'rating4',

        feature: "generate-coupon-code",//TODO move to json features
        role: "create",
        permissionHOC: "hidden",
    }

    return generateCouponCodeBtn
}

export default useGenerateCouponCode