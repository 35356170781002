import React from 'react'
import { Vowel } from './Style';
import { insertion, na, omission } from 'keys/types';

const LetterDescription = (props) => {
    const { type, items = {}, index, phoneme, updatePhoneme } = props
    const { letter_description, phoneme_def_letter_description, letter, letter_status, vowel_status } = phoneme || {}

    const handleChange = (keyVowel) => {
        const nPhoneme = { ...phoneme }
        if (nPhoneme.letter_description === keyVowel)
            delete nPhoneme.letter_description
        else nPhoneme.letter_description = keyVowel
        updatePhoneme(index, nPhoneme)
    }

    const disabled =
        letter_status === omission ||
        vowel_status === na ||
        letter_status === insertion && !letter

    return (
        Object.keys(items).map((key) => {
            const keyVowel = Number(key)
            return (
                <Vowel
                    key={keyVowel}
                    correct={keyVowel === letter_description || (keyVowel === phoneme_def_letter_description && !letter_description)}
                    incorrect={keyVowel != phoneme_def_letter_description && keyVowel === letter_description}
                    source={keyVowel === phoneme_def_letter_description}
                    insertion={keyVowel === letter_description && letter_status === insertion}
                    disabled={disabled}
                    onClick={() => handleChange(keyVowel)}
                >
                    {items[keyVowel].name}
                </Vowel>)
        })
    )
}

export default LetterDescription