import React, { useContext } from 'react'
import { AbilityContext } from "components/Permissions/Can";

const DisabledHOC = (props) => {
    const { HocComponent, fields } = props

    const ability = useContext(AbilityContext)

    const fieldsForCurrentPermission = fields.filter((field) =>
        ability.can(field.role, field.feature) || !field.role
    )

    return (
        <HocComponent {...props}
            fields={fieldsForCurrentPermission}
        />
    )
}

export default DisabledHOC
