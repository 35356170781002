import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import CutRecordCrud from './CutRecordCrud';
import { lightBlue, lightOrange, lightPurple } from "styles/theme"
import { silenceObj, stufferObj, wordObj } from 'mock/regionType';

const CutRecordData = () => {
    const {
        play, changeDuration, saveRegions,
        saveRegionsLoading
    } = CutRecordCrud()

    const durationBtns = [
        {
            id: 0,
            label: "נגן",
            color: "primary",
            icon: <PlayArrowOutlinedIcon />,
            action: play
        },
        {
            id: 1,
            label: "0.5x",
            color: "dark",
            action: () => changeDuration(0.5)
        },
        {
            id: 2,
            label: "1.0x",
            color: "dark",
            action: () => changeDuration(1)
        },
        {
            id: 3,
            label: "1.5x",
            color: "dark",
            action: () => changeDuration(1.5)
        }
    ]

    const actionsBtns = [
        {
            id: 0,
            label: 'מילה',
            bg: "lightBlue",
            ...wordObj
        },
        {
            id: 1,
            label: "לא רלוונטי",
            bg: "lightOrange",
            ...silenceObj
        },
        {
            id: 2,
            label: "גמגום",
            bg: "lightPurple",
            ...stufferObj
        }
    ]

    const headerActions = [
        {
            id: 0,
            label: "ביטול",
            color: "lightRed"
        },
        {
            id: 1,
            label: "סיים ושלח",
            className: 'save-regions',
            loading: saveRegionsLoading,
            className: 'save-regions',
            action: saveRegions,
            color: "gray"
        }
    ]

    return {
        durationBtns,
        actionsBtns,
        headerActions,
        fileName:"שם הקבץ:"
    }

}

export default CutRecordData