import React from 'react';
import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridToolbarFilterButton, GridToolbarQuickFilter, } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid';
import { GridToolbarColumnsButton } from '@mui/x-data-grid';
import { WrapGridToolbarContainer, WrapGridToolbarQuickFilter } from './Style'
import { Can } from 'components/Permissions';

const CustomToolbar = (props) => {
    const { customToolBar } = props

    return (
        <WrapGridToolbarContainer>
            <WrapGridToolbarQuickFilter />

            <div>
                {customToolBar ?
                    customToolBar :
                    <>
                        <Can I={"read"} a={"toolbar-columns-button"}>
                            <GridToolbarColumnsButton />
                        </Can>
                        <Can I={"read"} a={"toolbar-actions"}>
                            <GridToolbarFilterButton />
                            <GridToolbarDensitySelector />
                            <GridToolbarExport />
                        </Can>
                    </>
                }
            </div>
        </WrapGridToolbarContainer >
    );
}

export default CustomToolbar