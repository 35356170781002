import React from 'react';
import { Skeleton } from '@mui/material';
import { WrapPlaceHolder } from './Style'

export default function Variants() {
    return (
        <WrapPlaceHolder>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" sx={{ width: "200px" }} />
        </WrapPlaceHolder>
    );
}