import React from 'react'
import { Permissions } from 'components'

const PermissionHOC = (HocComponent) => {
    return props => {
        const { permissionHOC } = props
        const PermissionWrap = Permissions.UI[permissionHOC]

        return (
            permissionHOC ?
                <PermissionWrap {...props} HocComponent={HocComponent} /> :
                <HocComponent {...props} />
        )
    }
}

export default PermissionHOC
