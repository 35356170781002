import Phoneme from 'models/RM/Phoneme'
import { sequence } from 'hebrew-transliteration'
import { createLetterAddOnDict, defaultLetterAddOnValue } from 'utils/data';
import { keysSourceChanges } from 'mock/sourceChanges';
import { updateType } from 'keys';
import { correct, incorrect, na } from 'keys/types';

import { map } from 'lodash'
import update_type from 'mock/updateType';
import { PhonemeRM } from '.';

class Word {

    constructor(word, vowelCodes, letterAddOns) {
        const { phonemes, word_def_fe_details, word_def_text, word_def_id, word_def_index, word_def, status, id, text, index, errors } = word

        if (id)
            this.id = id
        //TODO check it
        this.word_def = word_def_id;
        this.word_def_id = word_def_id
        this.word_def_index = word_def_index
        this.word_def_text = word_def_text
        this.word_def_fe_details = word_def_fe_details
        if (word_def_id === 2724) {
            console.log(phonemes)
        }
        this.phonemes = getPhonemes(phonemes)
        this.errors = errors

        let transcription = ""
        let flagCheckStatus = false

        // phonemes
        this.phonemes.map((phoneme) => {
            if (phoneme.update_type != updateType.delete) {
                const {
                    substitution_letter, letter_add_ons_dynamic, phoneme_def_vowel_code, letter_add_ons_static, all_letter_add_ons, letter,
                    letter_add_ons, vowel_code, letter_add_on, vowel_status = 'CORRECT', letter_status = 'CORRECT', letter_add_on_status = 'CORRECT' } = phoneme

                transcription += substitution_letter || letter

                if (vowel_code) {
                    const vowelCodeSrt = vowelCodes[vowel_code].ascii_code
                    if (vowelCodeSrt)
                        transcription += String.fromCodePoint(parseInt(vowelCodeSrt, 16))
                }

                // let nLetter_add_ons = checkLetterAddOns(nPhoneme)
                letter_add_ons?.forEach(add_on => {
                    if (add_on) {
                        const { letter_add_on, letter_add_on_status } = add_on
                        console.log(letter_add_on, letterAddOns)
                        const letterAddOnsStr = letterAddOns[letter_add_on].ascii_code
                        if (letterAddOnsStr)
                            transcription += String.fromCodePoint(parseInt(letterAddOnsStr, 16))
                        if (letter_add_on_status && letter_add_on_status != keysSourceChanges.correct)
                            flagCheckStatus = true
                    }
                });

                if (vowel_status && vowel_status != correct && vowel_status != na)
                    flagCheckStatus = true

                if (letter_status && letter_status != correct && letter_status != na)
                    flagCheckStatus = true

            }
        })

        this.transcription = sequence(transcription);

        // if (flagCheckStatus) {
        //     this.status = incorrect
        // }
        // else
        this.status = correct

    }
}

const getPhonemes = (phonemesSave) => {
    let nPhonemes = []

    phonemesSave.forEach((phoneme, index) => {
        nPhonemes.push(new PhonemeRM(phoneme, index))
    });

    return nPhonemes
}

const checkLetterAddOns = (phoneme) => {
    const { all_letter_add_ons, letter_add_ons_dynamic, letter_add_ons_static } = phoneme

    let nLetter_add_ons = []
    all_letter_add_ons?.forEach(add_on => {
        let flagBreak = false
        if (add_on) {
            let letter_add_on = { letter_add_on: add_on }

            //check status
            const i = createLetterAddOnDict[add_on][0]
            const j = createLetterAddOnDict[add_on][1]

            if (letter_add_ons_dynamic[i][j].selected === letter_add_ons_static[i][j].selected) {
                letter_add_on.letter_add_on_status = keysSourceChanges.correct
            }
            else {
                if (defaultLetterAddOnValue[add_on]) {
                    if (!letter_add_ons_static[i][Number(!j)].selected)
                        flagBreak = true
                    else
                        letter_add_on.letter_add_on_status = keysSourceChanges.substitution
                }

                else {
                    letter_add_on.letter_add_on_status = keysSourceChanges.substitution
                }
            }
            if (!flagBreak)
                nLetter_add_ons.push(letter_add_on)
        }
    });
    return nLetter_add_ons
}

export { checkLetterAddOns }

export default Word
