import React from 'react'
import { WrapWarningCell } from './Style'
import { deviationRatingIndex, naLabel, ratingListLabel, ratingListTheme } from 'keys/rating'
import { AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks'
import { Tooltip } from '@mui/material'
import { statuses } from 'keys'

const WarningCell = (props) => {
    const
        { row } = props,
        { last_assessment } = row,
        { rating, status } = last_assessment || {},
        { fluency_rating_index, percent_accuracy_rating_index } = rating || {}

    const { indicationIcon } = AssessmentsAndTestTakersData()
    const { noraml, warning, nodata } = indicationIcon

    let indication
    let minIndication

    if (status != statuses.brokenFile)
        if (!fluency_rating_index && !percent_accuracy_rating_index)
            indication = nodata

        else {
            minIndication = fluency_rating_index > percent_accuracy_rating_index ? fluency_rating_index : percent_accuracy_rating_index
            minIndication -= 1
            indication =
                minIndication > deviationRatingIndex ?
                    warning :
                    noraml
        }

    return (
        <Tooltip title={ratingListLabel[minIndication]} placement='left'>
            <WrapWarningCell>
                {indication ?
                    <indication.icon {...indication} color={ratingListTheme[minIndication]} fontSize='small' /> :
                    naLabel}
            </WrapWarningCell>
        </Tooltip>
    )
}

export default WarningCell