import React, { useEffect, useRef } from 'react'
import { reset } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { CreateAssessmentCrud } from 'components/School/CreateAssessment/hooks'
import { WrapSections, WrapUploadAudio } from './Style'
import { CustomSection } from 'styles/GlobalStyle'
import { fluency_and_accuracy } from 'keys/progress'
import { CreateAssessmentFormCrud } from './CreateAssessmentForm/hooks'
import { actions } from 'store'
import { CreateAssessmentForm, Progress } from 'components/School/CreateAssessment'
import { assessmentDefRQ } from 'store/hooks'

const CreateAssessment = () => {
    const formname = "createAssessment"
    const { progressIndication, isRunProgress } = useSelector(state => state.assessment)
    const createAssessmentFormValues = useSelector(state => state.form[formname]?.values)
    const { assessment } = useSelector(state => state.assessment)
    const { init } = CreateAssessmentCrud()
    const { getProgressIndication, updateProgress } = CreateAssessmentFormCrud()
    const progressIndicationRef = useRef(progressIndication)
    const progressIntervalRef = useRef()
    const dispatch = useDispatch()

    const { assesment_def, file } = createAssessmentFormValues || {}

    const { } = assessmentDefRQ.useGetAllAssessmentDefs()

    useEffect(() => {
        init()

        //clean createAssessment form values
        //reset all the progress
        return (() => {
            dispatch(actions.setIsRunProgress(false))
            dispatch(reset(formname))
        })
    }, [])


    //check if valid form and submit form clicked setinterval and get indiction
    useEffect(() => {
        if (assesment_def && file && assessment?.id && isRunProgress) {
            let nProegrss = {
                ...progressIndicationRef.current,
                fluency_and_accuracy: { start: true },
                matching_learning: { disabled: true }
            }
            progressIndicationRef.current = nProegrss
            updateProgress(nProegrss)
            progressIntervalRef.current = setInterval(async () => {
                getProgressIndication(progressIndicationRef)
            }, 10000);
        }
        else {
            dispatch(actions.setIsRunProgress(false))
            clearInterval(progressIntervalRef.current)
        }
    }, [isRunProgress])

    //check if the progress completed
    useEffect(() => {
        if (progressIndication && progressIndication[fluency_and_accuracy]?.completed) {
            clearInterval(progressIntervalRef.current)
            dispatch(actions.setIsRunProgress(false))
        }
    }, [progressIndication])

    return (
        <WrapUploadAudio>
            <WrapSections>
                <CustomSection>
                    <CreateAssessmentForm />
                </CustomSection>
                <CustomSection>
                    <Progress />
                </CustomSection>
            </WrapSections>
        </WrapUploadAudio>
    )
}

export default CreateAssessment