import { useQuery, useQueryClient } from "react-query"
import { queryClient as qc } from 'keys'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import { getClassStatsFetch, getRatingFetch, getScoreRatingMethodFetch } from "store/middlewares/rating.crud"
import { ScoreRatingMethodVM } from "models/VM"

const useGetRating = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)

    return useQuery(qc.rating,
        {
            queryFn: ({ signal }) => getRatingFetch({ id: assessment.id, signal, }),
            enabled: !!assessment.id,
            onSuccess: (res) => {
                const ratingData = res.data
                dispatch(actions.setRating(ratingData.rating))
            }
        })
}

const useGetScoreRatingMethod = () => {
    const params = useParams()
    const { assessment } = useSelector(state => state.assessment)
    const assessmentID = params?.assessmentID || assessment.id

    return useQuery({
        queryFn: qc.scoreRatingMethod,
        queryFn: async ({ signal }) => {
            const res = await getScoreRatingMethodFetch({ signal, id: assessmentID })
            let nRating = {}
            res.data.forEach((ratingItem) => {
                nRating[ratingItem.id] = new ScoreRatingMethodVM(ratingItem)
            })
            return nRating
        }
    })
}

const useGetClassStats = () => {
    const params = useParams()
    const { assessment } = useSelector(state => state.assessment)
    const assessmentID = assessment.id || params?.assessmentID

    return useQuery({
        queryKey: qc.getClassStats,
        queryFn: async ({ signal }) => {
            const res = await getClassStatsFetch({ signal, id: assessmentID })
            return res.data
        },
        retry: 0
    })
}

export default { useGetClassStats, useGetRating, useGetScoreRatingMethod }