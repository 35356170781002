import { app } from "components/Router/routes"

import { School, Office, Home } from 'assets'


const constants = {
    title: "כל ילד בכל מקום יכול לרכוש קריאה בלי תסכולים",
    subTitle: "- בחרו את הסביבה שלכם -"
}

const useCards = () => {

    const cards = [
        {
            label: "בבית הספר",
            action: () => { },
            disabled: true,
            img: School
        },
        {
            label: "בקליניקה",
            action: () => { },
            disabled: true,
            img: Office
        },
        {
            label: "בבית",
            path: app.register2,
            img: Home
        }
    ]

    return cards
}

export default { useCards, constants }