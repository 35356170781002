import { Button } from "@mui/material";
import styled, { css } from "styled-components";
import { Disabled, Scroll, Section } from "styles/GlobalCss";
import { light, dark } from 'styles/theme'
import { WaveSurfer, WaveForm, Region, Marker } from "wavesurfer-react";

const WrapCutRecord = styled.div`
display: flex;
flex-direction: column;
row-gap: 10px;
overflow:auto;
height: calc(100% - 150px);
${Scroll};

`

const CutRecordSectionCss = css`
padding-top:10px;
padding-bottom:10px;
`

const WrapSetting = styled.div`
${Section};
${CutRecordSectionCss};

height:40px;
display:flex;
column-gap: 10px;
justify-content: space-between;
align-items:center;

.file-name-title{
    font-size: 18px;
    font-weight: bold;
    width:40%;
}

.file-name{
    font-weight: normal;
}

.region-btns{
    width:40%;
    display: flex;
    column-gap: 10px;
    justify-content:end;
    ${props => props.$disabled && Disabled}
}

.save-regions{
    background: #A1A5B7 !important;

}
`

const WrapAudio = styled.div`
${Section};
${CutRecordSectionCss};

display: flex;
row-gap: 10px;
flex-direction: column;

height:auto;



`

const Content = styled.div`
${props => props.$disabled && Disabled}
`



const Word = styled(Button)`
background: #E0E4F3 !important;
display: flex !important;
width: 122px !important;
height:28px !important;
padding: 12px 0px !important;
gap: 4px !important;
border-radius: 50px !important;
color:#000000 !important;
`

const WrapWords = styled.div`
display:flex;
flex-wrap: wrap;
column-gap: 10px;
row-gap:10px;
margin-top:20px;
overflow:auto;
padding-bottom: 10px;
${Scroll};
`


const WrapRecord = styled.div`

${Section};
height:auto;
display:flex;
column-gap: 10px;
justify-content: center;
flex-direction: ${props => !props.basic && "column"};
row-gap:10px;
height:${props => props.basic && '40px'};
display:${props => props.hidden && 'none'};


#play{
    width:40px !important;
    height:40px !important;
    // transform: scaleX(-1)  !important;
}
${Scroll};

.record{
    width:100% !important;
    // transform: scaleX(-1);
    direction: ltr;
    ${Scroll};
    overflow-x:hidden !important;
    overflow-y:hidden !important;

}

.basic-record{
    width:calc(100% - 40px) !important;
    direction: ltr;
    ${Scroll};
    // overflow-x:hidden !important;
    // overflow-y:hidden !important;
}

.scroll{
    overflow-x:hidden !important;
    overflow-y:hidden !important;

    ${Scroll};
}

.linearProgress{
    width:100% !important;
}


.action-btns{
    display:flex;
    column-gap: 10px;
}

`

const ActionBtn = styled(Button)`

`

const AudioContent = styled.div`

`

const WrapRegion = styled(Region)`

`

const WrapWaveSurfer = styled(WaveSurfer)`

`


export { WrapWaveSurfer, Content, AudioContent, WrapRegion, ActionBtn, WrapRecord, WrapSetting, WrapWords, Word, WrapCutRecord, WrapAudio }