import React from 'react'
import { SvgIcon } from '@mui/material'
import { CustomIconButton } from './Styled'
import { useTheme } from 'styles'

const IconButtons = (props) => {
    const { selected = props.btns[0].key, setSelected, btns } = props

    const { Layout } = useTheme()

    return (
        <div style={{ ...Layout.flex }}>
            {btns.map((btn) =>
                <CustomIconButton
                    onClick={() => {
                        setSelected(btn.key)
                        btn.action()
                    }}
                    color="thirdly2"
                    variant={selected === btn.key ? "contained" : "text"}>
                    <SvgIcon fontSize="small" component={btn.icon} inheritViewBox />
                </CustomIconButton>
            )}
        </div>
    )
}

export default IconButtons