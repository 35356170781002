import { GridActionsCellItem, } from '@mui/x-data-grid';
import PermissionHOC from 'components/Permissions/PermissionHOC';

const GridAction = (props) => {
    const { action, color } = props

    return (
        <GridActionsCellItem
            icon={<props.icon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
                action(props)
            }}
            color={color}
        />
    )
}

export default PermissionHOC(GridAction)

