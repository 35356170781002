import styled, { css } from "styled-components";
import { dark } from "styles/theme";

const WrapAdminAssessments = styled.div`
display: flex;
flex-direction: column;
row-gap: 10px;
height: 100%;
width:100%;
`

const Header = styled.div`
width:100%;
display: flex;
column-gap: 10px;
`

const WrapItem = styled.div`
height:40px;
padding:10px;
display: flex;
align-items: center;
justify-content: space-between;
`

const WrapFilters = styled.div`
height:100px;
background:${dark};
display:flex;
column-gap:10px;
`

const FileDetails = styled.div`
font-size: 17px;
text-overflow: ellipsis;
overflow: hidden;
width: 320px;
white-space: nowrap;
`

const Date = styled.div`
font-size: 14px;
`

const Item = styled.div`

`

const WrapSection = styled.div`
height: 100%;

`

const FlexCss = css`
display: flex;
column-gap: 20px;
align-items:center;
`

const WrapLeft = styled.div`
${FlexCss};
`

const WrapRight = styled.div`
${FlexCss};
`

export { Header, WrapLeft, WrapFilters, WrapRight, FileDetails, Date, Item, WrapItem, WrapAdminAssessments, WrapSection }