import React from 'react';
import { CircularProgress, Fab, Tooltip } from '@mui/material';
import { WrapFab } from './Styled'
import { isEmpty } from 'lodash';

const CustomFab = (props) => {
    const { title, disabled, component, icon, label, loading } = props

    return (
        <>
            {component ?
                <props.component
                    {...props} disabled={disabled} /> :
                // <Tooltip title={title} aria-label='record' placement='top'>
                <WrapFab disabled={loading} {...props}>
                    {loading ? <CircularProgress color='dark' /> :
                        <>
                            {label && label}
                            {!isEmpty(icon?.icon) && <icon.icon color={loading ? "disabled" : icon.color} />}
                        </>
                    }
                </WrapFab>
                // </Tooltip>
            }
        </>
    );
}

export default CustomFab;