import { useGeneralData } from "components/General/hooks"

const MatchingLearningData = () => {
    const { goBackRating } = useGeneralData()

    const headerActions = [
        goBackRating
    ]

    return {
        headerActions
    }
}

export default MatchingLearningData