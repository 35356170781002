import React, { useContext, useEffect } from 'react'
import { Word, WrapWords } from './Style'
import { CutRecordContext } from './CutRecordContext'
import { CutRecordCrud } from './hooks'

const SelectWord = () => {
    const { words } = useContext(CutRecordContext)
    const { selectWord } = CutRecordCrud()

    return (
        <WrapWords>
            {words?.map((word) => {
                let text = word.transcription || word.word_def_text
                return (
                    <Word variant='contained' onClick={() => selectWord(word)}>
                        {text}
                    </Word>
                )
            }
            )}
        </WrapWords>
    )
}

export default SelectWord