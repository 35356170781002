import SelectType from 'components/School/CreateAssessment/CreateAssessmentForm/SelectType'
import SelectAssessmentDef from 'components/School/CreateAssessment/CreateAssessmentForm/SelectAssessmentDef'
import RecordAssessment from 'components/School/CreateAssessment/CreateAssessmentForm/RecordAssessment'
import { useSelector } from 'react-redux'
import { FormCrud } from 'components/Form/hooks'
import { CreateAssessmentFormCrud } from '.'
import { fluency_and_accuracy } from 'keys/progress'

const CreateAssessmentData = () => {
    const { cleanAssessment, formName } = CreateAssessmentFormCrud()
    const { actionSubmit } = FormCrud()
    const { assessmentDefs, isRunProgress, progressIndication } = useSelector(state => state.assessment)
    const { testTaker } = useSelector(state => state.testTaker)

    const createAssessmentFormValues = useSelector(state => state.form[formName]?.values)
    const { assesment_def, file } = createAssessmentFormValues || {}
    const isValidForm = assesment_def && file

    const fields = [
        {
            id: 2,
            component: 'select_type',
            section: true,
            name: 'file',
            component: 'custom',
            customComponent: SelectType,
        }
    ]

    const tabs = [
        {
            id: 0,
            label: "הקלטת אודיו",
            component: 'fields',
            fields: [
                {
                    name: "file",
                    customComponent: RecordAssessment,
                    component: 'custom',
                    label: "הקלטת אודיו",
                    pickerLabel: "מעבר להקלטת אבחון",
                    section: true,
                }
            ]
        },
        {
            id: 1,
            label: "העלאת קובץ אודיו",
            component: 'fields',
            section: true,
            fields: [
                {
                    id: 0,
                    name: 'file',
                    component: 'uploadFile',
                    section: true,
                    label: "העלאת קובץ אודיו",
                },
                {
                    id: 1,
                    name: "assesment_def",
                    placeholder: "שם המבדק",
                    options: assessmentDefs,
                    component: 'custom',
                    customComponent: SelectAssessmentDef,
                    title: "טקסט התייחסות",
                    className: "n-assesment_def",
                    section: true,
                }
            ]
        }
    ]



    const submitBtns = [
        {
            id: 0,
            label: "ניקוי",
            color: "light",
            fullWidth: true,
            action: () => cleanAssessment(formName)
        },
        {
            id: 1,
            label: "פענוח",
            color: "secondary",
            fullWidth: true,
            action: () => actionSubmit(formName),
            disabled: !isValidForm || !testTaker?.test_taker_id || isRunProgress || progressIndication?.[fluency_and_accuracy]?.completed,
            disabledColor: "secondary"
        }
    ]

    return {
        submitBtns,
        tabs,
        formName,
        fields,
        isValidForm
    }
}

export default CreateAssessmentData