import { statuses } from 'keys'

const useTabs = () => {

    return [
        {
            id: 0,
            label: "משוקלל",
        },
        {
            id: 1,
            label: "לא משוקלל",
        }
    ]

}



export default useTabs
