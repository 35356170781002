import React from 'react';
import { Label, Error } from 'components/Form'
import { MenuItem, CircularProgress } from '@mui/material'
import { WrapOutlinedInput, BootstrapSelect, WrapField, Placeholder, WrapProgress, WrapFieldContent } from './Styled'
import { isEmpty } from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MultiSelect = (props) => {
    const { createNew, label, width, action, input, options, disabled, className, placeholder, multiple, mandatory } = props,
        { onChange, value } = input;

    const handleChange = (event, opt) => {
        const {
            target: { value },
        } = event;
        if (value)
            onChange(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
    };

    const renderValue = () => {
        if (typeof value != 'object') {
            const optionIndex = options?.findIndex((opt) => opt?.id.toString() === value?.toString())
            return options?.[optionIndex] || ""
        }
        return value
    }

    return (
        <WrapField className={className}>
            <WrapFieldContent>
                {label && <Label label={label} mandatory={mandatory} />}
                <BootstrapSelect
                    disabled={disabled}
                    multiple={multiple}
                    displayEmpty
                    notched={"true"}
                    value={isEmpty(options) ? "" : renderValue()}
                    onChange={handleChange}
                    defaultValue=""
                    input={<WrapOutlinedInput />}
                    renderValue={(selected) => {
                        if (selected?.length === 0 || isEmpty(selected)) {
                            return <Placeholder>{placeholder}</Placeholder>;
                        }
                        else if (multiple)
                            return selected.join(', ');
                        else return selected.name
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: width || 250,
                            },
                        },
                    }}
                >
                    {createNew &&
                        <MenuItem
                            onClick={() => createNew.action()}
                        >
                            <b> {createNew.label}</b>
                        </MenuItem>
                    }
                    {options ? options.map((opt) => (
                        <MenuItem
                            key={opt.id}
                            onClick={() => action && action(opt)}
                            value={opt}>
                            {opt?.name}
                        </MenuItem>
                    ))
                        :
                        <WrapProgress>
                            <CircularProgress size={20} />
                        </WrapProgress>
                    }
                </BootstrapSelect>
            </WrapFieldContent>
            <Error {...props} />
        </WrapField>
    );
}

export default MultiSelect
