import { useSelector } from "react-redux"
import { AffiliationCrud } from "."
import { FormCrud } from "components/Form/hooks"

const AffliationData = () => {
    const formName = "createAffliation"
    const title = "הוספת יחידה"
    const { groups, methods } = useSelector(state => state.affiliation)
    const { handleCreateAffiliationAlert } = AffiliationCrud()
    const { actionSubmit } = FormCrud()

    const fields = [
        {
            name: 'name',
            component: "input",
            placeholder: "שם",
            className: "b-name",
            mandatory: true
        },
        {
            name: 'group',
            component: "autoComplete",
            placeholder: "קבוצה",
            options: groups,
            className: "b-group",
            mandatory: true
        },
        {
            name: 'method',
            component: "autoComplete",
            placeholder: "שיטה",
            className: "b-method",
            options: methods,
            mandatory: true
        },
        {
            name: 'contact_first_name',
            component: "input",
            placeholder: "שם פרטי איש קשר",
            className: "b-contact_first_name"
        },
        {
            name: 'contact_last_name',
            component: "input",
            placeholder: "שם משפחה איש קשר",
            className: "b-contact_last_name"
        },
        {
            name: 'contact_phone',
            component: "input",
            placeholder: "טלפון איש קשר",
            className: "b-contact_phone"
        }
    ]

    const submitBtns = [
        {
            id: 0,
            label: "הוספה",
            color: "secondary",
            action: () => actionSubmit(formName)
        },
        {
            id: 1,
            label: "ביטול",
            color: "light",
            action: () => handleCreateAffiliationAlert(false)
        }
    ]


    return {
        formName,
        fields,
        title,
        submitBtns
    }
}

export default AffliationData