import styled from 'styled-components'
import { Center, ColumnCenter, Disabled, Scroll } from 'styles/GlobalCss'
import { black, secondary, white } from 'styles/theme'

const WrapPrices = styled.div`
${ColumnCenter};
height:100%;
row-gap: 30px;
color:${black};
`

const NoBalanceAlert = styled.div`
.alert_content{
 row-gap: 15px;
 flex-direction: column;
 display: flex;
}
.alert_btn{
 position: absolute;
 bottom: 50px;}
`

const WrapChildrenPayment = styled.div`

`

const WrapCouponCode = styled.div`
height: 10px;
position: relative;
bottom: 20px;
`

const CardTitle = styled.div`
font-weight: 700;
`

const WrapContentPadding = styled.div`
padding-left:20px;
padding-right:20px;
${ColumnCenter};
row-gap:20px;
flex-shrink: 0;
height: 138px;
`

const CardHeader = styled.div`
height: 100px;
flex-shrink: 0;
border-radius: 25px 25px 0px 0px;
border: 1px #CADDEA;
background: #CBE1FF;
font-size:20px;
overflow:hidden;
${ColumnCenter};
justify-content: start;
`

const Price = styled.div`
color: var(--, #09081E);
text-align: center;
font-size: 45px;
font-weight: 700;
line-height: 87%;
`

const CardContent = styled.div`
flex-grow:1;
${ColumnCenter};
justify-content:start;
row-gap:13px;
padding-top:10px;
padding-bottom: 10px;
overflow: auto;
box-sizing: border-box;
`

const Feature = styled.div`
font-size: 16px;
line-height: 25px; 
`

const WrapFeatures = styled.div`
width: 100%;
overflow: auto;
flex-grow: 1;

${Scroll};
`

const CardFooter = styled.div`
height: 62px;
flex-shrink: 0;
${Center};

.payment_btn{
pointer-events:${props => props.$disabled && 'none !important'}; 
}
`

const WrapDiscount = styled.div`
width: 204.14px;
height: 36px;
flex-shrink: 0;
color:white;

position: relative;
right: 104px;
z-index: 0;
top: 17px;
overflow: hidden;
transform: rotate(-46.991deg);
background: ${props => props.discount ? props.discount.color : "transparent"};

`

const Discount = styled.div`
    position: relative;
    top: 5px;
    ${Center};
`

const SubPrice = styled.div`
color: var(--, #09081E);
text-align: center;
font-size: 16px;
line-height: 87%; 
`

const Description = styled.div`
color: #91BDFA;
font-size: 16px;
font-weight: 400;
line-height: 18px;
`

const WrapPrice = styled.div`
${ColumnCenter};
row-gap:8px
`

const WrapCards = styled.div`
display: flex;
column-gap: 30px;
`

const Card = styled.div`
width: 278px;
height: ${props => props.height}px;
flex-shrink: 0;
border-radius: 25px;
border: 1px solid #CADDEA;
background: ${white};
display: flex;
flex-direction: column;
opacity:${props => props.$disabled && '0.5'}
`

const Title = styled.div`
font-size: 20px;
`

export { Description, SubPrice, NoBalanceAlert, WrapChildrenPayment, WrapCouponCode, CardTitle, WrapDiscount, Discount, Title, CardFooter, Feature, WrapPrice, WrapPrices, WrapFeatures, WrapCards, Price, CardContent, WrapContentPadding, Card, CardHeader }