import React, { useContext } from "react";
import { Close } from '@mui/icons-material';
import { Col, Table, WrapPhoneme } from './Style'
import { TranscriptionCurd } from './hooks'
import { keysSourceChanges } from "mock/sourceChanges";
import { IconButton, Input } from "@mui/material";
import { Categories, Phoneme } from "components/Admin/Transcription";
import { TranscriptionContext } from "./TranscriptionContext";
import { PlaceHolder } from "components/DataGrid";
import { updateType } from "keys";
import { asci } from "utils/hebrew";
import { omission } from "keys/types";

const Words = () => {
    const { insertion } = keysSourceChanges

    const { currentWord, setCurrentWord, vowelCodes, letterAddOns } = useContext(TranscriptionContext)
    const { phonemes } = currentWord || []

    const { addPhoneme } = TranscriptionCurd()

    const getTranscription = (phoneme = {}) => {
        const { letter = '', vowel_code, letter_add_on } = phoneme
        let transcription = ''

        transcription += letter || ''

        transcription += asci(vowelCodes?.[vowel_code]?.ascii_code)

        transcription += asci(letterAddOns?.[letter_add_on]?.ascii_code)

        return transcription
    }

    const deleteNewPhoneme = (index) => {
        let phonemes = [...currentWord.phonemes]
        phonemes[index].update_type = updateType.delete
        phonemes[index].hide = true
        setCurrentWord({ ...currentWord, phonemes: phonemes })
    }

    const saveNewPhoneme = (index, value) => {
        let phonemes = [...currentWord.phonemes]
        phonemes[index].letter = value
        setCurrentWord({ ...currentWord, phonemes: phonemes })
    }

    return (
        phonemes ?
            <Table>
                {phonemes?.map((phoneme, index) => {
                    const { letter_status, hide } = phoneme
                    return (hide || letter_status === omission) ||
                        <>
                            <div onClick={() => addPhoneme(index)} className="header header-plus">+</div>
                            <Col insertion={letter_status === insertion}>
                                <div className="header">
                                    {
                                        letter_status != insertion ?
                                            <WrapPhoneme>
                                                <Phoneme phoneme={phoneme} />
                                            </WrapPhoneme>
                                            :
                                            <>
                                                <IconButton className="delete-phoneme" onClick={() => deleteNewPhoneme(index)}>
                                                    <Close className="delete-phoneme-icon" />
                                                </IconButton>
                                                <Input
                                                    value={getTranscription(phoneme)}
                                                    inputProps={{
                                                        'aria-label': 'description',
                                                        maxLength: 1
                                                    }}
                                                    onChange={({ target }) => saveNewPhoneme(index, target.value)}
                                                />
                                            </>
                                    }
                                </div>
                                <Categories phoneme={phoneme} index={index} />
                            </Col >
                        </>
                }
                )
                }
                {
                    <div>
                        <div onClick={() => addPhoneme(phonemes.length)} className="header header-plus">+</div>
                    </div>
                }
            </Table >
            :
            <PlaceHolder />
    )
}

export default Words