import { useMutation } from "react-query"
import { useSelector } from "react-redux"
import { sendMessageFetch } from "store/middlewares/messages.crud"
import { isValid, submit } from "redux-form"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { toastConfig } from "config"
import constants from './constants'

const useSubmitBtn = () => {
    const { formname } = constants
    const dispatch = useDispatch()

    const formValues = useSelector(state => state.form[formname]?.values)
    const isFormValid = useSelector(state => isValid(formname)(state));

    const { mutateAsync: sendMessage, isLoading: sendMessageLoading } = useMutation({
        mutationFn: sendMessageFetch,
        onSuccess: () => {
            toast("פנייתכם התקבלה בהצלחה", toastConfig)
        }
    })

    const postMessage = () => {
        if (isFormValid)
            sendMessage(formValues)
        else dispatch(submit(formname))
    }

    const submitBtn = {
        label: "שליחה",
        action: postMessage,
        color: "secondary",
        fullWidth: true,
        loading: sendMessageLoading,
        disabled: sendMessageLoading
    }

    return submitBtn
}

export default useSubmitBtn


