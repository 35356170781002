import { getName } from "utils/formatter"


class Assessment {

    constructor(assessment) {
        const { audio_cutting_update_by_username, marking_words_update_by_username, transcription_start_date, transcription_end_date, transcription_update_by, transcription_update_by_username, transcription_duration, audio_cutting_start_date, audio_cutting_end_date, audio_cutting_update_by, audio_cutting_duration, marking_words_start_date, marking_words_end_date, marking_words_update_by, marking_words_duration, audio_file_url, first_name, test_taker_id, last_name, sub_status, reading_assessment_type, status, id, date, update_date, audio_data, reading_assessment_name, test_taker_name } = assessment

        this.id = id
        this.status = status
        this.date = date
        this.sub_status = sub_status
        this.audio_data = audio_data
        this.reading_assessment_name = reading_assessment_name
        this.first_name = first_name
        this.last_name = last_name
        this.update_date = update_date
        this.reading_assessment_type = reading_assessment_type
        this.test_taker_name = getName(assessment)
        this.test_taker_id = test_taker_id
        this.audio_file_url = audio_file_url
        this.marking_words_duration = marking_words_duration
        this.marking_words_update_by = marking_words_update_by
        this.marking_words_update_by_username = marking_words_update_by_username
        this.marking_words_end_date = marking_words_end_date
        this.marking_words_start_date = marking_words_start_date

        this.audio_cutting_duration = audio_cutting_duration
        this.audio_cutting_update_by = audio_cutting_update_by
        this.audio_cutting_update_by = audio_cutting_update_by_username
        this.audio_cutting_end_date = audio_cutting_end_date
        this.audio_cutting_start_date = audio_cutting_start_date
        
        this.transcription_duration = transcription_duration
        this.transcription_update_by = transcription_update_by
        this.transcription_end_date = transcription_end_date
        this.transcription_start_date = transcription_start_date
        this.transcription_update_by_username = transcription_update_by_username
    }
}

export default Assessment