import axios from 'config/axiosConfig'

import api, { assessment, go, reading_assessment, assessments } from 'keys/apis';
import { keyBy } from 'lodash';
import actions from 'store/actions';

let CancelToken = axios.CancelToken;

export const getRating = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_RATING') {
        let { id } = getState().assessment.assessment
        if (!id) id = action.payload

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_rating/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getRatingFetch = ({ id }) => {
    if (id)
        return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_rating/`)
}

export const getClassStatsFetch = ({ id }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_class_stats/`)
}

export const getMatchingLearning = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_MATCHING_LEARNING') {
        let { rating } = getState().rating

        const { percent_accuracy_rating_index, fluency_rating_index, fluency_rating_id, percent_accuracy_rating_id } = rating
        const maxIndicationRatingId = percent_accuracy_rating_index > fluency_rating_index ? percent_accuracy_rating_id : fluency_rating_id

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}score_rating_methods/${maxIndicationRatingId}/${api.get_matching_learning}/`, {
                // cancelToken: new CancelToken(function executor(c) {
                //     // An executor function receives a cancel function as a parameter
                //     dispatch(actions.updateController({ [api.get_matching_learning]: c }))
                // })
            }
            )
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getScoreRatingMethod = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_SCORE_RATING_METHOD') {
        let { id } = getState().assessment.assessment
        if (!id) id = action.payload

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_score_rating_method/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}

export const getScoreRatingMethodFetch = ({ dispatch, id }) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${assessments}${id}/get_score_rating_method/`)
}

export const getScoreRatingMethods = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_SCORE_RATING_METHODS') {
        const { testTaker } = getState().testTaker
        const { semester, assessment_def, t_class } = testTaker || {}

        let params = {}
        if (semester)
            params.semester = semester
        if (assessment_def)
            params.assessment_def = assessment_def
        if (t_class)
            params.t_class = t_class

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}score_rating_methods/`, {
                params: params
            })
                .then(response => {
                    const { data } = response
                    resolve(keyBy(data, "id"))
                })
                .catch(error => {
                    console.error(error);
                    return {}
                });
        })
    }
    return next(action)
}




