import React, { useEffect, useState, useRef } from 'react';
import { WrapProgressNum, WrapBackdrop } from './Style'

const Progress = () => {
    let nProegrss = 3
    const [progress, setProgress] = useState(nProegrss);
    const progressRef = useRef(nProegrss)

    const handleClose = () => {
        setProgress(nProegrss)
        progressRef.current = nProegrss
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (progressRef.current == 0) {
                clearInterval(timer)
            }
            else {
                let nProgress = progressRef.current - 1
                setProgress(nProgress);
                progressRef.current = nProgress
            }
        }, 1000);

        return () => {
            clearInterval(timer)
            handleClose()
        };
    }, []);

    return (
        <WrapBackdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <WrapProgressNum>
                {progress}
            </WrapProgressNum>
        </WrapBackdrop>
    );
}

export default Progress