import React from 'react';
import { GridColumnMenu } from '@mui/x-data-grid';
import { ColumnMenuItem } from 'components/DataGrid'


const CustomColumnMenu = (props) => {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuItem: ColumnMenuItem
            }}
            slotProps={{
                columnMenuUserItem: {
                    displayOrder: 1
                },
            }}
        />
    );
}

export default CustomColumnMenu