import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { isValid, destroy, initialize } from 'redux-form'
import { actions } from 'store'
import { CreateAssessmentRM } from "models/RM"
import { IndicationVM } from 'models/VM'
import { useRef } from 'react'
import { app } from 'components/Router/routes'
import { statuses } from 'keys'
import { StoreCrud } from 'store/hooks'
import { QueryClient, useQueryClient } from 'react-query'
import { queryClient as qc } from 'keys'
import { SuccessToast } from 'components/School/MakingRecordAssessments'
import { toastConfig } from 'config'
import { toast } from 'react-toastify'

const CreateAssessmentCrud = () => {
    const formName = "createAssessment"
    const dispatch = useDispatch()
    const { testTaker } = useSelector(state => state.testTaker)
    const { assessment } = useSelector(state => state.assessment)
    const { progressIndication } = useSelector(state => state.assessment)
    const isValidForm = useSelector((state) => isValid(formName)(state))
    const { updateAssessmentStatus, getAudioFile } = StoreCrud()

    const queryClient = useQueryClient()

    const isValidFormRef = useRef(isValidForm)
    const progressIndicationRef = useRef(progressIndication)
    const navigate = useNavigate()

    const updateProgress = (value) => {
        dispatch(actions.setProgressIndication(value))
    }

    const goRating = async () => {
        // navigate(`${app.assessments}/${id}`)
        // const file = await dispatch(actions.getAudioFile())
        // dispatch(actions.updateAssessment({ audio_file: file }))
    }

    const getProgressIndication = async (ref) => {
        if (assessment.id) {
            let indication = await dispatch(actions.getProgressIndication(assessment.id))
            let nIndication = {}
            Object.keys(indication).forEach(key => {
                nIndication[key] = new IndicationVM(
                    { key: key, value: indication[key] },
                    ref.current[key])
            });
            updateProgress(nIndication)
            ref.current = nIndication
        }
    }

    const createAssessment = async (values) => {
        if (assessment.id) {
            await dispatch(actions.uploadAudioFile({ id: assessment.id, file: values.file }))
            queryClient.invalidateQueries({ queryKey: qc.assessments })
        }
        else {
            let assessment = new CreateAssessmentRM(values, testTaker)
            try {
                let nAssessment = await dispatch(actions.createAssessment(assessment))
                dispatch(actions.setSnackbars({ type: toast.createAssessment, open: true }))
                updateAssessmentStatus(statuses.uploading, null, nAssessment.id)
                await dispatch(actions.uploadAudioFile({ id: nAssessment.id, file: values.file }))
                await updateAssessmentStatus(statuses.uploaded, null, nAssessment.id)
                queryClient.invalidateQueries({ queryKey: qc.assessments })
            }
            catch {
                console.log("error createAssessment")
            }
        }
    }

    const goAssessmentDefs = () => {
        navigate(app.createAssessmentRecord)
    }

    //change to test taker id
    const goCreateAssessment = (id) => {
        navigate(app.createAssessment, {
            state: {
                id: id
            }
        })
        dispatch(actions.updateTestTaker({ id: id }))
        dispatch(actions.setAssessment(null))
    }

    const cleanAssessment = (formName) => {
        // dispatch(reset(formName))
        dispatch(destroy(formName))
        dispatch(initialize(formName))
        dispatch(actions.setIsRunProgress(false))
        isValidFormRef.current = false
        progressIndicationRef.current = null
        dispatch(actions.setProgressIndication({}))
    }

    return {
        cleanAssessment,
        createAssessment,
        goAssessmentDefs,
        goCreateAssessment,
        getProgressIndication,
        updateProgress,
        goRating,
        formName
    }
}

export default CreateAssessmentCrud