import { actions } from "store"
import { AlertCrud } from "components/Alerts/hooks"
import { useDispatch, useSelector } from "react-redux"
import { alerts } from "keys"
import { TestTakersCrud } from "components/School/TestTakers/hooks"
import { convertSelectToValue } from "utils/formatter"
import { change, reset } from "redux-form"
import { useCallback } from "react"

const CreateUserCrud = () => {
    const formName = "createTestTaker"
    const dispatch = useDispatch()
    const { setAlerts } = AlertCrud()
    const createTestTakerValues = useSelector(state => state.form[formName]?.values)

    const resetData = () => {
        //clean the pull down options if the filters header are empty
        // if (!group) {
        //     dispatch(actions.setAffiliations(null))
        //     dispatch(actions.setGrades(null))
        //     dispatch(actions.setTClasses(null))
        // }
        // else if (group && !affiliation) {
        //     dispatch(actions.setGrades(null))
        //     dispatch(actions.setTClasses(null))
        // }
        // else if (affiliation && !grade) {
        //     dispatch(actions.setTClasses(null))
        // }

        dispatch(reset(formName))
    }

    const handleCreateClassAlert = useCallback((value) => {
        setAlerts(alerts.createClass, value)
    }, [])

    const cleanFormValues = (keys) => {
        keys.map((key) =>
            dispatch(change(formName, key, null))
        )
    }

    const handleChangeAffiliationGroup = async (key, value) => {
        const values = convertSelectToValue({ ...createTestTakerValues, [key]: value })
        try {
            const affiliations = await dispatch(actions.getAffiliations(values))
            dispatch(actions.setAffiliations(affiliations))
        }
        catch {
            console.log("error getAffiliations")
        }
    }

    const handleChangeAffiliation = async (key, value) => {
        cleanFormValues(["grade", "t_class"])

        const values = convertSelectToValue({ ...createTestTakerValues, [key]: value })
        try {
            const grades = await dispatch(actions.getGrades(values))
            dispatch(actions.setGrades(grades))
        }
        catch {
            console.log("error getGrades")
        }
    }

    const handleChangeTClasses = async () => {
    }

    const cancelAction = () => {
        resetData()
        handleCreateClassAlert(false)
    }

    return {
        cancelAction,
        handleChangeAffiliation,
        handleChangeAffiliationGroup,
        handleChangeTClasses,
        handleCreateClassAlert
    }
}

export default CreateUserCrud