import React from 'react'
import { Img } from 'styles/GlobalStyle';
import { CartoonItem, WrapCartoon, WrapForm, WrapFormFields } from './Style';

const AuthWithCartoon = (props) => {
    const { children, cartoon, cartoonSize } = props

    return (
        <>
            <WrapForm>
                <WrapFormFields>
                    {children}
                </WrapFormFields>
                <WrapCartoon>
                    <CartoonItem {...cartoonSize}>
                        <Img src={cartoon} />
                    </CartoonItem>
                </WrapCartoon>
            </WrapForm>
        </>
    )
}

export default AuthWithCartoon