import { useDispatch } from "react-redux"
import { actions } from 'store'

const Crud = () => {
    const dispatch = useDispatch()

    const closeSnackbar = (type) => {
        dispatch(actions.setSnackbars({ type: type, open: false }))
    }

    const openSnackbar = (type) => {
        dispatch(actions.setSnackbars({ type: type, open: true }))
    }

    return {
        closeSnackbar,
        openSnackbar
    }

}

export default Crud