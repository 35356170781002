import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { actions } from "store"
import { AssessmentRM } from "models/RM"
import { useMutation, useQueryClient } from "react-query"
import { markingWordsSaveDraftFetch } from "store/middlewares/markingWords.crud"
import { saveWordsFetch } from "store/middlewares/words.crud"
import { surferHooks } from 'components/Record';
import { queryClient as qc } from 'keys'

const MarkingWordsCrud = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const onPause = surferHooks.useOnPause()

    const { words } = useSelector(state => state.words)
    const { assessment } = useSelector(state => state.assessment)

    const { mutateAsync: markingWordsSaveDraftRQ, isLoading: markingWordsSaveDraftLoading } = useMutation(markingWordsSaveDraftFetch)
    const { mutateAsync: saveWordsRQ, isLoading: saveWordsLoading } = useMutation(saveWordsFetch)


    const saveMarkingWords = async () => {
        const nWords = await saveWordsRQ({ assessment, words })
        let data = new AssessmentRM({
            timePoint: assessment.timePoint,
            end: assessment.end >= 0 ? assessment.end : null,
            words: nWords,
        })
        return data
    }

    const markingWordsSaveDraft = async () => {
        onPause()
        const data = await saveMarkingWords()
        await markingWordsSaveDraftRQ({ id: assessment.id, data })
        queryClient.invalidateQueries({ queryKey: qc.assessmentAndWords })

        dispatch(actions.setIsCloseSttoper(true))
    }


    return {
        saveDraft: markingWordsSaveDraft,
        loading: markingWordsSaveDraftLoading || saveWordsLoading
    }
}

export default MarkingWordsCrud