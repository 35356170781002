
class Class {
    constructor(testTaker) {
        const { name, grade, group, affiliation } = testTaker
        this.name = name

        this.group = group.id
        this.grade = grade.id
        this.affiliation = affiliation.id
    }
}

export default Class