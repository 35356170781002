import { updateType } from "keys"
import TestTaker from "./TestTaker"


class CustomUser {

    constructor(user, sourceChildrenList) {
        const { balance, gender, email, username, user_type, password, first_name, last_name, children } = user

        this.username = username
        this.password = password
        this.gender = gender?.id
        this.first_name = first_name
        this.last_name = last_name
        this.user_type = user_type
        this.email = email
        this.balance = balance?.id || balance
        // this.children = getChildren(children, sourceChildrenList)
    }
}

const getChildren = (children, sourceChildrenList) => {
    let nChildren = []

    children.map((child) =>
        nChildren.push(new TestTaker(child, "parent"))
    )

    // sourceChildrenList.map((child) => {
    //     const childIndex = nChildren.findIndex(({ test_taker_id }) => child.test_taker_id === test_taker_id)
    //     if (childIndex < 0)
    //         nChildren.push({ ...child, update_type: updateType.delete })
    // })

    return nChildren
}


export default CustomUser