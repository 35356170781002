

const constants = {
    instructionsTitle: "לביצוע האבחון:",
    instructions: [
        {
            label: "לחצו על כפתור הצהוב להתחלת הקלטה"
        },
        {
            label: "לחצו על עמודי הספר בכדי לדפדף בין העמודים"
        },
        {
            label: "בסיום ההקלטה לחצו שוב על הכפתור הצהוב"
        },
        {
            label: "כפתור העלאה יהפוך לכתום לחצו עליו להעלות את האבחון"
        }
    ]
}

export default constants