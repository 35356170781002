import React from 'react'
import { WrapInput } from './Style'
import { gTheme } from 'styles/theme';

const SelectTime = (props) => {
    const { onChange, value, color, disabled } = props

    const themeColor = gTheme[color]?.main

    return (
        <WrapInput
            disabled={disabled}
            maxLength={2}
            color={themeColor}
            $border
            value={value}
            onChange={(({ target }) => onChange(target.value))}
            onDoubleClick={() => onChange("60")}
        />
    )
}
export default SelectTime