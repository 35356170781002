import React, { useContext } from "react";
import CategoryList from "./Category";
import { TranscriptionData } from './hooks'
import { LetterDescription, Substitution, LetterAddOns, Vowel, Omission, NA } from "components/Admin/Transcription";
import { TranscriptionContext } from "./TranscriptionContext";
import { changeItem } from "utils/array";
import { omission, substitution } from "keys/types";

const Words = (props) => {
    const { phoneme, index } = props
    const { editingOptions } = TranscriptionData()

    const { currentWord, setCurrentWord } = useContext(TranscriptionContext)

    const updatePhoneme = (phonemeIndex, phonemeVal) => {
        const nPhonemes = changeItem(currentWord.phonemes, phonemeIndex, phonemeVal)
        setCurrentWord({ ...currentWord, phonemes: nPhonemes })
    }

    const commonProps = {
        phoneme: phoneme,
        updatePhoneme: updatePhoneme,
        index: index
    }

    return (
        <div className="content">
            <CategoryList label={editingOptions[0].label}>
                <Vowel
                    {...editingOptions[0]}
                    {...commonProps}
                />
            </CategoryList>
            <CategoryList label={editingOptions[1].label}>
                <LetterAddOns
                    {...editingOptions[1]}
                    {...commonProps}
                />
            </CategoryList>
            <CategoryList label={editingOptions[2].label}>
                <LetterDescription
                    {...editingOptions[2]}
                    {...commonProps}
                />
            </CategoryList>
            <CategoryList label={editingOptions[3].label}>
                <Substitution
                    {...editingOptions[3].items[substitution]}
                    {...commonProps}
                />
                <Omission
                    {...editingOptions[3].items[omission]}
                    {...commonProps}
                />
            </CategoryList>
            <CategoryList label={editingOptions[4].label}>
                <NA
                    {...editingOptions[4]}
                    {...commonProps}
                />
            </CategoryList>
        </div>
    )
}

export default Words