import { AdminAssessmentsCrud } from "components/Admin/AdminAssessments/hooks"
import { surferHooks } from "components/Record"
import { statuses } from "keys"
import { pick } from "lodash"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { actions } from "store"
import { continueMarkingWordsFetch, startMarkingWordsFetch } from "store/middlewares/markingWords.crud"

const useStartMarkingWords = () => {
    const dispatch = useDispatch()

    const { assessment } = useSelector(state => state.assessment)
    const { isCloseSttoper } = useSelector(state => state.markingWords)

    const { openBackToMarkingWordsConfirmation, backToMarkingWordsLoading } = AdminAssessmentsCrud()
    const onPlay = surferHooks.useOnPlay()

    const { data: startMarkingWordsData, mutateAsync: startMarkingWordsRQ, isLoading: startMarkingWordsLoading } = useMutation(startMarkingWordsFetch, {})
    const { mutateAsync: continueMarkingWordsRQ, isLoading: continueMarkingWordsLoading } = useMutation(continueMarkingWordsFetch)

    const startMarkingWords = async (nAssessment) => {
        const res = await startMarkingWordsRQ(nAssessment)
        const assessmentDTO = pick(res?.data, ["status", "sub_status"])
        dispatch(actions.updateAssessment(assessmentDTO))
        dispatch(actions.setIsCloseSttoper(false))
        if (assessment.audio_file)
            onPlay()
    }

    const continueMarkWords = async () => {
        await continueMarkingWordsRQ(assessment)
        dispatch(actions.setIsCloseSttoper(false))
        if (assessment.audio_file)
            onPlay()
    }

    const markWords = {
        [statuses.inProgress]: {
            [statuses.wordsInProgress]: {
                label: "המשך סימון",
                action: continueMarkWords,
                loading: continueMarkingWordsLoading,
                disabled:
                    // startMarkingWordsData && 
                    !isCloseSttoper
            },
            [statuses.wordsComplete]: {
                label: "החזר לסימון",
                action: openBackToMarkingWordsConfirmation,
                loading: backToMarkingWordsLoading,
                disabled: backToMarkingWordsLoading
            }
        },
        [statuses.uploaded]: {
            "default": {
                label: "התחל סימון",
                action: startMarkingWords,
                loading: startMarkingWordsLoading,
                disabled: startMarkingWordsLoading
            }
        },
        [statuses.backToMarking]: {
            "default": {
                label: "התחל סימון",
                action: startMarkingWords,
                loading: startMarkingWordsLoading,
                disabled: startMarkingWordsLoading
            }
        },
        [statuses.brokenFile]: {
            "default": {
                label: "החזר לסימון",
                action: openBackToMarkingWordsConfirmation,
                loading: backToMarkingWordsLoading,
                disabled: backToMarkingWordsLoading
            }
        }
    }

    const markWordsValue = markWords[assessment.status]?.[assessment.sub_status || "default"]

    const startMark = [{
        label: markWordsValue?.label || "התחל סימון",
        color: "secondary",
        action: () => {
            markWordsValue?.action(assessment)
        },
        disabled: markWordsValue?.disabled || !markWordsValue,
        component: 'button',
        width: "200px",
        disabledColor: "gray2",
        loading: markWordsValue?.loading,
        hide: markWordsValue?.hide
    }]

    return startMark
}

export default useStartMarkingWords