
import React, { useState, useEffect, useRef } from 'react';
import { toastConfig } from 'config';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { markingWordsHooks } from '.';
import { Backdrop, Typography } from '@mui/material';
import { FreeBreakfast } from '@mui/icons-material';

const InactivityAlertComponent = () => {
    const [inactiveTime, setInactiveTime] = useState(0);
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const { words } = useSelector(state => state.words)
    const { isPlaying } = useSelector(state => state.wavesurfer)
    const { assessment } = useSelector(state => state.assessment)
    const { isCloseSttoper } = useSelector(state => state.markingWords)

    const { saveDraft } = markingWordsHooks.useSaveDraft()

    useEffect(() => {
        let inactivityTimer;

        const resetTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                // Show alert message after 10 seconds of inactivity
                if (!isCloseSttoper) {
                    // toast('לא בוצעה פעולה בזמן האחרון, אנחנו מעבירים אותך למצב הפסקה', toastConfig)
                    saveDraft()
                    setOpenBackdrop(true)
                }
            }, 10000); // 10 seconds
        };

        const handleUserActivity = () => {
            if (!isCloseSttoper && !isPlaying) {
                setInactiveTime(0);
                resetTimer();
            }
        };

        const handleUserInactivity = () => {
            // Increment inactiveTime if needed or perform any additional checks
            // For this example, we will reset the inactiveTime
            setInactiveTime(0);
            resetTimer();
        };

        window.addEventListener('mousemove', handleUserActivity); // Add other event listeners as needed

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            clearTimeout(inactivityTimer);
        };
    }, [assessment, isPlaying, words, isCloseSttoper]);

    return (
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={openBackdrop}
            onClick={() => setOpenBackdrop(false)}
        >
            <div>
                <h3>
                    {" לא בוצעה פעולה בשניות האחרונות, הועברת למצב הפסקה"}
                </h3>
            </div>
        </Backdrop>)
};

export default InactivityAlertComponent