import { get, isEmpty } from "lodash";

//add new item
const addItem = (array, index, newItem) => {
    if (!array) return
    const newArray = [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index)
    ];

    return newArray
}

const noneIncluded = (arr1, arr2) => {
    // Check if none of the items in arr1 are included in arr2
    return arr1.every(item => !arr2.includes(item));
}

//add new item
const removeItem = (array, index) => {
    const newArray = [...array]
    newArray.splice(index, 1)
    return newArray
}


const changeItem = (array, index, newItem) => {
    const newArray = [...array]
    newArray[index] = newItem
    return newArray
}

const splitArrayDynamically = (array, field) => {
    let result = array?.reduce((i => (r = [], s) => {
        if (get(s, field)) {
            r.push([]);
            i++;
        }
        if (isEmpty(r))
            r.push([])
        r[r.length ? r.length - 1 : 0].push(s);
        return r;
    })(0), []) || []

    return result
}

const replaceOddWithEven = (arr) => {
    for (let i = 0; i < arr.length - 1; i += 2) {
        let temp = arr[i];
        arr[i] = arr[i + 1];
        arr[i + 1] = temp;
    }
    return arr;
}

const searchByKeys = (list, search, keys) => {
    //search = searchVal
    //keys = ["keyName","keyName"]
    let listTemp = []
    list.filter(item => {
        let flagPush = false
        keys.some(key => {
            if (!flagPush)
                if (item[key] && item[key].toLowerCase().includes(search) || !search) {
                    listTemp.push({ ...item, notExist: false })
                    flagPush = true
                }
            // else
            //     listTemp.push({ ...item, notExist: true })
        }
        )
    }
    );

    return listTemp;
}

const convertDictArraytoArray = (array) => {
    const nObj = {}
    array.forEach((item, index) => {
        Object.keys(item).forEach((key) =>
            nObj[key] = item[key]
        )
    });
    return nObj
}

export {noneIncluded, convertDictArraytoArray, searchByKeys, replaceOddWithEven, splitArrayDynamically, addItem, changeItem, removeItem }
