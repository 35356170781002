import { useDispatch } from "react-redux"
import { submit } from "redux-form"

const FormCrud = () => {
    const dispatch = useDispatch()

    const actionSubmit = (formName) => {
        dispatch(submit(formName))
    }

    return {
        actionSubmit
    }

}

export default FormCrud