import { statuses } from 'keys';
import { correct, incorrect, minorError, omission, repetition, selfCorrection } from 'keys/types';
import { isEmpty, keyBy } from 'lodash'
import update_type from 'mock/updateType';

class AssessmentRM {
    constructor(assessmentData) {
        const { end, timePoint = {}, words } = assessmentData
        if (timePoint)
            this.time_point = timePoint.value

        let errorsCount = 0
        let correctWordsInTimePoint = 0
        let selfCorrectionAndMinorError = 0

        let customErrorsCount = {
            [repetition]: 0,
            [selfCorrection]: 0,
            [minorError]: 0,
            [omission]: 0,
        }

        for (let index = 0; index < words?.length; index++) {
            // if (index > end)
            //     break
            let word = words[index]
            const { errors, status } = word
            let nErrors = errors.filter((error) => error.update_type != update_type.delete)
            nErrors.forEach(error => {
                customErrorsCount[error.status]++
            });
            let errorKeys = keyBy(nErrors, "status")
            if (errorKeys[minorError] && errorKeys[selfCorrection]) {
                selfCorrectionAndMinorError += 1

                customErrorsCount[minorError]--
                customErrorsCount[selfCorrection]--
            }

            if (status === incorrect)
                errorsCount++

            if (index <= timePoint.index && word.status === correct)
                correctWordsInTimePoint++
        }

        this.repetition_count = customErrorsCount.REPETITION
        this.self_correction_count = customErrorsCount.SELF_CORRECTION
        this.errors_count = errorsCount
        this.omission_count = customErrorsCount.OMISSION
        this.minor_error_count = customErrorsCount.MINOR_ERROR
        this.self_correction_and_minor_error_count = selfCorrectionAndMinorError
        //TO DO
        // this.insertion_count = customErrorsCount.

        if (end) {
            this.words_count = end + 1 - customErrorsCount.OMISSION
            this.correct_words_count = this.words_count - errorsCount
        }
        else {
            this.words_count = words?.length - customErrorsCount.OMISSION
            this.correct_words_count = this.words_count - errorsCount
        }

        if (!isEmpty(timePoint)) {
            this.words_in_time_point = timePoint.index + 1 - customErrorsCount.OMISSION
            this.correct_words_in_time_point = correctWordsInTimePoint
        }
    }
}

export default AssessmentRM