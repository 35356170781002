import React from 'react';
import { WrapSetHeader, WrapSet, Title, WrapFooterBtns, WrapFooter, WrapList, WrapDroppable, WrapDragIcon, AssessmentName, WrapSectionList, WrapDraggable, WrapAssessmentName } from './Style'
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { UploadAudioData } from './hooks';
import { CustomSection, Space } from 'styles/GlobalStyle';
import { Button } from 'components/Form';
import { UploadItem } from 'components/School/UploadAudio';

const UploadAudioFile = (props) => {
    const { prevSelectedAssessments } = props

    const { selectedAssessmentDef } = useSelector(state => state.assessment)

    const { title, footerBtns } = UploadAudioData()

    return (
        <WrapSet>
            <WrapSetHeader>
                <Title>
                    {title}
                </Title>
            </WrapSetHeader>
            <WrapSectionList>
                <CustomSection $bg $grow $flex $border>
                    <WrapList className="characters">
                        {prevSelectedAssessments.map((prevSelectedAssessment) =>
                            <>
                                <UploadItem disabled item={prevSelectedAssessment} />
                                <Space />
                            </>
                        )}
                        {!isEmpty(selectedAssessmentDef) &&
                            <UploadItem item={selectedAssessmentDef} />
                        }
                    </WrapList>
                </CustomSection>
            </WrapSectionList>
            <WrapFooter>
                <WrapFooterBtns>
                    {footerBtns.map((btn, index) =>
                        <Button key={index} {...btn} action={() => btn.action(props)} />
                    )}
                </WrapFooterBtns>
            </WrapFooter>
        </WrapSet>
    );
}

export default UploadAudioFile;
