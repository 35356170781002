import styled from "styled-components"
import { Bold, Scroll, Shadow } from "styles/GlobalCss"
import { lightBlue2, rating1, rating2, rating3, secondary, white } from "styles/theme"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const WrapDraggable = styled(Draggable)`
width:100% !important;

`

const WrapDroppable = styled(Droppable)`
`

const WrapList = styled.div`
display: flex;
row-gap: 10px;
flex-direction: column;
width:100% !important;
height: 100%;
overflow: auto;
${Scroll};
`

const WrapDragIcon = styled.div`
display:flex;
align-items:center;
background:${secondary};
border-radius:8px;
padding:3px;
`

const AssessmentName = styled.div`
${Bold};
`

const WrapSet = styled.div`
height: 100%;
display:flex;
align-items:center;
flex-direction: column;
`

const WrapSectionList = styled.div`
height:100%;
width:100%;
overflow: auto;
display: flex;
flex-grow: 1;
`

const WrapItem = styled.li`

`

const Title = styled.div`
font-size:18px;
${Bold};
`

const WrapSetHeader = styled.div`
height: 70px;
display: flex;
flex-shrink: 0;
align-items: center;

`

const AssessmentItem = styled.div`
background: ${white};
padding: 15px;
border-radius: 8px;
justify-content: space-between;
display: flex;

${Shadow};
`

const WrapCreateSet = styled.div`
height: 100%;
overflow:auto;
${Scroll};
`

const WrapContent = styled.div`
display: grid;
grid-template-columns: auto 300px;
column-gap: 10px;
height: 100%;
`

const WrapAssessmentDefs = styled.div`
display: flex;
flex-direction: column;
row-gap: 8px;
height: 100%;
overflow:auto;
${Scroll};
`

const WrapFooterBtns = styled.div`
height: 30px;
display:flex;
justify-content:center;
width:100%;
`

const WrapFooter = styled.div`
height:70px;
display:flex;
align-items:center;
width:100%;
`


export { WrapFooter, WrapFooterBtns, AssessmentName, Title, WrapDroppable, WrapDraggable, WrapSectionList, WrapList, WrapSet, WrapItem, WrapSetHeader, WrapAssessmentDefs, WrapContent, WrapCreateSet, AssessmentItem, WrapDragIcon }