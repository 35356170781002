import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"


const DataGridCrud = () => {
    const dispatch = useDispatch()
    const { columnItems } = useSelector(state => state.general)

    const setColumnItems = (field) => {
        dispatch(actions.setColumnItems({ key: field, value: !columnItems[field] }))
    }

    return {
        setColumnItems
    }
}

export default DataGridCrud