import styled from "styled-components";
import { Hover } from "styles/GlobalCss";

const WordWithFrequency = styled.div`
border:${props => `2px solid ${props.color}`};

${Hover};
`

const AssessmentCount = styled.div`
height:30px;
backgroun
`

const WrapStatus = styled.div`
display:${props => props.hide && 'none'};
color:${props => props.color};
`

export { WrapStatus, WordWithFrequency, AssessmentCount }