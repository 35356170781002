import { Alert } from 'components'
import React from 'react'
import { WrapInstructions, WrapInstructionsAlert, InstructionsTitle, WrapCartoon } from './Style'
import { constants } from './hooks'
import { Img, WrapImg } from 'styles/GlobalStyle'
import { Go2 } from 'assets'
import { alerts } from 'keys'
import { AlertCrud } from 'components/Alerts/hooks'

const RecordInstructions = () => {
    const { instructions, instructionsTitle } = constants

    const { setAlerts } = AlertCrud()

    const handleInstructions = (val) => {
        setAlerts(alerts.recordInstructions, val)
    }

    return (
        <Alert systemUI
            handleAlert={handleInstructions}>
            <WrapInstructionsAlert>
                <WrapCartoon>
                    <WrapImg width={230} height={230}>
                        <Img src={Go2} />
                    </WrapImg>
                </WrapCartoon>

                <WrapInstructions>
                    <InstructionsTitle>
                        {instructionsTitle}
                    </InstructionsTitle>
                    <ol>
                        {instructions.map((instruction, index) =>
                            <li key={index}>{instruction.label}</li>
                        )}
                    </ol>
                </WrapInstructions>
            </WrapInstructionsAlert>
        </Alert>
    )
}

export default RecordInstructions