import { Button, Tooltip } from '@mui/material'
import { EmailAvailable } from './Style'
import React from 'react'


const EmailTooltip = (props) => {
    const { className } = props

    return (
        <div className={className}>
            <Tooltip title={"אנחנו צריכים את כתובת המייל כדי שנוכל לשלוח לכם עדכונים על הדוחות והאבחונים. לעולם לא נסחור במיילים שלכם ולא נשלח לכם מידע שאינכם מעוניינים בו"} placement='left'>
                <EmailAvailable variant='text'>
                    למה צריך כתובת זמינה?
                </EmailAvailable>
            </Tooltip>
        </div>
    )
}

export default EmailTooltip