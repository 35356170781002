import React, { useState } from 'react';
import { Tab, Box } from '@mui/material';
import { ratingHooks } from '.';
import { WrapTabs, Soon } from './Style'
import { dark } from 'styles/theme';
import { useTheme } from 'styles';
import { LightButton, ScrollBox, Title } from 'styles/GlobalStyle';
import { thirdly2 } from 'styles/theme';
import { Chip } from 'components/Form';

const ChallengesTabs = () => {
    const [value, setValue] = useState(0);

    const { Layout, Fonts } = useTheme()

    const challengesTabs = ratingHooks.useChallengesTabs()
    const matchingLeraningBtn = ratingHooks.useMatchingLeraningBtn()
    const { title } = ratingHooks.constants
    const { soon } = ratingHooks.constants

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ ...Layout.fullWidth, color: dark, ...Layout.grow, ...Layout.scroll, ...Layout.col }}>
            <div style={{ ...Layout.rowHCenter }}>
                <div style={{ width: '100px', ...Layout.shrink, ...Fonts.textSmall }}>{title}</div>
                <Box sx={{ ...Layout.grow, borderBottom: 1, borderColor: thirdly2, ...Layout.rowHCenter, ...Layout.justifyContentBetween, ...Layout.shrink, height: 45 }}>
                    <WrapTabs
                        value={value}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: dark,
                                fontWeight: 'bold'
                            }
                        }}
                        onChange={handleChange}
                        aria-label="basic tabs example">
                        {challengesTabs.map((tab, index) =>
                            <Tab
                                disabled={tab.disabled}
                                label={
                                    <div style={{ ...Layout.rowCenter, ...Layout.scGap }}>
                                        {tab.soon && <Chip borderColor="transparent">{soon}</Chip >}
                                        {tab.label}
                                    </div>
                                }
                                id={`simple-tab-${index}`}
                                aria-controls={`simple-tabpanel-${index}`}
                            />
                        )}
                    </WrapTabs>
                    <LightButton {...matchingLeraningBtn} />
                </Box>
            </div>
            {challengesTabs.map((tab, index) =>
                <ScrollBox
                    role="tabpanel"
                    hidden={value !== index}
                    id={`simple-tabpanel-${index}`}
                    aria-labelledby={`simple-tab-${index}`}
                    style={{ ...Layout.grow }}
                >
                    {value === index &&
                        <Box>
                            <tab.component />
                        </Box>}
                </ScrollBox>
            )}
        </Box>
    );
}

export default ChallengesTabs
