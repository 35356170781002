import { useContext } from "react"
import { PaymentContext } from "../PaymentContext"
import CouponDone from "../CouponDone"

const useCouponCode = () => {
    const { setCouponCode, couponCode } = useContext(PaymentContext)

    const coupon = {
        input: {
            onChange: setCouponCode,
            value: couponCode
        },
        endAdornment: {
            component: CouponDone
        },
        placeholder: "יש לך קוד קופון? הכנס כאן"
    }

    return coupon
}

export default useCouponCode