

class SourceWordVM {

    constructor(word) {
        const { id, transcription, word_def_text, word_def_index, word_def_id, word_def_fe_details } = word

        if (id)
            this.id = id
        this.status = undefined
        this.erros = []
        this.transcription = transcription
        this.word_def_text = word_def_text
        this.word_def_index = word_def_index
        this.word_def_id = word_def_id
        this.word_def_fe_details = word_def_fe_details
    }
}

export default SourceWordVM