

import en from 'config/strings'

//validation login form
const validate = (values) => {
    const errors = {}

    if (values) {
        if (!values.username) {
            errors.username = en.require('username')
        }

        if (!values.password) {
            errors.password = en.require('password')
        }
        else if (values.password.length < 5)
            errors.password = en.minimumChars

    }

    return errors;
}

export default validate;

