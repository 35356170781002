import React, { useCallback } from 'react'
import { AssessmentDef, Page } from 'components'
import Instructions from './Instructions'
import AssessmentRecordFooter from 'components/School/CreateAssessmentRecord/AssessmentRecordFooter'

const MakingRecordAssessments = () => {
    return (
        <Page>
            <AssessmentDef
                customHeader={{
                    right: [
                        { component: "custom", customComponent: Instructions }
                    ]
                }}
                student={true}
                hidePoints
                hideBackBtn />
            <AssessmentRecordFooter />
        </Page>
    )
}

export default MakingRecordAssessments