import { css } from 'styled-components'
import { primary, secondary } from './theme'

const Hover = css`
:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor:pointer;
}
`

const Link = css`
:hover{
  opacity:0.7;
  cursor:pointer;
}
`

const Removed = css`
  opacity:0.3;
`

const Disabled = css`
  pointer-events: none;
  opacity:0.5;
`

const Font = css`
// font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
// font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
font-family: "Calibri", sans-serif;

`

const Scroll = css`

&::-webkit-scrollbar {
  
  width: 4px;
  height: 3px !important;
}

&::-webkit-scrollbar-track {
  border-radius: 30px;
  // box-shadow: inset 0 0 5px rgba(51, 102, 255, 0.155731);
}

&::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #C7C4C4;

}

& ::-webkit-scrollbar-thumb:hover {
  background: #C7C4C4;
}




`

const WrapScreen = css`
width:100%;
height:100%;
display: flex;
row-gap: 20px;
flex-direction: column;
`


const Section = css`
background: #FFFFFF;
border-radius: 12px;
padding:20px;
height: 100%;
box-sizing: border-box;
`

const Center = css`
display: flex;
align-items: center;
justify-content: center;
`

const ColumnCenter = css`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

`

const CustomButton = css`
padding: 15px;
color:#416783;
text-align: right;
border-radius: 10px;
border: 1px solid ${props => props.selected ? 'black' : '#D7D7D7'};
display: flex;
align-items: center;
column-gap: 10px;
background:${props => props.selected && '#F3F3F3'};
opacity:${props => props.disabled && '0.5'};
`

const Shadow = css`
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08) !important;
`

const Bold = css`
font-weight: bold;
`

const FabShadow = css`
box-shadow:0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)
`

export { ColumnCenter, Link, Removed, Disabled, Bold, FabShadow, Shadow, Center, Font, Scroll, CustomButton, Hover, WrapScreen, Section }