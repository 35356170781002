import { keysSourceChanges } from "./sourceChanges"


const clarityCharacters = {
    1: {
        id: 1,
        name: "אות לא ברור",
        type: 'letter_status',
        action: (phoneme, isStatusNA) => {
            let nPhoneme = { ...phoneme }
            if (phoneme) {
                const { phoneme_def_letter, phoneme_def_letter_add_on } = phoneme
                if (isStatusNA)
                    nPhoneme = {
                        ...nPhoneme,
                        letter_status: keysSourceChanges.correct,
                    }
                else {
                    nPhoneme = {
                        ...nPhoneme,
                        letter_status: keysSourceChanges.na,
                        letter: phoneme_def_letter,
                    }
                }
            }
            return nPhoneme
        }
    },
    2: {
        id: 2,
        name: "ניקוד לא ברור",
        type: 'vowel_status',
        action: (phoneme, isStatusNA) => {
            let nPhoneme = { ...phoneme }
            if (phoneme) {
                let { phoneme_def_vowel_code } = phoneme
                if (isStatusNA)
                    nPhoneme = {
                        ...nPhoneme,
                        vowel_status: keysSourceChanges.correct,

                    }
                else {
                    nPhoneme = {
                        ...nPhoneme,
                        vowel_status: keysSourceChanges.na,
                        vowel_code: phoneme_def_vowel_code,
                    }
                }
            }
            return nPhoneme
        }
    }
}

export { clarityCharacters }