import styled from "styled-components";
import { Disabled } from "styles/GlobalCss";


const WrapFooter = styled.div`
display: flex;
justify-content: end;
column-gap: 10px;
height:70px;
align-items: center;
${props => props.$disabled && Disabled};
`

const WrapEndActions = styled.div`
display: flex;
column-gap: 10px;
`

const WrapHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`

const WrapActionBtn = styled.div`
width:${props => props.width}px;
height:30px;
`


export { WrapFooter, WrapActionBtn, WrapHeader, WrapEndActions }