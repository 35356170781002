import { correct } from 'keys/types'
import Phoneme from './Phoneme'
import { PhonemeVM } from '.'

class NestedWord {
    constructor(word, staticWord = {}) {
        const { word_def_id, word_def_text, word_def_index, transcription, phoneme_defs, errors = [], phonemes, index, text, status = 'CORRECT', id } = word

        if (word_def_id)
            this.id = id
        this.index = index
        this.status = status || correct
        this.word_def_text = word_def_text
        this.word_def_id = word_def_id
        this.word_def_index = word_def_index || index
        this.errors = errors
        this.transcription = transcription
        this.phonemes = getPhonemes(phonemes)
    }
}

const getPhonemes = (phonemes) => {
    let nPhonemes = []

    phonemes?.forEach(phoneme => {
        let nPhoneme = new PhonemeVM(phoneme)
        nPhonemes.push(nPhoneme)
    });

    return nPhonemes
}

export default NestedWord
