const female = "FEMALE"
const male = "MALE"

const gender = {
    female: female,
    male: male
}

// export { female, male }
export default gender
