import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AssessmentDefs, Page } from 'components'
import { MatchingLearningCrud, MatchingLearningData } from 'components/School/MatchingLearning/hooks'
import { SubTitle } from 'styles/GlobalStyle'
import { constants } from './hooks'

const MatchingLearning = () => {
    const { init, cleanData } = MatchingLearningCrud()
    const { headerActions } = MatchingLearningData()
    const { assessment } = useSelector(state => state.assessment)
    const { matchingLearning, rating } = useSelector(state => state.rating)

    useEffect(() => {
        init()
    }, [rating, assessment])

    useEffect(() => {
        return (() => {
            cleanData()
        })
    }, [])

    return (
        <Page>
            <SubTitle>{constants.title} </SubTitle>
            <AssessmentDefs
                headerActions={headerActions}
                assessments={matchingLearning || []}
                cleanData={cleanData} />
        </Page>
    )

}

export default MatchingLearning