import React from 'react'
import { Chip } from './Styled'

const CustomChip = (props) => {
    const { bg, children ,borderColor} = props

    return (
        <Chip bg={bg} borderColor={borderColor}>
            {children}
        </Chip>
    )
}

export default CustomChip