import { constants } from '.'
import { FormCrud } from 'components/Form/hooks'
import { isSubmitting } from 'redux-form'
import { useSelector } from 'react-redux'

const useSubmit = () => {
    const { actionSubmit } = FormCrud()

    const { formName } = constants

    const isSubmittingForm = useSelector((state) => isSubmitting(formName)(state))


    const submitBtns = [
        {
            id: 0,
            label: "שליחה",
            type: "submit",
            color: "secondary",
            fullWidth: true,
            action: () => actionSubmit(formName),
            loading: isSubmittingForm
        }
    ]

    return submitBtns
}

export default useSubmit