import { useMutation, useQuery, useQueryClient } from "react-query"
import { alerts, queryClient as qc } from 'keys'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { bulkCreateByLevelFetch, getAssessmentFetch, getAssessmentReportFetch, getAssessmentsFetch, getAudioFileFetch, getFrequencyByWordDefFetch, getFrequencyFetch, saveAssessmentFetch } from "store/middlewares/assessment.crud"
import { useParams, useSearchParams } from "react-router-dom"
import { secretStorage } from "config"

const useGetAssessment = () => {
    const dispatch = useDispatch()
    const currentAssessment = useSelector(state => state.assessment.assessment)
    const params = useParams()
    const [searchParams] = useSearchParams();

    let assessmentID = params?.assessmentID

    // let assessmentID = currentAssessment?.id
    if (!assessmentID)
        assessmentID = currentAssessment?.id || searchParams.get('assessment')

    return useQuery(
        {
            queryKey: qc.assessment,
            queryFn: ({ signal }) => getAssessmentFetch({ id: assessmentID, signal, }),
            enabled: !!assessmentID,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                const nAssessment = res.data
                dispatch(actions.updateAssessment(nAssessment))
                dispatch(actions.editTestTaker({ test_taker_id: nAssessment.test_taker_id }))
            }
        })
}

const useGetAudioFile = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)

    return useQuery({
        queryKey: qc.getAudioFile,
        queryFn: ({ signal }) => getAudioFileFetch({ assessmentID: assessment.id, signal }),
        refetchOnWindowFocus: false,
        enabled: !!assessment?.audio_file_url,
        refetchIntervalInBackground: false,
        retry: 0,
        onSuccess: (res) => {
            if (res) {
                const audioURL = URL.createObjectURL(new Blob([res.data]));
                dispatch(actions.updateAssessment({ audio_file: audioURL }))
            }
        }
    }
    )
}

const useGetAssessments = () => {
    const dispatch = useDispatch()
    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters, statusFilters } = useSelector(state => state.assessment)

    return useQuery({
        queryKey: qc.assessments,
        queryFn: ({ signal }) => getAssessmentsFetch({ assessmentFilters, statusFilters, headerFilters, testTaker, signal }),
        // onSuccess: (res) => {
        //     // dispatch(actions.setAssessments(res.data))
        // }
    })
}

const useSaveAssessment = () => {
    const { assessment } = useSelector(state => state.assessment)

    return useMutation({
        mutationFn: (assessmentToSave) => saveAssessmentFetch({ assessmentID: assessment.id, assessment: assessmentToSave })
    })
}

const useGetAssessmentReport = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters, statusFilters } = useSelector(state => state.assessment)

    return useQuery({
        queryKey: qc.report,
        queryFn: ({ signal }) => getAssessmentReportFetch({ assessmentFilters, statusFilters, headerFilters, testTaker, signal })
    })
}

const useGetFrequency = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters, statusFilters } = useSelector(state => state.assessment)

    return useQuery({
        queryKey: qc.assessmentFrequency,
        enabled: false,
        refetchOnWindowFocus: false,
        queryFn: ({ signal }) => getFrequencyFetch({ assessmentFilters, statusFilters, headerFilters, testTaker, signal })
    })
}

const useGetFrequencyByWordDef = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters, statusFilters } = useSelector(state => state.assessment)

    return useQuery({
        queryKey: qc.wordFrequency,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryFn: ({ word_def }) => {
            debugger
            return getFrequencyByWordDefFetch({ word_def, assessmentFilters, statusFilters, headerFilters, testTaker })
        }
    })
}

const useBulkCreateByLevel = (testTakerID) => {
    const user = secretStorage.getUser()
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (test_taker_id) => bulkCreateByLevelFetch({ test_taker_id: testTakerID || test_taker_id }),
        onSuccess: () => {
            let userData = { ...user, balance: user.balance - 1 }
            secretStorage.setUser(userData)
            dispatch(actions.setUser(userData))
            dispatch(actions.setAlerts({
                key: alerts.createTestTaker,
                value: {
                    open: false,
                }
            }))
            queryClient.invalidateQueries({ queryKey: qc.dashboard })
        },
        onError: () => {
            dispatch(actions.setAlerts({
                key: alerts.createTestTaker,
                value: {
                    open: false
                }
            }))
            dispatch(actions.setAlerts({
                key: alerts.noBalance,
                value: {
                    open: true,
                    props:
                        testTakerID ? {
                            test_taker_id: testTakerID
                        } : null
                }
            }))
        }
    })
}

export default { useGetFrequencyByWordDef, useGetFrequency, useBulkCreateByLevel, useGetAssessmentReport, useSaveAssessment, useGetAssessment, useGetAudioFile, useGetAssessments }

