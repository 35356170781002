import { useQuery, useQueryClient } from "react-query"
import { queryClient as qc } from 'keys'
import { getAssessmentDefFetch, getAssessmentDefsFetch } from "store/middlewares/assessment.crud"
import { useSearchParams } from "react-router-dom"
import { actions } from "store"
import { useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { useSelector } from "react-redux"

const useGetAssessmentDef = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()

    const assessmentDefID = searchParams.get('assessmentDef');

    return useQuery(
        {
            queryKey: qc.assessmentDef,
            queryFn: ({ signal }) => getAssessmentDefFetch({ id: assessmentDefID, signal, }),
            enabled: !!assessmentDefID,
            onSuccess: (res) => {
                dispatch(actions.setAssessmentDef(res.data))
            }
        })
}

const useGetAssessmentDefs = () => {
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters } = useSelector(state => state.assessment)

    const dispatch = useDispatch()

    return useQuery(
        {
            queryKey: qc.assessmentDefs,
            refetchOnWindowFocus: false,
            retry: 0,
            queryFn: ({ signal }) => getAssessmentDefsFetch({ headerFilters, assessmentFilters, signal, }),
        })
}

const useGetAllAssessmentDefs = () => {
    const { assessmentDefs } = useSelector(state => state.assessment)

    const dispatch = useDispatch()

    return useQuery(
        {
            queryKey: qc.allAssessmentDefs,
            enabled: isEmpty(assessmentDefs),
            refetchOnWindowFocus: false,
            queryFn: ({ signal }) => getAssessmentDefsFetch({ signal, }),
            onSuccess: (res) => {
                dispatch(actions.setAssessmentDefs(res.data))
            }
        })
}


export default { useGetAssessmentDefs, useGetAssessmentDef, useGetAllAssessmentDefs }