import { RateAndAccuracy } from "..";

const useChallengesTabs = () => {

    const tabs = [
        {
            label: "קצב ודיוק",
            component: RateAndAccuracy
        },
        {
            label: "אבני יסוד",
            soon: true,
            disabled: true
        },
        {
            label: "הבנת הנקרא ופרוזודיה",
            disabled: true

        },
        {
            label: "תפקודי שפה",
            disabled: true

        },
        {
            label: "תפקודי ניהול",
            disabled: true

        }
    ]

    return tabs
}

export default useChallengesTabs;