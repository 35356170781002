import React, { useEffect, useRef, useState } from "react";
import './PageFlipSass.scss'
import { isEmpty } from "lodash";
import Title from 'components/Words/PageFlip/Title'
import Cover from 'components/Words/PageFlip/Cover'
import Page from "components/Words/PageFlip/Page";
import HTMLFlipBook from "react-pageflip";

const CustomBook = (props) => {
    const { pages, title } = props

    const flipBook = useRef()


    return (
        <>
            {!isEmpty(pages) &&
                <HTMLFlipBook
                    startPage={pages.length + 1}
                    width={203}
                    height={123}
                    autoSize={true}
                    disableFlipByClick={false}
                    size="stretch"
                    minWidth={200}
                    maxWidth={610}
                    minHeight={120}
                    maxHeight={850}
                    maxShadowOpacity={0.5}
                    showCover={true}
                    mobileScrollSupport={true}
                    onChangeState={(e) => {
                    }}
                    onChangeOrientation={() => { }}
                    className="demo-book"
                    usePortrait
                    ref={(el) => (flipBook.current = el)}
                >
                    <Cover />
                    {pages?.map((page, index) =>
                        <Page key={index} number={pages.length - index} firstPage={index == pages.length - 1} title={title}>
                            {page.map((word) =>
                                word
                            )}
                        </Page>
                    )}
                    <Cover firstPage>
                        <Title>
                            {title}
                        </Title>
                    </Cover>
                </HTMLFlipBook >
            }
        </>
    )
}

export default CustomBook
