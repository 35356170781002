import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AllAssessmentsData, AllAssessmentsCrud } from './hooks';
import { Assessments } from 'components/School/AssessmentsAndTestTakers';
import { headercss } from './Style'
import { dataGridModel } from 'keys';
import { assessmentRQ } from 'store/hooks';
import { assessmentUtils } from 'helpers';

const AllAssessments = () => {
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters } = useSelector(state => state.assessment)
    const { testTaker } = useSelector(state => state.testTaker)

    const { headerItems, fields } = AllAssessmentsData()
    const {
        cleanData,
    } = AllAssessmentsCrud()

    const { data: assessmentsData, refetch: refetchAssessments, remove: removeAssessments } = assessmentRQ.useGetAssessments()

    const refetchData = () => {
        removeAssessments()
        refetchAssessments()
    }

    useEffect(() => {
        refetchData()
    }, [headerFilters, testTaker])

    useEffect(() => {
        return (() => {
            cleanData()
        })
    }, [])

    const firstAssessments = assessmentsData?.data && Object.values?.(
        assessmentsData?.data.reduce((acc, obj) => {
            if (!acc[obj.test_taker_id]) {
                acc[obj.test_taker_id] = { ...obj, assessment_count: 1, assessment_group: [obj] };
            } else {
                acc[obj.test_taker_id].assessment_count += 1;
                acc[obj.test_taker_id].assessment_group.push(obj)
            }
            return acc;
        }, {})
    );

    return (
        <Assessments
            visibilityModelKey={dataGridModel.all_assessments}
            fields={fields}
            headerItems={headerItems}
            refetchData={refetchData}
            data={firstAssessments}
        />
    );
}

export default AllAssessments
