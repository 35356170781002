import { KeyboardVoice } from '@mui/icons-material'
import { useGeneralData } from "components/General/hooks"
import { IconButton, Button } from "components/Form"

const AssessmentDefData = () => {
    const { goBackRating } = useGeneralData()

    const actionsBtns = [
        {
            id: 0,
            component: IconButton,
            icon: <KeyboardVoice />
        },
        {
            id: 2,
            label: "הקלטה מחדש",
            disabled: true,
            component: Button
        },
        {
            id: 3,
            label: "העלאה",
            disabled: true,
            component: Button
        }


    ]

    const headerActions = [
        {
            ...goBackRating,
        }
    ]

    return {
        headerActions,
        actionsBtns
    }
}
export default AssessmentDefData