import React, { useState, useEffect } from 'react';
import { WrapStack } from './Style'
import { Stack, CircularProgress } from '@mui/material';

const CircularDeterminate = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 250);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <WrapStack spacing={2} direction="row">
            <CircularProgress color='secondary' variant="determinate" value={progress} />
        </WrapStack>
    );
}

export default CircularDeterminate
