import { useGeneralData } from "components/General/hooks";
import { app } from "components/Router/routes";
import { useNavigate } from "react-router-dom"

const AssessmentData = () => {
    const navigate = useNavigate()
    const { goBackRating } = useGeneralData()

    const headerActions = [
        goBackRating,
        {
            id: 1,
            label: 'לקריאה מותאמת',
            component: 'button',
            color: "dark",
            height:'40px',
            action: () => navigate(app.matchingLearning)
        }
    ]

    return {
        headerActions
    }
}

export default AssessmentData