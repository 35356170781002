import { statuses } from 'keys'

const AdminAssessmentsData = () => {
    const { inProgress, backToCutting, brokenFile, wordsComplete, wordsInProgress, uploaded, backToMarking, audioCuttingComplete, audioCuttingInProgress } = statuses

    return [
        {
            id: 0,
            label: "ממתינים לחיתוך",
            status: [inProgress, backToCutting],
            sub_status: wordsComplete
        },
        {
            id: 1,
            label: "בחיתוך",
            status: [inProgress],
            sub_status: audioCuttingInProgress
        },
        {
            id: 2,
            label: "חיתוך הסתיים",
            status: [inProgress],
            sub_status: audioCuttingComplete
        },
        {
            id: 3,
            label: "אודיו לא תקין",
            status: [brokenFile],
            sub_status: ""
        }
    ]

}



export default AdminAssessmentsData
