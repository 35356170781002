

const constants = {
    omission: "השמטה",
    minorError: "שגיאה קלה",
    selfCorrection: "היסוס",
    error: "שגיאה",
    errorWithSelfCorrection: "תיקון עצמי של שגיאה",
    minorErrorWithSelfCorrection: "תיקון עצמי של שגיאה קלה"
}

export default constants