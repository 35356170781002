import styled, { css } from "styled-components"
import { dark } from "styles/theme"
import { Link } from "styles/GlobalStyle"
import { Center } from "styles/GlobalCss"

const WrapAuth = styled.div`
display: flex;
height: 100%;
`

const Logo = styled.div`
width:70px;
height:70px;
position: absolute;
top: 10px;
left: 25px;
`

const InstructionItem = styled.li`
color: rgba(0, 0, 0, 0.50);
font-size: 17px;
`

const Instructions = styled.ul`
margin-block-start: inherit;
display: flex;
flex-direction: column;
row-gap: 6px;
`

const FormContent = styled.div`
width:400px;

`

const ForgotUserNameOrPassword = styled.div`
height:50px;
${Center};
`

const RegisterLink = styled(Link)`
    position: relative;
    top: 100px;
`

const WrapLogo = styled.div`
width: 208.77px;
height: 156.28px;
`

const Left = styled.div`
background:${dark};
width:40%;
display: flex;
align-items: center;
justify-content: center;
`

const Right = styled.div`
width:60%;
display: flex;
align-items: center;
justify-content: center;
`


const WrapForm = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:85%;
position: relative;
min-width:800px;
column-gap:50px;

@media only screen and (min-width: 1900px) {
column-gap:100px;
}

@media only screen and (min-height: 550px) {
top: 50px;
}
`

const WrapFormFields = styled.div`
width:430px;
`

const WrapCartoon = styled.div`
display:flex;
justify-content:start;
`


const CartoonItem = styled.div`
width: ${props => props.height || "350"}px;
height: ${props => props.height || "350"}px;
`

export { CartoonItem, WrapCartoon, WrapFormFields, WrapForm, Logo, FormContent, WrapLogo, ForgotUserNameOrPassword, WrapAuth, Instructions, InstructionItem, RegisterLink, Left, Right }