import React from 'react';
import { Stepper, Step, StepLabel, StepButton } from '@mui/material';
import { WrapStepper } from './Style'
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

const AssessmentStepper = () => {
    const { assessment } = useSelector(state => state.assessment)
    
    const { state } = useLocation();
    const { assessmentsGroup } = state || {}

    const assessmentIndex = assessmentsGroup?.findIndex((nAssessment) => nAssessment.id === assessment.id)

    return (
        isEmpty(assessmentsGroup) || <WrapStepper>
            <Stepper nonLinear activeStep={assessmentIndex}>
                {[...assessmentsGroup, {}].map((assessment, index) => (
                    <Step
                        sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: 'dark.dark', // circle color (COMPLETED)
                            },
                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                            {
                                color: 'grey.500', // Just text label (COMPLETED)
                            },
                            '& .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed': {
                                color: 'thirdly.main', // circle color (ACTIVE)
                            },
                            '& .MuiStepLabel-root .Mui-active': {
                                color: 'dark.main', // circle color (ACTIVE)
                            },
                            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                            {
                                color: 'common.white', // Just text label (ACTIVE)
                            },
                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fill: 'white', // circle's number (ACTIVE)
                            },
                        }}
                        key={index} completed={assessment.complete}>
                        <StepLabel color="inherit">
                            {assessment.reading_assessment_title || "סִיּוּם"}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </WrapStepper>
    );
}

export default AssessmentStepper