import en from "config/strings/en"
import dayjs from "dayjs"
import { validateIsraeliIdNumber } from "utils/validation"

const validate = (child) => {
    const errors = {}

    if (child) {
        if (!child.first_name)
            errors.first_name = en.require('first_name')
        if (!child.last_name)
            errors.last_name = en.require('last_name')
        if (!child.dob)
            errors.dob = en.require('dob')
        else if (new Date().getFullYear() - new Date(dayjs(child.dob)).getFullYear() < 3)
            errors.dob = en.minAge

        if (!child.school)
            errors.school = en.require('school')
        if (!child.grade)
            errors.grade = en.require('grade')
        if (!child.t_class)
            errors.t_class = en.require('t_class')

        if (!child.tz)
            errors.tz = en.require('tz')
        else if (!validateIsraeliIdNumber(child.tz))
            errors.tz = en.invalidTZ

        if (!child.gender)
            errors.gender = en.require('gender')
    }

    return errors;
}

export default validate