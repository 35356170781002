import { useDispatch } from "react-redux"
import { actions } from 'store'

const AlertCrud = () => {
    const dispatch = useDispatch()

    const setAlerts = (type, value) => {
        dispatch(actions.setAlerts(
            {
                key: type,
                value: { open: value }
            }))
    }

    return {
        setAlerts
    }
}

export default AlertCrud