import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { WrapLetter, VowelCode } from './Style'
import { TranscriptionContext } from './TranscriptionContext'
import { types } from 'keys'
import { na } from 'keys/types'

const RenderPhoneme = (props) => {
    const { phoneme } = props
    const { letter_status, vowel_status, substitution_letter, phoneme_def_letter, phoneme_def_letter_add_ons, phoneme_def_letter_description, phoneme_def_vowel_code } = phoneme
    const { wordIndex } = useSelector(state => state.words)

    const { vowelCodes, letterAddOns } = useContext(TranscriptionContext)

    const [vowel, setVowel] = useState(vowelCodes?.[phoneme_def_vowel_code]?.html_code)
    const [add_on, setAddOn] = useState(vowelCodes?.[phoneme_def_vowel_code]?.html_code)
    const letterVal = substitution_letter || phoneme.letter || phoneme_def_letter
    const [letter, setLetter] = useState(letterVal)

    const updateRender = () => {
        let nVowel = vowelCodes?.[phoneme_def_vowel_code]?.html_code
        if (nVowel)
            if (vowel_status === na || letter_status === na)
                setVowel(null)

            else
                setVowel(nVowel)
        else setVowel(null)

        if (letter_status === na)
            setLetter('?')
        else
            setLetter(letterVal)

        setLetter(letterVal)
    }

    useEffect(() => {
        updateRender()
    },
        [
            phoneme_def_vowel_code,
            phoneme.phoneme_def_letter, phoneme.etter,
            vowel_status, letter_status, wordIndex, substitution_letter
        ]
    )

    const renderAddOn = () => {
        return phoneme?.letter_add_ons.map(({ letter_add_on }) =>
            !(add_on && vowel_status != na && letter_status != na) ||
            <span key={letterAddOns?.[letter_add_on]?.html_code} dangerouslySetInnerHTML={{ __html: letterAddOns?.[letter_add_on]?.html_code }} />
        )
    }

    return (
        vowel ?
            <WrapLetter>
                {letter}
                <VowelCode bold={phoneme.edited} className='vowelCode'
                    dangerouslySetInnerHTML={{ __html: vowelCodes?.[phoneme_def_vowel_code]?.html_code }}
                />
                {renderAddOn()}
            </WrapLetter> :
            <WrapLetter>
                {letter}
                {renderAddOn()}
            </WrapLetter>
    )

}

export default RenderPhoneme