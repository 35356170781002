import { Button, IconButton } from "@mui/material";
import styled, { css } from "styled-components";
import { Disabled, Hover, Scroll, Section } from "styles/GlobalCss";
import { black } from "styles/theme";
import { Font } from "styles/GlobalCss";
import { WrapContent } from "styles/GlobalStyle";


const Content = styled(WrapContent)`
display: flex;
flex-direction: column;
row-gap: 10px;
box-sizing: border-box;
height: 100%;
overflow: auto;
`

const WrapPhoneme = styled.div`
font-size:20px;
`

const LetterAddOnRow = styled.div`
display:flex;
column-gap: 5px;

.letter_add_on_col{
    width:50%;
}
`

const Vowel = styled(Button)`
    border-radius: 6px;
    border: 0.5px dashed #E4E6EF !important;
    background: rgba(245, 248, 250, 0.30) !important;
    padding: 10px !important;
    display: block !important;
    width:100%;
    color: #7D7D7D !important;
    text-align: right !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    ${Font};

    background: ${props => props.bold && 'rgb(199, 196, 196)'} !important;;


    color:${props => props.defualtVowel && black} !important;;
    border: ${props => props.defualtVowel && '1px solid #37940C'} !important;;
    background: ${props => props.defualtVowel && '#C3FFA7'} !important;;

    color:${props => props.error && black} !important;
    background:${props => props.error && 'rgba(253, 0, 0, 0.30)'} !important;
    border: ${props => props.error && '1px solid #FD0000'} !important;;

    background:${props => props.defualtDisabled && 'rgba(71, 221, 1, 0.17)'} !important; 
    border: ${props => props.defualtDisabled && 'none'} !important;
    color:${props => props.defualtDisabled && '#7D7D7D'} !important; 

    opacity:${props => props.disabled && '0.4'} !important; 


    background:${props => props.source && 'rgba(71, 221, 1, 0.17)'} !important; 
    border: ${props => props.source && 'none'} !important;
    color:${props => props.source && '#7D7D7D'} !important; 

    color:${props => props.correct && black} !important;;
    border: ${props => props.correct && '1px solid #37940C'} !important;;
    background: ${props => props.correct && '#C3FFA7'} !important;

    color:${props => props.incorrect && black} !important;
    background:${props => props.incorrect && 'rgba(253, 0, 0, 0.30)'} !important;
    border: ${props => props.incorrect && '1px solid #FD0000'} !important;;
`

const WrapLetter = styled.span`

`

const VowelCode = styled.span`
`

const Col = styled.div`
background: #FFF;
box-shadow: 0px 0px 20px 0px rgba(56, 71, 109, 0.03);
#FFFFFF;
width: 170px;
min-width: 170px;
border:${props => props.insertion && '1px solid #FD0000'};
display: flex;
flex-direction: column;
.phoneme{
    font-size:20px !importnat;
}
`

const Table = styled.div`
overflow-x:auto;
display: flex;
height: 100%;
overflow: auto;
${Scroll};

&::-webkit-scrollbar {
    width: 5px;
    height: 13px !important;
}

border-collapse: separate;
display: flex;

.col-plus{
}

.delete-phoneme{
    position: relative;
    left: 15px;
    background: #b1b1b1 !important;

}

.delete-phoneme-icon{
    width:10px !important;
    height:10px !important;

}

.header{
    font-weight: 600;
    font-size: 15px;
    height: 20px;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    background: #C7C4C4;
    
}

.content-grid{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}


input{
    font-weight: 600;
    font-size: 20px;
    height: auto !important;
    outline: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    -webkit-appearance: none;
    background: transparent;
    border-bottom: 1px solid;
    width: 20px;
    text-align: center;
}

.content{
    box-sizing: border-box;
    padding:5px;
    padding-top:10px;
    row-gap: 10px;
    display: flex;
    flex-direction: column;
    background: #FFF;
    height: 100%;
    flex-grow: 1;
    overflow: auto;
    ${Scroll};

}

.content-items{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.header-plus{
    width: 15px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FCA350;
    display: flex;
    cursor:pointer;
    color: #000;
    font-size: 16px;
    font-weight: 600;

    ${Hover};
}

.title{
    color: #121212;
    text-align: right;
    ${Font};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
`

const WrapTranscription = styled.div`
    flex-grow: 1;
    height: 100%;
    overflow: auto;

    ${props => props.$disabled && Disabled};
`


const Letter = css`
color: var(--Black, #000);
text-align: right;
${Font};
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
display:flex;
column-gap: 2px;

`

const StyleIconButton = styled(IconButton)`
display: flex !important;
// margin-top: 7px !important;
width: 30px !important;
height: 30px !important;
padding: 12px 16px !important;
justify-content: center !important;
align-items: center !important;
gap: 10px !important;
flex-shrink: 0;
border-radius: 8px !important;
background: #37940C !important;

.play-icon{
// transform: scaleX(-1) !important;
display: flex;
width: 20px  !important;
height: 20px  !important;
justify-content: center;
align-items: center  !important;
color:white  !important;
flex-shrink: 0 ;
border-raduis:10px  !important;
}
`

const Word = css`
height:100px;
`

const WrapHeader = styled.div`
height:100%;
display: flex;
box-sizing: border-box;
width:100%;
justify-content: space-between;
align-items:center;

.assessment-data{
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.words{
    display: flex;
    column-gap: 30px;
    ${props => props.$disabled && Disabled}
}

.playAudio{
    display:flex;
}

.rightWord{
${Letter};
${Word};
}

.clear{
    width:150px;
}

.clearData{
    display: flex;
    align-items: center;
}

.wrapWrod{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.word{
    letter-spacing: 4px;
    display: flex;
    column-gap: 2px;
}

.wordType{
column-gap: 10px;
color: #7D7D7D;
text-align: right;
${Font};
font-size: 14px;
font-style: normal;
font-weight: 100;
line-height: normal;
text-transform: capitalize;
display: flex;
align-items: center;
}

.letter{
    position: relative;
}

.vowelCode{
    color:${props => props.bold && 'red'}}
}

.wrap-letter{
    background: #C7C4C4;
    padding:2px;
    min-width:20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wordAndType{
    display: flex;
    row-gap: 10px;
    column-gap: 3px;
    height:40px;
    align-items: center;
    justify-content: center;

    ${Letter};
}

.worngWord{
    display:flex;
    ${Letter};
    align-items: center;
    padding: 1px;
    column-gap: 3px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    ${Word};
 
}

.btn{
    height:40px !important;
}
`

const SaveActions = styled.div`
    display: flex;
    column-gap: 10px;
    height:40px;
    ${props => props.$disabled && Disabled}
`

export { SaveActions, WrapHeader, StyleIconButton, WrapTranscription, Content, VowelCode, WrapLetter, LetterAddOnRow, Table, Vowel, Col, WrapPhoneme }