import { dark, secondary } from "styles/theme"


const usePrices = () => {
    const prices = [
        {
            index: 0,
            height: 475,
            title: "אבחון בסיסי",
            subTitle: "לאיתור קשיים",
            price: '78',
            discount: {

            },
            subPrice: "תשלום חד פעמי",
            description: "סינון מהיר הנותן תוצאות מדוייקות של קצב ורמת הדיוק בקריאה וחומרי קריאה מותאמים ליכולות",
            features: [
                {
                    label: "דוח קצב ודיוק ו WCPM"
                },
                {
                    label: "מיקום ביחס לשכבת הגיל"
                },
                {
                    label: "התאמת טקסטים לתרגול"
                }
            ],
            button: {
                label: (val, count) => count > 1 ? `לתשלום ₪${val * count}  (₪${val}X${count})` : `לתשלום ₪${prices[0].price}`,
            }
        },
        {

            index: 1,
            height: 520,
            title: "אבחון מורחב",
            subTitle: "לשטף קריאה",
            price: '199',
            discount: {
                color: dark,
                label: "בקרוב!"
            },
            subPrice: "תשלום חד פעמי",
            description: "מידע מורחב על היכולות והאתגרים בשטף הקריאה, מיקום ביחס לשכבה, המלצות לטיפול וחומרי קריאה מותאמים",
            features: [
                {
                    label: "דוח שטף קריאה מורחב"
                },
                {
                    label: "מיקום ביחס לשכבת הגיל"
                },
                {
                    label: "המלצות טיפול"
                },
                {
                    label: "התאמת טקסטים לתרגול"
                }
            ],
            button: {
                label: (val) => "בקרוב!",
            },
            disabled: true
        },
        {
            index: 2,
            height: 575,
            title: "אבחון עומק",
            subTitle: "לתמונה מלאה",
            price: '350',
            discount: {
                color: dark,
                label: "בקרוב!"
            },
            subPrice: "תשלום חד פעמי",
            description: "מידע מקיף על היכולות והאתגרים במיומנויות המהוות את תשתית הקריאה ותוכנית תמיכה מלאה מותאמת אישית",
            features: [
                {
                    label: "קצב, דיוק ושטף קריאה"
                },
                {
                    label: "אבני יסוד"
                },
                {
                    label: "הבנת הנקרא ופרוזודיה"
                },
                {
                    label: "סגנון עבודה (תפקודי ניהול)"
                },
                {
                    label: "תפקודי שפה דבורה וכתובה"
                },
                {
                    label: "יכולות חזותיות ושמיעתיות"
                },
                {
                    label: "תפקוד רגשי"
                }
            ],
            disabled: true,
            button: {
                label: (val) => "בקרוב!",
            }
        }
    ]

    return prices
}

export default usePrices 