import React, { useContext } from 'react'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { WrapHeader, StyleIconButton, SaveActions } from './Style'
import { CircularProgress } from '@mui/material'
import { PauseOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RecordCrud } from 'components/Record/hooks';
import { TranscriptionContext } from 'components/Admin/Transcription/TranscriptionContext';
import { Phoneme } from 'components/Admin/Transcription';
import { isEmpty } from 'lodash';
import { TranscriptionCurd } from 'components/Admin/Transcription/hooks';
import { omission } from 'keys/types';
import { CustomSection } from 'styles/GlobalStyle';
import { Button } from 'components/Form';

import transcriptionHooks from './hooks'
import { surferHooks } from 'components/Record';

const Header = (props) => {
    const { disabledContent } = props
    const { isPlaying } = useSelector(state => state.wavesurfer)
    const { currentWord, words } = useContext(TranscriptionContext)
    const { phonemes } = currentWord || []

    const onPlayPause = surferHooks.useOnPlayPause()

    const {
        cleanTranscription,
        deleteWordLoading, saveWordLoading
    } = TranscriptionCurd()

    const startTranscrption = transcriptionHooks.useStartTranscription()
    const saveActions = transcriptionHooks.useSaveActions()

    const sourceWord = "המילה הנכונה"
    const transcriptionWord = "המילה שהתקבלה"

    const loadingWords = saveWordLoading || isEmpty(currentWord)

    return (
        <CustomSection $bg height={120} $flex>
            <WrapHeader className='assessment-data'
            // $disabled={disabledContent}
            >
                <div className='words'>
                    <div className='wrapWrod'>
                        <div className='wordAndType'>
                            <StyleIconButton onClick={onPlayPause}>
                                {isPlaying ? <PauseOutlined className='play-icon' /> : <PlayArrowOutlinedIcon className='play-icon' />}
                            </StyleIconButton>
                            <div style={{ width: '10px' }} />
                            {loadingWords ?
                                <CircularProgress size={20} /> :
                                currentWord?.word_def_text}
                        </div>
                        <div className='wordType'>
                            <div>
                                {sourceWord}
                            </div>
                        </div>
                    </div>

                    <div className='wrapWrod'>
                        <div className='wordAndType'>
                            {loadingWords ? <CircularProgress size={20} /> :
                                phonemes?.map((phoneme, index) => {
                                    const { update_type, letter_status, hide } = phoneme
                                    return (
                                        (hide || letter_status === omission) ||
                                        <div className='wrap-letter' key={index}>
                                            <div class='letter'>
                                                <Phoneme phoneme={phoneme} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='wordType'>
                            {transcriptionWord}
                        </div>

                    </div>
                    <div className='clearData'>
                        <Button className='clear' loading={deleteWordLoading} action={cleanTranscription} color="secondary" disabled={!currentWord?.id} label="איפוס" variant='outlined' />
                    </div>
                </div>
                <Button {...startTranscrption} />
                {
                    <SaveActions
                    // $disabled={disabledContent}
                    >
                        {saveActions.map((headerAction) =>
                            <Button {...headerAction} />
                        )}
                    </SaveActions>
                }
            </WrapHeader>
        </CustomSection>
    )
}

export default Header