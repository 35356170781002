import React, { createContext, useEffect, useState } from 'react'
import { RegistrationRoutes } from 'components/Router'
import { WrapRegister, WrapHeader } from './Style'
import { useLocation, useNavigate } from 'react-router-dom'
import { formname, login, userLogin } from './hooks/constants';
import { app } from 'components/Router/routes';
import { alerts, updateType } from 'keys';
import { AlertCrud } from 'components/Alerts/hooks';
import { secretStorage } from 'config';
import { arrayPush, change, reset } from 'redux-form';
import { useDispatch } from 'react-redux';
import { validate } from './Validate'
import { Link } from 'styles/GlobalStyle';

import Papa from 'papaparse';
import schoolsFile from 'mock/Schools.csv';
import { isEmpty, uniqueId } from 'lodash';
import { Form } from 'components/Form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getOpenTestTakersFetch } from 'store/middlewares/testTakers.crud';
import { ContactUsFab } from 'components/ContactUs';

const RegirsterWrap = () => {
    const dispatch = useDispatch()
    const { setAlerts } = AlertCrud()

    const secretUser = secretStorage.getItem("user")

    const { state } = useLocation()
    const { user } = useSelector(state => state.user)

    const [step, setStep] = useState(state?.step || 0)
    const [schools, setSchools] = useState(null)
    const [childrenList, setChildrenList] = useState()
    const [currentChild, setCurrentChild] = useState()
    const [couponCode, setCouponCode] = useState(null)

    const userStorage = secretStorage.getItem("user")
    const formValues = useSelector(state => state.form[formname]?.values)

    const cleanState = () => window.history.replaceState({}, '')

    const { refetch: refetchChildren } = useQuery({
        queryFn: () => getOpenTestTakersFetch({ user_id: userStorage.user_id }),
        enabled: false
    })

    useEffect(() => {
        if (state?.step) {
            setStep(state.step)
        }
    }, [state?.step])

    useEffect(() => {
        if (state?.openNewChild) {
            dispatch(reset(formname))
            setAlerts(alerts.newChild, true)

        }
    }, [state?.openNewChild])

    useEffect(() => {
        window.addEventListener("beforeunload", cleanState);
        return () => {
            window.removeEventListener("beforeunload", cleanState);
        };
    }, []);

    useEffect(() => {
        if (!schools)
            fetch(schoolsFile)
                .then(response => response.text())
                .then(csvData => {
                    Papa.parse(csvData, {
                        header: true,
                        complete: (results) => {
                            console.log("schools")
                            setSchools(results.data)
                        },
                        error: (error) => {
                            console.error('CSV Parsing Error:', error);
                        }
                    });
                })
                .catch(error => {
                    console.error('Error fetching CSV file:', error);
                });
    }, [])

    const newChild = { uuid: uniqueId(), update_type: updateType.create, last_name: formValues?.last_name }

    const addNewChild = () => {
        dispatch(arrayPush(formname, "children", newChild))
    }

    const getChildren = async () => {
        let children = []

        if ((!isEmpty(user) || userStorage) && isEmpty(formValues?.children)) {
            const res = await refetchChildren()
            children = res.data
        }
        else if (formValues?.children)
            children = formValues?.children
        dispatch(change(formname, "children", children))
        setChildrenList(children)
    }

    useEffect(() => {
        getChildren()
    }, [])

    const commonValue = {
        step, setStep,
        childrenList, setChildrenList,
        currentChild, setCurrentChild,
        couponCode, setCouponCode,
        numSteps: 3,
        formname: "register",
        schools,
        addNewChild
    }

    return (
        <RegisterContext.Provider value={commonValue}>
            <WrapRegister>
                <WrapHeader>
                    <Link to={app.login}>
                        {isEmpty(secretUser) ? login : userLogin}
                    </Link>
                </WrapHeader>
                <Form
                    form={formname}
                    title={null}
                    validations={validate}
                    submitBtns={[]}
                    submit={() => { }}
                    initialValues={{
                        balance: 1
                    }}
                >
                    <RegistrationRoutes />
                </Form>
            </WrapRegister>
            <ContactUsFab />
        </RegisterContext.Provider>
    )
}

export const RegisterContext = createContext()
export default RegirsterWrap