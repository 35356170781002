import React, { useState } from 'react'
import { RateAndAccuracyItem, RateAndAccuracyLabel, GraphDisplay, WrapAccordion } from './Style'
import { GradeGraph, ratingHooks } from '.'
import { useTheme } from 'styles'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';
import { ReactComponent as ChildrenIcon } from 'assets/icons/Children.svg';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Divider, SvgIcon } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

const RateAndAccuracy = () => {
    const [showAllChildren, setShowAllChildren] = useState({
        fluency: true,
        accuracy: true,
        factored_accuracy: true
    })

    const { Layout, Gutters } = useTheme()

    const rateAndAccuracyItems = ratingHooks.useRateAndAccuracyItems()

    const { rating } = useSelector(state => state.rating)

    const updateGraphDisplay = (e, key, val) => {
        setShowAllChildren({ ...showAllChildren, [key]: val })
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <div>
            {rateAndAccuracyItems.map((item) =>
                <>
                    <WrapAccordion>
                        <AccordionSummary
                            sx={{
                                ...Layout.fullHeight,
                                pointerEvents: !item.expand && "none"
                            }}
                            expandIcon={
                                item.expand &&
                                <ExpandMore
                                    sx={{
                                        pointerEvents: "auto"
                                    }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <div style={{ ...Layout.rowHCenter, ...Layout.justifyContentBetween, ...Layout.fullWidth }}>
                                <div style={{ ...Layout.rowHCenter, ...Layout.mcGap }}>
                                    {item.label && <RateAndAccuracyLabel>{item.label}</RateAndAccuracyLabel>}
                                    {isEmpty(rating) || <item.text />}
                                </div>
                                <div style={{ ...Layout.rowHCenter, ...Layout.mcGap, ...Gutters.tinyLPadding }}>
                                    {item.showGraphBtns &&
                                        <React.Fragment>
                                            <GraphDisplay onClick={(e) => updateGraphDisplay(e, item.type, false)} color="thirdly2" variant={showAllChildren[item.type] ? "text" : "contained"}>
                                                <SvgIcon fontSize="small" component={ChildIcon} inheritViewBox />
                                            </GraphDisplay>
                                            <GraphDisplay onClick={(e) => updateGraphDisplay(e, item.type, true)} color="thirdly2" variant={showAllChildren[item.type] ? "contained" : "text"}>
                                                <SvgIcon fontSize="small" component={ChildrenIcon} inheritViewBox />
                                            </GraphDisplay>
                                        </React.Fragment>}
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GradeGraph percent={item.percent} type={item.type} showAllChildren={showAllChildren[item.type]} />
                        </AccordionDetails>
                    </WrapAccordion>
                    {/* <RateAndAccuracyItem style={{ ...Gutters.smallTPadding, ...Gutters.smallBPadding }}>
                        <div style={{ ...Layout.rowHCenter, ...Layout.justifyContentBetween }}>
                            <div style={{ ...Layout.rowHCenter, ...Layout.mcGap }}>
                                {item.label && <RateAndAccuracyLabel>{item.label}</RateAndAccuracyLabel>}
                                {isEmpty(rating) || <item.text />}
                            </div>
                            <div style={{ ...Layout.rowHCenter, ...Layout.mcGap, ...Gutters.tinyLPadding }}>
                                <GraphDisplay onClick={() => updateGraphDisplay(item.type, false)} color="thirdly2" variant={showAllChildren[item.type] ? "text" : "contained"}>
                                    <SvgIcon fontSize="small" component={ChildIcon} inheritViewBox />
                                </GraphDisplay>
                                <GraphDisplay onClick={() => updateGraphDisplay(item.type, true)} color="thirdly2" variant={showAllChildren[item.type] ? "contained" : "text"}>
                                    <SvgIcon fontSize="small" component={ChildrenIcon} inheritViewBox />
                                </GraphDisplay>
                            </div>
                        </div>
                        <Space />
                        <GradeGraph percent={item.percent} type={item.type} showAllChildren={showAllChildren[item.type]} />
                    </RateAndAccuracyItem> */}
                    {/* <Divider sx={{ borderColor: thirdly2 }} /> */}
                </>
            )}
        </div>
    )
}

export default RateAndAccuracy