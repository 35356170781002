
import { useDispatch, useSelector } from "react-redux"
import { correct, incorrect, na } from "keys/types"
import { changeItem } from "utils/array"
import { actions } from 'store'
import { alerts, updateType } from "keys"
import { useNavigate } from "react-router-dom"
import { useReactToPrint } from "react-to-print"

const MarkWordsCrud = () => {
    const dispatch = useDispatch()

    const { words } = useSelector(state => state.words)
    const { assessment } = useSelector(state => state.assessment)
    const navigate = useNavigate()
    const handlePrint = useReactToPrint({
        documentTitle: "Go-Read",
        removeAfterPrint: true,
        pageStyle: { background: 'red', color: "red", padding: '30px' }
    });

    const printWords = (contentToPrint) => {
        handlePrint(null, () => contentToPrint.current);
    }

    const selectWordError = (index) => {
        let nWord = { ...words[index] }
        //cancel removed 
        nWord.removed = false
        // nWord.status = correct

        const { status = correct } = nWord

        //first click
        if (status === correct && !nWord.minorError)
            nWord.status = incorrect

        //third click
        if (nWord.minorError) {
            nWord.minorError = false
            nWord.status = correct
        }
        //second click
        else if (!nWord.minorError && status === incorrect) {
            nWord.minorError = true
        }

        nWord.update_type = updateType.update
        dispatch(actions.updateSingleWord({ index: index, word: nWord }))
    }

    const removeWord = (index) => {
        let nWord = { ...words[index] }
        nWord.removed = !nWord.removed
        if (nWord.removed)
            nWord.status = incorrect
        else
            nWord.status = correct
        nWord.minorError = false
        nWord.customStatus = false
        nWord.update_type = updateType.update
        dispatch(actions.updateSingleWord({ index: index, word: nWord }))
    }

    const setCustomStatus = (index) => {
        let nWord = { ...words[index] }
        nWord.customStatus = !nWord.customStatus
        nWord.update_type = updateType.update
        nWord.removed = false
        // nWord.status = nWord.status === incorrect || undefined
        dispatch(actions.updateSingleWord({ index: index, word: nWord }))
    }

    const updateDynamicPointTime = (value, index) => {
        if (value)
            if (Number(value) <= 60) {
                const timePoint = {
                    index: index,
                    value: value
                }
                dispatch(actions.updateAssessment({ timePoint: timePoint }))
            }
            else {
                alert("הכנס מספר עד 60")
            }
        else
            dispatch(actions.updateAssessment({ timePoint: {} }))
    }

    const updateWordEnd = (index) => {
        if (assessment.end === index)
            dispatch(actions.updateAssessment({ end: null }))
        else
            dispatch(actions.updateAssessment({ end: index }))
    }

    const goAllAssessments = () => {
        navigate(-1)
    }

    const getPointsL = (sumWords) => {
        return sumWords * 2
    }

    const getPointsP = (sumWords) => {
        return sumWords
    }

    return {
        selectWordError,
        goAllAssessments,
        getPointsL,
        getPointsP,
        setCustomStatus,
        updateDynamicPointTime,
        updateWordEnd,
        printWords,
        removeWord
    }
}

export default MarkWordsCrud