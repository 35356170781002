import { app } from "components/Router/routes"
import { isEmpty } from "lodash-contrib"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { actions } from "store"

const useSelectAndViewAssessment = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()

    const { statusFilters, assessmentFilters } = useSelector(state => state.assessment)

    const selectAssessment = async (assessment, route) => {
        dispatch(actions.setAssessment(assessment))
        dispatch(actions.setTestTaker({ test_taker_id: assessment.test_taker_id }))
        navigate(`${route}/${assessment.id}`, {
            state: {
                statusFilters: isEmpty(statusFilters) ? state?.statusFilters : statusFilters,
                assessmentFilters: isEmpty(assessmentFilters) ? state?.assessmentFilters : assessmentFilters
            },
        })
    }
    return selectAssessment
}

const useSelectAndMarking = () => {
    const selectAndViewAssessment = useSelectAndViewAssessment()

    return (assessment) => {
        selectAndViewAssessment(assessment, app.markingWordsAssessments)
    }
}

const useSelectAndCutting = () => {
    const selectAndViewAssessment = useSelectAndViewAssessment()

    return (assessment) => {
        selectAndViewAssessment(assessment, app.audioCuttingAssessments)
    }
}

const useSelectAndTranscription = () => {
    const selectAndViewAssessment = useSelectAndViewAssessment()

    return (assessment) => {
        selectAndViewAssessment(assessment, app.transcriptionAssessments)
    }
}


export { useSelectAndTranscription, useSelectAndCutting, useSelectAndMarking }