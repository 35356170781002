import React from 'react'

const Category = (props) => {
    const { label } = props

    return (
        <div className="content-grid">
            <div className="title">{label}</div>
            <div className="content-items" >
                {props.children}
            </div>
        </div>
    )
}

export default Category