import { AssessmentRM, RegionRM } from "models/RM"
import { useContext } from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { audioCuttingCompleteFetch, audioCuttingSaveDraftFetch } from "store/middlewares/markingWords.crud"
import { saveRegionsFetch } from "store/middlewares/region.crud"
import { lightDark, secondary } from "styles/theme"
import { CutRecordContext } from "../CutRecordContext"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { app } from "components/Router/routes"

const useSaveActions = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { assessment } = useSelector(state => state.assessment)
    const { state } = useLocation()
    const { regions } = useContext(CutRecordContext)

    const { mutateAsync: audioCuttingSaveDraftRQ, isLoading: saveDraftLoading } = useMutation(audioCuttingSaveDraftFetch, {})
    const { mutateAsync: audioCuttingCompleteRQ, isLoading: completeLoading } = useMutation(audioCuttingCompleteFetch, {})
    const { mutateAsync: saveRegionsRQ, isLoading: saveRegionsLoading } = useMutation(saveRegionsFetch)

    const goAdminAssessments = () => {
        dispatch(actions.setStatusFilters(state.statusFilters))
        navigate(app.audioCuttingAssessments)
    }

    const saveAudioCutting = async () => {
        let nRegions = []
        regions.forEach(region => {
            nRegions.push(new RegionRM(region))
        });
        await saveRegionsRQ({ assessment, regions: nRegions })
        return new AssessmentRM(assessment)
    }

    const audioCuttingSaveDraft = async () => {
        const data = await saveAudioCutting()
        await audioCuttingSaveDraftRQ({ id: assessment.id, data })
        dispatch(actions.setIsCloseSttoper(true))
    }

    const audioCuttingComplete = async () => {
        const data = await saveAudioCutting()
        await audioCuttingCompleteRQ({ id: assessment.id, data })
        dispatch(actions.setIsCloseSttoper(true))
        goAdminAssessments()
    }

    // const markingWordsFinish = async () => {
    //     await markingWordsComplete()
    //     goAdminAssessments()
    // }

    // const markingWordsSaveAndNext = async () => {
    //     await markingWordsComplete()

    //     const adminAssessments = queryClient.getQueryData(qc.adminAssessments)?.data

    //     let assessmentIndex = adminAssessments?.findIndex((ass) => ass.id === assessment.id)
    //     let assessmentLength = adminAssessments?.length

    //     if (assessmentIndex < assessmentLength - 1) {
    //         goWords(adminAssessments[assessmentIndex + 1])
    //         dispatch(actions.setWords(null))
    //     }
    // }

    const actionBtns = [
        // {
        //     id: 1,
        //     label: "ניקוי",
        //     color: "dark",
        //     // action: cleanMarkingWords,
        //     width: 80,
        //     height: "35px",
        // },
        {
            id: 0,
            label: saveDraftLoading || "שמירה",
            action: audioCuttingSaveDraft,
            color: "primary",
            loading: saveDraftLoading,
            width: 110,
            height: "35px",
            disabled: saveDraftLoading,
            disabledColor: "gray2"
        },
        // {
        //     id: 2,
        //     label: completeLoading || "שמירה ומעבר למבדק הבא",
        //     action: markingWordsSaveAndNext,
        //     color: "secondary",
        //     loading: completeLoading,
        //     width: 200,
        //     // disabled: completeDisabled,
        //     themeColor: secondary,
        //     disabledColor: "gray2",
        //     height: "35px"
        // },
        {
            id: 3,
            label: completeLoading || "סיום",
            action: audioCuttingComplete,
            color: "lightDark",
            loading: completeLoading,
            width: 80,
            // disabled: completeDisabled,
            themeColor: lightDark,
            disabledColor: "gray2",
            height: "35px",
        }
    ]

    return actionBtns
}

export default useSaveActions