import React, { useState } from 'react'
import Payment from './Payment'

const PaymentPage = (props) => {
    const [couponCode, setCouponCode] = useState(null)

    const commonState = {
        couponCode, setCouponCode
    }

    return (
        <PaymentContext.Provider value={commonState}>
            <Payment {...props} />
        </PaymentContext.Provider>
    )
}

export const PaymentContext = React.createContext()
export default PaymentPage