import { useSelector } from "react-redux"
import { formname } from "./constants"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { toastConfig } from "config"
import { sendVerificationEmailFetch } from "store/middlewares/auth.crud"
import { customUserRQ } from "store/hooks"
import { EmailTooltip, EmailVarification } from ".."

const constants = {
    title: "הי, נתחיל בהכרות קצרה :)"
}

const useFields = () => {
    const fields = [
        {
            name: "first_name",
            component: "input",
            placeholder: "שם פרטי של ההורה",
            className: "_first_name",
            autocomplete:"given-name",
        },
        {
            name: "last_name",
            component: "input",
            placeholder: "שם משפחה",
            className: "_last_name",
            autocomplete:"family-name"
        },
        // {
        //     name: 'gender',
        //     component: "autoComplete",
        //     placeholder: "מי אני?",
        //     options: [{ id: "MALE", name: "אבא" }, { id: "FEMALE", name: "אמא" }],
        //     className: "_gender"
        // },
        {
            name: "email",
            autocomplete:"email",
            component: "input",
            placeholder: "כתובת אימייל",
            className: "_email"
        },
        {
            name: "email_tooltip",
            component: "custom",
            customComponent: EmailTooltip,
            label:"למה צריך כתובת זמינה",
            className: "_email_tooltip"
        }
    ]


    return fields
}


const useNext = () => {

    const continueBtn = {
        label: "המשך",
        systemUI: true,
        color: "thirdly",
        className: "_next",
    }

    return continueBtn
}

const useVerificationEmailBtn = () => {
    const formValues = useSelector(state => state.form[formname]?.values)

    const { mutateAsync: verificationEmail, isLoading: verificationEmailLoading } = useMutation(sendVerificationEmailFetch)
    const { mutateAsync: validateEmail, isLoading: validateEmailLoading } = customUserRQ.useValidateEmail()

    const sendVerificationEmail = async () => {
        const email = formValues.email

        const isValidEmail = await validateEmail(email)

        if (isValidEmail) {
            await verificationEmail({ email: email })
            toast(<EmailVarification />, toastConfig)
        }
    }

    const verificationEmailBtn = {
        label: "אימות כתובת אימייל",
        color: "secondary",
        systemUI: true,
        className: "_verification_email",
        action: sendVerificationEmail,
        loading: verificationEmailLoading || validateEmailLoading,
        width: '160px'
    }

    return verificationEmailBtn
}

export default { useFields, useNext, constants, useVerificationEmailBtn }