import { app } from "components/Router/routes"
import { useNavigate } from "react-router-dom"


const useGoRegisterBtn = () => {
    const navigate = useNavigate()

    const goRegister = () => {
        navigate(app.register)
    }

    const goRegisterBtn = {
        color: "secondary",
        action: goRegister,
        label: "עוד לא נרשמתם? הירשמו",
        fullWidth: true
    }

    return goRegisterBtn
}

export default useGoRegisterBtn