import React from 'react'
import { Audio } from 'components/Form'


const AudioPlayer = (props) => {
    const { colDef, row } = props
    const { width, height } = colDef.props
    const { audio_file_url } = row

    return (
        <Audio width={width} height={height} input={{ value: audio_file_url }} />
    )
}

export default AudioPlayer