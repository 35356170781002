

class MarkingWordsAssessmentVM {

    constructor(assessment) {
        const { assessment_def_name, assessment_def_visible_type, reading_assessment_def, id, time_point_word,

            time_point,
            words_count,
            errors_count,
            correct_words_count,
            words_in_time_point,
            correct_words_in_time_point,
            words,
            omission_count
        } = assessment

        this.id = id
        this.assessment_def_name = assessment_def_name
        this.assessment_def_visible_type = assessment_def_visible_type
        this.reading_assessment_def = reading_assessment_def
        this.time_point_word = time_point_word

        //for finish the assessment before the end - save the index of last word
        const words_count_with_omissions = words_count + omission_count
        if (words_count_with_omissions < words.length)
            this.end = words_count_with_omissions - 1

        const wordWithTimePointIndex = words.findIndex((word) => word.id === time_point_word)

        if (wordWithTimePointIndex != -1)
            this.timePoint = {
                index: wordWithTimePointIndex,
                // value: "60"
                value: time_point
            }
    }
}

export default MarkingWordsAssessmentVM