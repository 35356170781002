import { statuses } from 'keys'

const AdminAssessmentsData = () => {
    const { backToTranscription, transcriptionComplete, transcriptionInProgress, inProgress, brokenFile, wordsComplete, wordsInProgress, uploaded, backToMarking, audioCuttingComplete, audioCuttingInProgress } = statuses

    return [
        {
            id: 0,
            label: "ממתינים לתעתוק",
            status: [inProgress, backToTranscription],
            sub_status: audioCuttingComplete
        },
        {
            id: 1,
            label: "בתעתוק",
            status: [inProgress],
            sub_status: transcriptionInProgress
        },
        {
            id: 2,
            label: "תעתוק הסתיים",
            status: [inProgress],
            sub_status: transcriptionComplete
        },
        {
            id: 3,
            label: "אודיו לא תקין",
            status: [brokenFile],
            sub_status: ""
        }
    ]

}



export default AdminAssessmentsData
