// import { StyleSheet, Dimensions } from 'react-native';
import { ThemeVariables } from 'styles/theme';
import { Scroll } from './GlobalCss';

export default function ({ }: ThemeVariables) {
    return {
        //   StyleSheet.create({
        /* Column Layouts */

        col: {
            display: "flex",
            flexDirection: 'column',
        },
        colReverse: {
            flexDirection: 'column-reverse',
        },
        colCenter: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        colVCenter: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        colHCenter: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'center',
        },
        /* Row Layouts */
        row: {
            flexDirection: 'row',
        },
        shrink: {
            flexShrink: 0
        },
        rowReverse: {
            flexDirection: 'row-reverse',
        },
        rowCenter: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        rowVCenter: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        rowHCenter: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        /* Default Layouts */
        center: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        alignItemsCenter: {
            alignItems: 'center',
        },
        alignItemsStart: {
            alignItems: 'flex-start',
        },
        alignItemsStretch: {
            alignItems: 'stretch',
        },
        alignItemsEnd: {
            alignItems: 'flex-end',
        },
        justifyContentCenter: {
            justifyContent: 'center',
        },
        justifyContentAround: {
            justifyContent: 'space-around',
        },
        justifyContentBetween: {
            display: "flex",
            justifyContent: 'space-between',
        },
        justifyContentEnd: {
            display: "flex",
            justifyContent: 'flex-end',
        },
        justifyContentStart: {
            justifyContent: 'flex-start',
        },
        scrollSpaceAround: {
            flexGrow: 1,
            justifyContent: 'space-around',
        },
        grow: {
            flexGrow: 1,
        },
        scroll: {
            overflow: 'auto',
            Scroll
        },
        scrollSpaceBetween: {
            flexGrow: 1,
            justifyContent: 'space-between',
        },
        selfStretch: {
            alignSelf: 'stretch',
        },
        flex: {
            display: "flex",
            columnGap: '5px'
        },
        /* Sizes Layouts */
        fill: {
            flex: 1,
        },
        fullSize: {
            height: '100%',
            width: '100%',
        },
        fullSizeDim: {
            width: '100%',
            height: '100%',
        },
        fullWidth: {
            width: '100%',
        },
        halfWidth: {
            width: '50%',
        },
        fullHeight: {
            height: '100%',
        },
        overflow: {
            overflow: 'auto'
        },
        /* Operation Layout */
        mirror: {
            transform: [{ scaleX: -1 }],
        },
        rotate90: {
            transform: [{ rotate: '90deg' }],
        },
        rotate90Inverse: {
            transform: [{ rotate: '-90deg' }],
        },
        // Position
        relative: {
            position: 'relative',
        },
        absolute: {
            position: 'absolute',
        },
        top0: {
            top: 0,
        },
        bottom0: {
            bottom: 0,
        },
        left0: {
            left: 0,
        },
        right0: {
            right: 0,
        },
        srGap: {
            rowGap: 5
        },
        mrGap: {
            rowGap: 10
        },
        lrGap: {
            rowGap: 15
        },
        xlrGap: {
            rowGap: 20
        },
        xxlrGap: {
            rowGap: 30
        },
        trGap: {
            rowGap: 2
        },
        tcGap: {
            columnGap: 2
        },
        scGap: {
            columnGap: 5
        },
        mcGap: {
            columnGap: 10
        },
        lcGap: {
            columnGap: 20
        },
        xlcGap: {
            columnGap: 25
        },
        tinySpace: {
            height: 5
        },
        smallSpace: {
            height: 10
        },
        regularSpace: {
            height: 10
        },
        largeSpace: {
            height: 20
        },
        colTinySpace: {
            width: 8
        },
        colRegularSpace: {
            width: 15
        },
        colSmallSpace: {
            width: 15
        },
        multipleLines: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        lDirection: {
            direction: 'ltr'
        },
        rDirection: {
            direction: 'rtl'
        },
    }
    //   });
}
