import { lightBlue, lightOrange, lightPurple } from "styles/theme"

const stuffer = "STUTTER"
const silence = "SILENCE"
const word = "SPEECH"

const stufferObj = {
    color: lightPurple,
    type: stuffer
}

const silenceObj = {
    color: lightOrange,
    type: silence
}

const wordObj = {
    color: lightBlue,
    type: word
}


export { stuffer, silence, word, stufferObj, silenceObj, wordObj }