import { statuses } from "keys"
import { pick } from "lodash"
import { useMutation } from "react-query"
import { useSelector, useDispatch } from "react-redux"
import { actions } from "store"
import { continueAudioCuttingFetch, startAudioCuttingFetch } from "store/middlewares/markingWords.crud"
import { alerts as alertKeys } from 'keys'
import { AdminAssessmentsCrud } from "components/Admin/AdminAssessments/hooks"

const useStartAudioCutting = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const { wavesurfer } = useSelector(state => state.wavesurfer)
    const { isCloseSttoper } = useSelector(state => state.markingWords)

    const { openBackToAudioCuttingConfirmation, backToAudioCuttingLoading } = AdminAssessmentsCrud()

    const { mutateAsync: startAudioCuttingRQ, data: startAudioCuttingData, isLoading: startAudioCuttingLoading } = useMutation(startAudioCuttingFetch, {})
    const { mutateAsync: continueAudioCuttingRQ, isLoading: continueAudioCuttingLoading } = useMutation(continueAudioCuttingFetch, {})

    const startAudioCutting = async (assessment) => {
        const res = await startAudioCuttingRQ(assessment)
        const assessmentDTO = pick(res?.data, ["status", "sub_status"])
        console.log(assessmentDTO)
        dispatch(actions.updateAssessment(assessmentDTO))
        dispatch(actions.setIsCloseSttoper(false))
        wavesurfer?.play()
    }

    const continueAudioCutting = async () => {
        await continueAudioCuttingRQ(assessment)
        dispatch(actions.setIsCloseSttoper(false))
        wavesurfer?.play()
    }

    const audioCutting = {
        [statuses.inProgress]: {
            [statuses.audioCuttingInProgress]: {
                label: "המשך חיתוך",
                action: continueAudioCutting,
                loading: continueAudioCuttingLoading,
                disabled:
                    //  startAudioCuttingData && 
                    !isCloseSttoper || continueAudioCuttingLoading
            },
            [statuses.audioCuttingComplete]: {
                label: "החזר לחיתוך",
                action: openBackToAudioCuttingConfirmation,
                loading: backToAudioCuttingLoading,
                disabled: backToAudioCuttingLoading
            },
            [statuses.wordsComplete]: {
                label: "התחל חיתוך",
                action: startAudioCutting,
                loading: startAudioCuttingLoading,
                disabled: startAudioCuttingLoading
            }
        },
        [statuses.backToCutting]: {
            [statuses.wordsComplete]: {
                label: "התחל חיתוך",
                action: startAudioCutting,
                loading: startAudioCuttingLoading,
                disabled: startAudioCuttingLoading
            }
        },
        [statuses.brokenFile]: {
            "default": {
                label: "החזר לחיתוך",
                action: openBackToAudioCuttingConfirmation,
                loading: backToAudioCuttingLoading,
                disabled: backToAudioCuttingLoading
            }
        }
    }

    const markWordsValue = audioCutting[assessment.status]?.[assessment.sub_status || "default"]

    const startCutting = {
        label: markWordsValue?.label || "התחל חיתוך",
        color: "secondary",
        action: () => {
            markWordsValue?.action(assessment)
        },
        disabled: markWordsValue?.disabled || !markWordsValue,
        component: 'button',
        width: "200px",
        disabledColor: "gray2",
        loading: markWordsValue?.loading,
        hide: markWordsValue?.hide
    }

    return startCutting
}

export default useStartAudioCutting