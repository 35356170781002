import { produce } from 'immer'
import createReducer from "./reducerUtils"
import { cloneDeep } from 'lodash'

const initialState = {
    user: null,
    permissions: null,
    token: null
}

const user = {
    setUser(state, action) {
        state.user = action.payload
    },
    setPermissions(state, action) {
        state.permissions = action.payload
    },
    setToken(state, action) {
        state.token = action.payload
    }
}

export default produce(
    (state, action) => createReducer(state, action, user),
    initialState
)

