import { produce } from 'immer'
import createReducer from "./reducerUtils"

const initialState = {
    headerFilters: {}
}

const header = {
    setHeaderFilters(state, action) {
        state.headerFilters = action.payload
    },
    updateHeaderFilters(state, action) {
        state.headerFilters = { ...state.headerFilters, ...action.payload }
    },
}

export default produce(
    (state, action) => createReducer(state, action, header),
    initialState
)

