import React from 'react';
import { Checkbox } from '@mui/material';
import { BpIcon, BpCheckedIcon } from './Styled'
import { Label } from '.';
import { useTheme } from 'styles';

const CustomCheckBox = (props) => {
    const { label, name, input = {}, section, className, mandatory } = props,
        { onChange, value } = input;


    const { Layout } = useTheme()

    return (
        <div style={{ ...Layout.rowHCenter }}>
            <Checkbox
                name={name}
                checked={value ? true : false}
                onChange={({ target }) => onChange(target.checked)}
                sx={{
                    '&:hover': { bgcolor: 'transparent' },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
                {...props}
            />
            {label && <Label label={label} mandatory={mandatory} />}
        </div>
    );
}

export default CustomCheckBox