

const transliteration = "transliteration"
const query = "query"
const brain = "brain"
const details = "details"
const login = "login"
const cut = "cut"
const uploadAudio = "upload-audio"
const assessment = "assessment"
const rating = "rating"
const uploadAudioFile = "create-upload-assessment"
const words = "words"
const createAssessmentRecord = "create-assessment-record"
const matchingLearning = "matching-learning"
const assessmentDefs = "assessment-defs"
const adminAssessments = "admin-assessments"
const testTakers = "test-takers"
const assessments = "assessments"
const transcription = "transcription"
const audioTranscription = "audio-transcription"
const register1 = "1"
const register2 = "2"
const register3 = "3"
const register4 = "4"
const reset1 = "1"
const reset2 = "2"
const reset = "reset"
const recoverUsername = "recover-username"
const thankYou = "thank-you"
const manage = "manage"

const allAssessments = "all-assessments"
const createAssessments = "create-assessments"
const createAssessment = "create-assessment"
const dashboard = "home"
const payments = "payments"
const contactUs = "contact-us"
const markingWords = "marking-words"
const audioCutting = "audio-cutting"
const assessmentDef = "assessment-def"
const assessmentView = "assessment-view"
const makingRecordAssessments = "making-record-assessments"
const learningResources = "learning-resources"
const linguisticLab = "linguistic-lab"

const app = {
    app: '/',
    login: `/${login}`,
    rating: `/${rating}`,
    createAssessment: `/${createAssessment}`,
    testTakers: `/${testTakers}`,
    assessments: `/${assessments}`,
    assessmentsById: `/${assessments}/:assessmentID`,
    assessmentDefsById: `/${assessmentDefs}/:assessmentDefID`,
    adminAssessmentById: `/${adminAssessments}/:assessmentID`,
    assessmentDefs: `/${assessmentDefs}`,
    assessment: `/${assessment}`,
    assessmentById: `/${assessment}/:assessmentID`,
    matchingLearning: `/${matchingLearning}`,
    matchingLearningById: `/${matchingLearning}/:assessmentID`,
    createAssessmentRecordById: `/${createAssessmentRecord}/:testTakerID`,
    createAssessmentRecord: `/${createAssessmentRecord}`,
    createAssessments: `/${createAssessments}`,
    contactUs: `/${contactUs}`,
    allAssessments: `/${allAssessments}`,
    assessmentDef: `/${assessmentDef}`,
    uploadAudioFile: `/${uploadAudioFile}`,
    uploadAudioFileById: `/${uploadAudioFile}/:testTakerID`,
    adminAssessments: `/${adminAssessments}`,
    markingWords: `/${markingWords}`,
    assessmentView: `/${assessmentView}`,
    assessmentViewById: `/${assessmentView}/:assessmentID`,
    makingRecordAssessments: `/${makingRecordAssessments}`,
    learningResources: `/${learningResources}`,
    transcription: `/${transcription}`,
    audioCuttingAssessments: `/${manage}/${audioCutting}`,
    markingWordsAssessments: `/${manage}/${markingWords}`,
    transcriptionAssessments: `/${manage}/${transcription}`,
    transcriptionById: `/${manage}/${transcription}/:assessmentID`,
    audioCuttingById: `/${manage}/${audioCutting}/:assessmentID`,
    markingWordsById: `/${manage}/${markingWords}/:assessmentID`,
    audioTranscription: `/${audioTranscription}`,
    linguisticLab: `/${linguisticLab}`,
    audioCutting: `/${audioCutting}`,
    register: "/register",
    register1: `/register/${register1}`,
    register2: `/register/${register2}`,
    register3: `/register/${register3}`,
    register4: `/register/${register4}`,
    reset1: `/reset/${reset1}`,
    dashboard: `/${dashboard}`,
    payments: `/${payments}`,
    reset: `/${reset}`,
    recoverUsername: `/${recoverUsername}`,
    thankYou: `/${thankYou}`
}

export { contactUs, manage, thankYou, payments, dashboard, register1, recoverUsername, reset1, register2, reset2, register4, register3, transcription, linguisticLab, audioTranscription, audioCutting, learningResources, makingRecordAssessments, assessmentView, markingWords, adminAssessments, uploadAudioFile, assessmentDef, createAssessments, allAssessments, app, createAssessment, assessments, testTakers, assessmentDefs, createAssessmentRecord, matchingLearning, words, assessment, rating, uploadAudio, cut, transliteration, query, brain, details, login }
