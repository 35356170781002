import { GridActionsCellItem, } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material'
import { get, pick } from 'lodash';
import { GraphicEq, Remove, NotificationsNone, Done, StickyNote2Outlined, Print, ArrowBack } from '@mui/icons-material';
import { AssessmentsAndTestTakersCrud } from '.';
import { statuses, semesters, loading as loadingKeys } from 'keys';
import { RateAndAccuracyValue, RateAndAccuracyLabel, AudioPlayer } from 'components/School/AssessmentsAndTestTakers';
import { assessmentUtils } from 'helpers';
import moment from 'moment';
import { useCallback } from 'react';
import qc from 'keys/queryClient';
import { Button } from 'components/Form';

const AssessmentsAndTestTakersData = () => {
    const { assessmentDefs, assessmentFilters } = useSelector(state => state.assessment)
    const { loading } = useSelector(state => state.general)
    const { cleanAssessmentFilters, goRating, sortByRateAndAccuracy, goAllAssessmnets, handleChangeAssessmentsFilters, printAssessment } = AssessmentsAndTestTakersCrud()
    const { complete, created, inProgress, wordsComplete, wordsInProgress } = statuses

    const showAllAssessments = (fieldname) => {
        return {
            field: 'allAssessments',
            type: 'actions',
            headerName: "אבחונים קודמים",
            width: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            feature: "assessments-for-testTaker",
            role: "create",
            getActions: ({ row }) => {
                let rowData = row
                if (fieldname)
                    rowData = get(row, fieldname)

                return [
                    <GridActionsCellItem
                        icon={<StickyNote2Outlined />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            goAllAssessmnets(row)
                        }}
                        color="gray"
                    />
                ];
            }
        }
    }

    const testTakerNameField = {
        field: 'first_name',
        headerName: "שם התלמידה",
        width: 140,
        align: 'center',
        headerAlign: "center",
        valueGetter: (value) => `${value.row?.first_name || ''} ${value.row?.last_name || ''}`,
    }

    const mappingStatus = {
        [inProgress]: "בסיסי",
        [complete]: "מורחב",
        [created]: "בסיסי",
        [wordsInProgress]: "בסיסי",
        [wordsComplete]: "בסיסי",
    }

    const indicationIcon = {
        "noraml": {
            icon: Done,
            color: "success"
        },
        "warning": {
            color: "error",
            icon: NotificationsNone,
        },
        "nodata": {
            icon: Remove,
            color: "ligthGray"
        }
    }

    const fields = [
        {
            field: 'first_name',
            headerName: "שם התלמידה",
            width: 140,
            align: 'center',
            headerAlign: "center",
            valueGetter: (value, row) => `${value.row?.first_name || ''} ${value.row?.last_name || ''}`,
        },
        {
            field: 'reading_assessment_name',
            headerName: "שם אבחון",
            width: 140,
            align: 'center',
            headerAlign: "center"
        },
        // {
        //     field: 'status',
        //     headerName: "סטטוס תוצאות",
        //     type: 'number',
        //     width: 140,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: StatusCell,
        //     valueGetter: (value) => `${value.row?.sub_status || ""}`,
        // },
        // {
        //     field: 'create_date',
        //     headerName: 'עדכון אחרון',
        //     headerAlign: 'center',
        //     type: 'numbr',
        //     width: 140,
        //     align: "center",
        //     valueGetter: (value) => `${timeAgoWithOutSeconds(value.row?.update_date) || ""}`,
        // },
        // {
        //     field: 'rate',
        //     headerName: 'קצב',
        //     width: 140,
        //     align: 'center',
        //     headerAlign: "center",
        //     renderCell: RateAndAccuracy,
        //     valueGetter: (value) => { return { ...value.row, fields: { id: "fluency_rating_id", index: "fluency_rating_index" } } },
        //     sort: (sortModel) => sortByRateAndAccuracy(sortModel, "fluency_rating_index")
        // },

        {
            field: 'scoreRate',
            headerName: "מילים לדקה",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            color: "red",
            renderCell: RateAndAccuracyValue,
            valueGetter: (value) => { return { ...value.row, fields: { id: "num_words_per_minute", index: "fluency_rating_index" } } },
            // sort: (sortModel) => sortByRateAndAccuracy(sortModel, "fluency_rating_index")
        },
        {
            field: 'rateRating',
            headerName: "דירוג",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            renderCell: RateAndAccuracyLabel,
            valueGetter: (value) => { return { ...value.row, fields: { id: "fluency_rating_id", index: "fluency_rating_index" } } },
            sort: (sortModel) => sortByRateAndAccuracy(sortModel, "fluency_rating_index", qc.assessments)
        },
        // {
        //     field: 'accuracy',
        //     headerName: "דיוק",
        //     type: 'number',
        //     width: 140,
        //     align: 'center',
        //     headerAlign: "center",
        //     renderCell: RateAndAccuracy,
        //     valueGetter: (value) => { return { ...value.row, fields: { id: "percent_accuracy_rating_id", index: "percent_accuracy_rating_index" } } },
        //     sort: (sortModel) => sortByRateAndAccuracy(sortModel, "percent_accuracy_rating_index")
        // },

        {
            field: 'scoreFluency',
            headerName: "אחוז דיוק",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            renderCell: RateAndAccuracyValue,
            valueGetter: (value) => { return { ...value.row, fields: { id: "percent_accuracy", index: "percent_accuracy_rating_index" } } },
            // sort: (sortModel) => sortByRateAndAccuracy(sortModel, "fluency_rating_index")
        },
        {
            field: 'percentAccuracyRating',
            headerName: "דירוג",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            renderCell: RateAndAccuracyLabel,
            valueGetter: (value) => { return { ...value.row, fields: { id: "percent_accuracy_rating_id", index: "percent_accuracy_rating_index" } } },
            sort: (sortModel) => sortByRateAndAccuracy(sortModel, "percent_accuracy_rating_index", qc.assessments)
        },
    ]

    // const headerItems = [
    //     {
    //         id: 1,
    //         placeholder: "שנה",
    //         options: [{ id: 2024, name: "2024" }],
    //         name: "year",
    //         input: {
    //             value: assessmentFilters?.year || new Date().getFullYear(),
    //             onChange: (value) => handleChangeAssessmentsFilters('year', value)
    //         },
    //         component: "autoComplete",
    //         width: 100
    //     },
    //     {
    //         id: 2,
    //         placeholder: "סמסטר",
    //         options: semesters,
    //         name: "semester",
    //         input: {
    //             value: assessmentFilters?.semester || getCurrentSemester(),
    //             onChange: (value) => handleChangeAssessmentsFilters('semester', value)
    //         },
    //         component: "autoComplete",
    //         width: 200
    //     },
    //     {
    //         id: 3,
    //         placeholder: "מבדק",
    //         options: assessmentDefs,
    //         name: "assessment_def",
    //         input: {
    //             value: assessmentFilters?.assessment_def || [],
    //             onChange: (value) => {
    //                 handleChangeAssessmentsFilters('assessment_def', value || [])
    //             }
    //         },
    //         component: "autoComplete",
    //         multiple: true,
    //         width: 250
    //     },
    //     // {
    //     //     id: 4,
    //     //     label: "ניקוי",
    //     //     name: "clear",
    //     //     action: cleanAssessmentFilters,
    //     //     component: "button",
    //     //     height: '42px',
    //     //     width: '80px',
    //     //     color: "secondary"
    //     // },
    // ]

    const playField = {
        field: 'paly_audio',
        headerName: "נגן",
        width: 250,
        headerAlign: 'center',
        align: 'center',
        props: {
            width: "250px",
            height: "35px",
        },
        renderCell: AudioPlayer
    }

    return {
        showAllAssessments,
        playField,
        indicationIcon,
        // headerItems,
        mappingStatus,
        fields,
        testTakerNameField
    }
}

export default AssessmentsAndTestTakersData