import { ratingList } from "keys"


const constants = {
    frequency: [
     
        {
            label: "גבוהה",
            color: ratingList[0]
        },
        {
            label: "טובה",
            color: ratingList[1]
        },
        {
            label: "קצת נמוכה",
            color: ratingList[2]
        },
        {
            label: "נמוכה",
            color: ratingList[3]
        },
        {
            label: "נמוכה מאד",
            color: ratingList[4]
        } ,
        {
            label: "אין נתונים",
            color: ratingList[5]
        },
    ]
}

export default constants