

import en from 'config/strings'

const validate = (values) => {
    const errors = {}

    if (values) {
        if (!values.price) {
            errors.price = en.require()
        }
        if (!values.name) {
            errors.name = en.require()
        }
        return errors;
    }
}

export default validate
