import styled from "styled-components";
import { Section, Scroll, Shadow, Hover, Center, Disabled } from 'styles/GlobalCss'
import { dark, gTheme, white, ligthGray } from "styles/theme";
import { Button, Fab } from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles';
import { Link as RouterLink } from "react-router-dom";
import { keyframes } from '@mui/system';
import CustomButton from 'components/Form/Submit'

const WrapScreen = styled.div`
width:100%;
height:100%;
overflow:hidden;
`

const SubTitle = styled.div`
font-size:17px;

`

const Title = styled.div`
font-size:23px;
color:black;
`

const Top = styled.div`
position: relative;
top:${props => props.top}px;
`

const TestTakerName = styled.div`
font-size:20px;
font-weight: bold;
`

const ScrollBox = styled.div`
overflow-y:auto;
${Scroll};
`

const WrapAll = styled.div`
height: 100%;
flex-grow: 1;
overflow: auto;
display: flex;
overflow: auto;
flex-direction: column;

${props => props.$disabled && Disabled}
`


const WrapHeader = styled.div`
display:flex;
height:${props => props.height ? `${props.height}px` : "70px"};
flex-shrink: 0;
column-gap: 10px;
align-items:center;
width:100%;
`

const Text = styled.text`
width:${props => props.width}px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`

const FlexCenter = styled.div`
${Center};
width: 100%;
height: 100%;
`

const blink = keyframes`
  from { opacity: 0.4; }
  to { opacity: 1; }
`;


const LightButton = styled(CustomButton)`
width:310px !important;
display: flex !important;
justify-content: space-around !important;
border-radius: 33px !important;
border: 1px solid #CCE1FF !important;
background: ${white} !important;
color:${dark} !important;
`

const FlexBetween = styled.div`
display:flex;
${props => props.$center && Center};
width: 100%;
height: 100%;
justify-content: space-between;
column-gap:10px
`

const FlexAround = styled.div`
${Center};
width: 100%;
height: 100%;
justify-content: space-around;
`

const WrapImg = styled.div`
height:${props => props.height}px;
width:${props => props.width}px;
`

const Link = styled(RouterLink)`
color: ${dark};
text-align: center;
font-size: 16px;
text-decoration: underline;
${Center};
`

const Img = styled.img`
width:100%;
height:100%;
object-fit: contain;
`

const Card = styled(Button)`
opacity:${props => props.notActive && '0.5'};
border:1px solid #DDDDDD !important;
border:${props => props.$bold && '1px solid #000000 !important'};
display: flex !important;
justify-content: center !important;
column-gap: 10px !important;
align-items: center !important;
width: 100% !important;
justify-content:${props => props.align && props.align} !important;
text-align:center;
border-radius:16px;
color:#000000 !important;
width:140px;
border-color:${props => props.typeBold && '#FF0000'} !important;
background:${props => props.errorTypeBold && props.$bg} !important;
border-color:${props => props.errorTypeBold && props.customColor} !important;
font-size:17px !important;

#bold{
    color:${props => props.warningBold ? '#FB7401' : '#FF0000'} !important;;
}

#line{
    color:#E2E2E2 !important;
    margin-right:5px !important;
    margin-left:5px !important;
}

`

const Space = styled.div`
height:20px;
`

const LgSpace = styled.div`
height:40px;
`

const SmallSpace = styled.div`
height:10px;
`

const SpaceBetween = styled.div`
width:20px;
`

const XSSpaceBetween = styled.div`
width:5px;
`

const WrapContent = styled.div`
display:flex;
column-gap: 10px;
${Scroll};
`

const Left = styled.div`
width:${props => props.size ? props.size : '85%'};  
overflow: auto;
${Section};
row-gap: 20px;
display: flex;
flex-direction: column;
${Scroll};
height: calc(100% - 110px);

`


const Right = styled.div`
overflow: auto;
width:${props => props.size ? props.size : '15%'};    
${Section};
${Scroll};
height: calc(100% - 110px);

`

const CustomSection = styled.div`
padding: ${props => !props.$noPadding && '10px'};
box-sizing: border-box;
border-radius: ${props => props.$systemUI ? 25 : 4}px;
background:${props => props.$bg && white};
background:${props => props.$customBg && props.$customBg};
text-align:${props => props.$textCenter && 'center'};

column-gap:${props => props.$cGap && props.$cGap}px;

flex-direction: ${props => props.$column && "column"};

width: ${props =>
        props.width ?
            typeof props.width === 'number' ? `${props.width}px` : props.width
            : "100%"};
height: ${props => props.height ? `${props.height}px` : '100%'};



${props => props.$grow && `
flex-grow:1;
`}

${props => props.$shrink && `
flex-shrink:0;
`}



${props => props.$hover && `
:hover{
    opacity:0.7;
}
`}

${props => props.$flex && `
display:flex;
`}

${props => props.$auto && `
width:auto;
`}

${props => props.$title && `
display: flex;
flex-direction: column;
row-gap: 10px;
`}


${props => props.$center && Center};

${props => props.justify && `
justify-content:${props.justify} !important;
`};

${props => props.align && `
align-items:${props.align} !important;
`};

${props => !props.$noShadow && Shadow};

${props => props.shadow === 3 &&
        `
box-shadow: inset 2px 0 10px -3px rgba(0, 0, 0, 0.4) !important;`
    };

${props => props.$border &&
        `
    border: 1px solid ${ligthGray} !important;`
    };

${props => props.$scroll &&
        `
   overflow:auto;
   ${Scroll};
   `
    }; 

${props => props.$disabled && `
    opacity: 0.3;
`}

${props => props.zindex && `
    z-index: ${props.zindex};
`}
`

const CustomFab = muiStyled(Fab)`
:hover{
    background - color: ${props => gTheme[props.color].main} !important;
    opacity: 0.5!important;
}
`

export { Text, LgSpace, SmallSpace, Top, LightButton, XSSpaceBetween, blink, WrapAll, CustomFab, TestTakerName, FlexAround, SubTitle, FlexBetween, WrapHeader, Link, ScrollBox, FlexCenter, SpaceBetween, CustomSection, WrapImg, Space, Img, Card, WrapScreen, Title, WrapContent, Left, Right }