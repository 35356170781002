import React from 'react'
import { WrapStatus } from './Style'
import { isEmpty } from 'lodash'
import { AllAssessmentsData } from './hooks'
import { statuses } from 'keys'

const StatusCell = (props) => {
    const { row } = props
    const { audio_file_url, assessment_group } = row
    const { uploaded } = statuses
    const { mappingByStatus } = AllAssessmentsData()

    // let customStatus = mappingByStatus[status]
    // if (audio_file_url)
    //     customStatus = mappingByStatus[uploaded]

    const unCompleteAssessmentGroup = assessment_group.filter((ass) => !ass.audio_file_url || ass.status === statuses.brokenFile)

    let customStatus 
    if(unCompleteAssessmentGroup.length===assessment_group.length)
        customStatus=mappingByStatus.unComplete
    else if(isEmpty(unCompleteAssessmentGroup))
        customStatus=mappingByStatus.complete
    else customStatus=mappingByStatus.part

    return (
        <WrapStatus
            border={customStatus.border}
        >
            {customStatus.label}
        </WrapStatus>
    )

}

export default StatusCell