import React from 'react';
import { Skeleton, Stack } from '@mui/material';

const PlaceHolder = () => {
    return (
        <Stack spacing={3} sx={{ mt: "10px" }}>
            <Stack spacing={2}>
                <Skeleton variant="rectangular" width={'100%'} height={80} />
            </Stack>
            <Stack spacing={1}>
                <Skeleton variant="rectangular" width={'100%'} height={60} />
                <Skeleton variant="rectangular" width={'100%'} height={60} />
                <Skeleton variant="rectangular" width={'100%'} height={60} />
                <Skeleton variant="rectangular" width={'100%'} height={60} />
                {/* <Skeleton variant="rectangular" width={'100%'} height={60} /> */}
            </Stack>
        </Stack>
    );
}

export default PlaceHolder