import React from "react"
import { Input, Label, Select, DatePicker, UploadFile, RadioList, Fields, Button, CheckBox, AutoComplete, Divider, Gender, IconButton } from 'components/Form'
import { SpaceBetween } from "styles/GlobalStyle"
import DataGrid from "components/DataGrid"

const fieldsTyps = {
    input: (props) => <Input {...props} />,
    select: (props) => <Select {...props} />,
    datePicker: (props) => <DatePicker {...props} />,
    uploadFile: (props) => <UploadFile {...props} />,
    custom: (props) => <props.customComponent {...props} />,
    radio: (props) => <RadioList {...props} />,
    fields: (props) => <Fields {...props} />,
    button: (props) => <Button {...props} />,
    spaceBetween: (props) => <SpaceBetween {...props} />,
    empty: (props) => <div />,
    checkbox: (props) => <CheckBox {...props} />,
    autoComplete: (props) => <AutoComplete {...props} />,
    dataGrid: (props) => <DataGrid {...props} permissionHOC="gridColumn" />,
    label: (props) => <Label {...props} />,
    divider: (props) => <Divider {...props} />,
    gender: (props) => <Gender {...props} />,
    iconButton: (props) => <IconButton {...props} />,
}

export default fieldsTyps 