import React from 'react';
import { fieldsTyps } from 'components/Form';

const renderField = (props) => {

    const {
        meta,
        dynamicComponent,
        hide
    } = props;

    return (
        hide ||
        fieldsTyps[dynamicComponent](
            {
                ...props,
                ...meta
            }
        )
    )
}


export default React.memo(renderField)
