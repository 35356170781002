import { clarityCharacters } from "mock/clarityCharacters";
import { useContext } from "react";
import { TranscriptionContext } from "../TranscriptionContext";
import { omission, substitution } from "keys/types";

const DecipheringData = () => {
    const { vowelCodes, letterDescriptions } = useContext(TranscriptionContext)

    let editingOptions = [
        {
            id: 0,
            type: "vowel",
            items: vowelCodes,
            label: "בחר את הניקוד",
        },
        {
            id: 1,
            type: "other",
            // items: (index) => phonemes[index].letter_add_ons_dynamic,
            label: "תוספי אות",
        },
        {
            id: 2,
            type: "letterDesciption",
            items: letterDescriptions,
            label: "מתארי אות",
        },
        {
            id: 3,
            type: "letter",
            items: {
                [substitution]: {
                    id: 1,
                    name: "החלפת אות",
                    // action: (phoneme, vowel, staticPhoneme) => {
                    //     if (phoneme?.phoneme_def_letter) {
                    //         let nPhonemeSave = cloneDeep(phoneme)
                    //         nPhonemeSave.letter_status = vowel.status
                    //         // nPhonemeSave.phoneme_def = staticPhoneme.id
                    //         return nPhonemeSave
                    //     }
                    // }
                },
                [omission]: {
                    id: 2,
                    name: "השמטה",
                    // action: (phoneme, vowel, staticPhoneme) => {
                    //     let nPhonemeSave = cloneDeep(phoneme)
                    //     if (phoneme) {

                    //         const { phoneme_def_letter, phoneme_def_letter_add_on, phoneme_def_vowel_code } = nPhonemeSave
                    //         if (phoneme.letter_status === keysSourceChanges.omission) {
                    //             nPhonemeSave = {
                    //                 ...nPhonemeSave,
                    //                 letter_status: 'CORRECT',
                    //                 vowel_status: 'CORRECT',
                    //                 letter_add_on_status: 'CORRECT',
                    //                 letter: phoneme_def_letter,
                    //                 letter_add_on: phoneme_def_letter_add_on,
                    //                 vowel_code: phoneme_def_vowel_code
                    //             }
                    //         }
                    //         else {
                    //             nPhonemeSave = {
                    //                 ...nPhonemeSave,
                    //                 letter_status: keysSourceChanges.omission,
                    //                 vowel_status: keysSourceChanges.omission,
                    //                 letter_add_on_status: keysSourceChanges.omission,
                    //                 letter: '',
                    //                 letter_add_on: null,
                    //                 vowel_code: null
                    //             }
                    //         }
                    //     }
                    //     return nPhonemeSave
                    // }
                }
            },
            label: "ברמת אות",
        },
        {
            id: 4,
            type: "clarity",
            items: clarityCharacters,
            label: "בהירות",
        },
        // {
        //     id: 4,
        //     type: "mispronunciation",
        //     items: {
        //         1: {
        //             id: 1,
        //             name: "שגיאת היגוי",
        //         }
        //     },
        //     label: null,
        //     handleChange: handleClickMispronunciationRow
        // }
    ]

    return {
        editingOptions
    }
}

export default DecipheringData