import { useDispatch, useSelector } from "react-redux"
import { actions } from 'store'
import { dataGridModel, statuses } from "keys"
import api from "keys/apis"
import { assessmentRQ } from "store/hooks"
import { app } from "components/Router/routes"
import { useNavigate } from "react-router-dom"
import { useQueryClient } from "react-query"
import qc from "keys/queryClient"

const AllAssessmentsCrud = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const getAssessments = async () => {
        try {
            const assessments = await dispatch(actions.getAssessments())
            dispatch(actions.setAssessments(assessments))
        }
        catch {
            console.log("error getAssessments")
        }
    }

    const setColumnVisibilityModel = () => {
        dispatch(actions.setVisibilityModel(dataGridModel.all_assessments))
    }

    const cleanData = () => {
        dispatch(actions.cleanAssessmentFilters())
    }

    const goRecord = (item) => {
        const { id, test_taker_id, reading_assessment_def_id, assessment_group } = item

        // const current_assessment_group = assessment_group.filter((assessment) =>
        //     !assessment.audio_file_url || assessment.status === statuses.brokenFile
        // )

        const currentAssessmentGroup = assessment_group.map(ass => {
            return {
                ...ass,
                complete: (!ass.audio_file_url || ass.status === statuses.brokenFile) ? false : true
            };
        });

        const sortedAssessmentGroup = currentAssessmentGroup.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));

        dispatch(actions.setTestTaker({ test_taker_id: test_taker_id }))

        const currentIncompleteAssessment = sortedAssessmentGroup.find((ass) => !ass.complete)
        dispatch(actions.setAssessment(currentIncompleteAssessment))

        navigate(`${app.makingRecordAssessments}?assessment=${currentIncompleteAssessment.id}&assessmentDef=${currentIncompleteAssessment.reading_assessment_def_id}`, {
            state: {
                assessmentsGroup: sortedAssessmentGroup
            }
        });
    }

    return {
        getAssessments,
        cleanData,
        goRecord,
        setColumnVisibilityModel,
    }
}

export default AllAssessmentsCrud