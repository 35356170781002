import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { omit } from "lodash"
import { actions } from 'store'
import { app } from "components/Router/routes"
import { alerts, dataGridModel } from "keys"
import { AlertCrud } from "components/Alerts/hooks"
import api from "keys/apis"

const TestTakersCrud = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { setAlerts } = AlertCrud()
    const { state } = useLocation();
    const { test_taker_id } = state || {}

    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { controllers } = useSelector(state => state.general)

    const getAssessments = async () => {
        try {
            const assessments = await dispatch(actions.getAssessmentReport(test_taker_id))
            dispatch(actions.setAssessments(assessments))
        }
        catch {
            console.log("error getAssessments")
        }
    }

    const setRowSelectionModel = (newRowSelectionModel) => {
        dispatch(actions.setSelectedAssessments(newRowSelectionModel))
    }

    const abortApis = () => {
        controllers[api.report]()
    }

    const init = () => {
        abortApis()
        getAssessments()
    }

    const goTestTakers = () => {
        navigate(app.testTakers)
    }

    const addNewTestTaker = () => {
        setAlerts(alerts.createTestTaker, true)
    }

    //change to test taker id
    const goCreateAssessment = (id) => {
        navigate(app.createAssessment, {
            state: {
                id: id
            }
        })
        dispatch(actions.updateTestTaker({ id: id }))
    }

    const handleChangeAssessmentsFilters = (key, value) => {
        const nHeaderFilters = { ...headerFilters, [key]: value }
        const nTestTaker = { ...testTaker, [key]: value.id }
        dispatch(actions.setHeaderFilters(nHeaderFilters))
        dispatch(actions.setTestTaker(nTestTaker))
    }

    const setColumnVisibilityModel = (value) => {
        dispatch(actions.setVisibilityModel({ key: dataGridModel.assessments, value: value }))
    }

    const cleanData = () => {
        dispatch(actions.cleanAssessmentFilters())
    }

    return {
        init,
        goTestTakers,
        cleanData,
        getAssessments,
        addNewTestTaker,
        goCreateAssessment,
        setRowSelectionModel,
        setColumnVisibilityModel,
        handleChangeAssessmentsFilters
    }
}

export default TestTakersCrud