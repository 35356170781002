

class Affliation {

    constructor(affiliation) {
        const { method, name, group, contact_first_name, contact_last_name, contact_phone } = affiliation
        this.name = name
        this.group = group.id
        this.contact_first_name = contact_first_name
        this.contact_last_name = contact_last_name
        this.contact_phone = contact_phone
        this.method = method.id
    }
}

export default Affliation