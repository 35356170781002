import { Button } from '@mui/material'
import styled, { css } from 'styled-components'
import { dark } from 'styles/theme'
import { styled as muiStyled } from '@mui/material/styles';
import { alpha } from '@mui/system';


const WrapContactUs = styled.div`

`

const WrapContactUsFab = styled.div`
height: 100px;
 width: 100%;
    position: absolute;
    display: flex;
    justify-content: end;
`


const ContactUsCss = css`
width:100%;
row-gap: 10px !important;
`

const ContactUsFab = muiStyled(Button)(({ theme }) => ({
    width: "73px",
    bottom: "112px",
    right: "33px",
    direction: "rtl",
    height: "73px",
    background: dark,
    borderRadius: '200px',
    boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    '&:hover': {
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    },
}));


export { ContactUsFab, WrapContactUsFab, WrapContactUs, ContactUsCss }