/**
 * Images should be stored in the `App/Images` directory and referenced using variables defined here.
 */

export default function () {
    return {
    //   logo: require('../../assets/images/health.png'),
    //   noItems: require('../../assets/images/noItems.png')
    };
  }
  