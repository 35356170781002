import React from 'react'
import { useSelector } from 'react-redux'
import { Divider } from '@mui/material'
import { WrapField, } from 'components/Form/Styled'
import { Select } from 'components/Form'
import { DefText, WrapDefTitle, WrapDefContent, WrapSelectAssessmentDef } from './Style'

const SelectAssessmentDef = (props) => {
    const { title, className } = props
    const createAssessment = useSelector(state => state.form?.createAssessment?.values)
    const { assesment_def } = createAssessment || {}

    return (
        <WrapField section={true} className={className}>
            <WrapSelectAssessmentDef>
                <WrapDefTitle>
                    <DefText>{title}</DefText>
                    <Select {...props} />
                </WrapDefTitle>
                <Divider />
                <WrapDefContent>
                    {assesment_def?.text}
                </WrapDefContent>
            </WrapSelectAssessmentDef>
        </WrapField>
    )
}

export default SelectAssessmentDef