
import axios from 'config/axiosConfig'
import { go } from 'keys/apis'

export const createAffiliation = ({ dispatch, getState }) => next => action => {
    if (action.type === 'CREATE_AFFILIATION') {
        let affiliation = action.payload

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${go}affiliations/`, {
                ...affiliation
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const getAffiliationGroups = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_AFFILIATION_GROUPS') {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}affiliation_groups/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    // reject(error)
                });
        })
    }
    return next(action)
}

export const getAffiliationsFetch = (filters = {}) => {
    const { group } = filters || {}

    let params = {}

    if (group)
        params.group = group

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}affiliations/`, {
        params: params
    })
}

export const getAffiliations = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_AFFILIATIONS') {
        const { group } = action.payload || {}

        let params = {}

        if (group)
            params.group = group

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}affiliations/options/`,
                {
                    params: params
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}

export const getMethods = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_METHODS') {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}methods/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}



