const constants = {
    assessmentKeysForSearch: ["type_name", "readability_score_name", "affiliation_name", "author", "name"],
    search: {
        component: "input",
        placeholder: "חיפוש...",
        className: "assessment-def-search"
    }

}

export default constants