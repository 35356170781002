import { css } from "styled-components"

const WrapCreateAffiliation = css`

display: grid;
grid-template-columns: 50% 50%;
grid-gap: 15px;
// grid-template:
// "name group"
// "method contact_first_name"
// "contact_last_name contact_phone"

// .b-name{
//   grid-area: name;
// }
// .b-group{
//   grid-area: group;
// }
// .b-method{
//   grid-area: method;
// }
// .b-contact_first_name{
//     grid-area: contact_first_name;
// }
// .b-contact_last_name{
//     grid-area: contact_last_name;
// }
// .b-contact_phone{
//     grid-area: contact_phone;
// }
`



export { WrapCreateAffiliation }