
const useFields = () => {

    const fields = [{
        name: 'username',
        component: "input",
        placeholder: "שם משתמש"
    },
    {
        name: 'password',
        component: "input",
        type: 'password',
        placeholder: "סיסמה"
    }]

    return fields
}

export default useFields;