import { useDispatch } from "react-redux"
import { useContext } from "react"
import { CutRecordContext } from "../CutRecordContext"
import { useCallback } from "react"
import { changeItem, removeItem } from "utils/array"
import { wordObj } from 'mock/regionType'
import { actions } from 'store'
import { RegionRM } from "models/RM"
import { RegionVM } from "models/VM"
import { createContentWord } from "models/VM/Region"
import { StoreCrud } from "store/hooks"
import { useNavigate, useParams } from "react-router-dom"
import { app } from "components/Router/routes"
import { updateType } from "keys"
import { useMutation } from "react-query"
import { useSelector } from "react-redux"
import { saveRegionsFetch } from "store/middlewares/region.crud"

const CutRecordCrud = () => {
    const dispatch = useDispatch()
    const { getAudioFile } = StoreCrud()
    const { setRegion, playRegionRef, regionRef, regionsRef, words, setWords, setRegions, regions, isLoading, setIsLoadingData } = useContext(CutRecordContext)

    const { assessment } = useSelector(state => state.assessment)
    const { mutateAsync: saveRegionsRQ, isLoading: saveRegionsLoading } = useMutation(saveRegionsFetch)

    const navigate = useNavigate()

    const params = useParams()

    const saveRegions = async () => {
        let nRegions = []
        regions.forEach(region => {
            nRegions.push(new RegionRM(region))
        });
        await saveRegionsRQ({ assessment, regions: nRegions })
        // navigate(app.adminAssessments)
        navigate(app.audioCuttingAssessments)
    }

    const changeRegion = (region) => {
        setRegion(region)
        regionRef.current = region
    }

    const selectWord = (word) => {
        let text = word.transcription || word.word_def_text

        if (!regionRef.current)
            regionRef.current = regions[0]
        const currentRegionIndex = regions.findIndex((region) => region.id === regionRef.current.id)
        playRegionRef.current = true
        if (currentRegionIndex > -1) {
            let content = regions[currentRegionIndex].content.children?.[1]
            if (!content) {
                content = createContentWord(word)

                regions[currentRegionIndex].content.appendChild(content)
            }
            if (text)
                content.innerHTML = text
            let nContent = regions[currentRegionIndex].content
            nContent.children[1].text = content
            let nRegion = { ...regions[currentRegionIndex] }
            nRegion.content = nContent
            nRegion = { ...nRegion, ...wordObj }
            if (word.id) {
                nRegion.word_id = word.id
            }
            else {
                nRegion.word_def_id = word.word_def_id
                delete nRegion.word_id
            }

            nRegion.update_type = updateType.update

            let nRegions = changeItem(regions, currentRegionIndex, nRegion)
            setRegions(nRegions)
            regionRef.current = nRegion

        }
    }

    const removeRegion = useCallback((id) => {
        let index = regionsRef.current.findIndex((region => region.id == id));
        if (index > -1) {
            let nextRegions = []
            if (regionsRef.current[index].isNew)
                nextRegions = removeItem(regionsRef.current, index)

            else nextRegions = changeItem(regionsRef.current, index, { ...regionsRef.current[index], update_type: updateType.delete })
            setRegions(nextRegions);
            regionsRef.current = nextRegions
            regionRef.current = null
        }
    }, [regions]);

    const regionFunc = {
        remove: removeRegion
    }

    const mappingRegions = (regions, words) => {
        let nRegions = []
        regions.forEach(region => {
            nRegions.push(new RegionVM(region, regionFunc, words))
        });
        setRegions(nRegions)
    }

    const getWordsAndRegions = async () => {
        const wordsAndRegions = await dispatch(actions.getWordsAndRegions(params.assessmentID))
        // getAudioFile()
        if (wordsAndRegions) {
            const { words, regions } = wordsAndRegions
            setWords(words)
            mappingRegions(regions, words)
        }
    }

    const cleanData = () => {
        dispatch(actions.setTestTaker({}))
    }

    const init = async () => {
        if (!words || !regions) {
            await getWordsAndRegions()
            setIsLoadingData(false)
        }
    }

    return {
        init,
        saveRegions,
        changeRegion,
        selectWord,
        cleanData,
        regionFunc,

        saveRegionsLoading
    }

}

export default CutRecordCrud