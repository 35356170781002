

import en from 'config/strings'

const validate = (values) => {
    const errors = {}

    if (values) {
        const { file, assesment_def, actions } = values

        if (!file) {
            errors.file = en.require()
        }

        if (!assesment_def) {
            errors.assesment_def = en.require()
        }

        // if (!actions) {
        //     errors.actions = en.require()
        // }


    }

    return errors;
}

export default validate;

