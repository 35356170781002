import { requestAI } from "config/axiosConfig"

const language = "he"

export const initAIFetch = async () => {
    return requestAI.get(`${process.env.REACT_APP_AI_SERVER_PORT}init/`)
}

export const whisperFetch = async (blob) => {
    let formData = new FormData();
    formData.append("file", blob, "file.mp3");
    formData.append("language", language);

    return await requestAI.post(`${process.env.REACT_APP_AI_SERVER_PORT}transcribe/whisper/`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
}

export const compareFetch = ({ original, spoken }) => {
    return requestAI.post(`${process.env.REACT_APP_AI_SERVER_PORT}compare/`, {
        original,
        spoken,
        language
    })
}