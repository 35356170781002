import styled from "styled-components";
import { Shadow } from "styles/GlobalCss";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { gray, light, primary } from "styles/theme";
import { styled as muiStyled } from '@mui/material/styles';

const WrapProgress = styled.div`
display: flex;
flex-direction: column;
height: 100%;
justify-content: space-around;
row-gap: 20px;
`

const WrapIndicationIcon = styled.div`
position: relative;
bottom: 42px;
display: flex;
justify-content: center;
`

const WrapSubmit = styled.div`
display: flex;
justify-content: end;
`

const BorderLinearProgress = muiStyled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#e6e6e6',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: primary,
        height: 30,
    }
}));

const WrapLinearProgress = styled(LinearProgress)`
height:30px !important;
border-radius: 4px !important;
${Shadow};


`

const WrapProgressItem = styled.div`
display: flex;
flex-direction: column;
row-gap: 15px;
`

export { WrapIndicationIcon, BorderLinearProgress, WrapSubmit, WrapProgressItem, WrapProgress, WrapLinearProgress }