import { correct, repetition, incorrect, insertion, selfCorrection, omission, minorError as minor, na } from "keys/types"
import updateType from "keys/updateType"
import { keyBy } from "lodash"

const removeSourceErrors = (error) => {
    const { id, type, status } = error
    return {
        id: id,
        type: type,
        status: status,
        update_type: updateType.delete
    }
}

class MiniWordRM {

    constructor(word, index) {
        const { minorError, removed, is_time_point_word, sourceErrors, customStatus, timePoint, word_def_text, update_type, transcription, status, word_def_id, id } = word

        if (id)
            this.id = id

        this.transcription = transcription || word_def_text
        this.status = status || correct
        if (removed)
            this.status = na
        this.update_type = update_type
        this.word_def = word_def_id

        if (is_time_point_word) {
            this.is_time_point_word = false
            this.update_type = updateType.update
        }

        if (timePoint?.index === index) {
            this.is_time_point_word = true
            this.update_type = updateType.update
        }

        this.errors = []
        const sourceErrorsKeys = keyBy(sourceErrors, "status")

        //omission
        if (removed) {
            if (sourceErrorsKeys[omission])
                this.errors.push(sourceErrorsKeys[omission])
            else
                this.errors.push({ type: omission, status: omission, update_type: updateType.update })
        }
        else if (sourceErrorsKeys[omission])
            this.errors.push(removeSourceErrors(sourceErrorsKeys[omission]))

        //minorError
        if (minorError) {
            if (sourceErrorsKeys[minor])
                this.errors.push(sourceErrorsKeys[minor])
            else
                this.errors.push({ type: minor, status: minor, update_type: updateType.update })
        }
        else if (sourceErrorsKeys[minor])
            this.errors.push(removeSourceErrors(sourceErrorsKeys[minor]))

        //self correction or repition
        if (customStatus) {
            if (sourceErrorsKeys[selfCorrection])
                this.errors.push(removeSourceErrors(sourceErrorsKeys[selfCorrection]))
            if (sourceErrorsKeys[repetition])
                this.errors.push(removeSourceErrors(sourceErrorsKeys[repetition]))

            //check if the error is self correction or repition
            if (this.status != correct)
                this.errors.push({ type: insertion, status: selfCorrection, update_type: updateType.update })
            else
                this.errors.push({ type: insertion, status: repetition, update_type: updateType.update })
        }
        else {
            if (sourceErrorsKeys[selfCorrection])
                this.errors.push(removeSourceErrors(sourceErrorsKeys[selfCorrection]))
            if (sourceErrorsKeys[repetition])
                this.errors.push(removeSourceErrors(sourceErrorsKeys[repetition]))
        }
    }
}
export default MiniWordRM