import React from 'react'
import { ContactUsCss } from './Style'
import { Form } from 'components/Form'
import { FlexCenter } from 'styles/GlobalStyle'

import Go3 from 'assets/Go3.png'
import contactUs from './hooks'
import validate from './Validate'
import { AuthWithCartoon } from 'components/Auth'

const ContactUs = () => {
    const { formname, title } = contactUs.constants

    const fields = contactUs.useFields()
    const submitBtn = contactUs.useSubmitBtn()

    return (
        <FlexCenter>
            <AuthWithCartoon cartoon={Go3}>
                <Form
                    title={title}
                    csswrap={ContactUsCss}
                    fields={fields}
                    submit={() => { }}
                    form={formname}
                    validations={validate}
                    submitBtns={[submitBtn]}
                />
            </AuthWithCartoon>
        </FlexCenter>
    )
}

export default ContactUs