import { statuses } from "keys"




class CreateAssessment {

    constructor(assessment, test_taker) {
        const { assesment_def, file } = assessment
        const { test_taker_id } = test_taker

        this.reading_assessment_def = assesment_def?.id || assesment_def
        this.test_taker = test_taker_id
        this.audio_file = file
    }
}

export default CreateAssessment