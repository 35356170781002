import { produce } from 'immer'
import createReducer from "./reducerUtils"

const initialState = {
    rating: null,
    matchingLearning: null,
    scoreRating: {},
    scoreRatingMethods: {},
    classStats: null
}

const rating = {
    setScoreRating(state, action) {
        state.scoreRating = action.payload
    },
    setClassStats(state, action) {
        state.classStats = action.payload
    },
    setScoreRatingMethods(state, action) {
        state.scoreRatingMethods = action.payload
    },
    setRating(state, action) {
        state.rating = action.payload
    },
    setMatchingLearning(state, action) {
        state.matchingLearning = action.payload
    },
}

export default produce(
    (state, action) => createReducer(state, action, rating),
    initialState
)

