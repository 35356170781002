import dayjs from "dayjs"

class Coupon {

    constructor(coupon) {
        const { expiration_date, is_open, price, name } = coupon

        this.name = name
        this.is_open = is_open || false
        this.price = price.id
        this.expiration_date = dayjs(expiration_date).format('YYYY-MM-DD')
    }
}

export default Coupon