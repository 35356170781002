
const useAssessmentNameField = () => {
    const assessmentNameField = {
        field: 'reading_assessment_name',
        headerName: "שם האבחון",
        width: 150,
        headerAlign: 'center',
        align: 'center',
    }
    return assessmentNameField
}

export default useAssessmentNameField