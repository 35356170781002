import React from "react";
import { WrapRegister1, Title, WrapCards, Card, SubTitle } from './Style'
import { register1 } from './hooks'
import { Button } from "components/Form";
import { useNavigate } from "react-router-dom";
import { Img, WrapImg } from "styles/GlobalStyle";

const Register1 = () => {
    const navigate = useNavigate()

    const cards = register1.useCards()

    const { title, subTitle } = register1.constants

    const selectCard = (path) => {
        navigate(path)
    }

    return (
        <WrapRegister1>
            <Title>
                {title}
            </Title>
            <SubTitle>
                {subTitle}
            </SubTitle>
            <WrapCards>
                {cards.map((card, index) =>
                    <Card key={index} $disabled={card.disabled}>
                        <WrapImg width={170} height={110}>
                            <Img src={card.img} />
                        </WrapImg>
                        <Button
                            action={() => selectCard(card.path)}
                            width={"170px"}
                            systemUI
                            color="secondary"
                            label={card.label}
                        />
                    </Card>
                )}
            </WrapCards>
        </WrapRegister1>
    )
}

export default Register1