import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom";
import { actions } from 'store'

const AssessmentDefCrud = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const { words } = useSelector(state => state.words)
    const params = useParams()

    const getWordDefs = async (assessmentdefID) => {
        try {
            const assessmentID = assessment.reading_assessment_def_id || assessmentdefID
            const wordDefs = await dispatch(actions.getWordDefs(assessmentID))
            dispatch(actions.setWordDefs(wordDefs))
        }
        catch {
            console.log("error getWordDefs")
        }
    }

    const cleanAssessmentDef = () => {
        dispatch(actions.setWordDefs(null))
    }

    const init = (assessmentDefID) => {
        dispatch(actions.updateAssessment({ reading_assessment_def_id: assessmentDefID }))
        if (isEmpty(words)) getWordDefs(assessmentDefID)
    }

    return {
        init,
        cleanAssessmentDef
    }
}

export default AssessmentDefCrud