
const grades = {
    "1": {
        fontsize: 45,
        // fontfamily:"",
        vowel: true
    },
    "2": {
        fontsize: 45,
        // fontfamily:"",
        vowel: true
    },
    "3": {
        fontsize: 45,
        // fontfamily:"",
        vowel: true
    },
    "4": {
        fontsize: 45,
        // fontfamily:"",
        vowel: true
    },
    "5": {
        fontsize: 33,
        // fontfamily:"",
        vowel: true
    },
    "6": {
        fontsize: 25,
        // fontfamily:"",
        vowel: true
    },
    "7": {
        fontsize: 25,
        // fontfamily:"",
        vowel: true
    },
    "8": {
        fontsize: 23,
        // fontfamily:"",
        vowel: true
    },
    "9": {
        fontsize: 23,
        // fontfamily:"",
        vowel: true
    }
}

export default grades