import React, { createContext, useEffect, useState } from 'react'
import { Transcription } from '.'
import { isEmpty } from 'lodash'

const TranscriptionPage = () => {
    const [words, setWords] = useState([])
    const [currentWord, setCurrentWord] = useState([])
    const [letterAddOns, setLetterAddOns] = useState([])
    const [vowelCodes, setVowelCodes] = useState([])
    const [letterDescriptions, setLetterDescriptions] = useState([])

    let commonValue = {
        words, setWords,
        currentWord, setCurrentWord,
        letterAddOns, setLetterAddOns,
        vowelCodes, setVowelCodes,
        letterDescriptions, setLetterDescriptions
    }

    useEffect(() => {
        if (!isEmpty(words)) {
            const wordIndex = words?.findIndex((word) => Number(word.word_def_id) === Number(currentWord?.word_def_id))

            if (wordIndex >= 0)
                setCurrentWord(words[wordIndex])
            else setCurrentWord(words[0])
        }
    }, [words])

    return (
        <TranscriptionContext.Provider value={commonValue}>
            <Transcription />
        </TranscriptionContext.Provider>
    )
}
export const TranscriptionContext = createContext()
export default TranscriptionPage