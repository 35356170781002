import { ResetRoutes } from 'components/Router'
import React from 'react'


const ResetPassword = () => {

    return (
        <ResetRoutes />
    )
}

export default ResetPassword