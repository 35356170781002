import { FormCrud } from "components/Form/hooks";
import { isSubmitting } from "redux-form";
import { loginHooks } from "..";
import { useSelector } from "react-redux";

const useSubmitBtns = () => {
    const { formname } = loginHooks.constants
    const { actionSubmit } = FormCrud()
    const isSubmittingForm = useSelector((state) => isSubmitting(formname)(state))


    const submitBtns = [
        {
            id: 0,
            label: "כניסה",
            type: "submit",
            color: "thirdly",
            fullWidth: true,
            action: () => actionSubmit(formname),
            loading: isSubmittingForm
        }
    ]

    return submitBtns
}

export default useSubmitBtns;