import React from 'react'
import { CustomSection } from 'styles/GlobalStyle'
import { AssessmentDefsCrud } from './hooks'
import { Type, WrapContent, WrapSelectAssessment, WrapAssessmentSection, Semester, WrapAssessment, WrapTitle, AssessmentName, WrapFooter, Method, WordsLength } from './Style'
import { CheckBox } from 'components/Form'
import { useSelector } from 'react-redux'
import { keyBy } from 'lodash'

const Assessment = (props) => {
    const { selectAssessment, multiple = false, student, title, footer, header, type_name, num_words, readability_score_name, name, affiliation_name, author, id } = props

    const { goAssessment, updateAssessmentDefSelected } = AssessmentDefsCrud()
    const { selectedAssessmentDefs, selectedAssessmentDef } = useSelector(state => state.assessment)

    const keyBySelectedAssessmentDefs = keyBy(selectedAssessmentDefs, "id")

    const onSelectAssessment = () => {
        if (props?.onSelectAssessment)
            props?.onSelectAssessment(props)
        else goAssessment(id, header, footer, student)
    }

    return (
        <WrapAssessmentSection>
            {selectAssessment &&
                <WrapSelectAssessment>
                    <CheckBox input={{
                        value: multiple ? keyBySelectedAssessmentDefs[id] : selectedAssessmentDef?.id === id,
                        onChange: (selected) => updateAssessmentDefSelected({ id, name }, selected, multiple)
                    }}
                    />
                </WrapSelectAssessment>}
            <CustomSection
                $bg
                height={200}
                width={300}
                $hover
                onClick={onSelectAssessment}
            >
                <WrapAssessment>
                    <WrapTitle>
                        <Type>
                            {type_name}
                        </Type>
                        <Semester>
                            {readability_score_name}
                        </Semester>
                    </WrapTitle>
                    <WrapContent>
                        <AssessmentName>{title}</AssessmentName>
                    </WrapContent>
                    <WrapFooter>
                        <Method $bg={"lightOrange"}>
                            {affiliation_name}{affiliation_name && author && ', '}{author}
                        </Method>
                        <WordsLength>{`${num_words} מילים`} </WordsLength>
                    </WrapFooter>
                </WrapAssessment>
            </CustomSection>
        </WrapAssessmentSection>
    )
}

export default Assessment