import constants from './constants'

const useGetTooltipTitle = () => {

    return (error, minorError, removed, customStatus) => {
        let tooltipContent = ""

        if (customStatus) {
            if (error)
                tooltipContent = constants.errorWithSelfCorrection
            else if (minorError)
                tooltipContent = constants.minorErrorWithSelfCorrection
            else tooltipContent = constants.selfCorrection
        }
        else if (minorError)
            tooltipContent = constants.minorError
        else if (error)
            tooltipContent = constants.error
        else if (removed)
            tooltipContent = constants.omission

        return tooltipContent
    }
}

export default useGetTooltipTitle