import React from 'react';
import { Box, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { SnackbarCrud } from './hooks'
import { toast } from 'keys';

const PositionedSnackbar = () => {
    const { snackbars } = useSelector(state => state.general)
    const { closeSnackbar } = SnackbarCrud()

    return (
        <Box sx={{ width: 500 }}>
            {snackbars[toast.createAssessment].open &&
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={true}
                    autoHideDuration={4000}
                    onClose={() => closeSnackbar(toast.createAssessment)}
                    message={"נשמר בהצלחה"}
                />
            }
        </Box>
    );
}

export default PositionedSnackbar