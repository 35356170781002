import { useQuery } from "react-query"
import { queryClient as qc } from "keys"
import { getPricesFetch } from "store/middlewares/package.crud"

const useCouponFields = () => {
    const { data: prices } = useQuery({
        queryFn: async () => {
            const { data } = await getPricesFetch()
            const itemsWithLabel = data.map(item => ({
                ...item,
                label: `${item.package} ${item.amount} ש"ח `
            }));
            return itemsWithLabel
        },
        refetchOnWindowFocus: false,
        queryKey: qc.prices
    })

    const fields = [
        {
            name: "name",
            placeholder: "שם הקופון",
            component: "input",
        },
        {
            name: "price",
            placeholder: "סוג קופון",
            component: "autoComplete",
            labelKey: "label",
            options: prices,
        },
        {
            name: "is_open",
            label: "קופון פתוח",
            component: "checkbox"
        },
        {
            name: "expiration_date",
            placeholder: "לשינוי תוקף קופון",
            component: "datePicker",
            systemUI: true,
        },
    ]

    return fields
}

export default useCouponFields