import React from 'react'
import audioCuttingHooks from './hooks'
import { AdminAssessments } from 'components'
import { dataGridModel, statuses } from 'keys'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { actions } from 'store'

const AudioCuttingAssessments = () => {
    const dispatch = useDispatch()

    const fields = audioCuttingHooks.useFields()
    const tabs = audioCuttingHooks.useTabs()

    const updateVisibilityModel = ({ status, sub_status }) => {
        const audioCuttingComleted = isEqual(sub_status, statuses.audioCuttingComplete)
        const audioCuttingInProgress = isEqual(sub_status, statuses.audioCuttingInProgress)
        const isBrokenFile = status.includes(statuses.brokenFile)
        const audioCuttingIsWating = isEqual(sub_status, statuses.wordsComplete)

        //hide and show columns in the DataGrid table
        dispatch(actions.setVisibilityModelByKeys({
            key: [dataGridModel.admin_assessments],
            value: {
                audio_cutting_update_by_username: audioCuttingComleted || audioCuttingInProgress || isBrokenFile,
                audio_cutting_duration: audioCuttingComleted,
                audio_cutting_start_date: audioCuttingComleted || audioCuttingInProgress,
                audio_cutting_end_date: audioCuttingComleted,
                back_to_cutting: audioCuttingComleted || isBrokenFile,
                update_date: audioCuttingIsWating,
                enter_audio_cutting: audioCuttingIsWating,
                view_audio_cutting: audioCuttingComleted || isBrokenFile || audioCuttingInProgress,
            }
        }))
    }

    return (
        <AdminAssessments
            fields={fields}
            tabs={tabs}
            updateVisibilityModel={updateVisibilityModel}
        />
    )

}

export default AudioCuttingAssessments