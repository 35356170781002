import { secretStorage } from 'config'
import React from 'react'


const SuccessToast = ({ username, password }) => {

    return (
        <div>
            נרשמת בהצלחה,
            <div>
                <b>  שם משתמש:</b>
                {" "}
                {username}
            </div>
            <div>
                <b> סיסמא:</b>
                {" "}
                {password}
            </div>
        </div>
    )
}

export default SuccessToast