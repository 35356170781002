import React from 'react';
import { Tooltip } from '@mui/material';
import { WrapRecordButton } from './Style'

const RecordButton = (props) => {
    const { onClick, title, isRecording, color, children } = props

    return (
        <Tooltip title={title} aria-label='record' placement='top'>
            <>
                <WrapRecordButton
                    onClick={onClick}
                    aria-label='record'
                    recording={"true"}
                    color='secondary'
                >
                    {isRecording && <div className='pulse-bg' />}
                    {children}
                    {isRecording ? "סִיַּמְתִּי" : <props.icon fontSize="large" />}
                </WrapRecordButton>
            </>
        </Tooltip>
    );
};
export default RecordButton;