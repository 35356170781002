import React, { useState } from 'react';
import { Dial, DialAction } from './Styled'
import { errorHooks } from '.';
import { Box } from '@mui/material';
import { Edit, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';
import { useTheme } from 'styles';
import { assign } from 'lodash'

const ErrorTypes = () => {
    const [openDial, setOpenDial] = useState(false)

    const { Layout } = useTheme()

    const errorTypeList = errorHooks.useErrorTypeList()
    const Icon = openDial ? KeyboardArrowRight : KeyboardArrowLeft

    return (
        <Box sx={{ height: 50, transform: 'translateZ(0px)', flexShrink: 0 }}>
            <Dial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 0 }}
                icon={
                    <div style={assign(Layout.rowCenter, Layout.scGap)}>
                        מקראה השגיאות
                        <Icon color='thirdly2' openIcon={<Edit />} />
                    </div>
                }
                open={openDial}
                direction='right'
                onClick={() => setOpenDial(!openDial)}
            >
                {errorTypeList.map((error, index) => (
                    <DialAction
                        aria-label='dsdsa'
                        key={index}
                        borderColor={error.borderColor}
                        borderStyle={error.borderStyle}
                        icon={error.label}
                    />
                ))}
            </Dial>
        </Box>
    );
}

export default ErrorTypes
