import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { actions } from "store"
import { MarkingWordsAssessmentVM, SourceWordVM, WordVM } from 'models/VM'
import { AssessmentRM } from "models/RM"
import { app } from "components/Router/routes"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useMutation, useQueryClient } from "react-query"
import { markingWordsCompleteFetch, updateBrokenFileStatusFetch } from "store/middlewares/markingWords.crud"
import { getWordsFetch, saveWordsFetch } from "store/middlewares/words.crud"
import { queryClient as qc } from "keys"
import { isEmpty } from "lodash"
import { wordsRQ } from "store/hooks"
import { adminAssessmentsHooks } from "components/Admin/AdminAssessments"

const MarkingWordsCrud = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { words } = useSelector(state => state.words)
    const { wavesurfer } = useSelector(state => state.wavesurfer)
    const { assessment } = useSelector(state => state.assessment)
    const { state } = useLocation()

    const selectAndViewAssessment = adminAssessmentsHooks.useSelectAndMarking()

    const { refetch: refetchGetAssessmentAndWords } = wordsRQ.useGetWords()
    const { mutateAsync: saveWordsRQ, isLoading: saveWordsLoading } = useMutation(saveWordsFetch)
    const { mutateAsync: markingWordsCompleteRQ, isLoading: markingWordsCompleteLoading } = useMutation({
        mutationFn: markingWordsCompleteFetch,
        onError: refetchGetAssessmentAndWords
    })
    const { mutateAsync: updateBrokenFileStatusRQ, isLoading: updateBrokenFileStatusLoading } = useMutation(updateBrokenFileStatusFetch)

    const init = async () => {
        // if (!words)
        refetchGetAssessmentAndWords()
    }

    const goAdminAssessments = () => {
        if (!isEmpty(state.statusFilters))
            dispatch(actions.setStatusFilters(state.statusFilters))
        if (!isEmpty(state.assessmentFilters))
            dispatch(actions.setAssessmentFilters(state.assessmentFilters))

        navigate(app.markingWordsAssessments)
    }

    const cleanData = () => {
        dispatch(actions.setWords(null))
        dispatch(actions.setIsCloseSttoper(true))
    }

    const cleanMarkingWords = () => {
        wavesurfer?.pause()
        let nWords = []
        words.forEach((word) => nWords.push(new SourceWordVM(word)))
        dispatch(actions.setWords(nWords))
    }

    const saveMarkingWords = async () => {
        const nWords = await saveWordsRQ({ assessment, words })
        let data = new AssessmentRM({
            timePoint: assessment.timePoint,
            end: assessment.end >= 0 ? assessment.end : null,
            words: nWords,
        })
        return data
    }

    const markingWordsComplete = async () => {
        const data = await saveMarkingWords()
        await markingWordsCompleteRQ({ id: assessment.id, data })
        dispatch(actions.setIsCloseSttoper(true))
    }

    const markingWordsFinish = async () => {
        await markingWordsComplete()
        goAdminAssessments()
    }

    const markingWordsSaveAndNext = async () => {
        await markingWordsComplete()
        dispatch(actions.setStatusFilters(state.statusFilters))

        const adminAssessments = queryClient.getQueryData(qc.adminAssessments)?.data

        let assessmentIndex = adminAssessments?.findIndex((ass) => ass.id === assessment.id)
        let assessmentLength = adminAssessments?.length

        if (assessmentIndex < assessmentLength - 1) {
            selectAndViewAssessment(adminAssessments[assessmentIndex + 1])
            dispatch(actions.setWords(null))
        }
        else goAdminAssessments()
    }

    const updateBrokenFileStatus = async () => {
        await updateBrokenFileStatusRQ(assessment)
        goAdminAssessments()
    }

    return {
        init,
        markingWordsSaveAndNext,
        cleanData,
        markingWordsComplete,
        markingWordsFinish,
        cleanMarkingWords,
        goAdminAssessments,
        updateBrokenFileStatus,

        markingWordsCompleteLoading,
        saveWordsLoading,
        updateBrokenFileStatusLoading
    }
}

export default MarkingWordsCrud