

const useFields = () => {

    const fields = [
        {
            name: "password",
            component: "input",
            placeholder: "סיסמא",
            type: "password"
        },
        {
            name: "confirmPassword",
            component: "input",
            placeholder: "אישור סיסמא",
            type: "password"
        }
    ]

    return fields
}


export default useFields