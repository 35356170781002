export const GRID_DEFAULT_LOCALE_TEXT = {
    // Root
    noRowsLabel: 'אין תוצאות',
    noResultsOverlayLabel: 'לא נמצאו תוצאות.',

    // Density selector toolbar button text
    toolbarDensity: 'תצוגה',
    toolbarDensityLabel: 'קטן',
    toolbarDensityCompact: 'קטן',
    toolbarDensityStandard: 'בינוני',
    toolbarDensityComfortable: 'גדול',

    // Columns selector toolbar button text
    toolbarColumns: 'עמודות',
    toolbarColumnsLabel: 'בחר עמודות',

    // Filters toolbar button text
    toolbarFilters: 'סינון',
    toolbarFiltersLabel: 'הצג סינון',
    toolbarFiltersTooltipHide: 'הסתר סינון',
    toolbarFiltersTooltipShow: 'הצג סינון',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} active filters` : `${count} active filter`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'חפש...',
    toolbarQuickFilterLabel: 'חיפוש',
    toolbarQuickFilterDeleteIconLabel: 'ניקוי',

    // Export selector toolbar button text
    toolbarExport: 'יצוא',
    toolbarExportLabel: 'יצא',
    toolbarExportCSV: 'הורד CSV',
    toolbarExportPrint: 'הדפס',
    toolbarExportExcel: 'הורד אקסל',

    // Columns management text
    columnsManagementSearchTitle: 'חופש',
    columnsManagementNoColumns: 'אין עמודות',
    columnsManagementShowHideAllText: 'הצג/הסתר הכל',
    columnsManagementReset: 'איפוס',

    // Filter panel text
    filterPanelAddFilter: 'הוסף סינון',
    filterPanelRemoveAll: 'הסר הכל',
    filterPanelDeleteIconLabel: 'מחק',
    filterPanelLogicOperator: 'סוג אופרטור',
    filterPanelOperator: 'אופרטור',
    filterPanelOperatorAnd: 'גם',
    filterPanelOperatorOr: 'או',
    filterPanelColumns: 'עמודות',
    filterPanelInputLabel: 'ערך',
    filterPanelInputPlaceholder: 'סינון ערך',

    // Filter operators text
    filterOperatorContains: 'מכיל',
    filterOperatorEquals: 'שווה',
    filterOperatorStartsWith: "מתחיל ב...",
    filterOperatorEndsWith: 'נגמר ב...',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',
    filterOperatorIsEmpty: 'ריק',
    filterOperatorIsNotEmpty: 'לא ריק',
    filterOperatorIsAnyOf:"הוא כל אחד מ",
    'filterOperator=': '=',
    'filterOperator!=': '!=',
    'filterOperator>': '>',
    'filterOperator>=': '>=',
    'filterOperator<': '<',
    'filterOperator<=': '<=',

    // Header filter operators text
    headerFilterOperatorContains: 'מכיל',
    headerFilterOperatorEquals: 'שווה',
    headerFilterOperatorStartsWith: 'מתחיל ב',
    headerFilterOperatorEndsWith: 'נגמר ב',
    headerFilterOperatorIs: 'Is',
    headerFilterOperatorNot: 'Is not',
    headerFilterOperatorAfter: 'אחרי',
    headerFilterOperatorOnOrAfter: 'Is on or after',
    headerFilterOperatorBefore: 'לפני',
    headerFilterOperatorOnOrBefore: 'Is on or before',
    headerFilterOperatorIsEmpty: 'Is empty',
    headerFilterOperatorIsNotEmpty: 'Is not empty',
    headerFilterOperatorIsAnyOf: 'Is any of',
    'headerFilterOperator=': 'Equals',
    'headerFilterOperator!=': 'Not equals',
    'headerFilterOperator>': 'Greater than',
    'headerFilterOperator>=': 'Greater than or equal to',
    'headerFilterOperator<': 'Less than',
    'headerFilterOperator<=': 'Less than or equal to',

    // Filter values text
    filterValueAny: 'any',
    filterValueTrue: 'true',
    filterValueFalse: 'false',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Show columns',
    columnMenuManageColumns: 'עמודות',
    columnMenuFilter: 'סינון',
    columnMenuHideColumn: 'הסתר',
    columnMenuUnsort: 'בטל מיון',
    columnMenuSortAsc: 'מיין מלמעלה ללמטה',
    columnMenuSortDesc: 'מיין מלמטה ללמעלה',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} active filters` : `${count} active filter`,
    columnHeaderFiltersLabel: 'Show filters',
    columnHeaderSortIconLabel: 'Sort',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} שורות נבחרו`
            : "שורה אחת נבחרה",

    // Total row amount footer text
    footerTotalRows: 'Total Rows:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',
    checkboxSelectionSelectAllRows: 'Select all rows',
    checkboxSelectionUnselectAllRows: 'Unselect all rows',
    checkboxSelectionSelectRow: 'Select row',
    checkboxSelectionUnselectRow: 'Unselect row',

    // Boolean cell text
    booleanCellTrueLabel: 'yes',
    booleanCellFalseLabel: 'no',

    // Actions cell more text
    actionsCellMore: 'more',

    // Column pinning text
    pinToLeft: 'Pin to left',
    pinToRight: 'Pin to right',
    unpin: 'Unpin',

    // Tree Data
    treeDataGroupingHeaderName: 'Group',
    treeDataExpand: 'see children',
    treeDataCollapse: 'hide children',

    // Grouping columns
    groupingColumnHeaderName: 'Group',
    groupColumn: (name) => `Group by ${name}`,
    unGroupColumn: (name) => `Stop grouping by ${name}`,

    // Master/detail
    detailPanelToggle: 'Detail panel toggle',
    expandDetailPanel: 'Expand',
    collapseDetailPanel: 'Collapse',

    // Used core components translation keys
    MuiTablePagination: {},

    // Row reordering text
    rowReorderingHeaderName: 'Row reordering',

    // Aggregation
    aggregationMenuItemHeader: 'Aggregation',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'size',
};

export default GRID_DEFAULT_LOCALE_TEXT