import { useDispatch } from "react-redux"
import { actions } from "store"
import { alerts } from "keys"
import { reset } from "redux-form"

const useAlert = () => {
    const dispatch = useDispatch()

    return (val, props) => {
        dispatch(actions.setAlerts({
            key: alerts.createTestTaker,
            value: { open: val, props: props || null }
        }))
        if (!val)
            dispatch(reset("test_taker"))
    }
}

export default useAlert