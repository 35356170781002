import React from 'react'
import { partition } from 'lodash'
import { splitArrayDynamically } from 'utils/array'
import { WrapCustomSection, WrapStudentParagraph, WrapWordsContent } from './Style'
import { PageFlip } from '.'
import { CustomSection } from 'styles/GlobalStyle'
import { Stepper } from 'components/School/MakingRecordAssessments'

const Paragraph = (props) => {
    const { words } = props

    const [title, body] = partition(words, word => word.word_def_fe_details?.is_title);

    let pages = splitArrayDynamically(body, "word_def_fe_details.new_page")
    pages = pages.slice().reverse();

    if (pages.length % 2) {
        pages.unshift([])
    }

    return (
        <WrapStudentParagraph>
            <WrapWordsContent>
                <PageFlip pages={pages} title={title} />
            </WrapWordsContent>
        </WrapStudentParagraph>
    )
}

export default Paragraph