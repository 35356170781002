import { secretStorage } from "config"
import { isEmpty } from "lodash"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { pulldowns } from "."

const useInitAppData = () => {
    const dispatch = useDispatch()
    const setPulldownDefaultValue = pulldowns.useDefaultValue()

    return (nUser) => {
        const currentUser = nUser || secretStorage.getItem("user")
        const { user_data } = currentUser || {}
        dispatch(actions.setUser(currentUser))

        if (!isEmpty(user_data)) {
            const { affiliation_groups, affiliations, grades } = user_data
            dispatch(actions.setGroups(affiliation_groups))
            dispatch(actions.setAffiliations(affiliations))
            dispatch(actions.setGrades(grades))
        }

        setPulldownDefaultValue(currentUser)
    }
}

export const useCleanAppData = () => {
    const dispatch = useDispatch()

    return () => {
        dispatch(actions.setGroups(null))
        dispatch(actions.setGroups(null))
        dispatch(actions.setAffiliations(null))
        dispatch(actions.setGrades(null))
        dispatch(actions.setTClasses(null))
        dispatch(actions.setHeaderFilters({}))
    }
}

export default { useInitAppData, useCleanAppData }