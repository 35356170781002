import { produce } from 'immer'
import createReducer from "./reducerUtils"

const initialState = {
    grades: null,
    testTakers: null,
    testTakerWithLastAssessment: null,
    tClasses: null,
    testTaker: {},
    selectedTestTakers: [],
}

const grades = {
    setGrades(state, action) {
        state.grades = action.payload
    },
    setTClasses(state, action) {
        state.tClasses = action.payload
    },
    setTestTakerWithLastAssessment(state, action) {
        state.testTakerWithLastAssessment = action.payload
    },
    setTestTaker(state, action) {
        state.testTaker = action.payload
    },
    cleanTestTakers(state, action) {
        state.testTakers = null
    },
    cleanTestTaker(state, action) {
        state.testTaker = {}
    },
    updateTestTaker(state, action) {
        const { key, value } = action.payload
        state.testTaker[key] = value
    },
    editTestTaker(state, action) {
        state.testTaker = { ...state.testTaker, ...action.payload }
    },
    setTestTakers(state, action) {
        state.testTakers = action.payload
    },
    setSelectedTestTakers(state, action) {
        state.selectedTestTakers = action.payload
    },
}

export default produce(
    (state, action) => createReducer(state, action, grades),
    initialState
)

