import React from 'react'
import { WrapSideBarItem, SelectedSideBarItem } from './Styled'
import PermissionHOC from 'components/Permissions/PermissionHOC'

const SideBar = (props) => {
    const { disabled, hideBorder, feature, selected, label, action, subItem } = props

    return (
        <WrapSideBarItem
            disabled={feature?.disabled || disabled}
            onClick={() => action(props)}
            $subItem={subItem}
            variant={"text"}
            $selected={selected}
            color={selected ? 'secondary' : 'white'}
        >
            <SelectedSideBarItem $selected={selected} $hideBorder={hideBorder}/>
            <props.icon fontSize="small" />
            {label}
        </WrapSideBarItem>
    )
}

export default PermissionHOC(SideBar)