import api from "keys/apis"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import actions from "store/actions"

const StoreCrud = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const { controllers } = useSelector(state => state.general)

    const assessmentId = assessment?.id || null

    const getTestTaker = async (id) => {
        try {
            const testTaker = await dispatch(actions.getTestTaker(id))
            return testTaker
        }
        catch {
            console.log("error getTestTaker")
        }
    }

    const getAudioFile = async (assessment) => {
        const { id = assessmentId } = assessment || {}
        try {
            dispatch(actions.updateAssessment({ audio_file: null }))
            const file = await dispatch(actions.getAudioFile({ id: id }))
            dispatch(actions.updateAssessment({ audio_file: file }))
        }
        catch {
            console.log("error getAudioFile")
            dispatch(actions.updateAssessment({ audio_file: null }))
        }
    }

    const updateAssessmentStatus = async (status, sub_status, assessment_id = assessment?.id) => {
        try {
            const res = await dispatch(actions.updateAssessmentStatus({ status: status, sub_status: sub_status, id: assessment_id }))
            return res
        }
        catch {
            console.log("error updateAssessmentStatus")
        }
    }

    const getAssessmentDefs = async () => {
        try {
            const assessmentDefs = await dispatch(actions.getAssessmentDefs())
            dispatch(actions.setAssessmentDefs(assessmentDefs))
        }
        catch {
            console.log("error getAssessmentDefs")
        }

    }

    const getAssessment = async (id) => {
        try {
            const assessment = await dispatch(actions.getAssessment(id))
            dispatch(actions.setAssessment(assessment))
        }
        catch {
            console.log("error getAssessmentDefs")
        }
    }

    return {
        getTestTaker,
        updateAssessmentStatus,
        getAssessmentDefs,
        getAssessmentDefs,
        getAudioFile,
        getAssessment
    }
}

export default StoreCrud