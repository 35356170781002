import styled from "styled-components";
import { Scroll } from "styles/GlobalCss";
import { header, sidebar } from "styles/theme";

const WrapContent = styled.div`
width:calc(100% - ${sidebar}px);
height:calc(100% - ${header}px);    

padding:20px;
box-sizing: border-box;
overflow-y:auto;
overflow-x:hidden;
display: flex;
flex-direction: column;
row-gap: 10px;
${Scroll};
`

const Title = styled.div`
margin-bottom: 15px;
font-size: 20px;
text-align:center;
padding:10px;

`

const Wrap = styled.div`
width: 100%;
margin-top:${header}px;
height: 100%;
margin-right: ${sidebar}px;
`

const WrapNav = styled.div`

`

export { Wrap, WrapContent, Title, WrapNav }