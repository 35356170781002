import React, { useEffect } from 'react'
import { Vowel } from './Style';
import { insertion, omission, substitution } from 'keys/types';
import { isEqual } from 'lodash';

const VowelCategory = (props) => {
    const { index, phoneme, updatePhoneme, name } = props
    const { letter, substitution_letter, letter_status } = phoneme || {}

    const onChangeLetter = ({ target }) => {
        const { value } = target
        const nPhoneme = { ...phoneme }
        if (value && value != letter)
            nPhoneme.letter_status = substitution
        else nPhoneme.letter_status = null
        nPhoneme.substitution_letter = target.value
        updatePhoneme(index, nPhoneme)
    }

    const deleteSubstitutionLetter = () => {
        const nPhoneme = { ...phoneme }
        delete nPhoneme.substitution_letter
        updatePhoneme(index, nPhoneme)
    }

    const disabled =
        letter_status === omission ||
        letter_status === insertion

    useEffect(() => {
        if (!isEqual(letter_status, substitution))
            deleteSubstitutionLetter()
    }, [letter_status])

    return (
        <Vowel
            incorrect={letter_status === substitution && substitution_letter}
            disabled={disabled}
        >
            {name}
            {<input
                value={substitution_letter || ""}
                maxlength={1}
                onChange={onChangeLetter}
            />}
        </Vowel>)

}

export default VowelCategory