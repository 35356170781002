import React, { useState } from 'react'
import { ChangeAudioSetting, ZoomIn, Rate, RateButton } from './Style'
import { RecordCrud } from './hooks'
import { Stack, Slider } from '@mui/material'
import { RecordData } from './hooks'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { actions } from 'store'

const ChangeSetting = () => {
    const dispatch = useDispatch()
    const { rateBtns, } = RecordData()
    const { handleZoomIn } = RecordCrud()
    const [zoom, setZoom] = useState(15)
    const [selectedBtn, setSelectedBtn] = useState(null)

    const { wavesurfer } = useSelector(state => state.wavesurfer)

    const zoomIn = (e, value) => {
        setZoom(value)
        wavesurfer.zoom(value * 10);
    }

    return (
        <ChangeAudioSetting>
            <Rate>
                {rateBtns.map((rateBtn) => {
                    const { label, color, id, icon, action, rightIcon, markSelected } = rateBtn

                    const handleClick = () => {
                        if (markSelected)
                            setSelectedBtn(id)
                        action()
                    }

                    return (
                        <RateButton
                            selected={selectedBtn === id}
                            onClick={handleClick}
                            key={id}
                            color={color}
                            variant='contained'
                            $rightIcon={rightIcon}
                            size='small'
                        >
                            {label}
                            {icon}
                        </RateButton>
                    )
                })}
            </Rate>
            <ZoomIn>
                <Stack className='zoom-in' direction="row" sx={{ mb: 1 }} alignItems="center">
                    <span className='min-max'>{"Max"}</span>
                    <Slider className='slider' aria-label="Default" value={zoom} onChange={zoomIn} />
                    <span className='min-max'>{"Min"}</span>
                </Stack>
            </ZoomIn>
        </ChangeAudioSetting>
    )
}

export default ChangeSetting