/**
 * This file defines the base application styles.
 *
 * Use it to define generic component styles (e.g. the default text styles, default button styles...).
 */
// import { StyleSheet } from 'react-native';
import buttonStyles from './Buttons.ts';
import { CommonParams } from './theme';
import { white } from './theme.js'

export default function <C>({ Colors, ...args }: CommonParams<C>) {
  return {
    button: buttonStyles({ Colors, ...args }),
    // ...StyleSheet.create({
    backgroundPrimary: {
      backgroundColor: Colors.primary,
    },
    backgroundDark: {
      backgroundColor: Colors.dark,
    },
    backgroundLightPrimary: {
      backgroundColor: Colors.lightPrimary,
    },
    backgroundReset: {
      backgroundColor: Colors.transparent,
    },
    backgroundWhite: {
      backgroundColor: Colors.white,
    },
    backgroundLight: {
      backgroundColor: white,
    },
    textInput: {
      backgroundColor: Colors.inputBackground,
      color: Colors.textGray400,
      height: 45,
      borderRadius: 10,
      paddingStart: 20,
    },
    sbRadius: {
      borderRadius: 10,
    },
    circle: {
      borderRadius: 70,
    },
    round: {
      borderRadius: 30,
    },
    tinyRound: {
      borderRadius: 8,
    },
    textArea: {
      height: 100,
      textAlignVertical: 'top',
    },
    shadow: {
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
      elevation: 2,
    },
    border: {
      borderWidth: 0.3,
      borderColor: Colors.textGray800,
    },
    // }),
  };
}