import React from 'react'


const EmailVarification = () => {

    return (
        <div>
            <div>בתיבת המייל מחכה לכם לינק לאימות</div>
            <div>
                לא קיבלתם מייל? אולי זה נחת בטעות בתיבת הספאם.
            </div>
        </div>
    )
}

export default EmailVarification