import React, { useEffect } from 'react';
import { Header,WrapFiltersHeader } from './Style';
import { Button, fieldsTyps } from 'components/Form';
import { WrapHeader } from 'styles/GlobalStyle'

import AssessmentsHooks from 'components/School/Assessments/hooks';
import { map } from 'lodash';
import { assessmentDefRQ } from 'store/hooks';
import { useSelector } from 'react-redux';

const Assessments = (props) => {
    const headerItemsP = AssessmentsHooks.useHeaderItems()
    const { headerItems = headerItemsP, disabledSearch, headercss, refetchData } = props

    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters } = useSelector(state => state.assessment)

    const { data, refetch: refetchAssessmentDefs } = assessmentDefRQ.useGetAssessmentDefs()


    useEffect(() => {
        map(headerItems).forEach((item) => {
            const { input = {}, defaultValue } = item
            if (!input?.value && defaultValue) {
                input.onChange(defaultValue)
            }
        })
    }, [headerItems])

    useEffect(() => {
        refetchAssessmentDefs()
    }, [headerFilters, assessmentFilters?.category])

    return (
        <WrapFiltersHeader>
            <Header headercss={headercss}>
                {headerItems.map((headerBtn, index) => {
                    let Component = fieldsTyps[headerBtn.component]

                    return (
                        <Component
                            {...headerBtn}
                            key={index}
                            fullwidth
                            options={headerBtn.name === 'assessment_def' ? data?.data : headerBtn.options}
                            input={{
                                ...headerBtn?.input,
                                value: headerBtn?.input?.value
                            }}
                        />
                    )
                })}
                <Button
                    disabled={disabledSearch}
                    label="חיפוש"
                    action={refetchData}
                    height={'40px'}
                    width={'80px'}
                    color={"thirdly"}
                />
            </Header>
        </WrapFiltersHeader>
    );
}

const isRender = (prev, next) => {
    return false
}

export default React.memo(Assessments, isRender)
