import React from 'react'
import { Vowel } from './Style';
import { insertion, na, omission } from 'keys/types';
import { isEqual } from 'lodash';

const VowelCategory = (props) => {
    const { type, items = {}, index, phoneme, updatePhoneme, name } = props
    const { vowel_status, letter_status } = phoneme || {}

    const handleChange = () => {
        const nPhoneme = { ...phoneme }
        nPhoneme.letter_status = isEqual(letter_status, omission) ? null : omission
        updatePhoneme(index, nPhoneme)
    }

    const disabled =
        vowel_status === na ||
        letter_status === insertion

    return (
        <Vowel
            incorrect={letter_status === omission}
            onClick={() => handleChange()}
            disabled={disabled}
        >
            {name}
        </Vowel>)

}

export default VowelCategory