import React from 'react'
import { Vowel } from './Style';
import { insertion, na, omission } from 'keys/types';
import { isEqual } from 'lodash';

const NA = (props) => {
    const { items = {}, index, phoneme, updatePhoneme } = props
    const { letter_status, vowel_status } = phoneme || {}

    const handleChange = (type) => {
        const nPhoneme = { ...phoneme }
        nPhoneme[type] = isEqual(nPhoneme[type], na) ? null : na
        updatePhoneme(index, nPhoneme)
    }

    const disabled =
        letter_status === omission ||
        letter_status === insertion

    return (
        Object.keys(items).map((key) => {
            const keyVowel = Number(key)
            const { type } = items[key]
            return (
                <Vowel
                    keyVowel={keyVowel}
                    items={items}
                    type={type}
                    incorrect={phoneme[type] === na}
                    disabled={disabled}
                    onClick={() => handleChange(type)}
                >
                    {items[keyVowel].name}
                </Vowel>)
        })
    )
}

export default NA