import React from 'react'
import { Button, RenderFields } from 'components/Form'
import { WrapStepFields, StepTitle, GridStep2 } from './Style'
import { step2 } from './hooks'
import { customUserRQ } from 'store/hooks'
import { useSelector } from 'react-redux'
import { formname } from './hooks/constants'
import { useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'

const Step1Fields = (props) => {
    const { handleNext, form } = props
    const { title } = step2.constants

    const navigate = useNavigate()

    const formValues = useSelector(state => state.form[formname]?.values)

    const fields = step2.useFields()
    const continueBtn = step2.useNext()
    const { mutateAsync: validateUsername } = customUserRQ.useValidateUsername()


    const handleNextStep = async () => {
        const isFormValid = handleNext(form)

        const isUsernameValid = await validateUsername(formValues?.username)

        if ((isFormValid || isFormValid === undefined) && isUsernameValid) {
            navigate(app.register3)
        }
    }

    return (
        <WrapStepFields>
            <StepTitle>{title}</StepTitle>
            <GridStep2>
                <RenderFields fields={fields} />
                <Button
                    {...continueBtn}
                    action={handleNextStep}
                />
            </GridStep2>
        </WrapStepFields>
    )
}

export default Step1Fields