import { useMutation } from "react-query";
import { logoutFetch } from "store/middlewares/auth.crud";
import { useNavigate } from "react-router-dom";
import { app } from "components/Router/routes";
import { secretStorage } from "config";
import { useDispatch } from "react-redux";

const AuthCrud = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { mutateAsync: logoutRQ } = useMutation(logoutFetch)

    const logout = async () => {
        dispatch({ type: "RESET_ALL_REDUCERS" })
        await logoutRQ()
        secretStorage.clean()
        navigate(app.login)
    }

    return {
        logout
    }
}

export default AuthCrud