import { AbilityContext } from "components/Permissions/Can";
import { useContext } from "react";


const useNameField = () => {
    const ability = useContext(AbilityContext)


    const sortComparator = (v1, v2, param1, param2) => {
        // Split full name into first and last name
        const [firstName1, lastName1] = v1.split(' ');
        const [firstName2, lastName2] = v2.split(' ');

        // Compare first names
        if (firstName1 !== firstName2) {
            return firstName1.localeCompare(firstName2);
        }

        // If first names are the same, compare last names
        return lastName1.localeCompare(lastName2);
    };

    const sortModel = {
        field: 'first_name_last_name', sort: 'asc', comparator: sortComparator
    };

    const student = ability.can("read", "student-label") ? "תלמיד" : "ילד"

    const testTakerNameField = {
        field: 'first_name',
        headerName: `שם ה${student}`,
        width: 140,
        align: 'center',
        headerAlign: "center",
        valueGetter: (value) => `${value.row?.first_name || ''} ${value.row?.last_name || ''}`,
    }

    return {
        field: testTakerNameField,
        sortModel: sortModel
    }
}

export default useNameField