import { actions } from "store"
import { AlertCrud } from "components/Alerts/hooks"
import { useDispatch, useSelector } from "react-redux"
import { alerts } from "keys"
import { convertSelectToValue } from "utils/formatter"
import { change, reset } from "redux-form"

const CreateUserCrud = () => {
    const formName = "createTestTaker"
    const dispatch = useDispatch()
    const { setAlerts } = AlertCrud()
    const createTestTakerValues = useSelector(state => state.form[formName]?.values)


    const resetData = () => {
        dispatch(reset(formName))
    }

    const handleCreateGradeAlert = (value) => {
        setAlerts(alerts.createGrade, value)
    }

    const cleanFormValues = (keys) => {
        keys.map((key) =>
            dispatch(change(formName, key, null))
        )
    }

    const handleChangeAffiliationGroup = async (key, value) => {
        // cleanFormValues(["affiliation", "grade", "t_class"])

        const values = convertSelectToValue({ ...createTestTakerValues, [key]: value })
        try {
            const affiliations = await dispatch(actions.getAffiliations(values))
            dispatch(actions.setAffiliations(affiliations))
        }
        catch {
            console.log("error getAffiliations")
        }
    }

    const handleChangeAffiliation = async (key, value) => {
        cleanFormValues(["grade", "t_class"])

        const values = convertSelectToValue({ ...createTestTakerValues, [key]: value })
        try {
            const grades = await dispatch(actions.getGrades(values))
            dispatch(actions.setGrades(grades))
        }
        catch {
            console.log("error getGrades")
        }
    }

    const handleChangeTClasses = async () => {
    }

    const cancelAction = () => {
        resetData()
        handleCreateGradeAlert(false)
    }

    return {
        cancelAction,
        handleChangeAffiliation,
        handleChangeAffiliationGroup,
        handleChangeTClasses,
        handleCreateGradeAlert
    }
}

export default CreateUserCrud