import { produce } from 'immer'
import createReducer from "./reducerUtils"

const initialState = {
    wavesurfer: null,
    isPlaying: false,
    rate: 1,
    zoomIn: 15,
    regions: [
        {
            id: "region-1",
            start: 0.5,
            end: 10,
            color: "rgba(0, 0, 0, .5)",
            data: {
                systemRegionId: 31,
            },
        },
        {
            id: "region-2",
            start: 5,
            end: 25,
            color: "rgba(225, 195, 100, .5)",
            data: {
                systemRegionId: 32,
            },
        },
        {
            id: "region-3",
            start: 15,
            end: 35,
            color: "rgba(25, 95, 195, .5)",
            data: {
                systemRegionId: 33,
            },
        },
    ],
    markers: [
        {
            time: 5.5,
            label: "V1",
            color: "#ff990a",
            draggable: true,
        },
        {
            time: 10,
            label: "V2",
            color: "#00ffcc",
            position: "top",
        },
    ],
    timelineVis: false,
    cutWavesurfer: null
}

const wavesurfer = {
    setWavesurfer(state, action) {
        state.wavesurfer = action.payload
    },
    setCutWavesurfer(state, action) {
        state.cutWavesurfer = action.payload
    },
    setTimelineVis(state, action) {
        state.timelineVis = action.payload
    },
    setRegions(state, action) {
        state.regions = action.payload
    },
    setIsPlaying(state, action) {
        state.isPlaying = action.payload
    },
    setRate(state, action) {
        state.rate = action.payload
    },
    setZoomIn(state, action) {
        state.zoomIn = action.payload
    },
}

export default produce(
    (state, action) => createReducer(state, action, wavesurfer),
    initialState
)

