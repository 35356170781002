import React from 'react'
import { AssessmentDefs } from 'components'
import { useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'

const LearningResources = () => {
    const navigate = useNavigate()

    const goAssessment = (assessmentDef) => {
        const { id } = assessmentDef
        navigate(`${app.audioTranscription}?assessmentDef=${id}`)
    }

    return <AssessmentDefs onSelectAssessment={goAssessment} />
}
export default LearningResources