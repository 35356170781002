import { isEmpty } from "lodash"



const removeKeys = (obj, keys) => {

}

const firstWord = (str) => {
    return str?.replace(/ .*/, '')
}

const isVal = (val) => {
    if (isEmpty(val))
        return null
    return val
}

export { isVal, removeKeys, firstWord }