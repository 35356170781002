
class Battery {

    constructor(battery) {
        const { assessment_defs, test_takers } = battery
        this.assessment_defs = assessment_defs
        this.test_takers = test_takers
        
    }

}

export default Battery