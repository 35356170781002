import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'store'

const useOnPlay = () => {
    const dispatch = useDispatch()
    const { wavesurfer, isPlaying } = useSelector(state => state.wavesurfer)

    return () => {
        if (!isPlaying) {
            dispatch(actions.setIsPlaying(true))
            if (wavesurfer)
                wavesurfer.play()
        }
    }
}

export default useOnPlay 