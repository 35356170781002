import { useSelector } from "react-redux"
import { CreateGradeCrud } from "."
import { FormCrud } from "components/Form/hooks"
import { SelectTestTakerData } from 'components/School/CreateTestTaker'

const CreateUserData = () => {
    const formName = "createGrade"
    const title = "הוספת שכבה"
    const { affiliations, groups } = useSelector(state => state.affiliation)
    const { cancelAction } = CreateGradeCrud()
    const { actionSubmit } = FormCrud()

    const fields = [
        {
            name: "name",
            component: "input",
            placeholder: "שם " ,
        }
    ]

    const submitBtns = [
        {
            id: 0,
            label: "הוספה",
            color: "secondary",
            action: () => actionSubmit(formName)
        },
        {
            id: 1,
            label: "ביטול",
            color: "light",
            action: () => cancelAction(formName)
        }
    ]


    return {
        formName,
        fields,
        title,
        submitBtns
    }
}

export default CreateUserData