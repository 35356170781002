import en from "config/strings/en";

function validateIsraeliIdNumber(idNumber) {
    // Check if the ID number is 9 digits long
    if (!/^\d{9}$/.test(idNumber)) {
        return false;
    }

    // alert(idNumber)
    // if (!idNumber.length < 9) {
    //     return false;
    // }

    // Calculate the check digit
    const idDigits = idNumber.split('').map(Number);
    let sum = 0;

    idDigits.forEach((digit, index) => {
        let weight = (index % 2 === 0) ? 1 : 2;
        let result = digit * weight;
        if (result > 9) {
            result -= 9;
        }
        sum += result;
    });

    return sum % 10 === 0;
}

function validatePassword(password) {
    let error = ""
    if (!password)
        error = en.require('password')
    else if (password.length < 8)
        error = en.minimumChars
    else if (/^\d+$/.test(password)) {
        error = en.invalidPassword
    }
    return error
}

function validateEmail(email) {
    let error = ""
    if (!email) {
        error = en.require('email')
    }
    else if (!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9_ ]+\.)+[a-zA-Z]{2,}))$/))
        error = en.invalidEmail

    return error
}

export { validateEmail, validateIsraeliIdNumber, validatePassword }


