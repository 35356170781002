import { useSelector } from "react-redux"
import { alerts as alertKeys } from 'keys'

const useBackToConfirmation = () => {
    const { alerts } = useSelector(state => state.general)

    const backToConfirmationFields = {
        title: alerts[alertKeys.backTo].props?.title,
        content: alerts[alertKeys.backTo].props?.content,
        btns: [{
            label: "אישור",
            action: async () => {
                await alerts[alertKeys.backTo].props?.onConfirm()
                alerts[alertKeys.backTo].props?.onClose()
            },
            variant: "text"
        },
        {
            label: "ביטול",
            action: alerts[alertKeys.backTo].props?.onClose,
            variant: "text"

        }]
    }

    return backToConfirmationFields
}

export default useBackToConfirmation