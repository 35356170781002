import { useContext } from "react"
import { RegisterContext } from "../RegisterContext"

const useAddNewChild = () => {
    const { addNewChild } = useContext(RegisterContext)

    const addChildBtn = {
        label: "להוספת ילד.ה",
        systemUI: true,
        color: "secondary",
        className: "_add_child",
        action: addNewChild,
        fullWidth: true
    }

    return addChildBtn
}
export default { useAddNewChild }