import React from 'react'
import { WrapContactUsFab, ContactUsFab } from './Style'
import { ReactComponent as MessageIcon } from 'assets/icons/Message.svg';
import { SvgIcon, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { app } from 'components/Router/routes';

const ContactUsFabComponent = () => {
    const navigate = useNavigate()

    const goContactUs = () => {
        navigate(app.contactUs)
    }

    return (
        <WrapContactUsFab>
            <Tooltip title={"צרכים עזרה? פנו אלינו"} placement='left'>
                <ContactUsFab variant='contained' color="dark" onClick={goContactUs}>
                    <SvgIcon fontSize="large" component={MessageIcon} inheritViewBox />
                </ContactUsFab>
            </Tooltip>
        </WrapContactUsFab>
    )
}

export default ContactUsFabComponent