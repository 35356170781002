import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { alerts, updateType } from 'keys';
import { AlertCrud } from 'components/Alerts/hooks';
import { secretStorage } from 'config';
import { arrayPush, change, reset } from 'redux-form';
import { useDispatch } from 'react-redux';
import { TestTakerPopup } from '.'
import Papa from 'papaparse';
import schoolsFile from 'mock/Schools.csv';

const RegirsterWrap = () => {
    const { state } = useLocation()
    const dispatch = useDispatch()

    const { setAlerts } = AlertCrud()

    const [schools, setSchools] = useState(null)

    const cleanState = () => window.history.replaceState({}, '')

    // useEffect(() => {
    //     if (state?.openNewChild) {
    //         dispatch(reset("test_taker"))
    //         setAlerts(alerts.newChild, true)

    //     }
    // }, [state?.openNewChild])

    useEffect(() => {
        window.addEventListener("beforeunload", cleanState);
        return () => {
            window.removeEventListener("beforeunload", cleanState);
        };
    }, []);

    useEffect(() => {
        if (!schools)
            fetch(schoolsFile)
                .then(response => response.text())
                .then(csvData => {
                    Papa.parse(csvData, {
                        header: true,
                        complete: (results) => {
                            console.log("schools")
                            setSchools(results.data)
                        },
                        error: (error) => {
                            console.error('CSV Parsing Error:', error);
                        }
                    });
                })
                .catch(error => {
                    console.error('Error fetching CSV file:', error);
                });
    }, [])


    const commonValue = {
        schools,
    }

    return (
        <CreateTestTakerContext.Provider value={commonValue}>
            <TestTakerPopup />
        </CreateTestTakerContext.Provider>
    )
}

export const CreateTestTakerContext = createContext()
export default RegirsterWrap