

import { validateEmail } from 'utils/validation';

//validation login form
const validate = (values) => {
    const errors = {}

    if (values) {
        errors.email = validateEmail(values.email)
    }

    return errors;
}

export default validate;

