import { useSelector } from "react-redux"
import { CreateClassCrud } from "."
import { FormCrud } from "components/Form/hooks"
import { SelectTestTakerData } from 'components/School/CreateTestTaker'

const CreateClassData = () => {
    const formName = "createClass"
    const title = "הוספת כיתה"
    const { grades } = useSelector(state => state.testTaker)
    const { affiliations, groups } = useSelector(state => state.affiliation)
    const { cancelAction } = CreateClassCrud()
    const { actionSubmit } = FormCrud()

    const fields = [
        {
            name: "name",
            component: "input",
            placeholder: "שם ",
        },
        {
            name: 'group',
            component: "autoComplete",
            placeholder: "קבוצה",
            options: groups,
            className: "b-group",
            mandatory: true,
        },
        {
            name: 'affiliation',
            component: "autoComplete",
            placeholder: "יחידה",
            options: affiliations,
            mandatory: true,
        },
        {
            name: "grade",
            component: "autoComplete",
            placeholder: "שכבה",
            options: grades,
            className: "b-group",
            mandatory: true,
        }
    ]

    const submitBtns = [
        {
            id: 0,
            label: "הוספה",
            color: "secondary",
            action: () => actionSubmit(formName)
        },
        {
            id: 1,
            label: "ביטול",
            color: "light",
            action: () => cancelAction(formName)
        }
    ]


    return {
        formName,
        fields,
        title,
        submitBtns
    }
}

export default CreateClassData