import { Bounce } from "react-toastify";

const toastConfig = {
    rtl: true, // Set RTL to true
    style: {
        textAlign: 'right',
        borderRadius:'11px'
    },
    position: "top-center",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
};

export default toastConfig