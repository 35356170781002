import axios from "config/axiosConfig";
import { letter_add_ons, letter_descriptions, reading_assessment, vowel_codes } from "keys/apis";
import { convertDictArraytoArray } from "utils/array";

export const getLetterAddOnFetch = async () => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${letter_add_ons}/`)
        .then(response => {
            //The array return in uncorrect format, we convert the array
            return { ...response, data: convertDictArraytoArray(response.data) }
        })
}


export const getVowelCodesFetch = async () => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${vowel_codes}/`)
        .then(response => {
            //The array return in uncorrect format, we convert the array
            return { ...response, data: convertDictArraytoArray(response.data) }
        })
}

export const getLetterDescriptionsFetch = async () => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${letter_descriptions}/`)
        .then(response => {
            //The array return in uncorrect format, we convert the array
            return { ...response, data: convertDictArraytoArray(response.data) }
        })
}