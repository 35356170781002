import MarkingWordsCrud from "./MarkingWords.crud";

import useFields from "./useFields";
import useTabs from './useTabs'
import useStartMarkingWords from './useStartMarkingWords'
import useSaveDraft from './useSaveDraft'
import useHeaderActions from "./useHeaderActions";
import useActionBtns from './useActionBtns'
import useInitializeConfirmation from './useInitializeConfirmation'

export default { useInitializeConfirmation, useHeaderActions, useFields, useTabs, useActionBtns, useStartMarkingWords, useSaveDraft }
export { MarkingWordsCrud }
