import React from 'react'
import { Tooltip } from '@mui/material'
import { QuestionMarkSharp } from '@mui/icons-material'
import { WrapTooltip } from './Styled'

const CustomTooltip = (props) => {
    const { title, placement = "top" } = props

    return (
        <Tooltip title={title} placement={placement}>
            <WrapTooltip>
                <QuestionMarkSharp sx={{ fontSize: 10 }}/>
            </WrapTooltip>
        </Tooltip>
    )
}

export default CustomTooltip