import React, { useEffect, useState } from 'react'
import { ratingRQ } from 'store/hooks'
import RatingPage from './RatingPage'
import { useSelector } from 'react-redux'

const WrapRatingContext = () => {
    const [reportDisplay, setReportDisplay] = useState()

    const { assessment } = useSelector(state => state.assessment)

    const { refetch: refetchScoreRating, data: scoreRating = {} } = ratingRQ.useGetScoreRatingMethod()
    const { data: classStats } = ratingRQ.useGetClassStats()

    useEffect(() => {
        if (assessment?.id)
            refetchScoreRating()
    }, [assessment?.id])

    const commonState = {
        scoreRating,
        classStats,
        reportDisplay,
        setReportDisplay
    }

    return (
        <RatingContext.Provider value={commonState}>
            <RatingPage />
        </RatingContext.Provider>
    )
}

export const RatingContext = React.createContext()
export default WrapRatingContext