import React from 'react'
import { WrapPrices, WrapCouponCode, WrapChildrenPayment, WrapCards, SubTitle, Title, CardHeader } from './Style'

import PricesHooks from './hooks'
import { PaymentCard } from '.'
import { Input } from 'components/Form'

const Payment = (props) => {
    const { moreCards } = props

    const prices = PricesHooks.usePrices()
    const coupon = PricesHooks.useCouponCode()
    const { title } = PricesHooks.constants

    return (
        <WrapPrices>
            <WrapCouponCode>
                <Input {...coupon} {...props} />
            </WrapCouponCode>
            <Title>
                {title}
            </Title>
            <WrapCards>
                <WrapChildrenPayment>
                    <PaymentCard {...prices[0]} {...props} />
                    {moreCards}
                </WrapChildrenPayment>
                <PaymentCard {...prices[1]} {...props} />
                <PaymentCard {...prices[2]} {...props} />
            </WrapCards>
        </WrapPrices>
    )
}

export default Payment