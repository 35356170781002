import React from 'react'
import { Can } from 'components/Permissions'

const DisabledHOC = (props) => {
    const { HocComponent, role, feature } = props

    return (
        <Can I={role} this={feature} passThrough>
            {allowed =>
                <HocComponent {...props}
                    feature={{
                        disabled: !allowed
                    }} />
            }
        </Can>
    )
}

export default DisabledHOC
