import { Fab, Backdrop } from "@mui/material";
import styled from "styled-components";
import { dark, primary, secondary, sidebar, white, yellow } from "styles/theme";
import { styled as muiStyled } from '@mui/material/styles';
import { WrapFab } from "components/Form/Styled";

const footerHeight = '40px'


const WrapCreateAssessmentRecord = styled.div`

`

const VoiceMessage = styled.div`
font-size:50px;
`

const WrapBackdrop = styled(Backdrop)`
margin-right:${sidebar}px !important;
`

const WrapUploadFab = styled.div`
display:flex;
justify-content: end;

`

const WrapProgressNum = styled.div`
font-size:150px;
color:#122837;
font-weight: bold;
`

const WrapFooterActions = styled.div`
display:flex;
width:100%;
width: 100%;
box-sizing: border-box;
align-items: center;
justify-content: ${props => props.align};
column-gap: 10px;
`

const WrapRecordActions = styled.div`
display: flex;
column-gap: 15px;
justify-content: center;
height:${footerHeight};
grid-template-columns: 25% 50% 25%;

`

const WrapRecord = styled.div`
display: flex;
justify-content: end;
`

const WrapAssessmentRecord = styled.div`
display: grid;
justify-content: end;
height: auto;
align-items: center;
grid-template-columns: 25% 50% 25%;
padding: 0px 15px;
`

const WrapUpload = styled.div`
height:${footerHeight};
display: flex;
justify-content: end;
`

const WrapRecordButton = muiStyled(WrapFab)`
  position: relative;
  color:${white};
  color: white;
  opacity: 1;
  transition: all 0.2s;

  height: 75px;
  width: 75px;


  .pulse-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${white} !important;
    border-radius: 100%;
    opacity: 0.5;
    z-index: -10;
    animation: pulse 1s ease-out infinite;
    :hover{
      background-color: ${secondary} !important;
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1, 1);
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
`;

export { VoiceMessage, WrapBackdrop, WrapRecordButton, WrapUploadFab, WrapProgressNum, WrapUpload, WrapRecordActions, WrapRecord, WrapAssessmentRecord, WrapCreateAssessmentRecord, WrapFooterActions }