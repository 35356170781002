import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Tooltip } from '@mui/material'
import { isEmpty, pick } from 'lodash'
import { WrapIndication } from './Style'
import { deviationRatingIndex, naLabel, ratingListLabel, ratingListTheme } from 'keys/rating'
import { AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks'
import { statuses } from 'keys'

const RateAndAccuracy = (props) => {
    const { value } = props
    const { fields, rating, status } = value || {}
    const { scoreRatingMethods } = useSelector(state => state.rating)
    const { indicationIcon } = AssessmentsAndTestTakersData()

    const { warning, noraml, nodata } = indicationIcon

    const ratingFields = pick(rating, [fields.id, fields.index])
    let ratingIndex = ratingFields[fields.index]
    let ratingId = ratingFields[fields.id]
    let currentScoreRatingMethod
    let indication

    if (status === statuses.brokenFile)
        currentScoreRatingMethod = {
            label: naLabel
        }

    else {
        if (isEmpty(scoreRatingMethods))
            currentScoreRatingMethod = {
                themeColor: ratingListTheme[ratingIndex - 1],
                label: ratingListLabel[ratingIndex - 1]
            }
        else {
            currentScoreRatingMethod = scoreRatingMethods?.[ratingId]
            ratingIndex = currentScoreRatingMethod?.index
        }

        indication = ratingIndex ? (ratingIndex <= deviationRatingIndex ? noraml : warning) : nodata
    }

    return (
        <Tooltip title={currentScoreRatingMethod?.label} placement='left'>
            <WrapIndication>
                {indication?.icon ? <indication.icon
                    {...indication}
                    fontSize='small'
                    color={currentScoreRatingMethod?.themeColor || 'ligthGray'}
                /> :
                    currentScoreRatingMethod?.label}

            </WrapIndication>
        </Tooltip>
    )

}

export default RateAndAccuracy