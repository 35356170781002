import { isBoolean } from "lodash"


class Indication {

    constructor(indication, prevIndication) {
        const { key, value } = indication

        this.key = key
        if (isBoolean(value)) {
            if (value) {
                this.start = false
                this.completed = true
            }
            else {
                this.completed = false
                if (prevIndication?.start)
                    this.start = true
                else this.start = false
            }
        }
        else this.disabled = true
    }
}

export default Indication