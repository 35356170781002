import React, { useEffect } from 'react'
import { Button, RenderFields } from 'components/Form'
import { WrapStepFields, StepTitle, WrapFooterStep3, GridStep3, GridStep33, WrapStep33Fields } from '../Style'
import { useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'
import { useSelector } from 'react-redux'
import { formname } from '../hooks/constants'
import { updateType } from 'keys'
import { step3 } from '../hooks'
import { FlexBetween, FlexCenter } from 'styles/GlobalStyle'

const Step1Fields = (props) => {
    const { form, handleNext } = props

    const navigate = useNavigate()
    const formValues = useSelector(state => state.form[formname]?.values)

    const continueBtn = step3.useNext()
    const fields = step3.useStep3Fields()
    const numChildren = step3.useNumChildren()
    const addChildBtn = step3.useAddNewChild()

    const { title } = step3.constants

    const handleNextStep = async () => {
        const isFormValid = handleNext(form)

        const newChildrenCount = formValues.children.filter((child) => child.update_type === updateType.create)?.length

        if ((isFormValid || isFormValid === undefined))
            if (newChildrenCount)
                navigate(app.register3)
            else navigate(app.register4)
    }

    return (
        <WrapStepFields>
            <StepTitle>{title}</StepTitle>
            <WrapStep33Fields>
                {/* <GridStep33 numChildren={formValues?.children?.length}> */}
                <GridStep3>
                    <RenderFields fields={[numChildren]} />
                    {/* </GridStep33> */}
                    {/* <FlexBetween> */}
                    {/* <Button
                        {...addChildBtn}
                    /> */}
                    <Button
                        {...continueBtn}
                        action={handleNextStep}
                    />
                </GridStep3>

                {/* </FlexBetween> */}
            </WrapStep33Fields>
        </WrapStepFields>
    )
}

export default Step1Fields