import { useSelector } from "react-redux"
import { alerts as alertKeys, loading, queryClient as qc } from 'keys'
import { deleteAssessmentWordsFetch } from "store/middlewares/words.crud"
import { useMutation, useQueryClient } from "react-query"
import { actions } from "store"
import { useDispatch } from "react-redux"
import { AlertCrud } from "components/Alerts/hooks"
import { useCallback } from "react"

const useInitializeAssessment = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const { assessment } = useSelector(state => state.assessment)
    const { setAlerts } = AlertCrud()

    const { mutateAsync: deleteAssessmentWords, isLoading: deleteAssessmentWordsLoading } = useMutation({
        mutationFn: deleteAssessmentWordsFetch,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: qc.assessmentAndWords })
            dispatch(actions.updateAssessment({ timePoint: 0 }))
            queryClient.invalidateQueries({ queryKey: qc.assessment })
        }
    })

    const setInitializeConfirmation = useCallback(async (value) => {
        setAlerts(alertKeys.initializeAssessment, value)
    }, [])


    const initializeAssessment = async () => {
        await deleteAssessmentWords(assessment.id)
        setInitializeConfirmation(false)
    }

    const initializeConfirmationFields = {
        title: "איפוס המבדק",
        content: "באיפוס יימחקו כל הנתונים ששמרת על המבדק",
        btns: [{
            label: "אישור",
            action: async () => {
                initializeAssessment()
            },
            variant: "text",
            loading: deleteAssessmentWordsLoading
        },
        {
            label: "ביטול",
            action: () => {
                setInitializeConfirmation(false)
            },
            variant: "text"
        }]
    }

    return initializeConfirmationFields
}

export default useInitializeAssessment