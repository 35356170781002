import React from 'react'
import { AuthWithCartoon } from 'components/Auth'
import { Button, Form } from 'components/Form'
import { LoginCrud, constants } from './hooks'
import { ForgotUserNameOrPassword ,Logo} from '../Style'
import { app } from 'components/Router/routes'
import { FlexCenter, Img, LgSpace, Link } from 'styles/GlobalStyle'
import { LoginCart } from 'assets'
import { loginHooks, validate } from '.'
import { Divider, SvgIcon } from '@mui/material'
import { thirdly2 } from 'styles/theme'
import { ContactUsFab } from 'components/ContactUs'
import { LogoGo } from 'assets'

const Login = () => {
    const { login } = LoginCrud()
    const { forgotPassword, forgotUsername, register } = constants

    const submitBtns = loginHooks.useSubmitBtns()
    const fields = loginHooks.useFields()
    const goRegisterBtn = loginHooks.useGoRegisterBtn()
    const { formname, title } = loginHooks.constants

    return (
        <>
            <FlexCenter>
                <Logo>
                    <Img src={LogoGo} />
                </Logo>
                <AuthWithCartoon
                    cartoon={LoginCart}
                    cartoonSize={{
                        height: 500,
                        width: 500
                    }}>
                    <Form
                        form={formname}
                        title={title}
                        submit={login}
                        validations={validate}
                        fields={fields}
                        submitBtns={submitBtns}
                        rowGap={10}
                    />
                    <ForgotUserNameOrPassword>
                        <Link to={app.recoverUsername}>{forgotUsername}</Link>
                        <Link to={app.reset}>{forgotPassword}</Link>
                    </ForgotUserNameOrPassword>
                    <Divider sx={{ borderColor: thirdly2 }} />
                    <LgSpace />
                    <LgSpace />
                    <FlexCenter>
                        <Button {...goRegisterBtn} />
                    </FlexCenter>
                </AuthWithCartoon>
            </FlexCenter>
            <ContactUsFab />
        </>
    )
}

export default Login