import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'store'

const useOnPause = () => {
    const dispatch = useDispatch()
    const { wavesurfer, isPlaying } = useSelector(state => state.wavesurfer)

    return () => {
        if (isPlaying) {
            dispatch(actions.setIsPlaying(false))
            if (wavesurfer)
                wavesurfer.pause()
        }
    }
}

export default useOnPause 