import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { RenderField, RenderFields } from './index'

const RenderArray = (props) => {
    const { fields, buildFields } = props
    return (

        fields.map((field, arrayIndex) =>
            <RenderFields
                fields={buildFields}
                name={`${field}`}
                key={field}
                arrayIndex={arrayIndex}
                index={arrayIndex}
            />
        )

    )
}

const RenderMultipeFields = (props) => {
    const { buildFields, name } = props

    return (
        <FieldArray name={name} props={{ buildFields: buildFields, name: name }} component={RenderArray} />
    )

}

// Render fields
const Fields = (props) => {
    const { fields, name, arrayIndex } = props

    return (
        fields.map((field) =>
            field.fields ?
                <RenderMultipeFields
                    // name={`${field.name}`}
                    buildFields={field.fields}
                    key={name ? `${name}.${field.name}` : field.name}
                    name={
                        name ? `${name}${field.name ? `.${field.name}` : ''}` : field.name
                    }
                />
                :
                <Field
                    {...field}
                    dynamicComponent={field.component}
                    key={name ? `${name}.${field.name}` : field.name}
                    type={field.type || 'text'}
                    component={RenderField}
                    // name={name ? `${name}${field.name ? `.${field.name}` : ""}` : field.name}
                    name={name ? `${name}.${field.name}` : field.name}
                    arrayIndex={arrayIndex + 1}
                    index={arrayIndex}
                />
        )
    )
}

export default Fields;