import React from 'react'
import { Button } from '.'

const TabButton = (props) => {
    const { selected, handleTab } = props

    return (
        <Button
            action={handleTab}
            height={"35px"}
            color={selected ? 'white' : 'lightDark'}
            {...props}
        />
    )
}

export default TabButton