import React from 'react'
import { ConfirmAlert } from 'components/Alerts'
import { markingWordsHooks } from '.'

const InitializeConfirmation = () => {
    const initializeConfirmation = markingWordsHooks.useInitializeConfirmation()

    return (
        <ConfirmAlert {...initializeConfirmation} />
    )
}

export default InitializeConfirmation