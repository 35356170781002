import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import words from './words'
import general from './general'
import staticData from './static'
import assessment from './assessment'
import user from './user'
import wavesurfer from './wavesurfer'
import affiliation from './affiliation'
import testTaker from './testTaker'
import rating from './rating'
import header from './header'
import dataGrid from './dataGrid'
import markingWords from './markingWords'

// Combine with other reducers we may add in the future
const appReducers = combineReducers({
    words: words,
    general: general,
    dataGrid: dataGrid,
    rating: rating,
    static: staticData,
    assessment: assessment,
    user: user,
    form: reduxFormReducer,
    wavesurfer: wavesurfer,
    header: header,
    affiliation: affiliation,
    testTaker: testTaker,
    markingWords: markingWords
})

const rootReducer = (state, action) => {
    if (action.type === "RESET_ALL_REDUCERS") {
        state = undefined;
    }

    return appReducers(state, action);
};


export { rootReducer }
export default rootReducer

