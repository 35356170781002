import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login, Main, Register, ThankYou } from 'components'
import { app, login, register1, register2, register3, register4, thankYou } from "./routes";
import { RouterData } from "components/Router/hooks";
import { PrivateRoutesApp, PrivateRoutesAuth } from "components/Router";
import { Register1, Register2, Register3, Register4 } from "components/Auth/Register";

const CreateRouter = () => {
    const { appRoutes } = RouterData()

    return (
        <>
            <Routes>
                <Route exact path={register1} element={
                    <Register1 />
                } />
                <Route exact path={register2} element={
                    <Register2 />
                } />
                <Route exact path={register3} element={
                    <Register3 />
                } />
                {/* <Route exact path={register4} element={
                    <Register4 />
                } /> */}
                <Route exact path={"/"} element={
                    <Navigate to={app.register1} />
                } />
            </Routes>
        </>
    );
}

export default CreateRouter
