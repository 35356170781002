

const useFields = () => {

    const fields = [
        {
            name: "email",
            component: "input",
            placeholder: "מייל"
        }
    ]

    return fields
}


export default useFields