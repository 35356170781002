import { produce } from 'immer'
import createReducer from "./reducerUtils"
import appReducers from './appReducers'
import { changeItem } from 'utils/array'

const initialState = {
    staticWords: null,
    staticWord: {},
    wordIndex: 0,
    words: null,
    wordDefs: null,
    word: {
        errors: []
    },
    phonemes: null,
    changeWord: false,
    pointTime: {}
}

const config = {
    setPointTime(state, action) {
        state.pointTime = action.payload
    },
    setStaticWords(state, action) {
        state.staticWords = action.payload
    },
    setWordDefs(state, action) {
        state.wordDefs = action.payload
    },
    setStaticWord(state, action) {
        state.staticWord = action.payload
    },
    setWordIndex(state, action) {
        state.wordIndex = action.payload
    },
    setWords(state, action) {
        state.words = action.payload
    },
    updateSingleWord(state, action) {
        const { index, word } = action.payload
        let nWords = changeItem(state.words, index, word)
        state.words = nWords
    },
    setWord(state, action) {
        state.word = action.payload
    },
    setPhonemes(state, action) {
        state.phonemes = action.payload
    },
    setChangeWord(state, action) {
        state.changeWord = action.payload
    },
    reset(state, action) {
        const { phonemes, staticWords, staticWord, word, words, wordIndex } = initialState
        state.staticWords = staticWords
        state.staticWord = staticWord;
        state.word = word
        state.words = words
        state.wordIndex = wordIndex
        state.phonemes = phonemes;
    }
}

export default produce(
    (state, action) => createReducer(state, action, config),
    initialState
)

