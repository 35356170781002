import React from 'react'
import { Can } from 'components/Permissions'

const HiddenHOC = (props) => {
    const { HocComponent, role, feature } = props

    return (
        <Can I={role} a={feature}>
            <HocComponent {...props} />
        </Can>
    )
}

export default HiddenHOC
