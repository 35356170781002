
const useHeaderActions = () => {

    const headerActions = [

    ]

    return headerActions
}

export default useHeaderActions