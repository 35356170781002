import React, { useState } from 'react'
import { Error, IconButton } from 'components/Form'
import { Label } from 'components/Form'
import { WrapField, BootstrapInput2, WrapFieldContent } from './Styled'
import './style.css'
import { InputAdornment, SvgIcon } from '@mui/material'
import { ReactComponent as Visibility } from 'assets/icons/Visibility.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/VisibilityOff.svg';

//Render input field
const Input = (props) => {
    const {autocomplete, endAdornment, required, label, name, input, type, placeholder, className, gridArea, arrayIndex, mandatory } = props
        ,
        { onChange, value = null } = input

    const [showPassword, setShowPassword] = useState(type === 'password')

    return (
        <WrapField className={className} style={arrayIndex ? { gridArea: `${gridArea}_${arrayIndex}` } : {}}>
            <WrapFieldContent>
                {label && <Label label={label} mandatory={mandatory} />}
                <BootstrapInput2
                    // hiddenlabel="true"
                    placeholder={`${required ? "*" : ""}${placeholder}`}
                    name={name}
                    value={value}
                    autocomplete={autocomplete}
                    type={showPassword ? "password" : "text"}
                    onChange={(e) => onChange(e.target.value)}
                    // id="outlined-basic"
                    label={label}
                    variant="outlined"
                    inputProps={{
                        'aria-label': 'description',
                        autocomplete: autocomplete
                    }}
                    endAdornment={
                        endAdornment ?
                            <InputAdornment position="end">
                                <endAdornment.component {...props} />
                            </InputAdornment> :
                            type != 'password' ||
                            <InputAdornment position="end">
                                <IconButton
                                    hideFill
                                    aria-label="toggle password visibility"
                                    action={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    icon={
                                        <SvgIcon fontSize="small" component={showPassword ? VisibilityOff : Visibility} color="black" inheritViewBox />
                                    }
                                />
                            </InputAdornment>
                    }
                />
            </WrapFieldContent>
            <Error {...props} />
        </WrapField>
    )
}

export default Input