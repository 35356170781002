import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Assessment, assessmentDefsHooks } from 'components/AssessmentDefs'
import { WrapAssessmentDefsItems, WrapHeaderActions, } from './Style'
import { fieldsTyps, Input } from 'components/Form'
import { searchByKeys } from 'utils/array'
import { WrapAll, WrapHeader } from 'styles/GlobalStyle'
import { isEmpty } from 'lodash'
import { assessmentDefRQ } from 'store/hooks'

const AssessmentDefs = (props) => {
    const { assessments } = props
    const { assessmentDefs } = useSelector(state => state.assessment)
    const [filterAssessments, setFilterAssessments] = useState(assessments || assessmentDefs || [])
    const [searchVal, setSearchVal] = useState('')

    const { } = assessmentDefRQ.useGetAllAssessmentDefs()
    const headerActions = assessmentDefsHooks.useHeaderActions()
    const { search, assessmentKeysForSearch } = assessmentDefsHooks.constants

    const currentAssessments = assessments || assessmentDefs || []

    useEffect(() => {
        if (!isEmpty(currentAssessments))
            setFilterAssessments(currentAssessments)
    }, [currentAssessments])

    const searchAssessmentDefs = (val) => {
        setSearchVal(val)
        const nAssessmentDefs = searchByKeys(assessments || assessmentDefs, val, assessmentKeysForSearch)
        setFilterAssessments(nAssessmentDefs)
    }

    return (
        <WrapAll>
            <WrapHeader>
                <WrapHeaderActions>
                    <Input {...search}
                        input={{
                            value: searchVal,
                            onChange: searchAssessmentDefs
                        }} />
                    {headerActions.map((act, index) => {
                        const Component = fieldsTyps[act.component]
                        return (
                            <Component key={index} {...act} />)
                    })}
                </WrapHeaderActions>
            </WrapHeader>

            <WrapAssessmentDefsItems>
                {filterAssessments.map((assessment) =>
                    <Assessment key={assessment.id} {...props} {...assessment} student={true} />
                )}
            </WrapAssessmentDefsItems>
        </WrapAll>
    )
}

export default AssessmentDefs