import { Checkbox } from "@mui/material";
import styled, { css } from "styled-components";
import { Center, Scroll, Shadow } from "styles/GlobalCss";
import { gTheme, gray, ligthGray, primary, secondary, white } from "styles/theme";

const WrapAssessmentSection = styled.div`


`

const WrapSelectAssessment = styled.div`
width: 30px;
position: relative;
left: 13px;
top: 17px;
`

const WrapAssessment = styled.div`
display: flex;
flex-direction: column;
height:100%;

// position: relative;
// bottom: 15px;
`

const WrapHeader = styled.div`

`

const SelectAssessment = styled.div`
left: 10px !important;
position: relative !important;
top: 10px !important;
width:20px;
height:20px;
border:2px solid ${gray};
border-radius: 30px;
background: white;
${Center};
${Shadow};
cursor:pointer;

`

const Row = css`
display:flex;
height:40px;
width: 100%;
justify-content: space-between;
align-items: center;
box-sizing:border-box;
`

const ItemWithBorder = css`
border: 1px solid ${ligthGray};
border-radius: 4px;
padding: 8px;
font-size:13px;
`

const WrapAssessmentDefsItems = styled.div`
overflow:auto;
display:grid;
height:100%;
flex-grow:1;
grid-template-columns: repeat(auto-fill, 300px);
grid-column-gap: 20px;
grid-row-gap: 20px;
box-sizing: border-box;
justify-content: center;
${Scroll};
`

const WrapHeaderActions = styled.div`
.assessment-def-search{
width:300px;

}
`

const WrapTitle = styled.div`
${Row};
`

const AssessmentName = styled.div`
font-weight: bold;
font-size: 20px;
`

const WrapContent = styled.div`
flex-grow:1;
align-items: center;
display:flex;
`

const WrapFooter = styled.div`
${Row};

`

const Method = styled.div`
${ItemWithBorder};

background:${props => gTheme[props.$bg].main};
color:${props => gTheme[props.$bg].contrastText};
`

const WordsLength = styled.div`
${ItemWithBorder};

`


const Type = styled.div`
${ItemWithBorder};

`

const Semester = styled.div`
${ItemWithBorder};
`

export { WrapAssessmentDefsItems, WrapHeaderActions, WrapSelectAssessment, WrapContent, WrapAssessmentSection, WrapHeader, ItemWithBorder, Type, Semester, WrapAssessment, SelectAssessment, WrapTitle, AssessmentName, WrapFooter, Method, WordsLength }