import React from 'react'
import { AssessmentsAndTestTakersData } from './hooks'
import { WrapButtonGroup } from 'components/Form/Styled'
import { WrapBasicButton, WrapFullButton } from './Style'
import { statuses } from 'keys'

const StatusCell = (props) => {
    const { value } = props
    const { mappingStatus } = AssessmentsAndTestTakersData()
    const { wordsComplete } = statuses

    const inProgress = value === wordsComplete
    const completed = false

    const getDynamicColor = (status) => {
        if (status)
            return 'contained'
        return 'outlined'
    }

    return (
        <WrapButtonGroup
            aria-label="Vertical button group"
            variant="text"
            size="small"
        >
            <WrapBasicButton color="lightPrimary" variant={getDynamicColor(completed)}>
                {mappingStatus.COMPLETE}
            </WrapBasicButton>
            <WrapFullButton color="lightBlue2" variant={getDynamicColor(inProgress)}>
                {mappingStatus.IN_PROGRESS}
            </WrapFullButton>
        </WrapButtonGroup>
    )

}

export default StatusCell