

import en from 'config/strings'

const validate = (values, testTaker = {}) => {
    const errors = {}

    if (values) {
        if (!values.group) {
            errors.group = en.require()
        }

        if (!values.affiliation) {
            errors.affiliation = en.require()
        }

        if (!values.grade) {
            errors.grade = en.require()
        }

        if (!values.name) {
            errors.name = en.require()
        }
    }

    return errors;
}

export default validate;

