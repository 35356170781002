import { minorError, omission, repetition, selfCorrection } from "keys/types"
import { keyBy } from "lodash"

class Word {
    constructor(word, index) {
        const { errors = [], transcription, assessment, status, word_def_text, id, word_def_fe_details, word_def_id, word_def_index } = word
        const {
            time_point_word,
            time_point,
            words_count,
            errors_count,
            correct_words_count,
            words_in_time_point,
            correct_words_in_time_point,
            words,
            omission_count = 0
        } = assessment

        if (id)
            this.id = id

        const errorKeys = keyBy(errors, "status")
        if (errorKeys[repetition])
            this.customStatus = true
        if (errorKeys[selfCorrection])
            this.customStatus = true
        if (errorKeys[omission])
            this.removed = true
        if (errorKeys[minorError])
            this.minorError = true

        if (time_point_word === id) {
            this.is_time_point_word = true
            this.time_point = time_point
        }

        this.status = status
        this.transcription = transcription

        this.word_def_index = word_def_index
        this.word_def_text = word_def_text
        this.word_def_id = word_def_id
        this.word_def_fe_details = word_def_fe_details
        this.sourceErrors = errors
    }
}

export default Word
