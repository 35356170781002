import React, { useState } from 'react'
import { SideBar } from '.'

const CutContext = () => {
    const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(null);
    const [selectedSubSidebarIndex, setSelectedSubSidebarIndex] = useState(null);

    const commonState = {
        selectedSidebarIndex, setSelectedSidebarIndex,
        selectedSubSidebarIndex, setSelectedSubSidebarIndex
    }

    return (
        <SideBarContext.Provider value={commonState}>
            <SideBar />
        </SideBarContext.Provider>
    )
}

export const SideBarContext = React.createContext()
export default CutContext