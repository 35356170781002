import React from "react";
import { WrapPage, WrapPageContent, End, PageHeader, PageText } from './Style'
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { grades } from "keys";

const Page = React.forwardRef((props, ref) => {
    const { children: words, title, firstPage, number } = props
    const { testTaker } = useSelector(state => state.testTaker)
    const { grade } = testTaker

    const gradeStyle = grades[grade] || {}
    const { fontsize } = gradeStyle

    return (
        <WrapPage className="page" ref={ref}>
            <WrapPageContent className="page-content">
                <PageHeader className="page-header" left={number % 2}>
                    <div className="number" >
                        {number}
                    </div>
                </PageHeader>
                {isEmpty(words) ?
                    <PageText $bold className="page-text" fontsize={fontsize}>
                        {"-סוֺף-"}
                    </PageText> :
                    <PageText fontsize={fontsize} className="page-text">
                        {words.map((word, index) => {
                            const { id, word_def_text, status, word_def_fe_details = {} } = word
                            const { is_title, break_line, new_page } = word_def_fe_details || {}
                            return (
                                <React.Fragment key={index}>
                                    {word_def_text}{" "}
                                    {break_line && <br />}
                                </React.Fragment>
                            )
                        })}
                    </PageText>}
            </WrapPageContent>
        </WrapPage>
    );
});

export default Page