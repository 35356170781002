import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Header } from './Style';
import { WrapDataGrid, WrapHeader } from 'components/DataGrid/Style';
import { DataGrid } from 'components';
import { Button, fieldsTyps } from 'components/Form';
import { AssessmentFiltersHeader } from '.';

const Assessments = (props) => {
    const { data } = props
    const { assessments } = useSelector(state => state.assessment)

    return (
        <WrapDataGrid>
            <AssessmentFiltersHeader {...props} />
            <DataGrid
                data={data || assessments}
                {...props}
            />
        </WrapDataGrid>
    );
}

export default Assessments
