import { produce } from 'immer'
import createReducer from "./reducerUtils"

const initialState = {
    affiliations: null,
    groups: null,
    methods: null
}

const affiliation = {
    setAffiliations(state, action) {
        state.affiliations = action.payload
    },
    setGroups(state, action) {
        state.groups = action.payload
    },
    setMethods(state, action) {
        state.methods = action.payload
    }
}

export default produce(
    (state, action) => createReducer(state, action, affiliation),
    initialState
)

