import { Pause, PlayArrow, Replay } from "@mui/icons-material"
import { RecordCrud } from "components/Record/hooks"

import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";

const RecordData = () => {
    const { changeDuration, onPlayPause, isPlaying, handleSeeking } = RecordCrud()

    const rateBtns = [
        // {
        //     id: 0,
        //     label: isPlaying ? "נגן" : "הפסק",
        //     color: "primary",
        //     icon: isPlaying ? <PlayArrow /> : <Pause />,
        //     action: onPlayPause,
        //     rightIcon: true
        // },
        {
            id: 5,
            label: "שתי שניות",
            icon: <Replay />,
            color: "rating1",
            rightIcon: true,
            action: () => handleSeeking()
        },
        {
            id: 1,
            label: "0.5x",
            color: "dark",
            action: () => changeDuration(0.5),
            markSelected: true
        },
        {
            id: 2,
            label: "0.75x",
            color: "dark",
            action: () => changeDuration(0.75),
            markSelected: true

        },
        {
            id: 3,
            label: "1.0x",
            color: "dark",
            action: () => changeDuration(1),
            markSelected: true

        },
        {
            id: 4,
            label: "1.5x",
            color: "dark",
            action: () => changeDuration(1.5),
            markSelected: true

        },

    ]

    const plugins = [
        {
            key: "top-timeline",
            plugin: TimelinePlugin,
            options: {
                height: 20,
                insertPosition: "beforebegin",
                style: {
                    color: "#2D5B88",
                },
                timeInterval: 0.1,
                primaryLabelInterval: 1,
            },

        },
        {
            key: "bottom-timeline",
            plugin: TimelinePlugin,
            options: {
                height: 10,
                style: {
                    color: "#6A3274",
                },
                primaryLabelInterval: 1,
                timeInterval: 0.1,
            },

        },
    ]

    return {
        rateBtns,
        plugins

    }
}

export default RecordData