import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ChangeSetting } from 'components/Record'
import { WrapAudio, WrapSetting, Content } from './Style'
import { CutRecordData, CutRecordCrud } from './hooks'
import { CutRecordContext } from './CutRecordContext'
import { Record, SelectWord } from 'components/Admin/CutRecord'
import { WrapAll } from 'styles/GlobalStyle'
import { getDecodedFilenameFromUrl } from 'utils/formatter'
import { PlaceHolder } from 'components/DataGrid'
import { Button } from 'components/Form'
import audioCuttingHooks from './hooks'
import Page from 'components/Page'

const CutRecord = () => {
    const { headerActions, startCutting } = CutRecordData()
    const { init, cleanData } = CutRecordCrud()

    const { assessment } = useSelector(state => state.assessment)
    const { isCloseSttoper } = useSelector(state => state.markingWords)
    const { regions } = useContext(CutRecordContext)

    const startAudioCutting = audioCuttingHooks.useStartAudioCutting()
    const saveActions = audioCuttingHooks.useSaveActions()

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        return (() => {
            cleanData()
        })
    }, [])

    const disabledContent = isCloseSttoper

    return (
        <Page>
            {/* {assessment.audio_file ? */}
            {/* <PlaceHolder /> */}
            <WrapAll>
                <WrapSetting $disabled={disabledContent}>
                    <div className='file-name-title'>
                        <span className='file-name'>
                            {` ${getDecodedFilenameFromUrl(assessment.audio_file_url)}`}
                        </span>
                    </div>
                    <Button
                        {...startAudioCutting}
                    />
                    {regions &&
                        <div className='region-btns'>
                            {saveActions.map((headerAction) =>
                                <Button {...headerAction} />
                            )}
                        </div>
                    }
                </WrapSetting>
                <Content $disabled={disabledContent}>
                    <WrapAudio >
                        <ChangeSetting />
                        <Record id="waveform" height={130} showRegions={true} />
                    </WrapAudio>
                    <SelectWord />
                </Content>
            </WrapAll>
            {/* : */}
            {/* <PlaceHolder /> */}
            {/* } */}
        </Page>
    )
}

export default CutRecord