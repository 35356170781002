import { QueryClient, useMutation, useQuery } from "react-query"
import { queryClient as qc } from 'keys'
import { deleteWordFetch, getNestedWordsFetch, getWordsFetch, saveWordFetch } from "store/middlewares/words.crud"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { MarkingWordsAssessmentVM, NestedWordVM, WordVM } from "models/VM"
import actions from "store/actions"

const useGetNestedWords = (props) => {
    const { assessment } = useSelector(state => state.assessment)
    const params = useParams()
    let assessmentID = assessment?.id
    if (!assessmentID)
        assessmentID = params.assessmentID

    return useQuery(
        {
            queryKey: qc.nestedWords,
            queryFn: ({ signal }) => getNestedWordsFetch({ id: assessmentID, signal, }),
            refetchOnWindowFocus: false,
            onSuccess: ({ data }) => {
                const words = []
                data.words.forEach(word => words.push(new NestedWordVM(word)))
                props?.onSuccess?.(words)
            }
        })
}

const useSaveWord = () => {
    const { assessment } = useSelector(state => state.assessment)
    const { id: assessmentID } = assessment
    const { refetch: refetchNestedWordsRQ } = useGetNestedWords({ enabled: false })

    return useMutation({
        mutationFn: (word) => {
            return saveWordFetch({ word, assessmentID })
        },
        onSuccess: () => {
            refetchNestedWordsRQ()
        }
    })
}

const useDeleteWord = () => {
    const { assessment } = useSelector(state => state.assessment)
    const { id: assessmentID } = assessment
    const { refetch: refetchNestedWordsRQ } = useGetNestedWords({ enabled: false })

    return useMutation({
        mutationFn: (word) => {
            return deleteWordFetch({ wordID: word.id, assessmentID })
        },
        onSuccess: () => {
            return refetchNestedWordsRQ()
        }
    })
}

const useSaveAssessmentAndWordsStore = () => {
    const dispatch = useDispatch()

    return (wordsRes) => {
        let { data } = wordsRes || {}
        const mWords = []
        data.forEach((word, index) => mWords.push(new WordVM({ assessment: data, ...word }, index)))

        dispatch(actions.setWords(mWords))
    }
}

const useGetWords = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const params = useParams()
    let assessmentID = assessment?.id
    if (!assessmentID)
        assessmentID = params.assessmentID

    return useQuery({
        queryFn: async ({ signal }) => {
            let assessmentData = await getWordsFetch({ signal, id: assessmentID })
            let { data } = assessmentData || {}
            const mWords = []
            data.words.forEach((word, index) => mWords.push(new WordVM({ assessment: data, ...word }, index)))

            dispatch(actions.setWords(mWords))
            let nAssessment = new MarkingWordsAssessmentVM(data)
            dispatch(actions.updateAssessment(nAssessment))
        },
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        queryKey: qc.assessmentAndWords
    })
}

export default { useSaveAssessmentAndWordsStore, useGetWords, useGetNestedWords, useSaveWord, useDeleteWord }
