import React from 'react'
import { Vowel } from './Style';
import { insertion, na, omission } from 'keys/types';

const VowelCategory = (props) => {
    const { type, items = {}, index, phoneme, updatePhoneme } = props
    const { phoneme_def_vowel_code, vowel_code, letter, letter_status, vowel_status } = phoneme || {}

    const handleChange = (keyVowel) => {
        const nPhoneme = { ...phoneme }
        nPhoneme.vowel_code = keyVowel
        updatePhoneme(index, nPhoneme)
    }

    const disabled =
        letter_status === omission ||
        vowel_status === na ||
        letter_status === insertion && !letter

    return (
        Object.keys(items).map((key) => {
            const keyVowel = Number(key)
            return (
                <Vowel
                    key={keyVowel}
                    correct={keyVowel === vowel_code || (keyVowel === phoneme_def_vowel_code && !vowel_code)}
                    incorrect={keyVowel != phoneme_def_vowel_code && keyVowel === vowel_code}
                    source={keyVowel === phoneme_def_vowel_code}
                    insertion={keyVowel === vowel_code && letter_status === insertion}
                    disabled={disabled}
                    onClick={() => handleChange(keyVowel)}
                >
                    {items[keyVowel].name}
                </Vowel>)
        })
    )
}

export default VowelCategory