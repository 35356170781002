import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, map } from 'lodash'
import { ProgressData } from './hooks'
import { Button, Label } from 'components/Form'
import { WrapProgress, WrapIndicationIcon, WrapProgressItem, BorderLinearProgress, WrapSubmit } from './Style'
import { changeItem } from 'utils/array'

const Progress = () => {
    const { progressList, submitBtn, getIcon } = ProgressData()
    const { progressIndication } = useSelector(state => state.assessment)
    const [progress, setProgress] = useState(new Array(progressList.length).fill(0))

    let progressInterval
    let completeInterval

    const progressRef = useRef(progress)

    const updateProgress = (index) => {
        progressInterval = setInterval(() => {
            if (progressRef.current[index].completed)
                clearInterval(progressInterval)
            else if (progressRef.current[index] < 90) {
                let nProegrss = changeItem(progress, index, progressRef.current[index] + 0.01)
                setProgress(nProegrss)
                progressRef.current = nProegrss
            }
            else clearInterval(progressInterval)
        }, 100);
    }

    const completeProgress = (index) => {
        completeInterval = setInterval(() => {
            if (progressRef.current[index] < 100) {
                let nProegrss = changeItem(progress, index, progressRef.current[index] + 1)
                setProgress(nProegrss)
                progressRef.current[index] = nProegrss[index]
            }
            else clearInterval(completeInterval)
        }, 10);
    }

    const clearIntervals = () => {
        for (var i = 1; i < 99999; i++)
            window.clearInterval(i);

        let nProegrss = new Array(progressList.length).fill(0)
        setProgress(nProegrss)
        progressRef.current = nProegrss
    }

    const checkIndication = () => {
        if (!isEmpty(progressIndication))
            map(progressIndication).forEach((indication, index) => {
                if (indication.start && !indication.completed)
                    updateProgress(0)
                else if (indication.completed) {
                    completeProgress(index)
                    completeProgress(3)
                }
            });
        else {
            clearIntervals()
        }
    }

    useEffect(() => {
        checkIndication()
    }, [progressIndication])


    return (
        <WrapProgress>
            {progressList.map((progressItem, index) => {
                const { id, label, disabled } = progressItem
                let currentProgress = progressRef.current[index]

                return (
                    <WrapProgressItem>
                        <Label label={label} />
                        <BorderLinearProgress
                            disabled={disabled}
                            key={id}
                            variant="determinate"
                            value={currentProgress}
                        />
                        <WrapIndicationIcon>
                            {getIcon(progressIndication[progressItem.progress])}
                        </WrapIndicationIcon>
                    </WrapProgressItem>
                )
            })}
            <WrapSubmit>
                <Button {...submitBtn} />
            </WrapSubmit>
        </WrapProgress>
    )
}

export default Progress