import { app } from "components/Router/routes"
import { isEmpty } from "lodash"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { assessmentHooks } from ".."


const useNextAssessmentBtn = () => {
    const navigate = useNavigate()

    const { assessmentIds, assessment } = useSelector(state => state.assessment)

    const goNextAssessment = () => {
        const { id } = assessment
        const index = assessmentIds.indexOf(id);
        if (index + 1 < assessmentIds.length) {
            const nextAssessmentId = assessmentIds[index + 1]
            navigate(`${app.assessments}/${nextAssessmentId}`, {
                state: {
                    id: nextAssessmentId
                }
            })
        }
    }

    const nextAssessmentBtn = {
        label: "מעבר למבדק הבא",
        color: "secondary",
        action: goNextAssessment,
        disabled: isEmpty(assessmentIds),

        feature: "next-assessment-btn",
        role: "read",
        permissionHOC: "hidden",
    }

    return nextAssessmentBtn
}

export default useNextAssessmentBtn