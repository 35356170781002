import React, { useRef } from 'react'
import { WrapMarkWordsContent, WrapMarkWords, WrapScreenMarkWords, WrapCustomSection } from './Style'
import { WordsData } from 'components/Words/hooks'
import { useLocation } from 'react-router-dom'
import { components } from 'keys'
import { Header, ErrorTypes, wordHooks } from 'components/Words'
import { Stepper } from 'components/School/MakingRecordAssessments'
import { isEmpty } from 'lodash'

const Words = (props) => {
    const { showErrorTypes, words, type, settings, disabled, student } = props
    const { state = {} } = useLocation();
    const contentToPrint = useRef(null);

    const assessmentCategory = wordHooks.useAssessmentCategory()

    const { header = props.header, footer = props.footer } = state || {}
    const studentView = student || state?.student

    let category = assessmentCategory[type || "DEFAULT"]
    const Component = studentView ? category.studentC : category.component

    return (
        <WrapScreenMarkWords>
            <Header category={category}{...props} contentToPrint={contentToPrint} />
            {components?.[header]}
            {showErrorTypes && <ErrorTypes />}

            <WrapMarkWords ref={contentToPrint} >
                <WrapMarkWordsContent $disabled={disabled}>
                    <WrapCustomSection $noShadow $bg={false} align="center" $flex $column>
                        <Stepper />
                        <Component words={words} settings={settings} />
                    </WrapCustomSection>
                </WrapMarkWordsContent>
            </WrapMarkWords>
            {components?.[footer]}
        </WrapScreenMarkWords>
    )
}

export default Words