import { silence, silenceObj, stuffer, stufferObj, word, wordObj } from "mock/regionType"


class Region {

    constructor(region, regionFunc, words) {
        this.end = region.end_time
        this.start = region.start_time
        this.type = region.type
        this.systemId = region.id
        this.id = region.id
        this.word_def_id = region.word_def_id
        this.word_id = region.word_id
        this.color = getColor(this.type)
        this.content = createContent(region, regionFunc, words)
    }
}

const createContent = ({ id, type, word_id }, regionFunc, words) => {
    let p = document.createElement("p")
    p.style.display = "flex"
    p.style.height = "100%"
    p.style.flexDirection = "column"
    p.style.justifyContent = "space-between"
    p.style.alignItems = "center"

    let ActiveBtn = document.createElement("button");
    ActiveBtn.setAttribute('class', 'errorrr');
    ActiveBtn.style.background = "transparent"
    ActiveBtn.style.border = "none"
    ActiveBtn.style.height = '30px'
    ActiveBtn.style.marginTop = '13px'
    ActiveBtn.style.borderRadius = "10px"
    ActiveBtn.style.border = "1px solid rgb(173 170 170)"
    ActiveBtn.style.padding = "3px 12px"

    let sp1 = document.createElement("span");
    sp1.innerHTML = "הסרה"
    sp1.style.color = "#555555";
    sp1.style.fontSize = "12px"

    ActiveBtn.appendChild(sp1);
    ActiveBtn.onclick = function (e) {
        e.stopPropagation()
        regionFunc.remove(id)
    }

    p.appendChild(ActiveBtn)

    if (type === word) {
        let footer
        footer = createContentWord()
        p?.appendChild(footer)
        for (let i = 0; i < words.length; i++) {
            let word = words[i]
            if (word.id && (word.id === word_id)) {
                footer.innerHTML = word.transcription || word.word_def_text
                p.children[1].text = footer
                break
            }
        }
    }
    return p
}

const createContentWord = (word = {}) => {
    let text = word.transcription || word.word_def_text

    let footer = document.createElement("span");
    footer.setAttribute("id", "word")
    footer.innerHTML = text || "מילה"
    footer.style.fontSize = "12px"
    footer.style.color = "white";
    footer.style.width = "100%";
    footer.style.background = "#3EBCE2";
    footer.style.textAlign = "center";
    footer.style.paddingTop = "5px";
    footer.style.paddingBottom = "5px";

    return footer
}

const getColor = (type) => {
    switch (type) {
        case silence:
            return silenceObj.color
        case stuffer:
            return stufferObj.color
        case word:
            return wordObj.color
    }
}

export { createContent, createContentWord }
export default Region