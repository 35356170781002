

import en from 'config/strings/en';
import { validatePassword } from 'utils/validation';

//validation login form
const validate = (values) => {
    const errors = {}

    if (values) {
        errors.password = validatePassword(values.password)

        if (!values.confirmPassword)
            errors.confirmPassword = en.require()
        else if (values.confirmPassword != values.password)
            errors.confirmPassword = "אישור הסיסמא לא תואם את הסיסמא"
    }

    return errors;
}

export default validate;

