import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import { Scroll } from "styles/GlobalCss";

const WrapUploadAudio = styled.div`

`

const WrapCreateAssessment = styled.div`
width: 100%;
height: 100%;
`

const WrapTab = styled.div`
flex-grow:1;
`

const WrapSelectType = styled.div`
height: 100%;
display: flex;
    flex-direction: column;
`

const WrapTabs = styled(Box)`
height: 50px !important;
`

const WrapSelectAssessmentDef = styled.div`
display:flex;
row-gap:15px;
flex-direction: column;

`


const WrapDefTitle = styled.div`
display: grid;
grid-template-columns: 60% 40%;
align-items: center;
`
const DefText = styled.div`

`

const csswrap = css`

.n-actions{
    display: grid;
    grid-template-columns: 50% 50%;
}

.n-file{
    height:100px;
}

.n-assesment_def{
    flex-grow:1;
}
`

const WrapDefContent = styled.div`
height:100%;
overflow: auto;
word-spacing: 2px;
line-height: 25px;
${Scroll};
`

const WrapRecordAssessment = styled.div`
height: 100%;
`

export { WrapRecordAssessment, WrapTab, WrapSelectType, WrapTabs, csswrap, WrapSelectAssessmentDef, WrapDefContent, WrapDefTitle, DefText, WrapUploadAudio, WrapCreateAssessment }