import { yellow, error, rating4 } from "styles/theme"
import constants from "./constants"

const useErrorTypeList = () => {

    const errorList = [
        {
            label: constants.omission,
            borderColor: "transparent",
            borderStyle: "dashed"
        },
        {
            label: constants.selfCorrection,
            borderColor: yellow,
            borderStyle: "dashed"
        },
        {
            label: constants.minorErrorWithSelfCorrection,
            borderColor: rating4,
            borderStyle: "dashed"
        },
        {
            label: constants.minorError,
            borderColor: rating4
        },
        {
            label: constants.errorWithSelfCorrection,
            borderColor: error,
            borderStyle: "dashed"
        },
        {
            label: constants.error,
            borderColor: error
        }
    ]

    return errorList
}

export default useErrorTypeList