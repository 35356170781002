

const constants = {
   backToLogin: "חזרה למסך הכניסה",
   formName: "resetPassword",
   title: "איפוס סיסמה",
   subTitle: "כדי לאפס את הסיסמה צריך למלא את המייל איתו נרשמתם",
   resetPasswordSentTitle: "שלחנו קישור לאיפוס הסיסמא למייל",
   resetPasswordSentSubTitle: "קבלת המייל יכולה לקחת כמה דקות. אם הוא מתעכב, זה מה שצריך לעשות:",
   instructions: [
      "לוודא שבדקת את תיבת המייל איתו נרשמת",
      "לבדוק בתיקיית הספאם, אולי הוא הגיע לשם",
      "אם בכל זאת לא מצאת את המייל, לחזור לעמוד איפוס סיסמא ולנסות שוב"
   ]
}

export default constants