import update_type from "mock/updateType"


class Region {

    constructor(region) {
        const { id, systemId, word_def_id } = region
        this.end_time = Number(region.end).toFixed(3)
        this.start_time = Number(region.start).toFixed(3)
        this.type = region.type
        this.update_type = region.update_type
        this.word_id = region.word_id
        this.word_def_id = region.word_def_id

        if (!systemId)
            this.update_type = update_type.update
        else this.id = systemId
    }
}

export default Region