

import en from 'config/strings'
import dayjs from 'dayjs'
import { validateEmail, validateIsraeliIdNumber, validatePassword } from 'utils/validation'

//validation login form
const validate = (values) => {
    const errors = {}

    if (values) {
        if (!values.first_name) {
            errors.first_name = en.require('first_name')
        }
        if (!values.last_name) {
            errors.last_name = en.require('last_name')
        }
        errors.email = validateEmail(values.email)

        if (!values.gender) {
            errors.gender = en.require('gender')
        }

        if (!values.username) {
            errors.username = en.require('username')
        }
        else if (!/^[\w.@+-]{1,150}$/.test(values.username))
            errors.username = en.invalidUsername

        if (!values.verification_email)
            errors.verification_email = en.require()

        errors.password = validatePassword(values.password)

        if (!values.numChildren)
            errors.numChildren = en.require()

        errors.children = []
        values.children?.forEach((child, index) => {
            const errorChild = childValidate(child)
            if (errorChild) {
                errors.children.push(errorChild)
            }
        });

    }

    return errors;
}

const childValidate = (child) => {
    const errors = {}

    if (child) {
        if (!child.first_name)
            errors.first_name = en.require('first_name')
        if (!child.last_name)
            errors.last_name = en.require('last_name')
        if (!child.dob)
            errors.dob = en.require('dob')
        else if (new Date().getFullYear() - new Date(dayjs(child.dob)).getFullYear() < 3)
            errors.dob = en.minAge

        if (!child.school)
            errors.school = en.require('school')
        if (!child.grade)
            errors.grade = en.require('grade')
        if (!child.t_class)
            errors.t_class = en.require('t_class')

        if (!child.tz)
            errors.tz = en.require('tz')
        else if (!validateIsraeliIdNumber(child.tz))
            errors.tz = en.invalidTZ

        if (!child.gender)
            errors.gender = en.require('gender')
    }

    return errors;
}

const shortValidate = (child) => {
    const errors = {}

    if (child) {
        if (!child.first_name)
            errors.first_name = en.require('first_name')
        if (!child.last_name)
            errors.last_name = en.require('last_name')
        if (!child.grade)
            errors.grade = en.require('grade')
        if (!child.gender)
            errors.gender = en.require('gender')
    }

    return errors;
}

export { childValidate, validate, shortValidate }

