import { correct, insertion, na, omission, substitution } from "keys/types";
import { isEqual, map, without } from "lodash";

class Phoneme {

    constructor(nPhoneme, index) {
        const { substitution_letter, phoneme_def_vowel_code, phoneme_def_letter_description, phoneme_def_letter_add_ons, phoneme_def_letter, phoneme_def_index, phoneme_def_id, error_in_speech = false, letter_description, update_type = 'UPDATE', letter_add_ons = [], phoneme_def, letter_add_on_status = 'CORRECT', vowel_status = correct, letter_status = 'CORRECT', id, letter, vowel_code = nPhoneme.phoneme_def_vowel_code, letter_add_on } = nPhoneme

        //TODO check if it needed
        // this.phoneme_def_id = phoneme_def_id
        // this.phoneme_def_index = phoneme_def_index
        // this.phoneme_def_letter = phoneme_def_letter
        // this.phoneme_def_letter_add_ons = phoneme_def_letter_add_ons
        // this.phoneme_def_letter_description = phoneme_def_letter_description
        // this.phoneme_def_vowel_code = phoneme_def_vowel_code

        this.id = id;
        this.index = index;
        this.update_type = update_type
        this.letter_add_ons = []
        this.error_in_speech = error_in_speech

        if (letter_status === omission) {
            this.vowel_status = omission;
            this.letter_status = omission;
            this.letter_add_on_status = omission;
        }
        else if (letter_status === insertion) {
            this.vowel_status = insertion;
            this.letter_status = insertion;
            this.letter_add_on_status = insertion;
        }
        else {
            if (vowel_status === na)
                this.vowel_status = na
            else if (vowel_code != phoneme_def_vowel_code)
                this.vowel_status = substitution;
            else this.vowel_status = vowel_status || correct

            if (letter_status === na)
                this.letter_status = na
            else this.letter_status = letter_status || correct

            if (letter_description != phoneme_def_letter_description)
                this.letter_status = substitution

            // if (!isEqual(letter_add_ons, phoneme_def_letter_add_ons))
            //     this.letter_add_on_status = substitution
            // else 
            this.letter_add_on_status = letter_add_on_status
        }

        this.letter_description = letter_description || null
        this.letter = substitution_letter || letter || phoneme_def_letter;
        this.vowel_code = vowel_code || phoneme_def_vowel_code;

        let map_letter_add_ons = map(letter_add_ons, "letter_add_on")
        let dynamic_letter_add_on = []

        phoneme_def_letter_add_ons?.forEach(({ letter_add_on }) => {
            if (map_letter_add_ons.includes(letter_add_on)) {
                dynamic_letter_add_on.push({ letter_add_on: letter_add_on, status: correct })
                map_letter_add_ons = without(map_letter_add_ons, letter_add_on)
            }
            else {
                dynamic_letter_add_on.push({ letter_add_on: letter_add_on, status: omission })
            }

        })

        map_letter_add_ons.forEach(letter_add_on => {
            dynamic_letter_add_on.push({ letter_add_on: letter_add_on, status: insertion })
        });

        console.log("dynamic_letter_add_on", dynamic_letter_add_on, phoneme_def_letter_add_ons, letter_add_ons)
        this.letter_add_ons = dynamic_letter_add_on;

        if (letter_status != insertion)
            this.phoneme_def = phoneme_def
    }
}

export default Phoneme