import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import { MicContext } from './AssessmentRecordFooter';

const AudioPlayer = (props) => {
    const { url: audioUrl, isPlaying } = props
    const { setIsPlaying } = useContext(MicContext)

    return (
        <ReactPlayer
            url={audioUrl}
            playing={isPlaying}
            controls={true}
            width='0px'
            height='0px'
            onPlay={() => console.log('onPlay')}
            onPause={() => setIsPlaying(false)}
            onEnded={() => setIsPlaying(false)}
            onError={(error) => setIsPlaying(false)}
        />
    );
};

export default AudioPlayer;