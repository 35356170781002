import React from 'react'
import { Alert } from 'components'
import { FlexBetween, Img, WrapImg, Title, SubTitle } from 'styles/GlobalStyle'
import { Go2 } from 'assets'
import { Button } from 'components/Form'
import { paymentHooks } from '.'
import { NoBalanceAlert } from './Style'

const RecordInstructions = () => {
    const { noBalanceTitle, noBalanceContent } = paymentHooks.constants

    const repeatPurchaseBtn = paymentHooks.useRepeatPurchaseBtn()
    const handleBalanceAlert = paymentHooks.useNoBalanceAlert()

    return (
        <Alert systemUI
            handleAlert={handleBalanceAlert}>
            <NoBalanceAlert>
                <FlexBetween>
                    <div>
                        <div className='alert_content'>
                            <Title>
                                {noBalanceTitle}
                            </Title>
                            <SubTitle>
                                {noBalanceContent}
                            </SubTitle>
                        </div>
                        <div className='alert_btn'>
                            <Button
                                {...repeatPurchaseBtn}
                            />
                        </div>
                    </div>
                    <WrapImg width={230} height={230}>
                        <Img src={Go2} />
                    </WrapImg>

                </FlexBetween>
            </NoBalanceAlert>
        </Alert>
    )
}

export default RecordInstructions