import React from 'react'
import transcriptionHooks from './hooks'
import { AdminAssessments } from 'components'
import { dataGridModel, statuses } from 'keys'
import { actions } from 'store'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'

const TranscriptionAssessments = () => {
    const dispatch = useDispatch()

    const fields = transcriptionHooks.useFields()
    const tabs = transcriptionHooks.useTabs()

    const updateVisibilityModel = ({ status, sub_status }) => {
        const transcriptionComleted = isEqual(sub_status, statuses.transcriptionComplete)
        const transcriptionInProgress = isEqual(sub_status, statuses.transcriptionInProgress)
        const isBrokenFile = status.includes(statuses.brokenFile)
        const transcriptionIsWating = isEqual(sub_status, statuses.audioCuttingComplete)

        //hide and show columns in the DataGrid table
        dispatch(actions.setVisibilityModelByKeys({
            key: [dataGridModel.admin_assessments],
            value: {
                transcription_update_by_username: transcriptionComleted || transcriptionInProgress || isBrokenFile,
                transcription_duration: transcriptionComleted,
                transcription_start_date: transcriptionComleted || transcriptionInProgress,
                transcription_end_date: transcriptionComleted,
                back_to_transcription: transcriptionComleted || isBrokenFile,
                update_date: transcriptionIsWating,
                enter_marking_words: transcriptionIsWating,
                view_marking_words: transcriptionComleted || isBrokenFile || transcriptionInProgress,
            }
        }))
    }

    return (
        <AdminAssessments
            fields={fields}
            tabs={tabs}
            updateVisibilityModel={updateVisibilityModel}
        />
    )

}

export default TranscriptionAssessments