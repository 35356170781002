import styled from "styled-components";
import { Skeleton } from '@mui/material';

const WrapPlaceHolder = styled.div`
width:100%;
`


const WrapSkeleton = styled(Skeleton)`
height:60px !important;
` 


export { WrapPlaceHolder, WrapSkeleton }