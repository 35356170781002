import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Header } from './Style'
import { AdminAssessmentsData, AdminAssessmentsCrud } from './hooks'
import { Button, TabButton } from 'components/Form'
import { useSelector } from 'react-redux'
import { dataGridModel } from 'keys'
import { AssessmentFiltersHeader } from 'components/School/AssessmentsAndTestTakers'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { WrapHeader } from 'styles/GlobalStyle'
import { DataGrid } from 'components'
import { adminAssessmentsRQ } from 'store/hooks'
import { isEmpty, isEqual, map } from 'lodash'
import { AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks'

import assessmentHooks from 'components/School/Assessments/hooks';
import testTakerHooks from 'components/School/TestTakers/hooks'

const AdminAssessments = (props) => {
    const { fields = [], updateVisibilityModel = () => { }, tabs = [] } = props
    const [currentTab, setCurrentTab] = useState(0)

    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters, statusFilters } = useSelector(state => state.assessment)

    const { headerBtns } = AdminAssessmentsData()
    const { updateFilters, cleanData, setRowSelectionModel, } = AdminAssessmentsCrud()

    const { playField } = AssessmentsAndTestTakersData()

    const idField = assessmentHooks.useIdField()
    const assessmentNameField = assessmentHooks.useAssessmentNameField()
    const headerItems = assessmentHooks.useHeaderItems()
    const nameField = testTakerHooks.useNameField()

    const { data: adminAssessments, refetch: refetchAdminAssessments, remove: removeBackToMarkingWords } = adminAssessmentsRQ.useGetAdminAssessments()

    let defaulTabIndex

    const refetchData = () => {
        removeBackToMarkingWords()
        refetchAdminAssessments(statusFilters)
    }

    useEffect(() => {
        refetchData()
    }, [headerFilters, statusFilters])

    useEffect(() => {
        if (isEmpty(statusFilters))
            defaulTabIndex = 0
        else
            defaulTabIndex = tabs.findIndex(({ status, sub_status }) => isEqual(status, statusFilters.status) && isEqual(sub_status, statusFilters.sub_status))

        if (defaulTabIndex < 0)
            defaulTabIndex = 0

        updateFilters(tabs[defaulTabIndex])
        updateVisibilityModel(tabs[defaulTabIndex])
        setCurrentTab(defaulTabIndex)
    }, [])

    useEffect(() => {

        return (() => {
            cleanData()
        })
    }, [])

    const handleTab = (index) => {
        setCurrentTab(index)
        updateFilters(tabs[index])
        updateVisibilityModel(tabs[index])
    }

    return (
        <WrapDataGrid>
            <AssessmentFiltersHeader
                refetchData={refetchData}
                headerItems={[...map(headerItems), ...headerBtns]} />
            <WrapHeader>
                <Header>
                    {tabs.map((filterBtn, index) =>
                        <TabButton
                            key={index}
                            handleTab={() => handleTab(index)}
                            selected={currentTab === index}
                            {...filterBtn}
                        />
                    )}
                </Header>
            </WrapHeader>
            <DataGrid
                onRowSelectionModelChange={setRowSelectionModel}
                visibilityModelKey={dataGridModel.admin_assessments}
                data={adminAssessments?.data}
                fields={[idField, assessmentNameField, nameField.field, ...fields, playField]}
            />
        </WrapDataGrid>
    )
}

export default AdminAssessments