import { Divider } from '@mui/material'
import React from 'react'
import { Space } from 'styles/GlobalStyle'


const DividerComponent = (props) => {
    const { className, arrayIndex, gridArea } = props

    return (
        <div className={className} style={arrayIndex ? { gridArea: `${gridArea}_${arrayIndex}` } : {}}>
            <Divider />
        </div>
    )

}

export default DividerComponent