import styled from "styled-components";

const WrapWarningCell = styled.div`

`

const TestTakerDetails = styled.div`
font-size:16px;
`

export { WrapWarningCell, TestTakerDetails }