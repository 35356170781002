import { Step2Fields, Step3Fields, Step1Fields } from ".."
import { Go3, Go2, Go1 } from 'assets'

const useSteps = () => {

    const steps = [
        {
            component: Step1Fields,
            cartoon: Go1
        },
        {
            component: Step2Fields,
            cartoon: Go2
        },
        {
            component: Step3Fields,
            cartoon: Go3
        }
    ]

    return steps
}

export default { useSteps }