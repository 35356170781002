import { isEmpty } from "lodash"
import CreateAssessmentsCrud from "./CreateAssessments.crud"
import { useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"

const CreateAssessmentsData = () => {
    const { batteryAllocation } = CreateAssessmentsCrud()
    const { selectedAssessmentDefs, createBatteryIsRun } = useSelector(state => state.assessment)

    const bateryData = [{
        name: "battery",
        placeholder: 'שם הסוללה',
        input: {
            value: "",
            onChange: () => { }
        }
    },
    {
        name: "batteryDetails",
        placeholder: "פרטים",
        input: {
            value: "",
            onChange: () => { }
        }
    }
    ]

    const footerBtns = [
        {
            id: 1,
            color: "secondary",
            label: createBatteryIsRun ? <CircularProgress color="white" size={20} /> : "הקצאה",
            action: batteryAllocation,
            disabled: isEmpty(selectedAssessmentDefs),
            disabledColor: "secondary",
            width: '150px'
        }
    ]

    const title = "V להוספת מבדקים לסוללה"

    return {
        bateryData,
        title,
        footerBtns
    }
}

export default CreateAssessmentsData