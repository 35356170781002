import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { app, reset1, reset2 } from "./routes";
import { ChangePassword, SendResetPassword } from 'components'

const ResetRoutes = () => {

    return (
        <>
            <Routes>
                <Route exact path={reset1} element={
                    <SendResetPassword />
                } />
                <Route path={`${reset2}/:uid/:token`} element={
                    <ChangePassword />
                } />
                <Route exact path={"/"} element={
                    <Navigate to={app.reset1} />
                } />
            </Routes>
        </>
    );
}

export default ResetRoutes
