

import en from 'config/strings'

//validation login form
const validate = (values) => {
    const errors = {}

    if (values) {
        if (!values.name) {
            errors.name = en.require('name')
        }

        if (!values.group) {
            errors.group = en.require('group')
        }

        if (!values.method) {
            errors.method = en.require('method')
        }
    }

    return errors;
}

export default validate;

