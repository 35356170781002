const correct = 'CORRECT'
const incorrect = 'INCORRECT'
const selfCorrection = "SELF_CORRECTION"
const repetition = "REPETITION"
const insertion = 'INSERTION'
const omission = "OMISSION"
const minorError = "MINOR_ERROR"
const na = "N_A"
const substitution = "SUBSTITUTION"

const t = {
    correct: correct,
    incorrect: incorrect,
    selfCorrection: selfCorrection,
    repetition: repetition,
    insertion: insertion,
    minorError: minorError,
    omission: omission,
    substitution: substitution,
    na: na
}

export { substitution, na, minorError, omission, insertion, correct, incorrect, selfCorrection, repetition }
export default t