import React from 'react'
import { WrapStatus } from './Style'
import { done } from 'styles/theme'
import { error } from 'styles/theme'

const StatusCell = (props) => {
    const { value, colDef } = props

    const statusUI = [{
        label: "V",
        color: done
    },
    {
        label: "X",
        color: error
    }]

    const currentStatus = statusUI[Number(value)]

    return (
        <WrapStatus hide={!value && colDef.hideCorrect} color={currentStatus.color}>
            {currentStatus.label}
        </WrapStatus>
    )

}

export default StatusCell