import { css } from "styled-components"

const WrapCreateAffiliation = css`
display: grid;
grid-template-columns: 50% 50%;
grid-gap: 15px;
`



export { WrapCreateAffiliation }