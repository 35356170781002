import React, { useCallback } from 'react'
import { SvgIcon } from '@mui/material'
import { alerts } from 'keys'
import { AlertCrud } from 'components/Alerts/hooks'
import { ReactComponent as InstructionsIcon } from 'assets/instructions.svg';
import { LightButton } from 'styles/GlobalStyle';

const Instructions = () => {
    const { setAlerts } = AlertCrud()

    const openInstructions = useCallback(() => {
        setAlerts(alerts.recordInstructions, true)
    }, [])

    return (
        <LightButton sx={{ width: 400 }} action={openInstructions}>
            <SvgIcon component={InstructionsIcon} inheritViewBox />
            <b>{"איך מתבצע האבחון?"}</b>
            {" לחצו כאן להסבר על השלבים"}
        </LightButton>
    )
}

export default Instructions