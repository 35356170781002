import { AlertCrud } from "components/Alerts/hooks"
import { toastConfig } from "config"
import { useMutation } from "react-query"
import { generateCouponCodeFetch } from "store/middlewares/auth.crud"
import { CouponCodeToast, couponHooks } from ".."
import { toast } from "react-toastify"
import { alerts } from "keys"
import { useSelector } from "react-redux"
import { isValid, submit } from "redux-form"
import { useDispatch } from "react-redux"
import { Coupon } from "models/RM"

const useSubmitBtns = () => {
    const { formname } = couponHooks.constants

    const dispatch = useDispatch()
    const formValues = useSelector(state => state.form[formname]?.values)
    const isFormValid = useSelector(state => isValid(formname)(state));
    const { setAlerts } = AlertCrud()


    const closeAlert = () => {
        setAlerts(alerts.createCoupon, false)
    }

    const { mutateAsync: generateCouponCode, isLoading: generateCouponCodeLoading } = useMutation({
        mutationFn: generateCouponCodeFetch,
        onSuccess: (res) => {
            const { data } = res
            closeAlert()
            toast(<CouponCodeToast {...data} />, toastConfig)
        }
    })

    const generateCoupon = () => {
        if (isFormValid) {
            const couponData = new Coupon(formValues)
            generateCouponCode(couponData)
        }
        else dispatch(submit(formname))
    }

    const submitBtns = [
        {
            id: 0,
            label: "צור לי קופון",
            action: generateCoupon,
            color: "secondary",
            loading: generateCouponCodeLoading,
        },
        {
            id: 1,
            label: "ביטול",
            color: "thirdly",
            action: closeAlert
        }
    ]

    return submitBtns
}

export default useSubmitBtns