const uploading = "UPLOADING"
const uploaded = "FILE_UPLOADED"
const inProgress = "IN_PROGRESS"
const complete = "COMPLETE"
const created = 'CREATED'
const wordsComplete = "MARKING_WORDS_COMPLETE"
const wordsInProgress = "MARKING_WORDS_IN_PROGRESS"
const brokenFile = "BROKEN_FILE"
const backToMarking = "BACK_TO_MARKING"
const backToCutting = "BACK_TO_CUTTING"
const cutAudio = "CUT_AUDIO"
const audioCuttingInProgress = "AUDIO_CUTTING_IN_PROGRESS"
const audioCuttingComplete = "AUDIO_CUTTING_COMPLETE"
const transcriptionInProgress = "TRANSCRIPTION_IN_PROGRESS"
const transcriptionComplete = "TRANSCRIPTION_COMPLETE"
const backToTranscription = "BACK_TO_TRANSCRIPTION"

const statuses = {
    inProgress,
    complete,
    created,
    backToCutting,
    wordsComplete,
    wordsInProgress,
    backToMarking,
    audioCuttingInProgress,
    audioCuttingComplete,
    transcriptionInProgress,
    transcriptionComplete,
    uploaded,
    backToTranscription,
    uploading,
    brokenFile,
    cutAudio
}

// export { wordsInProgress, inProgress, complete, created, wordsComplete }
export default statuses