import React from "react";
import { WrapPageCover, PageContent, Circle, Title, WrapCover } from './Style'

const PageCover = React.forwardRef((props, ref) => {
    return (
        <WrapCover>
            <WrapPageCover $firstPage={props.firstPage} className="page page-cover" ref={ref} data-density="hard">
                <PageContent className="page-content">
                    <Title>
                        {props.children}
                    </Title>
                </PageContent>
            </WrapPageCover>
        </WrapCover>
    );
});

export default PageCover