import React from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'
import { isEmpty, pick } from 'lodash'
import { WrapIndication, IndicationLabel } from './Style'
import { deviationRatingIndex, naLabel, ratingListLabel, ratingListTheme } from 'keys/rating'
import { AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks'
import { gTheme } from 'styles/theme'
import { statuses } from 'keys'

const RateAndAccuracy = (props) => {
    const { value } = props
    const { fields, rating, status } = value || {}
    const { scoreRatingMethods } = useSelector(state => state.rating)
    const { indicationIcon } = AssessmentsAndTestTakersData()

    const { warning, noraml, nodata } = indicationIcon

    const ratingFields = pick(rating, [fields.id, fields.index])
    let ratingIndex = ratingFields[fields.index]
    let ratingId = ratingFields[fields.id]
    let currentScoreRatingMethod

    if (status === statuses.brokenFile)
        currentScoreRatingMethod = {
            label: naLabel
        }

    else {
        if (isEmpty(scoreRatingMethods))
            currentScoreRatingMethod = {
                themeColor: ratingListTheme[ratingIndex - 1],
                label: ratingListLabel[ratingIndex - 1]
            }
        else {
            currentScoreRatingMethod = scoreRatingMethods?.[ratingId]
            ratingIndex = currentScoreRatingMethod?.index
        }
    }

    const indication = ratingIndex ? (ratingIndex <= deviationRatingIndex ? noraml : warning) : nodata

    return (
        <WrapIndication>
            <IndicationLabel color={gTheme?.[currentScoreRatingMethod?.themeColor]?.main || 'ligthGray'}>
                {currentScoreRatingMethod?.label}
            </IndicationLabel>
        </WrapIndication>
    )

}

export default RateAndAccuracy