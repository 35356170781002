import React from 'react'
import { Logo } from 'assets'
import { Img, WrapImg } from 'styles/GlobalStyle'

const CustomLogo = (props) => {
    const { width = 50, height = 50, action } = props

    return (
        <WrapImg width={width} height={height} onClick={() => action && action()}>
            <Img src={Logo} />
        </WrapImg>
    )
}

export default CustomLogo