import React, { useContext } from 'react'
import { LetterAddOnRow, Vowel } from './Style';
import { isEmpty, map } from 'lodash';
import { TranscriptionContext } from 'components/Admin/Transcription/TranscriptionContext';

const VowelCategory = (props) => {
    const { index, phoneme, updatePhoneme, name } = props
    const { letter_add_ons, phoneme_def_letter_add_ons, letter } = phoneme || {}

    const { letterAddOns } = useContext(TranscriptionContext)

    const offLabel = { name: "ללא" }
    const shinValue = "ש"

    const rightShin = { ...letterAddOns["4"], key: 4 }
    const leftShin = { ...letterAddOns["5"], key: 5 }

    const weakDagesh = {
        on: { ...letterAddOns["3"], key: 3 },
        off: { ...offLabel, key: "dageshOff" }
    }

    const strongDagesh = {
        on: { ...letterAddOns["19"], key: 19 },
        off: { ...offLabel, key: "strongDageshOff" }
    }

    const geresh = {
        on: { ...letterAddOns["6"], key: 6 },
        off: { ...offLabel, key: "gereshOff" }
    }

    const shin = {
        left: { ...letterAddOns["4"], key: 4 },
        right: { ...letterAddOns["5"], key: 5 }
    }

    const add_ons_onoff = [
        weakDagesh, strongDagesh, geresh
    ]

    const add_ons = map(letter_add_ons, "letter_add_on")
    const phoneme_def_add_ons = map(phoneme_def_letter_add_ons, "letter_add_on")

    const handleOff = ({ on, off }) => {
        const nPhoneme = { ...phoneme }
        nPhoneme.letter_add_ons = letter_add_ons.filter((add_on) => add_on.letter_add_on != on.key)
        nPhoneme.letter_add_ons.push({
            letter_add_on: off.key
        })
        updatePhoneme(index, nPhoneme)
    }

    const handleOn = ({ on, off }) => {
        const nPhoneme = { ...phoneme }
        nPhoneme.letter_add_ons = letter_add_ons.filter((add_on) => add_on.letter_add_on != off.key)
        nPhoneme.letter_add_ons.push({
            letter_add_on: on.key
        })
        updatePhoneme(index, nPhoneme)
    }

    const handleShin = (on, off) => {
        const nPhoneme = { ...phoneme }
        nPhoneme.letter_add_ons = letter_add_ons.filter((add_on) => add_on.letter_add_on != off.key)
        nPhoneme.letter_add_ons.push({
            letter_add_on: on.key
        })
        updatePhoneme(index, nPhoneme)
    }

    return (
        <>
            <LetterAddOnRow>
                <div className="letter_add_on_col">
                    <Vowel
                        correct={
                            phoneme_def_add_ons.includes(rightShin.key) &&
                            (
                                add_ons.includes(rightShin.key) ||
                                (!add_ons.includes(rightShin.key) && !add_ons.includes(leftShin.key))
                            )
                        }
                        source={phoneme_def_add_ons.includes(rightShin.key)}
                        incorrect={
                            phoneme_def_add_ons.includes(leftShin.key)
                            &&
                            (!add_ons.includes(leftShin.key)
                                &&
                                (add_ons.includes(rightShin.key))
                            )
                        }
                        disabled={letter != shinValue}
                        onClick={() => handleShin(rightShin, leftShin)}
                    >
                        {rightShin.name}
                    </Vowel>
                </div>
                <div className="letter_add_on_col">
                    <Vowel
                        correct={
                            phoneme_def_add_ons.includes(leftShin.key) &&
                            (
                                add_ons.includes(leftShin.key) ||
                                (!add_ons.includes(leftShin.key) && !add_ons.includes(rightShin.key))
                            )
                        }
                        source={phoneme_def_add_ons.includes(leftShin.key)}
                        incorrect={
                            phoneme_def_add_ons.includes(rightShin.key)
                            &&
                            (!add_ons.includes(rightShin.key)
                                &&
                                (add_ons.includes(leftShin.key))
                            )
                        }
                        disabled={letter != shinValue}
                        onClick={() => handleShin(leftShin, rightShin)}
                    >
                        {leftShin.name}
                    </Vowel>
                </div>
            </LetterAddOnRow >
            {
                add_ons_onoff.map((add_on, index) =>
                    <LetterAddOnRow key={index}>
                        <div className="letter_add_on_col">
                            <Vowel
                                correct={
                                    (phoneme_def_add_ons.includes(add_on.on.key) &&
                                        (
                                            add_ons.includes(add_on.on.key)
                                            ||
                                            //for the first transcription that dynamic letter_add_ons are empty and its correct state
                                            (!add_ons.includes(add_on.on.key) && !add_ons.includes(add_on.off.key))
                                        )
                                    )
                                }
                                incorrect={
                                    (!phoneme_def_add_ons.includes(add_on.on.key) &&
                                        add_ons.includes(add_on.on.key))
                                }
                                source={phoneme_def_add_ons.includes(add_on.on.key)}
                                onClick={() => handleOn(add_on)}
                            // disabled={disabled}
                            >
                                {add_on.on.name}
                            </Vowel>
                        </div>
                        <div className="letter_add_on_col">
                            <Vowel
                                correct={
                                    !phoneme_def_add_ons.includes(add_on.on.key) &&
                                    (add_ons.includes(add_on.off.key))
                                }
                                incorrect={
                                    (phoneme_def_add_ons.includes(add_on.on.key) &&
                                        (add_ons.includes(add_on.off.key) && !isEmpty(add_ons)))
                                }
                                onClick={() => handleOff(add_on)}
                            // disabled={disabled}
                            >
                                {add_on.off.name}
                            </Vowel>
                        </div>
                    </LetterAddOnRow >
                )
            }
        </>
    )
}

export default VowelCategory