import React from 'react'
import { map } from 'lodash'
import { Level, WrapLevels,Title } from './Style'

const Levels = (props) => {
    const { levels, levelKey = "label", title } = props

    return (
        <WrapLevels>
            <Title>
                {title}
            </Title>
            {levels.map((level => {
                const { id } = level
                return (
                    <Level key={id} {...level}>
                        {level[levelKey]}
                    </Level>
                )
            }
            ))}
        </WrapLevels>
    )
}

export default Levels