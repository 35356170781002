import { useDispatch } from "react-redux"
import { AssessmentDefVM } from 'models/VM'
import { actions } from 'store'
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"

const MatchingLearningCrud = () => {
    const dispatch = useDispatch()
    const { rating } = useSelector(state => state.rating)

    const getMatchingLearning = async () => {
        try {
            const matchingLearning = await dispatch(actions.getMatchingLearning())
            let nMatchingLearning = []
            matchingLearning.forEach(singleMatchingLearning => {
                nMatchingLearning.push(new AssessmentDefVM(singleMatchingLearning))
            });
            dispatch(actions.setMatchingLearning(nMatchingLearning))
        }
        catch {
            console.log("error getMatchingLearning")
        }
    }

    const cleanData = () => {
        dispatch(actions.setMatchingLearning(null))
    }

    const init = () => {
        if (!isEmpty(rating)) {
            getMatchingLearning()
        }
    }

    return {
        getMatchingLearning,
        init,
        cleanData
    }
}

export default MatchingLearningCrud