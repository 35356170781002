import React from 'react'
import { Error, IconError } from './Styled'

//Render error after touching filed
const ErrorField = (props) => {
    const {
        touched,
        error
    } = props;

    return (
        touched && error ?
            <Error> <IconError />{error}</Error > :
            null
    )

}

export default ErrorField