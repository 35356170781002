import styled from "styled-components";
import { Shadow } from "styles/GlobalCss";

const WrapSections = styled.div`
--column-gap:10px;
height: 100%;
display: grid;
grid-template-columns: 50% 50%;
grid-column-gap: var(--column-gap);
width: calc(100% - var(--column-gap));

@media (max-width: 992px){
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
`

const Section = styled.div`
padding: 10px;
box-sizing: border-box;
width: 100%;
border-radius: 4px;

${Shadow};
`

const WrapUploadAudio = styled.div`
height: 100%;
align-items: center;
row-gap: 15px;
display: flex;
flex-direction: column;
box-sizing:border-box;
`


export { WrapSections, WrapUploadAudio, Section }