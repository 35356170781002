import CryptoJS from 'crypto-js';

const SECRET_KEY = 'go-read'; // Keep this secure

// Encrypt data
function encryptData(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
}

// Decrypt data
function decryptData(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// Save encrypted data to localStorage
function setItem(key, data) {
    const encryptedData = encryptData(data);
    localStorage.setItem(key, encryptedData);
}

// Retrieve and decrypt data from localStorage
function getItem(key) {
    const encryptedData = localStorage.getItem(key);
    return encryptedData ? decryptData(encryptedData) : null;
}

function getUser() {
    const encryptedData = localStorage.getItem("user");
    return encryptedData ? decryptData(encryptedData) : null;
}

function setUser(val) {
    setItem("user", val)
}

function clean() {
    localStorage.clear()
}

function removeItem(key) {
    localStorage.removeItem(key);
}

export default { setUser, getUser, setItem, getItem, clean, removeItem }