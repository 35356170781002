import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { secretStorage } from 'config'

const usePayment = () => {
    const dispatch = useDispatch()

    const payment = async ({ registerForm, link }) => {
        const formValues = secretStorage.getItem("registerForm")
        const secretUser = secretStorage.getItem("user")

        if (secretUser) {
            secretStorage.setItem("user", { ...secretUser, user_id: secretUser.user_id })
            dispatch(actions.setUser({ user_id: secretUser.user_id }))
        }

        const secretData = registerForm ? registerForm : { ...formValues, new_balance: 1 }
        secretStorage.setItem("registerForm", secretData)

        const paymentLink = link

        if (paymentLink) {
            window.location.href = paymentLink;
        }
    }

    return payment
}

export default usePayment