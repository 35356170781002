import React, { useEffect } from 'react'
import { AssessmentDefs, Page } from 'components'
import { componentKeys } from 'keys/components'
import { WrapCreateAssessmentRecord } from './Style'
import { useNavigate, useParams } from 'react-router-dom'
import { app } from 'components/Router/routes'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { actions } from 'store'

const CreateAssessmentRecord = () => {
    const { recordFooter } = componentKeys
    const { testTaker } = useSelector(state => state.testTaker)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const goAssessment = (assessmentDef) => {
        const { id } = assessmentDef
        navigate(`${app.makingRecordAssessments}?testTaker=${testTaker.test_taker_id}&assessmentDef=${id}`)
    }

    return (
        <Page>
            <WrapCreateAssessmentRecord>
                <AssessmentDefs
                    footer={recordFooter}
                    student={true}
                    onSelectAssessment={goAssessment}
                />
            </WrapCreateAssessmentRecord>
        </Page>
    )
}

export default CreateAssessmentRecord