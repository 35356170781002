import React, { useContext } from 'react'
import { RegisterContext } from './RegisterContext'
import { register } from './hooks'
import { useSelector } from 'react-redux'
import { isValid, submit } from 'redux-form'
import { useDispatch } from 'react-redux'
import { formname } from './hooks/constants'
import { AuthWithCartoon } from '..'
import { BorderLinearProgress, WrapProgress } from './Style'

const Regirster = () => {
    const { step } = useContext(RegisterContext)

    const dispatch = useDispatch()

    const isFormValid = useSelector(state => isValid(formname)(state));

    const steps = register.useSteps()

    const handleNext = () => {
        if (!isFormValid)
            dispatch(submit(formname)) // Trigger validation for the current step

        return isFormValid
    };

    const StepComponent = steps[step].component

    return (
        <>
            <AuthWithCartoon cartoon={steps[step].cartoon}>
                <StepComponent handleNext={handleNext} />
            </AuthWithCartoon>
            <WrapProgress>
                <BorderLinearProgress variant="determinate" value={(100 / 5) * (step + 1)} />
            </WrapProgress>
        </>
    )
}

export default Regirster