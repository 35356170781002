import React from "react";
import { WrapWordList, WrapWord, WrapWordText, WordItem, WrapSettings, Word, SelectWord, SelectTimePoint, PointTime } from './Style'
import { WordsData } from "./hooks";
import { IconButton } from "components/Form";
import { isArray } from "lodash";

const WordSettings = (props) => {
    const { settingsItems, getColor } = WordsData()
    const { index, settings = {}, settingsValues = {} } = props

    const getValue = (keys) => {
        if (isArray(keys)) {
            for (let i = 0; i < keys.length; i++) {
                if (settingsValues?.[keys[i]])
                    return { value: settingsValues[keys[i]], key: keys[i] }
            }
            return { value: settingsValues[keys[0]], key: keys[0] }
        }
    }

    return (
        <WrapSettings>
            {settingsItems.map((settingItem, indexKey) => {
                const { wrap, props, button, icon, keys } = settingItem
                const { value, key } = getValue(keys)
                const color = getColor(key, value)
                const disabled = settings[key]?.disabled

                const commonState = {
                    color: color,
                    value: value,
                    disabled: disabled
                }
                const { hide, hideEmpty } = settings[key] || {}
                let hideSetting = false
                if (hide) hideSetting = hide
                else if (hideEmpty && !value) hideSetting = true

                if (!hideSetting)
                    return (
                        <SelectWord {...wrap} key={indexKey}>
                            {settingItem.component ?
                                <settingItem.component
                                    {...props}
                                    {...commonState}
                                    onChange={(val) => props.action(val, index)}
                                />
                                :
                                <IconButton
                                    {...button}
                                    {...commonState}
                                    action={() => button.action(index)}
                                    onDoubleClick={() => button?.dblClick && button.dblClick(index)}
                                    icon={
                                        <settingItem.icon.name
                                            color={color}
                                            {...icon}
                                        />}
                                />
                            }
                        </SelectWord>
                    )
            }
            )}
        </WrapSettings>
    )
}

export default WordSettings