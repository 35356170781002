
import axios from 'config/axiosConfig'

import actions from '../actions'
import api, { admin_assessments, reading_assessment } from 'keys/apis'

export const getWordsAndRegions = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_WORDS_AND_REGIONS') {
        const { assessment } = getState().assessment
        let { id } = assessment
        if (!id) id = action.payload

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/${api.get_words_and_regions}/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const saveRegions = ({ dispatch, getState }) => next => action => {
    if (action.type === 'SAVE_REGIONS') {
        const { assessment } = getState().assessment
        const { id } = assessment

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/${api.save_regions}/`, {
                regions: action.payload
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const saveRegionsFetch = ({ assessment, regions }) => {
    const { id } = assessment

    if (id)
        axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/${api.save_regions}/`, {
            regions: regions
        })
}



