import React from 'react'
import { Navigate, useLocation } from "react-router-dom";
import { app } from './routes';
import { secretStorage } from 'config';

const PrivateRoutesApp = (props) => {
    const token = secretStorage.getItem("user")?.token;
    const isAuth = !!token; // Assuming getToken() returns truthy value when token is present

    const location = useLocation();

    if (!isAuth)
        return <Navigate to={app.login} state={{ from: location }} replace />;


    return props.children;
}

export default PrivateRoutesApp