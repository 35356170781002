import React from 'react'
import { Wrap } from './Styled'
import { Field } from 'redux-form'
import { RenderField } from 'components/Form'

//Using redux from to create a generic form
const Fields = (props) => {
    const { csswrap, fields } = props

    return (
        <Wrap csswrap={csswrap}>
            {fields.map((field, index) =>
                <Field
                    {...field}
                    key={index}
                    dynamicComponent={field.component}
                    component={RenderField}
                />
            )}
        </Wrap>
    )
}

export default Fields