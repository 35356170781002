
import axios from 'config/axiosConfig'

import { app_auth, get_with_last_assessment, go, testTakers, test_takers } from 'keys/apis'
import { TestTakerVM } from 'models/VM'


export const createTestTaker = ({ dispatch, getState }) => next => action => {
    if (action.type === 'CREATE_TEST_TAKER') {
        let testTaker = action.payload

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_SERVER_PORT}${go}test_takers/`, {
                ...testTaker
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    resolve(null)
                });
        })
    }
    return next(action)
}

export const createTestTakerFetch = (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${go}test_takers/`, {
        ...values
    })
}

export const getGrades = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_GRADES') {
        const { affiliation, group } = action.payload || {}

        let params = {}
        if (affiliation)
            params.affiliation = affiliation

        if (group)
            params.group = group

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}grades/`, {
                params: params
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}

export const getTClasses = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_T_CLASSES') {
        const { group, affiliation, grade } = action.payload || {}

        let params = {}

        // if (group)
        //     params.group = group
        if (affiliation)
            params.affiliation = affiliation
        if (grade)
            params.grade = grade

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}classes/`, {
                params: params
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}

export const getTClassesFetch = (filters = {}) => {
    let params = {}

    if (filters.group)
        params.group = filters.group
    if (filters.affiliation)
        params.affiliation = filters.affiliation
    if (filters.grade)
        params.grade = filters.grade

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}classes/`, {
        params: params
    })
}

export const getOpenGradesFetch = () => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}open_grades/`)
}

export const getOpenTestTakersFetch = (values) => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}open_test_takers/`, {
        params: values
    })
        .then((res) => {
            const test_takers =
                res?.data?.map(item => (new TestTakerVM(item)))
            return test_takers
        })
}

export const getGradesFetch = (filters = {}) => {
    const { affiliation, group } = filters

    let params = {}

    if (group)
        params.group = group
    if (affiliation)
        params.affiliation = affiliation

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}grades/`, {
        // params: params
    })
}

export const getMethods = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_METHODS') {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}methods/`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })
    }
    return next(action)
}

export const getTestTakersOptions = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_TEST_TAKERS_OPTIONS') {
        const { group, affiliation, grade, t_class } = action.payload || {}

        let params = {}
        if (group) params.group = group
        if (t_class) params.t_class = t_class
        else {
            if (affiliation) params.affiliation = affiliation
            if (grade) params.grade = grade
        }

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}test_takers/options/`,
                {
                    params: params
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })

    }
    return next(action)
}

export const getTestTakersOptionsFetch = (filters = {}) => {
    const { group, affiliation, grade, t_class } = filters || {}

    let params = {}
    if (group) params.group = group
    if (t_class) params.t_class = t_class
    else {
        if (affiliation) params.affiliation = affiliation
        if (grade) params.grade = grade
    }

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}test_takers/options/`, {
        params: params
    })
}

export const getTestTakersFetch = () => {
    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}test_takers/`)
        .then((res) => {
            const test_takers =
                res?.data?.map(item => (new TestTakerVM(item)))
            return test_takers
        })
}


export const updateOrCreateTestTakerFetch = (values) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${go}test_takers/update_or_create_test_taker/`, {
        ...values
    })
}

export const getWithLastAssessmentsFetch = ({ headerFilters, signal }) => {
    const { group, t_class, affiliation, grade, test_taker } = headerFilters || {}

    let params = {}
    if (group) params.group = group.id
    if (t_class) params.t_class = t_class.id
    else {
        if (affiliation) params.affiliation = affiliation.id
        if (grade) params.grade = grade.id
    }
    if (test_taker)
        params.test_taker = test_taker.id

    return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}${testTakers}${get_with_last_assessment}/`, {
        signal,
        params: params
    })
}

export const getWithLastAssessment = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_WITH_LAST_ASSESSMENT') {
        let { headerFilters } = getState().header
        const { group, t_class, affiliation, grade, test_taker } = headerFilters || {}

        let params = {}
        if (group) params.group = group.id
        if (t_class) params.t_class = t_class.id
        else {
            if (affiliation) params.affiliation = affiliation.id
            if (grade) params.grade = grade.id
        }
        if (test_taker)
            params.test_taker = test_taker.id

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}${testTakers}${get_with_last_assessment}/`, {
                // cancelToken: new CancelToken(function executor(c) {
                //     // An executor function receives a cancel function as a parameter
                //     dispatch(actions.updateController({ [api.get_with_last_assessment]: c }))
                // }),
                params: params
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })

    }
    return next(action)
}

export const getAllTestTakers = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_ALL_TEST_TAKERS') {

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}test_takers/`,
            )
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })

    }
    return next(action)
}

export const getTestTaker = ({ dispatch, getState }) => next => action => {
    if (action.type === 'GET_TEST_TAKER') {
        const id = action.payload

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}${test_takers}/${id}/`, {
                // cancelToken: new CancelToken(function executor(c) {
                //     // An executor function receives a cancel function as a parameter
                //     dispatch(actions.updateController({ [api.test_taker]: c }))
                // }),
            }
            )
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        })

    }
    return next(action)
}

export const createGradeFetch = (data) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${go}grades/`, {
        ...data
    })
}

export const createClassFetch = (data) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${go}classes/`, {
        ...data
    })
}

export const getTestTakerFetch = ({ id, signal }) => {
    if (id)
        return axios.get(`${process.env.REACT_APP_SERVER_PORT}${go}${test_takers}/${id}/`, {
            signal
        }
        )
}




