import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { includes, isEmpty, map } from "lodash";
import secretStorage from "./secretStorage";

// ["administrative_admin", "administrative_read", "administrative_write"]
// ["school_admin", "school_read", "school_write"]
// ["parent_admin"]


const defineAbilityFor = () => {
    const user = secretStorage.getItem("user")
    const { user_permissions, is_superuser } = user || {}
    const permissions = map(user_permissions, "codename")

    const { can, cannot, build } = new AbilityBuilder(createMongoAbility)

    cannot("read", ["transcription", "audio-cutting"])
    if (isEmpty(permissions))
        can("manage", "all")
    else {
        can('read', 'all')
        cannot('create', ["generate-coupon-code", "create-upload-assessment", "create-record-assessment"])
        cannot('read', ["next-assessment-btn", "student-label", "toolbar-columns-button", "dashboard", "assessments-for-testTaker", "col_ID", "marking-words", "admin-assessments", "voice-lab", "linguistic-lab", "all-assessments", "assessments", "test-takers", "toolbar-actions"])

        if (["school_admin"]
            .some(item => includes(permissions, item))) {
            can("read", ["toolbar-columns-button", "student-label"]);
        }

        if (["school_admin", "school_read", "school_write"]
            .some(item => includes(permissions, item))) {
            can("read", ["dashboard", "all-assessments", "assessments", "test-takers"]);
        }

        if (["administrative_admin", "administrative_read", "administrative_write"]
            .some(item => includes(permissions, item))) {
            can("read", ["admin-assessments", "voice-lab", "linguistic-lab"]);
        }

        if (["parent_admin"]
            .some(item => includes(permissions, item))) {
            cannot("read", ["header"]);
        }

        if (is_superuser) {
            can(["manage"], "all")
            // cannot("read", ["transcription", "audio-cutting"])
        }
    }

    return build()
};

export default defineAbilityFor