import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    useMemo,
    useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { WaveSurfer, WaveForm, Region, Marker } from "wavesurfer-react";
import { WrapRecord, ActionBtn, AudioContent, WrapRegion, WrapWaveSurfer } from './Style'

import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";
import { v1 as uuid } from "uuid";

import { actions } from 'store'
import { primary } from "styles/theme";
import { changeItem } from "utils/array";
import { CutRecordCrud, CutRecordData } from './hooks'
import { CutRecordContext } from "./CutRecordContext";
import { createContent, createContentWord } from "models/VM/Region";
import { word } from "mock/regionType";
import update_type from "mock/updateType";

const Record = () => {
    const { assessment } = useSelector(state => state.assessment)
    const { actionsBtns } = CutRecordData()
    const { regionFunc, changeRegion } = CutRecordCrud()
    const [timelineVis, setTimelineVis] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [markers, setMarkers] = useState([]);
    const openRegionRef = useRef();
    const dispatch = useDispatch()

    const { isLoadingData, regionsPluginRef, wavesurferRef, playRegionRef, regionsRef, regionRef, regions, setRegions } = useContext(CutRecordContext)

    const plugins = useMemo(() => {
        return [
            {
                key: "regions",
                plugin: RegionsPlugin,
                options: {
                    dragSelection: true,
                    style: {
                        color: "#2D5B88",
                    },
                },
            },
            timelineVis && {
                key: "top-timeline",
                plugin: TimelinePlugin,
                options: {
                    height: 20,
                    insertPosition: "beforebegin",
                    style: {
                        color: "#2D5B88",
                    },
                    timeInterval: 0.1,
                    primaryLabelInterval: 1,
                    // formatTimeCallback: formatTime
                },

            },
            timelineVis && {
                key: "bottom-timeline",
                plugin: TimelinePlugin,
                options: {
                    height: 10,
                    style: {
                        color: "#6A3274",
                    },
                    primaryLabelInterval: 1,
                    timeInterval: 0.1,
                    // formatTimeCallback: formatTime
                },

            },
        ].filter(Boolean);
    }, [timelineVis]);


    useEffect(() => {
        // Add event listener for spacebar key press
        const handleKeyPress = (event) => {
            if (event.code === 'Space') {
                event.preventDefault();
                wavesurferRef.current.playPause();
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        wavesurferRef.current && wavesurferRef.current.on('seeking', () => {
            // if (!playRegionRef.current)
            wavesurferRef.current.pause()
        })

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [wavesurferRef]);

    useEffect(() => {
        regionsRef.current = regions;
    }, [regions]);

    const createMarker = useCallback((time) => {
        setMarkers([
            ...markers,
            {
                label: `יצירת מקטע`,
                time: time,
                color: "#555555"
            },
        ]);
        openRegionRef.current = time
    }, [markers, wavesurferRef])

    const createRegion = useCallback((time) => {

        if (openRegionRef.current) {
            let start = openRegionRef.current > time ? time : openRegionRef.current
            let end = openRegionRef.current > time ? openRegionRef.current : time

            let nRegion = {
                id: uuid(),
                start: start,
                end: end,
                color: "rgba(0, 0, 0, 0.1)",
                update_type: "UPDATE",
                isNew: true
            }

            let nContent = createContent(nRegion, regionFunc)
            nRegion.content = nContent

            // wavesurferRef.current.addRegion(nRegion)
            // const regionPlugin = regionsPluginRef.current.addRegion(nRegion)

            let nRegions = [
                ...regionsRef.current,
                nRegion
            ]
            setRegions(nRegions);
            regionsRef.current = nRegions
            changeRegion(nRegion)

            setMarkers([])
            openRegionRef.current = null
        }
        else {
            createMarker(time)
        }
    }, [regions, wavesurferRef]);

    const updateRegion = (newRegion) => {
        changeRegion({ ...newRegion, update_type: 'UPDATE' })
        const index = regionsRef.current.findIndex((region) => region.id === newRegion.id)
        const nRegions = changeItem(regionsRef.current, index, newRegion)
        regionsRef.current = nRegions
        setRegions(nRegions)
    }

    const updateTypeRegion = (obj) => {
        let index = regionsRef.current.findIndex(region => region.id === regionRef.current?.id)
        if (index > -1) {
            if (obj.type === word) {
                if (regionsRef.current[index].type != word) {
                    let footer = createContentWord()

                    regionsRef.current[index].content.appendChild(footer)
                }
            }
            else {
                const wordNode = regionsRef.current[index].content.children?.[1]
                if (wordNode)
                    regionsRef.current[index].content.removeChild(regionsRef.current[index].content.children[1])
            }


            regionsRef.current[index].word_id = null
            regionsRef.current[index].word__def_id = null
            regionsRef.current[index].update_type = update_type.update
            updateRegion({ ...regionsRef.current[index], ...obj })
        }
    }

    const handleWSMount =
        (waveSurfer) => {
            wavesurferRef.current = waveSurfer;

            if (wavesurferRef.current) {
                dispatch(actions.setWavesurfer(wavesurferRef.current))
                dispatch(actions.setIsPlaying(false))


                wavesurferRef.current.on("ready", (ws) => {
                    setIsLoaded(true);
                });

                // const wsRegions = wavesurferRef.current.registerPlugin(RegionsPlugin.create())
                // regionsPluginRef.current = wsRegions

                wavesurferRef.current.on("dblclick", (x, y, z) => {
                    let duration = wavesurferRef.current.getDuration()
                    createRegion(x * duration)
                });

                if (window) {
                    window.surferidze = wavesurferRef.current;
                }
            }
        }

    const handleRegionUpdate = useCallback((region, smth) => {
        console.log("region-update-end --> region:", region);
        const index = regionsRef.current.findIndex((reg) => reg.id === region.id)
        regionRef.current = { ...regionsRef.current[index], ...region }
        updateRegion(regionRef.current)
    }, []);

    return (
        <>
            {regions && assessment.audio_file &&
                <WrapRecord basic={false}>
                    <AudioContent id={"waveform1"} className="record" style={{ width: '100%' }} />
                    <WrapWaveSurfer
                        plugins={plugins}
                        onMount={handleWSMount}
                        container="#waveform1"
                        url={assessment.audio_file}
                        audioRate={1}
                        minPxPerSec={100}
                        height={150}
                        normalize={true}
                        wavesurferRef={wavesurferRef}
                        cursorColor={primary}
                    >
                        <WaveForm>
                            {isLoaded &&
                                regions?.map((regionProps) => (
                                    regionProps.update_type != 'DELETE' &&
                                    <WrapRegion
                                        onClick={(region, e, y) => {
                                            const index = regionsRef.current.findIndex((reg) => reg.id === region.id)
                                            regionRef.current = { ...region, ...regionsRef.current[index] }
                                            updateRegion(regionRef.current)
                                            changeRegion(regionRef.current)
                                        }}
                                        onUpdateEnd={handleRegionUpdate}
                                        key={regionProps.id}
                                        loop={true}
                                        onOut={(region) => {
                                            if (!playRegionRef.current)
                                                wavesurferRef.current.pause();
                                            else
                                                playRegionRef.current = false
                                        }}
                                        {...regionProps}
                                    />
                                ))}
                            {isLoaded &&
                                markers.map((markerProps) => (
                                    <Marker
                                        start={markerProps.time}
                                        color={markerProps.color}
                                        content={markerProps.label}
                                        drag={markerProps.draggable}
                                    />
                                ))}
                        </WaveForm>
                        <div id="timeline" />
                    </WrapWaveSurfer>
                    <div className="action-btns">
                        {actionsBtns.map((actionBtn) => {
                            const { id, label, bg, color, type } = actionBtn

                            const obj = { type, color }

                            return (
                                <ActionBtn color={bg} variant="contained" key={id} onClick={() => updateTypeRegion(obj)}>
                                    {label}
                                </ActionBtn>
                            )
                        })}
                    </div>

                </WrapRecord>
            }
        </>
    );
}

export default Record

