import React, { useRef, useState } from 'react'
import CutRecord from './CutRecord'


const CutContext = () => {
    const [regions, setRegions] = useState(null);
    const [region, setRegion] = useState(null);
    const [words, setWords] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [saveRegionsLoading, setSaveRegionsLoading] = useState(false);
    const wavesurferRef = useRef();
    const playRegionRef = useRef(false)
    const regionsRef = useRef([]);
    const regionRef = useRef();
    const regionsPluginRef = useRef();

    const commonState = {
        wavesurferRef,
        playRegionRef,
        regionsRef,
        regionRef,
        regionsPluginRef,
        words,
        regions,
        isLoadingData,
        region,
        saveRegionsLoading,
        setRegion,
        setIsLoadingData,
        setRegions,
        setWords,
        setSaveRegionsLoading
    }

    return (
        <CutRecordContext.Provider value={commonState}>
            <CutRecord />
        </CutRecordContext.Provider>
    )


}

export const CutRecordContext = React.createContext()
export default CutContext