import React, { useState } from 'react'
import LinguisticLab from './LinguisticLab'

const LabContext = () => {
    const [selectedWordDef, setSelectedWordDef] = useState(null)
    const [selectedTab, setSelectedTab] = useState(0)

    const commonState = {
        selectedWordDef, setSelectedWordDef,
        selectedTab, setSelectedTab
    }

    return (
        <LinguisticLabContext.Provider value={commonState}>
            <LinguisticLab />
        </LinguisticLabContext.Provider>
    )


}

export const LinguisticLabContext = React.createContext()
export default LabContext