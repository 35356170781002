import React from 'react'
import { Icon, IconButton, SvgIcon } from '@mui/material'
import { WrapField, WrapFieldContent, WrapGender } from 'components/Form/Styled'
import { ReactComponent as MaleIcon } from 'assets/Male.svg';
import { ReactComponent as FemaleIcon } from 'assets/Female.svg';
import { FlexAround } from 'styles/GlobalStyle';
import { Button, Error } from 'components/Form';
import { gender } from 'keys';
import { thirdly } from 'styles/theme';

const Gender = (props) => {
    const { gridArea, arrayIndex, className, input } = props
    const { value, onChange } = input

    const renderColor = (gender) => value === gender ? "thirdly2" : "white"

    return (
        <WrapField className={className} gridArea={`${gridArea}_${arrayIndex}`}>
            <WrapFieldContent>
                <WrapGender>
                    <Button color={renderColor(gender.male)} action={() => onChange(gender.male)}>
                        <SvgIcon component={MaleIcon} inheritViewBox />
                    </Button>
                    <Button color={renderColor(gender.female)} action={() => onChange(gender.female)}>
                        <SvgIcon component={FemaleIcon} inheritViewBox />
                    </Button>
                </WrapGender>
            </WrapFieldContent>
            <Error {...props} />
        </WrapField>
    )
}

export default Gender