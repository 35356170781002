import { Button } from 'components/Form';
import testTakerHooks from '.'
import { ArrowBack, Edit } from '@mui/icons-material';
import { useMutation } from 'react-query';
import { bulkCreateByLevelFetch } from 'store/middlewares/assessment.crud';
import { toast } from 'react-toastify';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { alerts } from 'keys';
import { AlertCrud } from 'components/Alerts/hooks';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import { useCallback, useState } from 'react';
import { TestTakerVM } from 'models/VM';
import { toastConfig } from 'config';
import { app } from 'components/Router/routes';
import { useNavigate } from 'react-router-dom';
import { CreateAssessmentBtn } from 'components/School/CreateAssessment';

const useTestTakerFields = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const nameField = testTakerHooks.useNameField()

    const updateOrCreateTestTaker = useCallback((test_taker) => {
        dispatch(actions.setAlerts(
            {
                key: alerts.createTestTaker,
                value: { open: true, props: test_taker }
            }))
    }, [])

    const fields = [
        nameField.field,
        {
            field: 'tz',
            headerName: "תעודת זהות",
            headerAlign: 'start',
            align: "start",
            width: 150,
        },
        {
            field: 'affiliation_name',
            headerName: "בית ספר",
            headerAlign: 'start',
            align: "start",
            width: 150,
        },
        {
            field: 'grade_name',
            headerName: "שכבה",
            headerAlign: 'start',
            align: "start",
            width: 150,
        },
        {
            field: 't_class_name',
            headerName: "כיתה",
            headerAlign: 'start',
            align: "start",
            width: 150,
        },
        {
            field: 'new_assessment',
            type: 'actions',
            headerName: "התאמת אבחון",
            width: 200,
            headerAlign: 'start',
            align: 'start',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <CreateAssessmentBtn
                        numAssessments={row.last_assessment}
                        disabled={row.last_assessment}
                        {...row}
                    />
                ];
            }
        },
        {
            field: 'edit',
            type: 'actions',
            headerName: "עריכה",
            width: 200,
            headerAlign: 'start',
            align: 'start',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<Edit color='dark' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => {
                            const testTaker = new TestTakerVM(row)
                            updateOrCreateTestTaker(testTaker)
                        }}
                        color="dark"
                    />
                ];
            }
        }
    ]

    return fields
}

export default useTestTakerFields
