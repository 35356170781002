import React from 'react'
import TestTakerHeader from 'components/School/TestTakers/TestTakerHeader'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import AssessmentDetails from 'components/School/Assessment/AssessmentDetails'

const WrapAppRoute = (props) => {
    const { isGeneralPage, children, hideHeader } = props

    const { assessment } = useSelector(state => state.assessment)

    return (
        <>
            {!isGeneralPage && !hideHeader &&
                <>
                    {isEmpty(assessment) ?
                        <TestTakerHeader /> :
                        <AssessmentDetails />
                    }
                </>
            }
            {children}
        </>
    )

}

export default WrapAppRoute