import { CloseOutlined, Stop, FileDownload, FileUpload, KeyboardVoice, Mic, PlayArrow, Pause, Delete } from '@mui/icons-material';
import { Button, IconButton } from "components/Form"
import { CreateAssessmentRecordCrud } from '.';
import { useContext } from 'react';
import { MicContext } from '../AssessmentRecordFooter';
import { CircularProgress, Fab } from '@mui/material';
import { RecordButton } from 'components/School/CreateAssessmentRecord';
import { useSelector } from 'react-redux';
import { statuses } from 'keys';

const CreateAssessmentRecord = () => {
    const {
        start,
        stop,
        cleanRecord,
        uploadAudioRecord,
        playPause,
        downloadRecording
    } = CreateAssessmentRecordCrud()

    const { isRecording, createAssessmentIsRun, isPlaying, blobURL } = useContext(MicContext)

    const { assessment } = useSelector(state => state.assessment)

    const actionsBtns = {
        record: {
            id: 0,
            component: IconButton,
            disabled: true,
            icon: <KeyboardVoice color="error" sx={{ fontSize: 60 }} />
        },
        rerecord: {
            id: 2,
            label: "הקלטה מחדש",
            disabled: true,
            component: Button,
            disabledColor: "gray2",
        },
        upload: {
            id: 3,
            label: "העלאה",
            disabled: true,
            component: Button,
            color: "secondary",
            disabledColor: "secondary"
        }


    }

    const actionFabs = [
        {
            id: 0,
            icon: {
                icon: FileDownload
            },
            disabled: !blobURL,
            title: "הורד",
            color: "lightBlue3",
            onClick: downloadRecording,
        }
    ]

    const clean = {
        id: 1,
        icon: {
            icon: Delete
        },
        title: "מחק",
        disabled: !blobURL,
        color: "lightSecondary",
        onClick: cleanRecord
    }

    const playRecord =
    {
        id: 2,
        icon: {
            icon: isPlaying ? Pause : PlayArrow
        },
        title: "נגן",
        disabled: !blobURL || isRecording,
        color: "primary",
        onClick: playPause,
    }

    const recordFab = {
        id: 3,
        icon: Mic,
        label: isRecording ? "סִיּוּם" : "",
        title: "הקלטה",
        "aria-label": 'record',
        recording: "true",
        isRecording: isRecording,
        color: "yellow",
        onClick: isRecording ? stop : start,
        component: RecordButton
    }

    const uploadFab = {
        id: 3,
        size: "68",
        icon: !blobURL ? {
            icon: FileUpload,
        } : null,
        label: blobURL ? "הֶמְשֵׁךְ" : "",
        title: "העלה",
        color: "secondary",
        onClick: uploadAudioRecord,
        loading: createAssessmentIsRun,
        disabled:
            createAssessmentIsRun ||
            isRecording ||
            !blobURL ||
            (assessment?.audio_file_url && assessment.status != statuses.brokenFile),
    }

    const constTime = {
        color: "light",
        label: "זמן מוגבל",
        variant: "contained",
        height: "30px",
        width: "100px"
    }

    return {
        actionsBtns,
        actionFabs,
        recordFab,
        constTime,
        uploadFab,
        clean,
        playRecord
    }
}
export default CreateAssessmentRecord