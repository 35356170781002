

const constants = {
    formname: "login",
    forgotUsername: "שכחת שם משתמש/",
    forgotPassword: " סיסמא? ",
    register: "עוד לא רשומים? הירשמו",
    title: "כניסה למערכת"
}

export default constants