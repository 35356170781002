const en = {
    require: (field) => `שדה חובה.`,
    invalidEmail: 'כתובת מייל לא תקינה',
    minAge: "האבחונים מגיל 3 ומעלה",
    submit: 'Login',
    minimumChars: "הסיסמה שלך חייבת להכיל לפחות 8 תווים",
    invalidTZ: "תעודת זהות לא תקינה",
    duplicateTZ: "תעודת זהות כפולה",
    invalidPassword: "הסיסמה שלך צריכה להכיל תווים נוספים מלבד מספרים",
    userCreated: 'User created',
    login: 'נכנסת בהצלחה',
    invalidUsername: "ניתן להשתמש באותיות אנגלית, מספרים ו @/./+/-/_ בלבד ללא רווחים",
    signup: 'Login',
    logout: 'המשתמש לא מחובר',
    title: (email) => `Hi ${email}`,
    subTitle: 'All specialties:',

    user: {
        submit: 'Continue',
        title: 'User Information'
    },
    query: {
        title: " התאמה אישית של אינדיקציות",
        conditions: "תנאים",
        ifDone: "אם בוצע",
        from: "מיחידת דיבור",
        to: "ליחידת דיבור"
    }
}

export default en