import styled from "styled-components";

const WrapAssessmentHeader = styled.div`

`

const WrapAssessmentDetails = styled.div`
height:30px;
`

export { WrapAssessmentHeader, WrapAssessmentDetails }