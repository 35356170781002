import { AssessmentsHooks } from "components/School/Assessments"
import { isEmpty } from "lodash-contrib"
import { useContext } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { actions } from "store"
import { LinguisticLabContext } from "../LinguisticLabContext"
import { labHooks } from ".."

const useHeader = () => {
    const dispatch = useDispatch()
    const { selectedTab } = useContext(LinguisticLabContext)
    const { assessmentFilters, categories } = useSelector(state => state.assessment)

    const { refetch: refetchFrequency, isFetching: getFrequencyLoading } = labHooks.useFrequency()
    const headerItems = AssessmentsHooks.useHeaderItems()

    const updateAssessmentFilters = (filter, value) => {
        dispatch(actions.updateAssessmentFilters({ [filter]: value }))
    }

    const headerBtns = [
        headerItems.category,
        {
            ...headerItems.assessment_def,
            multiple: false,
            input: {
                ...headerItems.assessment_def.input,
                value: isEmpty(assessmentFilters?.assessment_def) ? null : assessmentFilters?.assessment_def
            }
        },
        // {
        //     id: 1,
        //     placeholder: "גיל המלצת המקור",
        //     options: [],
        //     name: "age",
        //     input: {
        //         value: null,
        //         onChange: () => { }
        //     },
        //     component: "autoComplete",
        //     width: 170,
        //     disabled: true
        // },
        {
            id: 1,
            placeholder: "מגדר",
            options: [{ id: "MALE", name: "זכר" }, { id: "FEMALE", name: "נקבה" }],
            name: "gender",
            input: {
                value: assessmentFilters.gender,
                onChange: (val) => updateAssessmentFilters("gender", val)
            },
            component: "autoComplete",
            width: 110,
        },
        headerItems.semester,
        headerItems.year,
        headerItems.grade_of_allocation
    ]

    return headerBtns
}

export default useHeader