
const constants = {
    title: "מחירון אבחונים",
    headerTitle: "סוללת אבחונים",
    paymentLinks: [
        "https://meshulam.co.il/s/189b85cd-17ea-a840-f029-214e53e6c3ed",
        "https://meshulam.co.il/s/189b85cd-17ea-a840-f029-214e53e6c3ed",
        "https://meshulam.co.il/s/fb0b1866-7f7b-02f7-2509-65f60e876002",
        "https://meshulam.co.il/s/4e9962d1-a6d6-112d-5116-20dbeab12e52"
    ],
    noBalanceTitle: "מצטערים...",
    noBalanceContent: "אין לך זכאות לביצוע אבחון נוסף",
    noBalanceBtn: "לרכישת אבחון נוסף"
}

export default constants