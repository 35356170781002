import { app } from "components/Router/routes"
import { useNavigate } from "react-router-dom"

const useGeneralData = () => {
    const navigate = useNavigate()

    const goBackRating = {
        id: 0,
        label: 'לדו"ח אישי',
        component: 'button',
        color: "secondary",
        height:'40px',
        action: () => navigate(app.rating)
    }

    return {
        goBackRating
    }

}

export default useGeneralData