import styled from "styled-components";
import { white, header, sidebar } from "styles/theme";


const WrapHeader = styled.div`
height:${header - 20}px;
padding:10px;
background:${white};
position: absolute;
top: 0;
width: 100%;
box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;

`

const WrapPullDown = styled.div`
`

const WrapContent = styled.div`
margin-right:${sidebar + 10}px;
display:flex;
column-gap: 15px;
align-items:center;
`

export { WrapHeader, WrapPullDown, WrapContent }