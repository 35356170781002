import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import DataGridCrud from './hooks/DataGrid.crud';

const ColumnMenuItem = (props) => {
    const { colDef } = props
    const { customMenu, field } = colDef || {}
    const { setColumnItems } = DataGridCrud()

    return (
        customMenu && customMenu.map((menuItem) =>
            <MenuItem
                onClick={() => {
                    menuItem.action ? menuItem.action(field) :
                        setColumnItems(field)
                }}>
                <ListItemIcon>
                    <menuItem.icon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{menuItem.label}</ListItemText>
            </MenuItem>
        )
    );
}

export default ColumnMenuItem

