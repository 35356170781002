
import axios from 'config/axiosConfig'

import { statuses } from 'keys';
import { assessment, go, reading_assessment, assessments, report, reports, test_takers, admin_assessments } from 'keys/apis';

export const startAudioCuttingFetch = async (assessment) => {
    const { id } = assessment

    return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/start_audio_cutting/`)
}

export const continueAudioCuttingFetch = (assessment) => {
    const { id } = assessment

    if (id)
        return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/continue_audio_cutting/`)
}

export const audioCuttingSaveDraftFetch = ({ id, data }) => {
    // const data = { id: id }
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/audio_cutting_save_draft/`, {
        ...data
    })
}

export const audioCuttingCompleteFetch = async ({ id, data }) => {
    return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/audio_cutting_complete/`, {
        ...data
    })
}

export const startTranscriptionFetch = async (assessment) => {
    const { id } = assessment

    return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/start_transcription/`)
}

export const continueTranscriptionFetch = (assessment) => {
    const { id } = assessment

    if (id)
        return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/continue_transcription/`)
}

export const startMarkingWordsFetch = async (assessment) => {
    const { id } = assessment

    return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/start_marking_words/`)
}

export const continueMarkingWordsFetch = (assessment) => {
    const { id } = assessment

    if (id)
        return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/continue_marking_words/`)
}

export const backToMarkingWordsFetch = async (assessment) => {
    const { id } = assessment

    if (id)
        return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/back_to_marking_words/`)
}

export const backToAudioCuttingFetch = async (assessment) => {
    const { id } = assessment

    if (id)
        return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/back_to_audio_cutting/`)
}

export const backToTranscriptionFetch = async (assessment) => {
    const { id } = assessment

    if (id)
        return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/back_to_transcription/`)
}

export const markingWordsSaveDraftFetch = ({ id, data }) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/marking_words_save_draft/`, {
        ...data
    })
}

export const transcriptionSaveDraftFetch = ({ id, data }) => {
    return axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/transcription_save_draft/`, {
        ...data
    })
}

export const transcriptionCompleteFetch = async ({ id, data }) => {
    return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/transcription_complete/`, {
        ...data
    })
}

export const markingWordsCompleteFetch = async ({ id, data }) => {
    return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/marking_words_complete/`, {
        ...data
    })
}

export const updateBrokenFileStatusFetch = async (assessment) => {
    const { id } = assessment

    return await axios.post(`${process.env.REACT_APP_SERVER_PORT}${reading_assessment}${admin_assessments}${id}/update_status/`, {
        status: statuses.brokenFile,
        sub_status: null
    })
}




