import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { queryClient as qc } from 'keys'
import { LinguisticLabContext } from '../LinguisticLabContext'
import { getFrequencyFetch } from 'store/middlewares/assessment.crud'

const useFrequency = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters, statusFilters } = useSelector(state => state.assessment)
    const { selectedTab } = useContext(LinguisticLabContext)

    return useQuery({
        queryKey: qc.assessmentFrequency,
        enabled: false,
        refetchOnWindowFocus: false,
        queryFn: ({ signal }) => getFrequencyFetch({ factored: Boolean(!selectedTab), assessmentFilters, statusFilters, headerFilters, testTaker, signal })
    })
}

export default useFrequency