import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from 'styles'
import { labHooks, WordsByWordDef } from '.'
import { WordWithFrequency } from './Style'
import { ratingList } from 'keys'
import { Tooltip } from '@mui/material'
import { ScrollBox } from 'styles/GlobalStyle'
import { LinguisticLabContext } from './LinguisticLabContext'

const WordDefsWithFrequency = (props) => {
    const { word_defs } = props

    const { setSelectedWordDef } = useContext(LinguisticLabContext)

    const { Layout } = useTheme()
    const { frequency } = labHooks.constants

    return (
        <ScrollBox>
            <div style={{
                flexWrap: "wrap", ...Layout.lcGap, ...Layout.lrGap, ...Layout.flex
            }}>
                {word_defs?.map((word) =>
                    <Tooltip title={frequency[word.frequency_index].label} placement='top'>
                        <WordWithFrequency onClick={() => setSelectedWordDef(word.word_def_id)} color={ratingList[word.frequency_index]} style={{ height: '30px', width: "80px", ...Layout.rowCenter }}>
                            {word.word_def_text}
                        </WordWithFrequency>
                    </Tooltip>
                )}
            </div>
        </ScrollBox>
    )
}

export default WordDefsWithFrequency