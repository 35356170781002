import { EditNote, GraphicEq, AssessmentOutlined, Girl, Close, BookmarkAdded, RecordVoiceOver, Home } from '@mui/icons-material';
import { AbilityContext } from 'components/Permissions/Can';
import { allAssessments, adminAssessments, markingWords, assessmentDefs, assessments, createAssessments, testTakers, rating, learningResources, linguisticLab, transcription, audioCutting, dashboard, manage, app } from "components/Router/routes"
import { useContext } from 'react';

const SideBarData = () => {
    const ability = useContext(AbilityContext)

    const menuItems = [
        {
            index: 0,
            id: 0,
            label: "בית",
            path: dashboard,
            icon: Home,
            cleanTestTaker: true,
            feature: "dashboard"
        },
        {
            index: 1,
            id: 1,
            label: ability.can("read", "student-label") ? "תלמידים" : "ילדים",
            path: testTakers,
            icon: Girl,
            cleanTestTaker: true,

            feature: "test-takers"
        },
        {
            index: 2,
            id: 2,
            label: "אבחונים פעילים",
            path: allAssessments,
            icon: EditNote,
            cleanTestTaker: true,

            feature: "all-assessments"
        },
        {
            index: 3,
            id: 3,
            label: "דוחות",
            path: assessments,
            icon: GraphicEq,
            cleanTestTaker: true,

            feature: "assessments"
        },

        {
            index: 4,
            id: 4,
            label: "מערכת תעתוק",
            path: adminAssessments,
            icon: BookmarkAdded,
            cleanTestTaker: true,

            feature: "admin-assessments",
            subItems: [
                {
                    index: 100,
                    id: 100,
                    label: "סימון מילים",
                    path: app.markingWordsAssessments,
                    icon: BookmarkAdded,
                    feature: "admin-assessments",
                },
                {
                    index: 101,
                    id: 101,
                    label: "חיתוך אודיו",
                    path: app.audioCuttingAssessments,
                    icon: BookmarkAdded,
                    feature: "audio-cutting",
                },
                {
                    index: 102,
                    id: 102,
                    label: "תעתוק",
                    path: app.transcriptionAssessments,
                    icon: BookmarkAdded,
                    feature: "transcription"
                }
            ]
        },
        {
            index: 5,
            id: 5,
            label: "המעבדה הקולית",
            path: learningResources,
            icon: AssessmentOutlined,
            feature: "voice-lab"
        },
        {
            index: 6,
            id: 6,
            label: "המעבדה הלשונית",
            path: linguisticLab,
            icon: RecordVoiceOver,
            feature: "linguistic-lab"
        },
    ]

    return {
        menuItems
    }
}

export default SideBarData