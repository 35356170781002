import { useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"
import { loading as loadingKeys } from "keys"
import { UploadAudioCrud } from "."

const UploadAudioData = () => {
    const { createAssessmentWithFile, cleanAssessmentForm } = UploadAudioCrud()
    const { selectedAssessmentDef, createBatteryIsRun } = useSelector(state => state.assessment)
    const { loading } = useSelector(state => state.general)

    const footerBtns = [
        {
            id: 2,
            color: "dark",
            label: "ניקוי",
            action: cleanAssessmentForm,
            disabled: !selectedAssessmentDef,
            disabledColor: "dark",
            width: '150px',
        },
        {
            id: 1,
            color: "secondary",
            label: loading[loadingKeys.createAssessmentWithFile] ? <CircularProgress color="white" size={20} /> : "העלאה",
            action: createAssessmentWithFile,
            disabled: !selectedAssessmentDef?.audio_file,
            disabledColor: "secondary",
            width: '150px',
            // loading: loading[loadingKeys.createAssessmentWithFile]
        },

    ]

    const title = "בחר מבדק והעלה קובץ"

    return {
        title,
        footerBtns
    }
}

export default UploadAudioData