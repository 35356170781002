import React from 'react';
import validate from './Validate';

import { CreateAssessmentFormCrud, CreateAssessmentFormData } from './hooks';
import { Form } from 'components/Form';
import { WrapCreateAssessment, csswrap } from './Style'

const BasicTabs = () => {
    const { formName, fields, submitBtns } = CreateAssessmentFormData()
    const { createAssessment } = CreateAssessmentFormCrud()

    return (
        <WrapCreateAssessment>
            <Form
                form={formName}
                title={null}
                validations={validate}
                fields={fields}
                submitBtns={submitBtns}
                submit={createAssessment}
                csswrap={csswrap}
            />
        </WrapCreateAssessment>
    );
}

export default BasicTabs