import { styled as muiStyled } from '@mui/material/styles';
import { Dialog, DialogActions } from "@mui/material";
import { bg } from 'styles/theme';
import { borderRadius, padding } from '@mui/system';
import { Scroll } from 'styles/GlobalCss';


const WrapDialogActions = muiStyled(DialogActions)(({ theme }) => ({
    justifyContent: 'flex-start',
    padding: "0px 24px 15px 24px"
}));


const WrapDialog = muiStyled(Dialog)(({ theme, $systemUI }) => ({
    overflow: "hidden",
    '.MuiPaper-root': {
        background: $systemUI && bg,
        borderRadius: 35,
        overflow: "hidden",
    },
    '.MuiDialogContent-root': {
        paddingTop: '3px !important'
    }
}));

export { WrapDialogActions, WrapDialog }