import React from 'react'
import { ConfirmAlert } from 'components/Alerts'
import adminAssessmentsHooks from './hooks'

const BackToConfirmation = () => {
    const backToConfirmation = adminAssessmentsHooks.useBackToConfirmation()

    return (
        <ConfirmAlert  {...backToConfirmation} />
    )
}

export default BackToConfirmation